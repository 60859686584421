import React, { useState, useEffect, useRef } from "react"
import { useRouteMatch, Switch, Route, useHistory } from "react-router-dom"

// SEO
import generalSeoSchema from "../generalSeoSchema"
import { Helmet } from "react-helmet"

// Redux
import { useSelector, useDispatch } from "react-redux"
import * as clientsActions from "../clients/state/clientsActions"
import * as clickFunnelActions from "./state/clickFunnelActions"

// Firebase
import { ref, listAll, getDownloadURL } from "firebase/storage"
import { firebaseStorage } from "../firebase"

// Components
import ProgressBar from "./ProgressBar"
import JobCard from "../jobs/components/jobsListPage/JobCard"

// Material UI
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { Grid, Container, useMediaQuery } from "@material-ui/core"

// CSS
import clickFunnelStyles from "./clickFunnelStyles"

const useStyles = makeStyles(theme => clickFunnelStyles(theme))

// Component
const ClickFunnelClient = () => {
	// Hooks
	const { path } = useRouteMatch()
	const styles = useStyles()
	const history = useHistory()
	const theme = useTheme()
	const dispatch = useDispatch()

	// Media queries
	const mediaQueryMdUp = useMediaQuery(theme.breakpoints.up("md"))

	// References
	const anchorRef = useRef(null)

	// Local state
	const [logoSrcs, setLogoSrcs] = useState([])
	const [showFunnel, setShowFunnel] = useState(false)

	// Redux selectors
	const jobsReducer = useSelector(state => state.jobsReducer)
	const authReducer = useSelector(state => state.authReducer)
	const profileReducer = useSelector(state => state.profileReducer)
	const clientsReducer = useSelector(state => state.clientsReducer)

	// URL Parameters
	const queryString = window.location.search
	const urlParams = new URLSearchParams(queryString)
	const clientId = urlParams.get("clientId")

	// Getting client data
	useEffect(() => {
		if (clientId) {
			dispatch(clientsActions.asyncGetClientDataAction(clientId))
		}
	}, [dispatch, clientId])

	// Showing funnel if client data collected
	useEffect(() => {
		if (clientsReducer?.loadedClientData) {
			setShowFunnel(true)
		}
	}, [clientsReducer, dispatch])

	// Redirecting to job/s when logged in
	useEffect(() => {
		if (profileReducer?.changes?.userType === "affiliate") {
		} else if (
			jobsReducer.job === "invalid id" &&
			authReducer.userLoggedIn
		) {
			history.push("/jobs")
		}
	}, [
		profileReducer.changes,
		authReducer.userLoggedIn,
		jobsReducer.job,
		history,
	])

	// Get logos
	useEffect(() => {
		if (clientsReducer?.job_openings?.length > 0) {
			let allLogosSrc = []

			clientsReducer.job_openings.forEach(job => {
				const storageRef = ref(
					firebaseStorage,
					`JobOpenings/${job.zoho_job_openings_id}`
				)

				listAll(storageRef)
					.then(result => {
						if (result.items.length > 0) {
							const companyLogo = result.items.filter(el =>
								el.fullPath.includes("logo")
							)
							if (companyLogo.length > 0) {
								getDownloadURL(companyLogo[0])
									.then(url => {
										allLogosSrc = [
											...allLogosSrc,
											{
												url: url,
												zoho_job_openings_id:
													job.zoho_job_openings_id,
											},
										]
										setLogoSrcs(allLogosSrc)
									})
									.catch(error => {
										// Handle any errors
									})
							}
						}
					})
					.catch(error => {
						console.log(error)
					})
			})
		}
	}, [clientsReducer.job_openings])

	// Cleanup upon unmount
	useEffect(() => {
		return () => {
			dispatch(clickFunnelActions.clearReducerAction())
		}
	}, [dispatch])

	// Render
	return (
		<Switch>
			<Route exact path={path}>
				{showFunnel && (
					<div
						className={
							mediaQueryMdUp
								? styles.clickFunnelContainerContentLeft
								: styles.clickFunnelContainer
						}
					>
						{/* SEO */}
						<Helmet>
							<title>Workbee - Registrierungsformular</title>
							<meta
								name="title"
								content="Workbee - Registrierungsformular"
							/>
							<meta
								name="description"
								content="Workbee - Registrierungsformular"
							/>
							<script type="application/ld+json">
								{JSON.stringify(generalSeoSchema)}
							</script>
						</Helmet>

						{/* Header */}
						<div ref={anchorRef}></div>

						<Container
							maxWidth="sm"
							className={styles.topContainer}
						>
							{/* Progress Bar */}
							<ProgressBar
								formSlide={0}
								funnelType={"aspecific"}
							/>

							{/* Slide Main Text */}
							<h2>
								Du bewirbst dich bei{" "}
								{clientsReducer.company_name_app}
							</h2>

							{/* Slide Secondary Text */}
							<p stylestarget="secondaryText">
								Auf welche Stellenausschreibung möchtest du dich
								bewerben?
							</p>

							{/* Jobs */}
							<Grid
								container
								className={
									styles.clientFormJobCardGridContainer
								}
							>
								{clientsReducer?.job_openings?.length > 0 && (
									<JobCard
										jobs={clientsReducer.job_openings}
										url={"/jobs"}
										logoSrcs={logoSrcs}
										openInNewTab={false}
										clientFunnelPage={true}
									/>
								)}
							</Grid>
						</Container>
					</div>
				)}
			</Route>
		</Switch>
	)
}

export default ClickFunnelClient
