import React from "react"

// Components
import ApplicationFilterChip from "./ApplicationFilterChip"

// Material UI
import { makeStyles } from "@material-ui/core/styles"
import { Grid } from "@material-ui/core"

// Styles
const useStyles = makeStyles({
	filteringChipGridContainer: {
		flexWrap: "nowrap",
		overflowX: "auto",
		overflowY: "hidden",
		scrollbarWidth: "none",
		msOverflowStyle: "none",
		"&::-webkit-scrollbar": {
			display: "none",
		},
	},
	filteringChipGridItem: {
		marginRight: 5,
	},
})

// Component
const FilterChips = props => {
	// Destructuring props
	const { setApplicationStatusFilter, applicationStatusFilter } = props

	// Hooks
	const styles = useStyles()

	// Render
	return (
		<Grid container className={styles.filteringChipGridContainer}>
			<Grid item className={styles.filteringChipGridItem}>
				<ApplicationFilterChip
					fieldName="ALLE BEWERBUNGEN"
					applicationStatusFilter={applicationStatusFilter}
					setApplicationStatusFilter={setApplicationStatusFilter}
				/>
			</Grid>
			<Grid item className={styles.filteringChipGridItem}>
				<ApplicationFilterChip
					fieldName="AKTIV"
					applicationStatusFilter={applicationStatusFilter}
					setApplicationStatusFilter={setApplicationStatusFilter}
				/>
			</Grid>
			<Grid item className={styles.filteringChipGridItem}>
				<ApplicationFilterChip
					fieldName="INAKTIV"
					applicationStatusFilter={applicationStatusFilter}
					setApplicationStatusFilter={setApplicationStatusFilter}
				/>
			</Grid>
		</Grid>
	)
}

export default FilterChips
