const slideMainText = {
	0: "Wir wollen verstehen, was dir wichtig ist.",
	1: "Erzähl uns, wonach du suchst.",
	2: "Zu den Themen Zeit und Geld.",
	3: "Wir müssen noch ein paar Formalia klären.",
	4: "Erzähl uns von deinem bisherigen Berufsleben.",
	5: "Letzte Frage: Wie können wir dich erreichen?",
}

export const slideMainTextSpecific = {
	0: "Wir wollen verstehen, was dir wichtig ist.",
	1: "Zu den Themen Zeit und Geld.",
	2: "Wir müssen noch ein paar Formalia klären.",
	3: "Erzähl uns von deinem bisherigen Berufsleben.",
	4: "Letzte Frage: Wie können wir dich erreichen?",
}

export const slideMainTextAffiliate = {
	5: "Beero werden und 500 € Wunschgutschein sichern",
}

export default slideMainText
