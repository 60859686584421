import React, { useState } from "react"
import { useHistory } from "react-router-dom"

// Components
import LoginElement from "../../../auth/LoginElement"

// Redux
import { useDispatch, useSelector } from "react-redux"
import * as jobsActions from "../../state/jobsActions"

// Material UI
import { makeStyles } from "@material-ui/core/styles"
import { Dialog, Grid, useMediaQuery, Divider, Paper } from "@material-ui/core"

// Material UI icons
import EmailIcon from "@material-ui/icons/Email"
import CloseIcon from "@material-ui/icons/Close"

// Icons
import shareModalApplyNurse from "../../../images/shareModalApplyNurse.png"
import shareModalApplyAffiliate from "../../../images/shareModalApplyAffiliate.png"

// Styles
const useStyles = makeStyles({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: "rgba(0, 0, 0, 0.3)",
		fontFamily: "Nunito",
		"& .MuiPaper-root": {
			borderRadius: 12,
		},
	},
	modalGridItem: {
		padding: 16,
		paddingTop: 0,
		marginTop: 16,
	},
	paperSmall: {
		width: "90%",
		backgroundColor: "#fff",
		borderRadius: 12,
		border: "solid 1px #e8e8e8",
		"&:focus": {
			outline: "none",
		},
	},
	paper: {
		width: 360,
		backgroundColor: "#fff",
		borderRadius: 12,
		border: "solid 1px #e8e8e8",
		"&:focus": {
			outline: "none",
		},
	},
	modalTitle: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		marginTop: 0,
		marginBottom: 8,
		fontSize: 16,
		fontWeight: 600,
	},
	closeIcon: {
		cursor: "pointer",
	},
	modalParagraph: {
		fontSize: 12,
		fontWeight: 600,
		margin: 0,
	},
	modalParagraphAGB: {
		fontSize: 12,
		fontWeight: 600,
		margin: 0,
		marginTop: 10,
	},
	agbLink: {
		textDecoration: "none",
		color: "#3a5dab",
	},
	divider: {
		width: "100%",
	},
	// login
	emailIconGridItem: {
		display: "flex",
		justifyContent: "center",
		marginTop: 10,
	},
	emailSentContainer: {
		marginTop: 20,
		borderRadius: 12,
	},
	emailSentText: {
		padding: 12,
		margin: 0,
	},
	// funnel
	funnelBtnGridContainer: {
		marginTop: 8,
		borderRadius: 12,
		border: "solid 1px #e8e8e8",
		cursor: "pointer",
	},
	funnelBtnGridItemIcon: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	funnelBtnIcon: {
		width: 24,
		height: 24,
	},
	funnelBtnGridItemText: {
		display: "flex",
		flexDirection: "column",
	},
	funnelBtnGridItemTextHeader: {
		marginTop: 12,
		marginBottom: 0,
		fontSize: 14,
		fontWeight: 600,
		color: "#3a5dab",
	},
	funnelBtnGridItemTextParagraph: {
		width: "95%",
		fontSize: 10,
		margin: 0,
		marginBottom: 12,
	},
})

// Component
const JobShareAuthModal = () => {
	// Hooks
	const styles = useStyles()
	const history = useHistory()
	const dispatch = useDispatch()

	// Media queries
	const mediaQueryMdUp = useMediaQuery("(min-width:380px)")

	// Redux selectors
	const jobsReducer = useSelector(state => state.jobsReducer)

	// Local state
	const [showEmailSentInfo, setShowEmailSentInfo] = useState(false)

	// Render
	return (
		<Dialog
			open={jobsReducer.jobShareAuthModalOpen}
			onClose={() => {
				dispatch(
					jobsActions.setJobShareAuthModalAction({
						isModalOpen: false,
					})
				)
				setShowEmailSentInfo(false)
			}}
			className={styles.modal}
		>
			<div className={mediaQueryMdUp ? styles.paper : styles.paperSmall}>
				<Grid container>
					{/* Title */}
					<Grid item xs={12} className={styles.modalGridItem}>
						<h3 className={styles.modalTitle}>
							Job teilen und 500 € Prämie erhalten
							<CloseIcon
								className={styles.closeIcon}
								onClick={() => {
									dispatch(
										jobsActions.setJobShareAuthModalAction({
											isModalOpen: false,
										})
									)
									setShowEmailSentInfo(false)
								}}
							/>
						</h3>
						<p className={styles.modalParagraph}>
							In 3 einfachen Schritten <strong>500 €</strong>
							-Wunschgutschein erhalten mit dem
							Workbee-Empfehlungsprogramm: <br /> 1: Anmelden oder
							neu registrieren <br /> 2: Teile die Jobs über
							WhatsApp, Social Media oder wo auch immer! <br /> 3:{" "}
							<strong>500 €</strong>-Wunschgutschein erhalten,
							wenn ein/e Bewerber/in über deinen Link eingestellt
							wird
						</p>
						<p className={styles.modalParagraphAGB}>
							Klicke{" "}
							<a
								className={styles.agbLink}
								href="https://workbee.de/agb/#empfehlungsprogramm"
								target="_blank"
								rel="noreferrer"
							>
								hier
							</a>
							, um die AGB zu lesen.
						</p>
					</Grid>

					<Divider className={styles.divider} />

					{/* Login section */}
					<Grid item xs={12} className={styles.modalGridItem}>
						<LoginElement
							showRegisterText={false}
							location="shareModal"
							setShowEmailSentInfo={setShowEmailSentInfo}
						/>
						{showEmailSentInfo && (
							<Paper
								elevation={2}
								className={styles.emailSentContainer}
							>
								<Grid
									container
									spacing={1}
									className={styles.emailSentGridContainer}
								>
									<Grid
										item
										xs={12}
										className={styles.emailIconGridItem}
									>
										<EmailIcon />
									</Grid>
									<Grid item xs={12}>
										<p className={styles.emailSentText}>
											Wir haben dir eine E-Mail geschickt.
											Öffne sie und klicke auf den Link,
											um dich einzuloggen.
										</p>
									</Grid>
								</Grid>
							</Paper>
						)}
					</Grid>

					<Divider className={styles.divider} />

					{/* Registration section */}
					<Grid item xs={12} className={styles.modalGridItem}>
						<p className={styles.modalParagraph}>
							Du hast noch kein Workbee-Konto? Dann registriere
							dich jetzt als Bewerber/in oder Job-Empfehler/in.
						</p>

						{/* Regular user */}
						<Grid
							container
							className={styles.funnelBtnGridContainer}
							onClick={() => {
								dispatch(
									jobsActions.setJobShareAuthModalAction({
										isModalOpen: false,
									})
								)
								history.push("/form")
							}}
						>
							<Grid
								item
								xs={2}
								className={styles.funnelBtnGridItemIcon}
							>
								<img
									className={styles.funnelBtnIcon}
									src={shareModalApplyNurse}
									alt="apply as a nurse"
								/>
							</Grid>
							<Grid
								item
								xs={10}
								className={styles.funnelBtnGridItemText}
							>
								<h4
									className={
										styles.funnelBtnGridItemTextHeader
									}
								>
									Als Fach- oder Hilfskraft registrieren
								</h4>
								<p
									className={
										styles.funnelBtnGridItemTextParagraph
									}
								>
									Erhalte selbst Jobangebote und empfehle Jobs
									an Personen aus deinem Netzwerk. Im Falle
									einer Einstellung über das
									Workbee-Empfehlungsprogramm verdienst du
									einen <strong>500 €</strong>
									-Wunschgutschein. Wichtig: Hiermit bewirbst
									du dich nicht auf die angezeigte Stelle.

								</p>
							</Grid>
						</Grid>

						{/* Affiliate user */}
						<Grid
							container
							className={styles.funnelBtnGridContainer}
							onClick={() => {
								dispatch(
									jobsActions.setJobShareAuthModalAction({
										isModalOpen: false,
									})
								)
								history.push("/form?type=beero")
							}}
						>
							<Grid
								item
								xs={2}
								className={styles.funnelBtnGridItemIcon}
							>
								<img
									className={styles.funnelBtnIcon}
									src={shareModalApplyAffiliate}
									alt="apply as a affiliate"
								/>
							</Grid>
							<Grid
								item
								xs={10}
								className={styles.funnelBtnGridItemText}
							>
								<h4
									className={
										styles.funnelBtnGridItemTextHeader
									}
								>
									Als Job-Empfehler/in registrieren
								</h4>
								<p
									className={
										styles.funnelBtnGridItemTextParagraph
									}
								>
									Jobs an Personen aus deinem Netzwerk
									empfehlen und im Falle einer Einstellung
									über das Workbee-Empfehlungsprogramm einen{" "}
									<strong>500 €</strong>-Wunschgutschein
									verdienen.
								</p>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</div>
		</Dialog>
	)
}

export default JobShareAuthModal
