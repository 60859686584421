import React, { useEffect, useState } from "react"

// Data structures
import { slideHighestValue } from "../dataStructures/questionsStructure"
import { slideHighestValueSpecific } from "../dataStructures/questionsStructureSpecific"

// Material UI
import { makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"

// Components
import RegisterBtn from "./RegisterBtn"

// Redux
import { useSelector } from "react-redux"

// Hooks
import useCanGoForward from "../funnelLogicHooks/useCanGoForward"

// CSS
const useStyles = makeStyles({
	btnsContainer: {
		marginBottom: 64,
	},
	btnsGridContainer: {
		justifyContent: "space-between",
	},
	btnsGridContainerFirstSlide: {
		justifyContent: "flex-end",
	},
	nextBtn: {
		width: 140,
		height: 42,
		borderRadius: 12,
		backgroundColor: "#3a5dab",
		"&:disabled": {
			backgroundColor: "#3a5dab",
			opacity: 0.3,
		},
		"&:hover": {
			color: "#fafafa",
			backgroundColor: "#3a5dab",
		},
		"& span": {
			fontFamily: "'Nunito', sans-serif",
			fontSize: 16,
			fontWeight: 600,
			lineHeight: 1.25,
			color: "#fafafa",
		},
	},
	nextBtnSmall: {
		width: 100,
		height: 34,
		borderRadius: 12,
		backgroundColor: "#3a5dab",
		"&:disabled": {
			backgroundColor: "#3a5dab",
			opacity: 0.3,
		},
		"&:hover": {
			color: "#fafafa",
			backgroundColor: "#3a5dab",
		},
		"& span": {
			fontFamily: "'Nunito', sans-serif",
			fontSize: 14,
			fontWeight: 600,
			lineHeight: 1.25,
			color: "#fafafa",
		},
	},
	backBtn: {
		width: 140,
		height: 42,
		borderRadius: 12,
		border: "solid 1px #e8e8e8",
		backgroundColor: "#fff",
		"&:hover": {
			color: "#110c08",
			backgroundColor: "#fff",
		},
		"& span": {
			fontFamily: "'Nunito', sans-serif",
			fontSize: 16,
			fontWeight: 600,
			lineHeight: 1.25,
			color: "#110c08",
		},
	},
	backBtnSmall: {
		width: 100,
		height: 34,
		borderRadius: 12,
		border: "solid 1px #e8e8e8",
		backgroundColor: "#fff",
		"&:hover": {
			color: "#110c08",
			backgroundColor: "#fff",
		},
		"& span": {
			fontFamily: "'Nunito', sans-serif",
			fontSize: 14,
			fontWeight: 600,
			lineHeight: 1.25,
			color: "#110c08",
		},
	},
	registerAndAgbContainer: {
		marginBottom: 186,
	},
})

// Component
const NextPreviousBtns = props => {
	// Hooks
	const { canGoForward } = useCanGoForward()
	const styles = useStyles()
	const mediaQueryMdUp = useMediaQuery("(min-width:350px)")

	// Destructuring props
	const { formSlide, setSlide, questionTracker, fromProfile, funnelType } =
		props

	// Redux selectors
	const clickFunnelReducer = useSelector(state => state.clickFunnelReducer)

	// Local state
	const [nextBtnDisabled, setNextBtnDisabled] = useState(true)
	const [slideHighestValueDynamic, setSlideHighestValueDynamic] =
		useState(slideHighestValue)

	// (dis)Allowing to move to the next slide
	useEffect(() => {
		if (canGoForward(props)) {
			setNextBtnDisabled(false)
		} else {
			setNextBtnDisabled(true)
		}
	}, [clickFunnelReducer, formSlide, questionTracker, canGoForward, props])

	// Setting slide highest value, depending on funnel type
	useEffect(() => {
		if (funnelType === "specific") {
			setSlideHighestValueDynamic(slideHighestValueSpecific)
		}
	}, [setSlideHighestValueDynamic, funnelType])

	// Render
	return (
		<Container maxWidth="sm" className={styles.btnsContainer}>
			{/* Register btn */}
			{formSlide === slideHighestValueDynamic && (
				<Grid
					container
					direction="column"
					className={styles.registerAndAgbContainer}
				>
					<Grid item xs={12}>
						<RegisterBtn
							fromProfile={fromProfile}
							formSlide={formSlide}
							questionTracker={questionTracker}
							funnelType={funnelType}
						/>
					</Grid>
				</Grid>
			)}

			{/* Next/Previous btns */}
			<Grid
				container
				direction="row"
				className={
					formSlide === 0
						? styles.btnsGridContainerFirstSlide
						: styles.btnsGridContainer
				}
			>
				{/* Back btn */}
				{formSlide > 0 && funnelType !== "beero" && (
					<Grid item>
						<Button
							id="clickFunnelPreviousBtn"
							variant="outlined"
							color="primary"
							disableRipple
							disableTouchRipple
							className={
								mediaQueryMdUp
									? styles.backBtn
									: styles.backBtnSmall
							}
							onClick={() => {
								setSlide(formSlide - 1)
							}}
						>
							Zurück
						</Button>
					</Grid>
				)}

				{/* Next btn */}
				{formSlide < questionTracker.length &&
					formSlide !== slideHighestValueDynamic && (
						<Grid item>
							<Button
								id="clickFunnelNextBtn"
								disabled={nextBtnDisabled}
								variant="contained"
								color="secondary"
								disableRipple
								disableTouchRipple
								className={
									mediaQueryMdUp
										? styles.nextBtn
										: styles.nextBtnSmall
								}
								onClick={() => {
									setSlide(formSlide + 1)
								}}
							>
								Weiter
							</Button>
						</Grid>
					)}
			</Grid>
		</Container>
	)
}

export default NextPreviousBtns
