import React from "react"
import logoOutlined from "../images/logoOutlined.png"

// Material UI
import { makeStyles } from "@material-ui/core/styles"
import { Button } from "@material-ui/core"

// Redux
import { useDispatch, useSelector } from "react-redux"
import * as tutorialActions from "./state/tutorialActions"

// Styles
const useStyles = makeStyles({
	overlay: {
		position: "fixed",
		top: "0",
		left: "0",
		width: "100%",
		height: "100%",
		backgroundColor: "rgba(0, 0, 0, 0.8)",
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		alignItems: "center",
		color: "white",
		zIndex: "9999",
		padding: "20px",
		boxSizing: "border-box",
		textAlign: "center",
	},
	paragraph: {
		color: "black",
		"& strong": {
			color: "black",
		},
	},
	button: {
		position: "absolute",
		bottom: 25,
		width: "80%",
		maxWidth: 300,
		padding: 10,
		border: "none",
		cursor: "pointer",
		textAlign: "center",
		borderRadius: 12,
		fontFamily: "'Nunito', sans-serif",
		fontSize: 16,
		color: "#fafafa",
		backgroundColor: "#3a5dab",
		"&:hover": {
			color: "#fafafa",
			backgroundColor: "#3a5dab",
		},
	},
	logoContainer: {
		marginTop: 100,
		display: "flex",
		width: "100%",
		maxWidth: 350,
	},
	speechBubble: {
		fontSize: "1.1em",
		color: "#fff",
		background: "#fafafa",
		position: "relative",
		padding: "20px",
		borderRadius: "10px",
		margin: "25px auto",
		maxWidth: 350,
		"&::after": {
			content: '""',
			border: "20px solid transparent",
			position: "absolute",
		},
		"&.top::after": {
			borderBottomColor: "#fafafa",
			borderTop: "0",
			top: "-20px",
			left: "15%",
			marginLeft: "-20px",
		},
	},
})

const TutorialScreen = () => {
	// Hooks
	const styles = useStyles()
	const dispatch = useDispatch()

	// Redux selectors
	const tutorialReducer = useSelector(state => state.tutorialReducer)
	const jobsReducer = useSelector(state => state.jobsReducer)

	// Render
	if (tutorialReducer?.open) {
		return (
			<div
				onClick={() => {
					dispatch(tutorialActions.setOpenAction({ open: false }))
				}}
				className={styles.overlay}
			>
				<div className={styles.logoContainer}>
					<img
						src={logoOutlined}
						className={styles.logo}
						alt="app logo"
					/>
				</div>
				<div className={`${styles.speechBubble} top`}>
					{tutorialReducer?.type &&
						tutorialReducer?.type ===
							"specificCandidateRegistered" && (
							<p className={styles.paragraph}>
								Herzlichen Glückwunsch zu deiner Bewerbung bei{" "}
								<strong>
									{jobsReducer?.job?.company_name_app
										? jobsReducer?.job?.company_name_app
										: jobsReducer?.job?.client_name}
								</strong>
								! In deinem neuen Konto bei Workbee kannst du
								jederzeit nach weiteren passenden Jobs suchen.
								Hier siehst du die Jobs, die unser{" "}
								<strong>Passt-Genau-Filter</strong> für dich
								raus gesucht hat. <br /> <br />
								Übrigens: Bei Workbee musst du kein Passwort
								kreieren, sondern erhältst jedes Mal einen neuen
								Link zum Einloggen per Email.
							</p>
						)}
					{tutorialReducer?.type &&
						tutorialReducer?.type ===
							"aspecificCandidateRegistered" && (
							<p className={styles.paragraph}>
								Herzlich Willkommen bei Workbee! In deinem neuen
								Konto kannst du jederzeit nach weiteren
								passenden Jobs suchen. Hier siehst du die Jobs,
								die unser <strong>Passt-Genau-Filter</strong>{" "}
								für dich raus gesucht hat.
								<br />
								<br />
								Übrigens: Bei Workbee musst du kein Passwort
								kreieren, sondern erhältst jedes Mal einen neuen
								Link zum Einloggen per Email.
							</p>
						)}
				</div>
				<Button
					variant="contained"
					color="primary"
					className={styles.button}
					id="tutorialAcceptBtn"
				>
					Verstanden
				</Button>
			</div>
		)
	} else {
		return null
	}
}

export default TutorialScreen
