import React, { useState, useEffect, useRef } from "react"

// Material UI
import { useTheme, makeStyles } from "@material-ui/core/styles"
import {
	Modal,
	Grid,
	useMediaQuery,
	Divider,
	Button,
	Radio,
	RadioGroup,
	FormControlLabel,
	FormControl,
} from "@material-ui/core"

// Capacitor
import { Capacitor } from "@capacitor/core"

// Components
import FormGroup from "./FilterFormGroup"
import LocationField from "./LocationField"
import MultipleChoiceField from "./MultipleChoiceField"

// Redux
import { useDispatch, useSelector } from "react-redux"
import * as jobsActions from "../../../state/jobsActions"
import * as tagManagerActions from "../../../../tagManagerEvents/state/tagManagerActions"

// Data structures
import fieldOptions from "./fieldOptions"

// Styles
const useStyles = makeStyles(theme => ({
	modal: {
		fontFamily: "'Nunito', sans-serif",
		paddingTop: "env(safe-area-inset-top)",
		marginBottom: "env(safe-area-inset-bottom)",
	},
	modalGridContainerApple: {
		backgroundColor: theme.palette.background.paper,
		height: "80vh",
		margin: "24px 16px",
		width: "calc(100% - 32px)",
		overflow: "auto",
		borderRadius: 4,
	},
	modalGridContainerMobile: {
		backgroundColor: theme.palette.background.paper,
		height: "calc(100vh - 48px)",
		margin: "24px 16px",
		width: "calc(100% - 32px)",
		overflow: "auto",
		borderRadius: 4,
	},
	modalGridItemHidden: {
		display: "none",
	},
	modalGridItemBtnLeft: {
		display: "flex",
		justifyContent: "center",
		borderRight: "1px solid rgba(0,0,0,0.12) !important",
	},
	modalGridItemBtnRight: {
		display: "flex",
		justifyContent: "center",
	},
	modalGridContainerPc: {
		backgroundColor: theme.palette.background.paper,
		margin: "0 auto",
		height: "calc(100vh - 48px)",
		overflow: "auto",
		marginTop: 24,
		marginBottom: 24,
		maxWidth: 600,
		borderRadius: 4,
	},
	formControl: {
		marginBottom: 26,
	},
	divider: {
		width: "100%",
	},
	title: {
		fontSize: 16,
		fontWeight: "normal",
		marginTop: 2,
		marginBottom: 2,
	},
	optionName: {
		marginTop: 0,
		marginBottom: 0,
		fontSize: 16,
		fontWeight: "bold",
	},
	modalBtn: {
		display: "flex",
		fontSize: 16,
		fontWeight: 400,
		color: "#3a5dab",
	},
}))

// Component
const JobFilterModal = () => {
	// Hooks
	const dispatch = useDispatch()
	const styles = useStyles()
	const theme = useTheme()

	// Refs
	const isFirstRender = useRef(true)
	const anchorRef = useRef(null)
	const jobsFilterModalRef = useRef(null)

	// Destructuring funnel field options
	const { contract_type, employment_form, shifts } = fieldOptions

	// Media Queries
	const mediaQueryMdUp = useMediaQuery(theme.breakpoints.up("sm"))

	// Redux selectors
	const jobsReducer = useSelector(state => state.jobsReducer)
	const authReducer = useSelector(state => state.authReducer)
	const profileReducer = useSelector(state => state.profileReducer)

	// Local state
	const [workField, setWorkField] = useState(null)

	// Field Name Mapper
	const fieldNameMapper = {
		type_of_employer_educator: "type_of_employer",
		type_of_employer: "type_of_employer",
		contract_type: "contract_types",
		employment_form: "employment_forms",
		shifts: "shifts",
		qualification: "qualifications",
		qualification_educator: "qualifications",
		benefits: "benefits",
		locations: "locations",
		salary_expectation_month: "salary_expectation_month",
		work_field: "work_field",
	}

	// Scrolling to the top of the page when opening the modal
	useEffect(() => {
		if (jobsReducer.filterJobsModalOpen && jobsFilterModalRef) {
			setTimeout(() => {
				if (jobsFilterModalRef?.current) {
					jobsFilterModalRef.current.scrollIntoView({
						behavior: "smooth",
					})
				}
			})
		}
	}, [jobsReducer.filterJobsModalOpen])

	// Clearing all work field related fields after work field changes
	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false
			return
		}

		;[
			"qualification",
			"qualification_educator",
			"type_of_employer",
			"type_of_employer_educator",
		].forEach(fieldName => {
			dispatch(
				jobsActions.asyncSaveFilteringFieldAction(
					[],
					fieldNameMapper[fieldName]
				)
			)
		})
		// eslint-disable-next-line
	}, [workField, dispatch])

	// Render
	return (
		<Modal
			open={jobsReducer.filterJobsModalOpen}
			onClose={() => {}}
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			className={styles.modal}
			ref={jobsFilterModalRef}
			disableScrollLock={false}
		>
			<Grid
				container
				spacing={4}
				className={
					Capacitor.getPlatform() === "ios"
						? styles.modalGridContainerApple
						: mediaQueryMdUp
						? styles.modalGridContainerPc
						: styles.modalGridContainerMobile
				}
			>
				<Grid item xs={12} className={styles.modalGridItem}>
					<p className={styles.title}>Alle Filter</p>
				</Grid>

				<Divider className={styles.divider} />

				<Grid item xs={12}>
					<p className={styles.optionName}>Job-Vorteile</p>
					<MultipleChoiceField
						id={1}
						fieldName="benefits"
						fieldNameMapper={fieldNameMapper}
						anchorRef={anchorRef}
						trackingFilteringClickEvent={() => {
							dispatch(
								tagManagerActions.sendSimpleCustomEvent({
									eventName:
										"filterBenefitsSettingsChangedCustomEvent",
								})
							)
						}}
					/>
				</Grid>

				<Divider className={styles.divider} />

				<Grid item xs={12}>
					<p className={styles.optionName}>Berufsfeld</p>
					<FormControl
						component="fieldset"
						className={styles.formControl}
					>
						<RadioGroup
							aria-label="workField"
							name="workField"
							value={jobsReducer.filteringOptions.work_field}
							onChange={el => {
								let fieldValue = el.target.value
								setWorkField(fieldValue)
								dispatch(
									jobsActions.asyncSaveFilteringFieldAction(
										fieldValue,
										"work_field"
									)
								)
							}}
						>
							<FormControlLabel
								value="Pflege & Medizin"
								control={<Radio />}
								label="Pflege & Medizin"
							/>
							<FormControlLabel
								value="Pädagogik & Soziale Arbeit"
								control={<Radio />}
								label="Pädagogik & Soziale Arbeit"
							/>
						</RadioGroup>
					</FormControl>
					<p className={styles.optionName}>Qualifikation</p>
					<MultipleChoiceField
						id={2}
						fieldName={
							workField === "Pädagogik & Soziale Arbeit"
								? "qualification_educator"
								: "qualification"
						}
						fieldNameMapper={fieldNameMapper}
						anchorRef={anchorRef}
						trackingFilteringClickEvent={() => {
							dispatch(
								tagManagerActions.sendSimpleCustomEvent({
									eventName:
										"filterQualificationSettingsChangedCustomEvent",
								})
							)
						}}
					/>
				</Grid>

				<Divider className={styles.divider} />

				<Grid item xs={12}>
					<p className={styles.optionName}>
						Bevorzugte Einrichtungen
					</p>
					<MultipleChoiceField
						id={3}
						fieldName={
							workField === "Pädagogik & Soziale Arbeit"
								? "type_of_employer_educator"
								: "type_of_employer"
						}
						fieldNameMapper={fieldNameMapper}
						anchorRef={anchorRef}
						trackingFilteringClickEvent={() => {
							dispatch(
								tagManagerActions.sendSimpleCustomEvent({
									eventName:
										"filterTypeOfEmployerSettingsChangedCustomEvent",
								})
							)
						}}
					/>
				</Grid>

				<Divider className={styles.divider} />

				<Grid item xs={12}>
					<p className={styles.optionName}>
						Monatsgehalt (bei Vollzeit) ab
					</p>
					<MultipleChoiceField
						id={4}
						fieldName="salary_expectation_month"
						fieldNameMapper={fieldNameMapper}
						anchorRef={anchorRef}
						trackingFilteringClickEvent={() => {
							dispatch(
								tagManagerActions.sendSimpleCustomEvent({
									eventName:
										"filterSalaryExpectationsSettingsChangedCustomEvent",
								})
							)
						}}
					/>
				</Grid>

				<Divider className={styles.divider} />

				<Grid item xs={12}>
					<p className={styles.optionName}>Anstellungsart</p>
					<FormGroup
						fieldOptions={contract_type}
						fieldName="contract_type"
						fieldNameMapper={fieldNameMapper}
						trackingFilteringClickEvent={() => {
							dispatch(
								tagManagerActions.sendSimpleCustomEvent({
									eventName:
										"filterContractTypeSettingsChangedCustomEvent",
								})
							)
						}}
					/>
				</Grid>

				<Divider className={styles.divider} />

				<Grid item xs={12}>
					<p className={styles.optionName}>Umfang</p>
					<FormGroup
						fieldOptions={employment_form}
						fieldName="employment_form"
						fieldNameMapper={fieldNameMapper}
						trackingFilteringClickEvent={() => {
							dispatch(
								tagManagerActions.sendSimpleCustomEvent({
									eventName:
										"filterEmploymentFormSettingsChangedCustomEvent",
								})
							)
						}}
					/>
				</Grid>

				<Divider className={styles.divider} />

				<Grid item xs={12}>
					<p className={styles.optionName}>Schichten</p>
					<FormGroup
						fieldOptions={shifts}
						fieldName="shifts"
						fieldNameMapper={fieldNameMapper}
						trackingFilteringClickEvent={() => {
							dispatch(
								tagManagerActions.sendSimpleCustomEvent({
									eventName:
										"filterShiftsSettingsChangedCustomEvent",
								})
							)
						}}
					/>
				</Grid>

				<Divider className={styles.divider} />

				<Grid item xs={12}>
					<p className={styles.optionName}>Wunschort</p>
					<LocationField
						trackingFilteringClickEvent={() => {
							dispatch(
								tagManagerActions.sendSimpleCustomEvent({
									eventName:
										"filterLocationSettingsChangedCustomEvent",
								})
							)
						}}
					/>
				</Grid>

				<Divider className={styles.divider} />

				<Grid item xs={6} className={styles.modalGridItemBtnLeft}>
					<Button
						className={styles.modalBtn}
						onClick={() => {
							dispatch(
								jobsActions.setFilterJobsModalAction({
									isModalOpen: false,
								})
							)
							dispatch(
								tagManagerActions.sendSimpleCustomEvent({
									eventName: "filterCancelChangesCustomEvent",
								})
							)
						}}
					>
						Abbrechen
					</Button>
				</Grid>

				<Grid item xs={6} className={styles.modalGridItemBtnRight}>
					<Button
						id="saveFilterDataBtn"
						className={styles.modalBtn}
						onClick={() => {
							dispatch(
								jobsActions.setFilterJobsModalAction({
									isModalOpen: false,
								})
							)

							dispatch(jobsActions.removeJobsAction())
							dispatch(
								jobsActions.asyncGetJobsAction({
									page: 1,
									filteringOptions:
										jobsReducer.filteringOptions,
								})
							)

							dispatch(
								tagManagerActions.sendComplexCustomEvent({
									event: "filterModalSettingsChanged",
									isUserLoggedIn: authReducer?.userLoggedIn
										? "userLoggedIn"
										: "userLoggedOut",
									filterLocation:
										jobsReducer?.filteringOptions?.locations
											?.length > 0
											? jobsReducer.filteringOptions.locations.join(
													", "
											  )
											: "Deutschlandweit",
									filterQualification:
										jobsReducer?.filteringOptions
											?.qualifications?.length > 0
											? jobsReducer.filteringOptions.qualifications.join(
													", "
											  )
											: "Alle Qualifikationen",
									filterContractTypes:
										jobsReducer?.filteringOptions
											?.contract_types?.length > 0
											? jobsReducer.filteringOptions.contract_types.join(
													", "
											  )
											: "Alle",
									filterEmploymentForms:
										jobsReducer?.filteringOptions
											?.employment_forms?.length > 0
											? jobsReducer.filteringOptions.employment_forms.join(
													", "
											  )
											: "Alle",
									filterShifts:
										jobsReducer?.filteringOptions?.shifts
											?.length > 0
											? jobsReducer.filteringOptions.shifts.join(
													", "
											  )
											: "Alle",
									filterEmployerTypes:
										jobsReducer?.filteringOptions
											?.type_of_employer?.length > 0
											? jobsReducer.filteringOptions.type_of_employer.join(
													", "
											  )
											: "Alle",
									filterBenefits:
										jobsReducer?.filteringOptions?.benefits
											?.length > 0
											? jobsReducer.filteringOptions.benefits.join(
													", "
											  )
											: "Alle",
									zohoCandidateId: authReducer?.userLoggedIn
										? profileReducer.changes
												.zoho_candidate_id
										: "userLoggedOut",
								})
							)

							dispatch(
								tagManagerActions.sendSimpleCustomEvent({
									eventName: "filterApplyChangesCustomEvent",
								})
							)
						}}
					>
						Anwenden
					</Button>
				</Grid>
			</Grid>
		</Modal>
	)
}

export default JobFilterModal
