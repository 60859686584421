import React, { useState } from "react"

// Capacitor
import { Capacitor } from "@capacitor/core"

// Redux
import { useDispatch, useSelector } from "react-redux"
import * as profileActions from "../state/profileActions"

// Data structures
import fieldOptions from "../../jobs/components/jobsListPage/jobFilterModal/fieldOptions"

// Material UI
import { makeStyles } from "@material-ui/core/styles"
import {
	FormControl,
	InputLabel,
	Select,
	Grid,
	Divider,
	TextField,
	Button,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Checkbox,
} from "@material-ui/core"
import { useMediaQuery } from "@material-ui/core"

// Materal UI icons
import CheckIcon from "@material-ui/icons/Check"
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined"

// Styles
const useStyles = makeStyles({
	basicDataGridItem: {
		marginLeft: 16,
		marginRight: 16,
		marginBottom: 8,
	},
	gridFormItem: {
		marginBottom: 5,
		"& fieldset": {
			borderRadius: 12,
		},
	},
	formItem: {
		borderRadius: 12,
		width: "100%",
		backgroundColor: "white",
	},
	divider: {
		width: "90%",
		marginTop: 10,
		marginBottom: 15,
	},
	uploadBtn: {
		backgroundColor: "transparent",
		color: "#3a5dab",
		width: "100%",
		height: "100%",
		border: "1px solid #3a5dab",
		borderRadius: 12,
		"&:disabled": {
			backgroundColor: "transparent",
			opacity: 0.3,
			color: "#3a5dab",
		},
		"&:hover": {
			backgroundColor: "transparent",
			color: "#3a5dab",
		},
	},
	justify: {
		justifyContent: "center",
	},
	select: {
		minHeight: 52,
		backgroundColor: "white",
		borderRadius: 12,
		cursor: "pointer",
	},
	option: {
		paddingTop: 20,
		paddingBottom: 20,
		cursor: "pointer",
	},
	selectedOption: {
		backgroundColor: "rgba(245, 168, 0, 0.1) !important",
		fontWeight: 600,
	},
	selectedOptionNone: {
		backgroundColor: "rgba(255,255,255,1) !important",
	},
	invisibleIcon: {
		display: "none",
	},
	selectHeader: {
		position: "sticky",
		paddingTop: 8,
		top: 0,
		backgroundColor: "white",
		zIndex: 2,
		display: "flex",
		justifyContent: "end",
	},
	closeMultipleChoiceListBtn: {
		color: "#3a5dab",
		paddingRight: 12,
	},
	list: {
		padding: 0,
	},
})

// Component
const WorkExperienceField = props => {
	// Hooks
	const dispatch = useDispatch()
	const styles = useStyles()

	// Destructuring props
	const { anchorRef } = props

	// Local state
	const [selectOpen, setSelectOpen] = useState(null)

	// Redux selectors
	const fieldValue = useSelector(
		state => state.profileReducer.profileData.work_experience
	)

	// Media queries
	const mobileQuery = useMediaQuery("(max-width:445px)")

	// Variables
	const lastExperienceEntry = fieldValue[fieldValue.length - 1]

	// Field Options
	const fieldOptionsWorkExperience = ["btn", ...fieldOptions.work_experience]

	// Render
	return (
		<Grid item xs={12} className={styles.basicDataGridItem}>
			{fieldValue.map((company, id) => {
				return (
					<React.Fragment key={id}>
						<Grid container spacing={1} className={styles.justify}>
							<Grid item xs={12} className={styles.gridFormItem}>
								<TextField
									inputProps={{
										maxLength: 250,
										style: {
											WebkitBoxShadow:
												"0 0 0 1000px white inset",
											borderRadius: 12,
										},
									}}
									label="Name des Arbeitgebers (max. 250 Zeichen)"
									placeholder="Name des Arbeitgebers (max. 250 Zeichen)"
									id="companyNameExperienceField"
									className={styles.formItem}
									size="small"
									variant="outlined"
									onKeyDown={e => {
										if (e.key === "Enter") {
											e.target.blur()
										}
									}}
									value={
										company.company === null ||
										company.company === ""
											? ""
											: company.company
									}
									onChange={el => {
										let newVal = el.target.value
										dispatch(
											profileActions.changeExperienceProfileFieldAction(
												newVal,
												"company",
												id
											)
										)
									}}
								/>
							</Grid>
							<Grid item xs={12} className={styles.gridFormItem}>
								<Grid container spacing={1}>
									<Grid item xs={8}>
										<FormControl
											size="small"
											variant="outlined"
											className={styles.formItem}
										>
											<InputLabel id="durationLabel">
												Dauer
											</InputLabel>
											<Select
												labelId="durationLabel"
												label="Dauer"
												className={styles.formItem}
												open={selectOpen === id}
												onClose={() => {
													setSelectOpen(null)
												}}
												onOpen={() => {
													setSelectOpen(id)
												}}
												id="experienceDurationSelectInput"
												value={
													company.period_length ===
														null ||
													company.period_length ===
														"" ||
													!fieldOptions.work_experience.includes(
														company.period_length
													)
														? ""
														: company.period_length
												}
												onChange={el => {
													if (
														!el.target.value.includes(
															"btn"
														)
													) {
														let newVal =
															el.target.value

														dispatch(
															profileActions.changeExperienceProfileFieldAction(
																newVal,
																"period_length",
																id
															)
														)
													}
												}}
												renderValue={selected =>
													selected
												}
												MenuProps={
													mobileQuery
														? {
																classes: {
																	list: styles.list,
																},
																PaperProps: {
																	style: {
																		maxHeight:
																			"100vh",
																		minWidth: 200,
																		width: "100%",
																		bottom:
																			Capacitor.getPlatform() ===
																			"ios"
																				? "calc(16px + env(safe-area-inset-bottom))"
																				: 16,
																		top:
																			Capacitor.getPlatform() ===
																			"ios"
																				? "calc(16px + env(safe-area-inset-top))"
																				: 16,
																	},
																},
																marginThreshold: 0,
																variant: "menu",
																getContentAnchorEl:
																	null,
																anchorReference:
																	"anchorPosition",
																anchorPosition:
																	{
																		top:
																			Capacitor.getPlatform() ===
																			"ios"
																				? "calc(16px + env(safe-area-inset-top))"
																				: 16,
																		left: 16,
																		right: 16,
																	},
														  }
														: {
																classes: {
																	list: styles.list,
																},
																PaperProps: {
																	style: {
																		maxHeight:
																			"100vh",
																		minWidth: 200,
																		maxWidth: 552,
																		width: "100%",
																		bottom:
																			Capacitor.getPlatform() ===
																			"ios"
																				? "calc(16px + env(safe-area-inset-bottom))"
																				: 16,
																		top:
																			Capacitor.getPlatform() ===
																			"ios"
																				? "calc(16px + env(safe-area-inset-top))"
																				: 16,
																		marginTop: 16,
																	},
																},
																marginThreshold: 0,
																variant: "menu",
																getContentAnchorEl:
																	null,
																anchorEl:
																	anchorRef.current,
																anchorOrigin: {
																	horizontal:
																		"center",
																	vertical:
																		"center",
																},
																transformOrigin:
																	{
																		horizontal:
																			"center",
																		vertical:
																			"center",
																	},
														  }
												}
											>
												{fieldOptionsWorkExperience.map(
													(option, id) => {
														if (option === "btn") {
															return (
																<ListItem
																	key={option}
																	value={
																		option
																	}
																	className={
																		styles.selectHeader
																	}
																	classes={{
																		selected:
																			styles.selectedOptionNone,
																	}}
																	divider
																>
																	<Button
																		key="btn"
																		className={
																			styles.closeMultipleChoiceListBtn
																		}
																		id="closeMultipleChoiceListBtn"
																		color="primary"
																		onClick={() => {
																			setSelectOpen(
																				null
																			)
																		}}
																	>
																		Fertig
																	</Button>
																</ListItem>
															)
														} else {
															return (
																<ListItem
																	key={id}
																	value={
																		option
																	}
																	className={
																		styles.option
																	}
																	classes={{
																		selected:
																			styles.selectedOption,
																	}}
																	divider
																>
																	<ListItemText
																		primary={
																			option
																		}
																		disableTypography
																	/>

																	<ListItemSecondaryAction>
																		<Checkbox
																			color="secondary"
																			icon={
																				<CheckOutlinedIcon
																					className={
																						styles.invisibleIcon
																					}
																				/>
																			}
																			checkedIcon={
																				<CheckIcon />
																			}
																			checked={company[
																				"period_length"
																			].includes(
																				option
																			)}
																		/>
																	</ListItemSecondaryAction>
																</ListItem>
															)
														}
													}
												)}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={4}>
										<Button
											size="small"
											color="secondary"
											variant="contained"
											disabled={
												!fieldValue[id].client_name &&
												!fieldValue[id].period_length &&
												id === 0
											}
											className={styles.uploadBtn}
											onClick={() => {
												if (
													fieldValue[id] ===
														lastExperienceEntry &&
													!fieldValue[id]
														.client_name &&
													!fieldValue[id]
														.period_length
												) {
													dispatch(
														profileActions.removeExperienceProfileFieldAction(
															id
														)
													)
												} else if (
													fieldValue[id] ===
													lastExperienceEntry
												) {
													dispatch(
														profileActions.addExperienceProfileFieldAction()
													)
												} else {
													dispatch(
														profileActions.removeExperienceProfileFieldAction(
															id
														)
													)
												}
											}}
										>
											{fieldValue[id] ===
												lastExperienceEntry &&
											!fieldValue[id].client_name &&
											!fieldValue[id].period_length
												? "Entfernen"
												: fieldValue[id] ===
												  lastExperienceEntry
												? "Speichern"
												: "Entfernen"}
										</Button>
									</Grid>
								</Grid>
							</Grid>
							{id < fieldValue.length - 1 && (
								<Divider className={styles.divider} />
							)}
						</Grid>
					</React.Fragment>
				)
			})}
		</Grid>
	)
}

export default WorkExperienceField
