import React from "react"

// Data structures
import { profilePageFieldsAffiliate } from "./dataStructures/profilePageFields"
import standardProfileFieldNames from "./dataStructures/standardProfileFieldNames"
import funnelFieldOptions from "../clickFunnel/dataStructures/funnelFieldOptions"

// Redux
import { useSelector, useDispatch } from "react-redux"
import * as profileActions from "./state/profileActions"

// Material UI
import { makeStyles } from "@material-ui/core/styles"
import { Grid, TextField, InputAdornment, Button } from "@material-ui/core"

// Styles
const useStyles = makeStyles({
	basicDataGridItem: {
		marginLeft: 16,
		marginRight: 16,
		marginBottom: 8,
	},
	salutationGridContainer: {
		paddingLeft: 16,
		paddingRight: 16,
		paddingBottom: 8,
	},
	salutationGridItem: {
		display: "flex",
		"&:nth-child(1)": {
			justifyContent: "flex-start",
		},
		"&:nth-child(2)": {
			justifyContent: "center",
		},
		"&:nth-child(3)": {
			justifyContent: "flex-end",
		},
	},
	freeTextField: {
		"& .MuiOutlinedInput-notchedOutline": {
			border: "solid 1px #e8e8e8",
		},
	},
	option: {
		display: "flex",
		width: "95%",
		minHeight: 50,
		borderRadius: 12,
		justifyContent: "center",
		textTransform: "none",
		textAlign: "center",
		fontSize: 14,
		color: "#333333",
		backgroundColor: "white",
		border: "1px solid rgba(0,0,0,0.25)",
		wordBreak: "break-word",
		"&:hover": {
			backgroundColor: "white",
			border: "1px solid rgba(0,0,0,0.25)",
		},
	},
	optionSelected: {
		display: "flex",
		width: "95%",
		backgroundColor: "#fff5cc",
		border: "2px solid #e8b300",
		"&:hover": {
			backgroundColor: "#fff5cc",
			border: "2px solid #e8b300",
		},
	},
})

// Component
const ProfilePageFieldsAffiliate = props => {
	// Hooks
	const styles = useStyles()
	const dispatch = useDispatch()

	// Destructuring props
	let { setSaveBtnDisabled } = props

	// Redux selectors
	const profileReducer = useSelector(state => state.profileReducer)

	// Render
	return (
		<>
			{profilePageFieldsAffiliate.map((profileField, id) => {
				if (profileField.fieldType === "salutation") {
					return (
						<Grid item xs={12} key={id}>
							<Grid
								container
								className={styles.salutationGridContainer}
							>
								{funnelFieldOptions["salutation"].map(
									(option, id) => {
										return (
											<Grid
												item
												xs={4}
												key={id}
												className={
													styles.salutationGridItem
												}
											>
												<Button
													key={id}
													className={
														profileReducer
															.profileData[
															"salutation"
														] === option
															? `${styles.option} ${styles.optionSelected}`
															: styles.option
													}
													onClick={el => {
														let newVal =
															el.target.innerText

														dispatch(
															profileActions.changeProfileFieldAction(
																profileField.fieldName,
																newVal,
																"profileData"
															)
														)
													}}
												>
													{option}
												</Button>
											</Grid>
										)
									}
								)}
							</Grid>
						</Grid>
					)
				} else if (profileField.fieldType === "freeText") {
					return (
						<Grid
							item
							xs={12}
							key={id}
							className={styles.basicDataGridItem}
						>
							<TextField
								InputProps={{
									style: {
										WebkitBoxShadow:
											"0 0 0 1000px white inset",
										borderRadius: 12,
										border: "0.5px solid rgba(0,0,0,0)",
									},
									startAdornment:
										profileField.fieldName === "phone" ? (
											<InputAdornment position="start">
												+49
											</InputAdornment>
										) : null,
								}}
								fullWidth
								onWheel={event => event.target.blur()}
								type={
									profileField.fieldName === "phone"
										? "number"
										: ""
								}
								variant="outlined"
								placeholder={
									standardProfileFieldNames[
										profileField.fieldName
									]
								}
								error={
									profileReducer.profileData[
										profileField.fieldName
									] === ""
								}
								className={styles.freeTextField}
								onKeyDown={e => {
									if (e.key === "Enter") {
										e.target.blur()
									}
								}}
								onChange={el => {
									let newVal = el.target.value
									setSaveBtnDisabled(false)
									dispatch(
										profileActions.changeProfileFieldAction(
											profileField.fieldName,
											newVal,
											"profileData"
										)
									)
								}}
								value={
									profileField.fieldName === "phone"
										? Number(
												profileReducer.profileData[
													profileField.fieldName
												].replace("+49", "")
										  )
										: profileReducer.profileData[
												profileField.fieldName
										  ]
								}
							/>
						</Grid>
					)
				} else {
					return null
				}
			})}
		</>
	)
}

export default ProfilePageFieldsAffiliate
