import React, { useState, useEffect } from "react"

// Labels & Options
import fieldOptions from "./jobFilterModal/fieldOptions"

// Capacitor
import { Capacitor } from "@capacitor/core"

// Redux
import { useDispatch, useSelector } from "react-redux"
import * as jobsActions from "../../state/jobsActions"
import * as tagManagerActions from "../../../tagManagerEvents/state/tagManagerActions"

// Material UI
import { makeStyles } from "@material-ui/core/styles"
import {
	OutlinedInput,
	Checkbox,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	FormControl,
	Select,
	Chip,
	Button,
	Grid,
	useMediaQuery,
} from "@material-ui/core"

// Materal UI icons
import CheckIcon from "@material-ui/icons/Check"
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined"

// Icons
import jobListIconQualification from "../../../images/jobListIcons/ic-biceps.png"

// CSS
const useStyles = makeStyles(theme => ({
	select: {
		backgroundColor: "red !important",
		cursor: "pointer",
		display: "none",
	},
	label: {
		display: "none",
	},
	list: {
		padding: 0,
	},
	option: {
		fontFamily: "'Nunito', sans-serif",
		paddingTop: 20,
		paddingBottom: 20,
		cursor: "pointer",
	},
	selectedOption: {
		fontFamily: "'Nunito', sans-serif",
		backgroundColor: "rgba(245, 168, 0, 0.1) !important",
		fontWeight: 800,
	},
	selectedOptionNone: {
		backgroundColor: "rgba(255,255,255,1) !important",
	},
	invisibleIcon: {
		display: "none",
	},
	selectHeader: {
		position: "sticky",
		paddingTop: 8,
		top: 0,
		backgroundColor: "white",
		zIndex: 2,
		display: "flex",
		justifyContent: "end",
	},
	closeMultipleChoiceListBtn: {
		color: "#3a5dab",
		paddingRight: 12,
	},
	filteringChip: {
		fontFamily: "'Nunito', sans-serif",
		height: 32,
		fontSize: 10,
		backgroundColor: "#3a5dab",
		"&:hover": {
			color: "#fafafa",
			backgroundColor: "#20335e",
		},
		"&:focus": {
			color: "#fafafa",
			backgroundColor: "#3a5dab",
		},
		"&:active": {
			color: "#fafafa",
			backgroundColor: "#3a5dab",
		},
	},
	workFieldOption: {
		padding: 0,
	},
	workFieldTypeGridItem: {
		fontFamily: "'Nunito', sans-serif",
		textAlign: "center",
		cursor: "pointer",
	},
	workFieldTypeGridItemSelected: {
		fontFamily: "'Nunito', sans-serif",
		textAlign: "center",
		color: "#3a5dab",
		borderBottom: "3px solid #3a5dab",
		backgroundColor: "rgba(58, 93, 171, 0.1)",
	},
	workFieldTypeParagraph: {
		cursor: "pointer",
		paddingTop: 6,
		paddingBottom: 3,
	},
}))

const useOutlinedInputStyles = makeStyles(theme => ({
	input: {},
}))

// Component
const QualificationFilterChip = props => {
	// Hooks
	const styles = useStyles()
	const dispatch = useDispatch()
	const outlinedInputClasses = useOutlinedInputStyles()

	// Media queries
	const mobileQuery = useMediaQuery("(max-width:585px)")

	// Destructuring props
	const { setMatchingJobsPage, anchorRef } = props

	// Redux selectors
	const jobsReducer = useSelector(state => state.jobsReducer)
	const authReducer = useSelector(state => state.authReducer)
	const profileReducer = useSelector(state => state.profileReducer)

	// Local state
	const [selectOpen, setSelectOpen] = useState(false)
	const [qualificationFilterString, setQualificationFilterString] =
		useState(null)
	const [fieldName, setFieldName] = useState("qualification")

	// Field Name Mapper
	const fieldNameMapper = {
		qualification: "qualifications",
		qualification_educator: "qualifications",
		work_field: "work_field",
	}

	// Set the qualifikation string
	useEffect(() => {
		if (jobsReducer?.filteringOptions?.qualifications?.length > 0) {
			if (jobsReducer.filteringOptions.qualifications.length === 1) {
				setQualificationFilterString(
					jobsReducer.filteringOptions.qualifications[0]
				)
			} else {
				setQualificationFilterString(
					jobsReducer.filteringOptions.qualifications[0] +
						" (+" +
						(jobsReducer.filteringOptions.qualifications.length -
							1) +
						")"
				)
			}
		}
	}, [jobsReducer.filteringOptions.qualifications])

	// Set the work field type based on filtering preferences
	useEffect(() => {
		if (
			jobsReducer.filteringOptions.work_field ===
			"Pädagogik & Soziale Arbeit"
		) {
			setFieldName("qualification_educator")
		} else {
			setFieldName("qualification")
		}
	}, [jobsReducer.filteringOptions.work_field])

	// Field Options
	const filterFieldOptions = [
		"btn",
		"qualificationsTabs",
		...fieldOptions[fieldName],
	]

	// Render
	return (
		<FormControl variant="filled" className={styles.formControl}>
			<Chip
				size="small"
				icon={
					<img
						alt="qualification"
						src={jobListIconQualification}
						className={styles.jobPillDataIcon}
					/>
				}
				className={styles.filteringChip}
				label={
					<p>
						{jobsReducer.filteringOptions.qualifications.length > 0
							? `Qualifikationen: ${qualificationFilterString}`
							: `Qualifikationen: alle`}
					</p>
				}
				clickable
				onClick={() => {
					setSelectOpen(true)
				}}
				color="primary"
			/>
			<Select
				id={`multipleChoiceField_${fieldName}`}
				className={styles.select}
				open={selectOpen}
				onOpen={() => {
					setSelectOpen(true)
				}}
				multiple={true}
				displayEmpty
				value={jobsReducer.filteringOptions[fieldNameMapper[fieldName]]}
				onChange={el => {
					if (
						!el.target.value.includes("btn") &&
						!el.target.value.includes("qualificationsTabs")
					) {
						// Set value for this field in redux state
						const fieldValue = el.target.value

						dispatch(
							jobsActions.asyncSaveFilteringFieldAction(
								fieldValue,
								fieldNameMapper[fieldName]
							)
						)
					}
				}}
				input={
					<OutlinedInput
						classes={{ input: outlinedInputClasses.input }}
					/>
				}
				MenuProps={
					mobileQuery
						? {
								classes: { list: styles.list },
								PaperProps: {
									style: {
										maxHeight: "100vh",
										minWidth: 200,
										width: "100%",
										bottom:
											Capacitor.getPlatform() === "ios"
												? "calc(16px + env(safe-area-inset-bottom))"
												: 16,
										top:
											Capacitor.getPlatform() === "ios"
												? "calc(16px + env(safe-area-inset-top))"
												: 16,
									},
								},
								marginThreshold: 0,
								variant: "menu",
								getContentAnchorEl: null,
								anchorReference: "anchorPosition",
								anchorPosition: {
									top:
										Capacitor.getPlatform() === "ios"
											? "calc(16px + env(safe-area-inset-top))"
											: 16,
									left: 16,
									right: 16,
								},
						  }
						: {
								classes: { list: styles.list },
								PaperProps: {
									style: {
										maxHeight: "100vh",
										minWidth: 200,
										maxWidth: 552,
										width: "100%",
										bottom:
											Capacitor.getPlatform() === "ios"
												? "calc(16px + env(safe-area-inset-bottom))"
												: 16,
										top:
											Capacitor.getPlatform() === "ios"
												? "calc(16px + env(safe-area-inset-top))"
												: 16,
										marginTop: 16,
									},
								},
								marginThreshold: 0,
								variant: "menu",
								getContentAnchorEl: null,
								anchorEl: anchorRef.current,
								anchorOrigin: {
									horizontal: "center",
									vertical: "center",
								},
								transformOrigin: {
									horizontal: "center",
									vertical: "center",
								},
						  }
				}
			>
				{filterFieldOptions.map((option, id) => {
					if (option === "btn") {
						return (
							<ListItem
								key={option}
								value={option}
								className={styles.selectHeader}
								classes={{
									selected: styles.selectedOptionNone,
								}}
								divider
							>
								<Button
									key="btn"
									className={
										styles.closeMultipleChoiceListBtn
									}
									id="closeMultipleChoiceListBtn"
									color="primary"
									onClick={() => {
										setSelectOpen(false)
										setMatchingJobsPage(1)

										dispatch(jobsActions.removeJobsAction())
										dispatch(
											jobsActions.asyncGetJobsAction({
												page: 1,
												filteringOptions:
													jobsReducer.filteringOptions,
											})
										)

										// Send gtag event after qualification filter change
										dispatch(
											tagManagerActions.sendComplexCustomEvent(
												{
													event: "qualificationFilterChipChanged",
													isUserLoggedIn:
														authReducer?.userLoggedIn
															? "userLoggedIn"
															: "userLoggedOut",
													filterQualification:
														jobsReducer
															?.filteringOptions
															?.qualifications
															?.length > 0
															? jobsReducer.filteringOptions.qualifications.join(
																	", "
															  )
															: "Alle Qualifikationen",
													zohoCandidateId:
														authReducer?.userLoggedIn
															? profileReducer
																	.changes
																	.zoho_candidate_id
															: "userLoggedOut",
												}
											)
										)
									}}
								>
									Fertig
								</Button>
							</ListItem>
						)
					} else if (option === "qualificationsTabs") {
						return (
							<ListItem
								key={option}
								value={option}
								className={styles.workFieldOption}
								classes={{
									selected: styles.selectedOptionNone,
								}}
								divider
							>
								<Grid container key={option}>
									<Grid
										item
										xs={6}
										className={
											fieldName === "qualification"
												? styles.workFieldTypeGridItemSelected
												: styles.workFieldTypeGridItem
										}
									>
										<p
											className={
												styles.workFieldTypeParagraph
											}
											onClick={el => {
												el.stopPropagation()

												setFieldName("qualification")
											}}
										>
											Pflege & Medizin
										</p>
									</Grid>
									<Grid
										item
										xs={6}
										className={
											fieldName ===
											"qualification_educator"
												? styles.workFieldTypeGridItemSelected
												: styles.workFieldTypeGridItem
										}
									>
										<p
											className={
												styles.workFieldTypeParagraph
											}
											onClick={el => {
												el.stopPropagation()

												setFieldName(
													"qualification_educator"
												)
											}}
										>
											Pädagogik & Soziale Arbeit
										</p>
									</Grid>
								</Grid>
							</ListItem>
						)
					} else {
						return (
							<ListItem
								key={option}
								value={option}
								className={styles.option}
								classes={{
									selected: styles.selectedOption,
								}}
								divider
							>
								<ListItemText
									primary={option}
									disableTypography
								/>
								<ListItemSecondaryAction>
									<Checkbox
										color="secondary"
										icon={
											<CheckOutlinedIcon
												className={styles.invisibleIcon}
											/>
										}
										checkedIcon={<CheckIcon />}
										checked={jobsReducer.filteringOptions[
											fieldNameMapper[fieldName]
										].includes(option)}
									/>
								</ListItemSecondaryAction>
							</ListItem>
						)
					}
				})}
			</Select>
		</FormControl>
	)
}

export default QualificationFilterChip
