import React, { useEffect, useState } from "react"

// Material UI
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"

// Redux
import { useDispatch, useSelector } from "react-redux"
import * as clickFunnelActions from "../state/clickFunnelActions"

// Custom Hooks
import useCanGoForward from "../funnelLogicHooks/useCanGoForward"

// CSS
const useStyles = makeStyles({
	registerBtn: {
		width: "100%",
		height: 42,
		borderRadius: 12,
		backgroundColor: "#3a5dab",
		"&:hover": {
			color: "#fafafa",
			backgroundColor: "#3a5dab",
		},
		"& span": {
			fontFamily: "'Nunito', sans-serif",
			fontSize: 16,
			fontWeight: 600,
			lineHeight: 1.25,
			color: "#fafafa",
		},
		"&:disabled": {
			backgroundColor: "#3a5dab",
			opacity: 0.3,
		},
	},
})

// Component
const RegisterBtn = props => {
	// Hooks
	const { canGoForward } = useCanGoForward()
	const dispatch = useDispatch()
	const styles = useStyles()

	// Destructuring props
	const { questionTracker, fromProfile, funnelType } = props

	// Redux selectors
	const profileReducer = useSelector(state => state.profileReducer)
	const jobsReducer = useSelector(state => state.jobsReducer)

	// Local state
	const [registerBtnDisabled, setRegisterBtnDisabled] = useState(true)
	const [registerBtnClicked, setRegisterBtnClicked] = useState(false)

	// (dis)Allowing to register
	useEffect(() => {
		if (!registerBtnClicked) {
			if (canGoForward(props)) {
				setRegisterBtnDisabled(false)
			} else {
				setRegisterBtnDisabled(true)
			}
		}
	}, [registerBtnClicked, canGoForward, props])

	// Render
	return (
		<div
			onClick={() => {
				if (registerBtnDisabled) {
					dispatch(clickFunnelActions.checkContactDataFieldsAction())
				}
			}}
		>
			<Button
				id="clickFunnelRegisterBtn"
				className={styles.registerBtn}
				disabled={registerBtnDisabled}
				disableRipple
				disableTouchRipple
				variant="contained"
				color="secondary"
				onClick={() => {
					setRegisterBtnClicked(true)
					setRegisterBtnDisabled(true)
					window.scrollTo(0, 0)

					if (fromProfile) {
						dispatch(
							clickFunnelActions.asyncPutProfileDataAction(
								questionTracker
							)
						)
					} else if (
						profileReducer.changes.userType === "affiliate"
					) {
						// Change user type
						dispatch(
							clickFunnelActions.asyncPromoterToCandidateTransitionAction(
								questionTracker,
								jobsReducer.job.zoho_job_openings_id
							)
						)
					} else {
						dispatch(
							clickFunnelActions.asyncCheckUserExistsAction({
								questionTracker: questionTracker,
								funnelType: funnelType,
							})
						)
					}
				}}
			>
				{fromProfile
					? "Daten ändern"
					: profileReducer.changes.userType === "affiliate"
					? "Jetzt bewerben"
					: funnelType === "specific"
					? "Jetzt registrieren und bewerben"
					: "Jetzt Registrieren"}
			</Button>
		</div>
	)
}

export default RegisterBtn
