import React from "react"

// Redux
import { useSelector } from "react-redux"

// Material UI
import { makeStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import CircularProgress from "@material-ui/core/CircularProgress"

// Media
import logoOutlined from "../images/logoOutlined.png"

// Styles
const useStyles = makeStyles(theme => ({
	loadingScreen: {
		display: "flex",
		flexDirection: "column",
		width: "100vw",
		height: "100vh",
		position: "absolute",
		top: 0,
		zIndex: 3000,
		backgroundColor: "#e8b300",
		justifyContent: "center",
		alignItems: "center",
		opacity: 1,
	},
	loadingScreenExit: {
		animation: `$animationHide 1000ms ${theme.transitions.easing.easeInOut}`,
	},
	"@keyframes animationHide": {
		"0%": {
			opacity: 1,
		},
		"100%": {
			opacity: 0,
		},
	},
	paper: {
		marginTop: "20px",
		padding: "2px 10px",
	},
	logo: {
		display: "flex",
		marginBottom: "30px",
	},
	spinner: {
		color: "black",
	},
}))

// Component
const ReduxLoadingScreen = () => {
	// Hooks
	const styles = useStyles()

	// Redux Selectors
	const loadingScreenReducer = useSelector(
		state => state.loadingScreenReducer
	)

	// Render
	if (loadingScreenReducer.open) {
		return (
			<div
				className={
					!loadingScreenReducer.open
						? `${styles.loadingScreen} ${styles.loadingScreenExit}`
						: styles.loadingScreen
				}
			>
				<img
					src={logoOutlined}
					className={styles.logo}
					alt="app logo"
				/>
				<CircularProgress className={styles.spinner} />
				{loadingScreenReducer.message && (
					<Paper elevation={1} className={styles.paper}>
						<p>{loadingScreenReducer.message}</p>
					</Paper>
				)}
			</div>
		)
	} else {
		return null
	}
}

export default ReduxLoadingScreen
