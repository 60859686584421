import React, { useState } from "react"

// Material UI
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"

// Labels
import standardProfileFieldNames from "../../profile/dataStructures/standardProfileFieldNames"

// Redux
import { useDispatch, useSelector } from "react-redux"
import * as clickFunnelActions from "../state/clickFunnelActions"

// Funnel Logic Hooks
import useManageQuestions from "../funnelLogicHooks/useManageQuestions"

// CSS
const useStyles = makeStyles({
	gridItem: {
		"& fieldset": {
			borderRadius: 12,
		},
		marginBottom: 49,
	},
	input: {
		backgroundColor: "white",
		borderRadius: 12,
	},
})

// Component
const FreeTextField = props => {
	// Hooks
	const dispatch = useDispatch()
	const styles = useStyles()
	const { manageQuestions } = useManageQuestions()

	// Destructuring props
	const { id, fieldName } = props

	// Redux selectors
	const clickFunnelReducer = useSelector(state => state.clickFunnelReducer)

	// Local state
	const [errorMsg, setErrorMsg] = useState("")

	// Render
	return (
		<Grid item key={id} xs={12} className={styles.gridItem}>
			<TextField
				inputProps={{
					style: {
						WebkitBoxShadow: "0 0 0 1000px white inset",
						borderRadius: 12,
					},
				}}
				onWheel={event => event.target.blur()}
				id={`userInputFieldFreeText${fieldName}`}
				label={standardProfileFieldNames[fieldName]}
				className={styles.input}
				value={clickFunnelReducer.formData[fieldName]}
				variant="outlined"
				color="secondary"
				helperText={errorMsg}
				type={
					fieldName === "hours_per_week" || fieldName === "zip_code"
						? "number"
						: ""
				}
				InputProps={
					fieldName === "hours_per_week"
						? {
								inputProps: {
									min: 0,
									max: 40,
								},
						  }
						: {}
				}
				fullWidth
				onKeyDown={e => {
					if (e.key === "Enter") {
						e.target.blur()
					}
				}}
				onChange={el => {
					// Controlling zip code input
					if (fieldName === "zip_code") {
						el.target.value =
							el.target.value < 0 || el.target.value % 1 !== 0
								? ""
								: el.target.value
					}
					let textVal = el.target.value

					// Sending value to reducer
					dispatch(
						clickFunnelActions.setTextFieldValueAction(
							textVal,
							fieldName
						)
					)

					// add/hide questions, move to next slide (if necessary)
					manageQuestions(props)

					// Error message for email field
					if (fieldName === "email") {
						let emailMatch = textVal.match(
							/^[^\s@]+@[^\s@]+\.[^\s@]+$/g
						)

						if (emailMatch) {
							setErrorMsg("")
						} else {
							setErrorMsg(
								"Achte darauf, dass deine E-Mail-Adresse das richtige Format hat"
							)
						}
					}
				}}
			/>
		</Grid>
	)
}

export default FreeTextField
