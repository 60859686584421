import React from "react"

// Material UI
import { makeStyles } from "@material-ui/core/styles"

// Styles
const useStyles = makeStyles({
	ribbonMain: {
		position: "absolute",
		borderBottom: "22px solid #fef6e6",
		filter: "drop-shadow(0 0 3px rgba(245, 168, 0, 1))",
		borderLeft: "21px solid transparent",
		borderRight: "21px solid transparent",
		transform: "rotate(-45deg)",
		zIndex: 1,
		height: 0,
		top: 18,
		left: -29,
		width: 115,
	},
	ribbonMainJobOpening: {
		position: "absolute",
		borderBottom: "22px solid #fef6e6",
		filter: "drop-shadow(0 0 3px rgba(245, 168, 0, 1))",
		borderLeft: "22.5px solid transparent",
		borderRight: "21px solid transparent",
		transform: "rotate(-46deg)",
		zIndex: 1,
		height: 0,
		top: 10,
		left: -40,
		width: 115,
	},
	ribbonText: {
		margin: 0,
		marginTop: 6,
		marginLeft: -9,
		width: "110%",
		overflow: "auto",
		textAlign: "center",
		fontWeight: "bold",
		fontSize: 8,
	},
	moneyEmoji: {
		position: "absolute",
		marginLeft: 5,
		marginTop: -3,
		fontSize: 14,
	},
	ribbonLowerLeft: {
		position: "absolute",
		height: 2,
		width: 3,
		top: 75.5,
		zIndex: 0,
		left: -4,
		filter: "drop-shadow(0 0 1px rgba(245, 168, 0, 1))",
		backgroundColor: "rgba(245, 168, 0, 0.5)",
	},
	ribbonLowerLeftJobOpening: {
		position: "absolute",
		height: 20,
		width: 1,
		top: 49.5,
		zIndex: 0,
		left: -14.7,
		filter: "drop-shadow(0 0 1px rgba(245, 168, 0, 1))",
		backgroundColor: "rgba(256, 256, 256, 1)",
	},
	ribbonUpperRight: {
		position: "absolute",
		height: 3,
		width: 2,
		zIndex: 0,
		top: -4,
		left: 75,
		filter: "drop-shadow(0 0 1px rgba(245, 168, 0, 1))",
		backgroundColor: "rgba(245, 168, 0, 0.5)",
	},
	ribbonUpperRightJobOpening: {
		position: "absolute",
		height: 1,
		width: 29.7,
		zIndex: 0,
		top: -12.75,
		left: 35.5,
		filter: "drop-shadow(0 0 1.0px rgba(245, 168, 0, 1))",
		backgroundColor: "rgba(256, 256, 256, 1)",
	},
})

const SalaryRibbon = props => {
	// Hooks
	const styles = useStyles()

	// Destructuring props
	const { showRibbon, isInJobOpening } = props

	// Render
	if (showRibbon) {
		return (
			<>
				<div
					className={
						isInJobOpening
							? styles.ribbonMainJobOpening
							: styles.ribbonMain
					}
				>
					<p className={styles.ribbonText}>
						Top-Gehalt <span className={styles.moneyEmoji}>🤑</span>
					</p>
				</div>
				<div
					className={
						isInJobOpening
							? styles.ribbonLowerLeftJobOpening
							: styles.ribbonLowerLeft
					}
				/>
				<div
					className={
						isInJobOpening
							? styles.ribbonUpperRightJobOpening
							: styles.ribbonUpperRight
					}
				/>
			</>
		)
	} else {
		return null
	}
}

export default SalaryRibbon
