import React from "react"

// Data structures
import standardProfileFieldNames from "./dataStructures/standardProfileFieldNames"
import funnelFieldOptions from "../clickFunnel/dataStructures/funnelFieldOptions"

// Fields
import SingleAndMultipleChoiceField from "./fields/SingleAndMultipleChoiceField"
import FilterFormGroup from "./fields/FIlterFormGroup"
import LocationField from "./fields/LocationField"
import WorkExperienceField from "./fields/WorkExperienceField"

// Redux
import { useSelector, useDispatch } from "react-redux"
import * as profileActions from "./state/profileActions"

// Material UI
import { makeStyles } from "@material-ui/core/styles"
import {
	Grid,
	TextField,
	InputAdornment,
	Button,
	Radio,
	RadioGroup,
	FormControlLabel,
	FormControl,
} from "@material-ui/core"
import DatePickerField from "./fields/DataPickerField"

// Styles
const useStyles = makeStyles({
	basicDataGridItem: {
		marginLeft: 16,
		marginRight: 16,
		marginBottom: 8,
	},
	salutationGridContainer: {
		paddingLeft: 16,
		paddingRight: 16,
		paddingBottom: 8,
	},
	salutationGridItem: {
		display: "flex",
		"&:nth-child(1)": {
			justifyContent: "flex-start",
		},
		"&:nth-child(2)": {
			justifyContent: "center",
		},
		"&:nth-child(3)": {
			justifyContent: "flex-end",
		},
	},
	freeTextField: {
		"& .MuiOutlinedInput-notchedOutline": {
			border: "solid 1px #e8e8e8",
		},
	},
	option: {
		display: "flex",
		width: "95%",
		minHeight: 50,
		borderRadius: 12,
		justifyContent: "center",
		textTransform: "none",
		textAlign: "center",
		fontSize: 14,
		color: "#333333",
		backgroundColor: "white",
		border: "1px solid rgba(0,0,0,0.25)",
		wordBreak: "break-word",
		"&:hover": {
			backgroundColor: "white",
			border: "1px solid rgba(0,0,0,0.25)",
		},
	},
	optionSelected: {
		display: "flex",
		width: "95%",
		backgroundColor: "#fff5cc",
		border: "2px solid #e8b300",
		"&:hover": {
			backgroundColor: "#fff5cc",
			border: "2px solid #e8b300",
		},
	},
	radio: {
		color: "#3a5dab",
		"&$checked": {
			color: "#3a5dab",
		},
	},
	checked: {},
})

// Component
const ProfilePageFieldsCandidate = props => {
	// Hooks
	const styles = useStyles()
	const dispatch = useDispatch()

	// Destructuring props
	let { fieldsType, anchorRef } = props

	// Redux selectors
	const profileReducer = useSelector(state => state.profileReducer)

	// Render
	return (
		<>
			{fieldsType.map((profileField, id) => {
				if (profileField.fieldType === "salutation") {
					return (
						<Grid item xs={12} key={id}>
							<Grid
								container
								className={styles.salutationGridContainer}
							>
								{funnelFieldOptions["salutation"].map(
									(option, id) => {
										return (
											<Grid
												item
												xs={4}
												key={id}
												className={
													styles.salutationGridItem
												}
											>
												<Button
													key={id}
													className={
														profileReducer
															.profileData[
															"salutation"
														] === option
															? `${styles.option} ${styles.optionSelected}`
															: styles.option
													}
													onClick={el => {
														let newVal =
															el.target.innerText

														dispatch(
															profileActions.changeProfileFieldAction(
																profileField.fieldName,
																newVal,
																"profileData"
															)
														)
													}}
												>
													{option}
												</Button>
											</Grid>
										)
									}
								)}
							</Grid>
						</Grid>
					)
				} else if (profileField.fieldType === "freeText") {
					return (
						<Grid
							item
							xs={12}
							key={id}
							className={styles.basicDataGridItem}
						>
							<TextField
								InputProps={{
									style: {
										WebkitBoxShadow:
											"0 0 0 1000px white inset",
										borderRadius: 12,
										border: "0.5px solid rgba(0,0,0,0)",
									},
									startAdornment:
										profileField.fieldName === "phone" ? (
											<InputAdornment position="start">
												+49
											</InputAdornment>
										) : null,

									inputProps:
										profileField.fieldName ===
										"hours_per_week"
											? {
													min: 0,
													max: 40,
											  }
											: {},
								}}
								fullWidth
								id={`profileField${profileField.fieldName}`}
								onWheel={event => event.target.blur()}
								type={
									profileField.fieldName === "phone" ||
									profileField.fieldName === "hours_per_week"
										? "number"
										: ""
								}
								variant="outlined"
								placeholder={
									standardProfileFieldNames[
										profileField.fieldName
									]
								}
								error={
									profileReducer.profileData[
										profileField.fieldName
									] === ""
								}
								className={styles.freeTextField}
								onKeyDown={e => {
									if (e.key === "Enter") {
										e.target.blur()
									}
								}}
								onChange={el => {
									let newVal = el.target.value
									dispatch(
										profileActions.changeProfileFieldAction(
											profileField.fieldName,
											newVal,
											"profileData"
										)
									)
								}}
								value={
									profileField.fieldName === "phone"
										? Number(
												profileReducer.profileData[
													profileField.fieldName
												].replace("+49", "")
										  )
										: profileReducer.profileData[
												profileField.fieldName
										  ]
								}
							/>
						</Grid>
					)
				} else if (profileField.fieldType === "workField") {
					return (
						<Grid
							item
							key={id}
							xs={12}
							className={styles.basicDataGridItem}
						>
							<FormControl
								component="fieldset"
								className={styles.formControl}
							>
								<RadioGroup
									aria-label="workField"
									name="workField"
									value={
										profileReducer.profileData.work_field
									}
									onChange={el => {
										let newVal = el.target.value

										dispatch(
											profileActions.changeProfileFieldAction(
												"qualification",
												"",
												"profileData"
											)
										)
										dispatch(
											profileActions.changeProfileFieldAction(
												"type_of_employer",
												[],
												"profileData"
											)
										)
										dispatch(
											profileActions.changeProfileFieldAction(
												"ongoing_education",
												"",
												"profileData"
											)
										)
										dispatch(
											profileActions.changeProfileFieldAction(
												"education_finish_date",
												"",
												"profileData"
											)
										)
										dispatch(
											profileActions.changeProfileFieldAction(
												"occupational_group",
												"",
												"profileData"
											)
										)
										dispatch(
											profileActions.changeProfileFieldAction(
												"occupational_group_free_text",
												"",
												"profileData"
											)
										)
										dispatch(
											profileActions.changeProfileFieldAction(
												"departments",
												[],
												"profileData"
											)
										)
										dispatch(
											profileActions.changeProfileFieldAction(
												"work_field",
												newVal,
												"profileData"
											)
										)
									}}
								>
									<FormControlLabel
										id="profilePageRadioNursesWorkField"
										value="Pflege & Medizin"
										control={
											<Radio
												classes={{
													root: styles.radio,
													checked: styles.checked,
												}}
											/>
										}
										label="Pflege & Medizin"
									/>
									<FormControlLabel
										id="profilePageRadioSocialWorkField"
										value="Pädagogik & Soziale Arbeit"
										control={
											<Radio
												classes={{
													root: styles.radio,
													checked: styles.checked,
												}}
											/>
										}
										label="Pädagogik & Soziale Arbeit"
									/>
								</RadioGroup>
							</FormControl>
						</Grid>
					)
				} else if (profileField.fieldType === "multipleChoice") {
					return (
						<SingleAndMultipleChoiceField
							key={id}
							fieldName={profileField.fieldName}
							anchorRef={anchorRef}
						/>
					)
				} else if (profileField.fieldType === "singleChoice") {
					return (
						<SingleAndMultipleChoiceField
							key={id}
							fieldName={profileField.fieldName}
							anchorRef={anchorRef}
						/>
					)
				} else if (profileField.fieldType === "pickMultiple") {
					return (
						<FilterFormGroup
							key={id}
							fieldName={profileField.fieldName}
						/>
					)
				} else if (profileField.fieldType === "location") {
					return <LocationField key={id} />
				} else if (profileField.fieldType === "dateField") {
					return (
						<DatePickerField
							key={id}
							fieldName={profileField.fieldName}
						/>
					)
				} else if (profileField.fieldType === "workExperience") {
					return (
						<WorkExperienceField key={id} anchorRef={anchorRef} />
					)
				} else {
					return null
				}
			})}
		</>
	)
}

export default ProfilePageFieldsCandidate
