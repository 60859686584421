import React, { useState } from "react"
import { useHistory } from "react-router-dom"

// Components
import DialogUploadScan from "./DialogUploadScan"

// Redux
import { useDispatch, useSelector } from "react-redux"
import * as jobsActions from "../../state/jobsActions"
import * as clickFunnelActions from "../../../clickFunnel/state/clickFunnelActions"
import * as tagManagerActions from "../../../tagManagerEvents/state/tagManagerActions"
import * as authActions from "../../../auth/state/authActions"

// Material UI
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Button, CircularProgress } from "@material-ui/core"

// Icons
import jobCardIconApply from "../../../images/jobCardIcons/jobCardIconApply.png"

// Material UI icons
import DoneOutlineIcon from "@material-ui/icons/DoneOutline"

// Styles
const useStyles = makeStyles({
	applyBtnContainer: {
		display: "flex",
		justifyContent: "center",
	},
	applyBtn: {
		height: 52,
		display: "flex",
		marginBottom: 8,
		borderRadius: 16,
		width: "100%",
		maxWidth: 756,
		color: "#fafafa",
		backgroundColor: "#3a5dab",
		"&:hover": {
			color: "#fafafa",
			backgroundColor: "#3a5dab",
		},
		"&:disabled": {
			backgroundColor: "grey",
		},
	},
	applyIcon: {
		width: 24,
		height: 24,
	},
})

// Component
const JobApplyBtn = props => {
	// Hooks
	const styles = useStyles()
	const dispatch = useDispatch()
	const history = useHistory()

	// Destructuring props
	const { jobApplied, jobId, stickyHeader } = props

	// Local state
	const [applyBtnDisabled, disableApplyBtn] = useState(false)
	const [dialogOpen, setDialogOpen] = useState(false)

	// Redux selectors
	const authReducer = useSelector(state => state.authReducer)
	const profileReducer = useSelector(state => state.profileReducer)
	const jobsReducer = useSelector(state => state.jobsReducer)

	// Component
	return (
		<Grid item xs={12} className={styles.applyBtnContainer}>
			{stickyHeader && (
				<DialogUploadScan
					dialogOpen={dialogOpen}
					setDialogOpen={setDialogOpen}
					disableApplyBtn={disableApplyBtn}
				/>
			)}
			<Button
				variant="contained"
				color="primary"
				id="applyBtn"
				size={"large"}
				className={styles.applyBtn}
				disabled={applyBtnDisabled || jobApplied}
				startIcon={
					applyBtnDisabled && !jobApplied ? (
						""
					) : jobApplied ? (
						<DoneOutlineIcon />
					) : (
						<img
							className={styles.applyIcon}
							src={jobCardIconApply}
							alt="apply to job"
						/>
					)
				}
				onClick={() => {
					if (!authReducer?.userLoggedIn) {
						history.push("/form?type=specific")
					} else if (
						profileReducer?.changes?.userType === "affiliate"
					) {
						history.push("/form?type=specific")
						dispatch(
							clickFunnelActions.importProfileDataAction({
								...profileReducer.profileData,
								phone: profileReducer.profileData.phone.replace(
									/\+49/g,
									""
								),
								email: authReducer.email,
								email_confirmation: authReducer.email,
							})
						)
					} else if (
						(profileReducer?.changes !== undefined &&
							profileReducer?.profileData?.diploma_present) ||
						!jobsReducer?.job?.diploma_required
					) {
						disableApplyBtn(true)
						dispatch(
							jobsActions.asyncAssociateCandidateAction(jobId)
						)
						// sending gtm event
						dispatch(
							tagManagerActions.sendComplexCustomEvent({
								event: "loggedInJobApplied",
								singleJobQualification:
									jobsReducer.job.qualification
										.map(el => el.qualification_name)
										.join(", "),
								singleJobLocation: jobsReducer.job.city,
							})
						)

						// sending job application confirmation email
						dispatch(
							authActions.asyncSendEmailLoginAction({
								email: authReducer.email,
								emailType: "apply",
								userType: "candidate",
								jobOpeningId:
									jobsReducer.job.zoho_job_openings_id,
							})
						)
					} else if (jobsReducer?.job?.diploma_required) {
						setDialogOpen(true)
					}
				}}
			>
				{applyBtnDisabled && !jobApplied ? (
					<CircularProgress
						style={{ width: 30, height: 30, color: "#fafafa" }}
					/>
				) : jobApplied ? (
					"Beworben"
				) : (
					"Bewerben"
				)}
			</Button>
		</Grid>
	)
}

export default JobApplyBtn
