import React from "react"
import { Link } from "react-router-dom"

// Material UI
import { makeStyles } from "@material-ui/core/styles"
import { Grid } from "@material-ui/core"

// Styles
const useStyles = makeStyles({
	moreEmployerInfoBtn: {
		marginBottom: 24,
		textAlign: "center",
		fontWeight: "bold",
		"& a": {
			textDecoration: "none",
			color: "#3a5dab",
		},
	},
})

// Component
const ClientsFranchiseLink = props => {
	// Hooks
	const styles = useStyles()

	// Destructuring props
	const { chain, setGetData } = props

	// Render
	return (
		<Grid item xs={12}>
			<p className={styles.moreEmployerInfoBtn}>
				<Link
					id="MoreRanchiseInfoBtn"
					className={styles.moreEmployerInfoBtn}
					to={{
						pathname: `/chains/${chain.zoho_chains_id}`,
					}}
					onClick={() => {
						setGetData(true)
					}}
				>
					Mehr über die Gruppe {chain.company_name_app} erfahren
				</Link>
			</p>
		</Grid>
	)
}

export default ClientsFranchiseLink
