import { env } from "./environment"

// Firebase
import { initializeApp } from "firebase/app"
import {
	getAuth,
	indexedDBLocalPersistence,
	initializeAuth,
} from "firebase/auth"
import { getStorage } from "firebase/storage"
// import { getAnalytics } from "firebase/analytics"

// Capacitor
import { Capacitor } from "@capacitor/core"
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics"

// For AB tests
//import { getRemoteConfig } from "firebase/remote-config"

// Firebase config data
const firebaseConfig = {
	apiKey: "AIzaSyBNZx4ixI9ApYJf2x8_kLlVqTSwyEMvqV4",
	authDomain: "krankenpflege-5848f.firebaseapp.com",
	databaseURL: "https://krankenpflege-5848f.firebaseio.com",
	projectId: "krankenpflege-5848f",
	storageBucket: "krankenpflege-5848f.appspot.com",
	messagingSenderId: "416128878490",
	appId: "1:416128878490:web:86d75da86574d9b7f7dc23",
	measurementId: "G-Y2YWYVRCJQ",
}

const firebaseConfigDevelopment = {
	apiKey: "AIzaSyA-auqqrYMcnzpKnkoiNgmtpymBTFATmZE",
	authDomain: "krankenpflege-development.firebaseapp.com",
	databaseURL:
		"https://krankenpflege-development-default-rtdb.europe-west1.firebasedatabase.app",
	projectId: "krankenpflege-development",
	storageBucket: "krankenpflege-development.appspot.com",
	messagingSenderId: "733941672772",
	appId: "1:733941672772:web:0124d7f3b6e9e64b484511",
	measurementId: "G-DFVSCWTXX0",
}

const firebaseConfigStaging = {
	apiKey: "AIzaSyCl-jO_Bdl00j7LGIESZtqV0bkIKSqN1BM",
	authDomain: "krankenpflege-staging.firebaseapp.com",
	databaseURL:
		"https://krankenpflege-staging-default-rtdb.europe-west1.firebasedatabase.app",
	projectId: "krankenpflege-staging",
	storageBucket: "krankenpflege-staging.appspot.com",
	messagingSenderId: "1044011486273",
	appId: "1:1044011486273:web:cd44696cd6010cf6b40ac0",
}

let app = null

// Initialize Firebase
if (env === "development") {
	app = initializeApp(firebaseConfigDevelopment)
} else if (env === "staging") {
	app = initializeApp(firebaseConfigStaging)
} else {
	app = initializeApp(firebaseConfig)
	// analytics = getAnalytics(app)
}

// Initialize separate Firebase services
export const firebaseAuth =
	Capacitor.getPlatform() === "ios"
		? initializeAuth(app, {
				persistence: indexedDBLocalPersistence,
		  })
		: getAuth(app)
export const firebaseStorage = getStorage(app)

if (Capacitor.getPlatform() === "ios" && env === "production") {
	FirebaseAnalytics.setCollectionEnabled({
		enabled: true,
	})
}

/* Can be reused for later AB tests
// Initialize Remote Config and get a reference to the service
export const remoteConfig = getRemoteConfig(app)

if (env === "development") {
	remoteConfig.settings.minimumFetchIntervalMillis = 1000
	remoteConfig.defaultConfig = {
		abtest27925000002289005: "variant1",
	}
} else {
	remoteConfig.settings.minimumFetchIntervalMillis = 43200000
	remoteConfig.defaultConfig = {
		abtest29054000005167199: "variant1",
		abtest29054000007979566: "variant1",
		abtest29054000006731003: "variant1",
		abtest29054000004059542: "variant1",
		abtest29054000008956021: "variant1",
	}
}*/
