import React, { useEffect, useState } from "react"
import { Link, useParams, useHistory, useLocation } from "react-router-dom"
import * as Sentry from "@sentry/browser"

// Images
import logoNoText from "../../../images/logoNoText.png"

// SEO
import sanitizeHtml from "sanitize-html"
import { Helmet } from "react-helmet"

// Capacitor
import { Capacitor } from "@capacitor/core"

// Components & Functions
import jobStyles from "./jobStyles"
import JobHeader from "./JobHeader"
import ImgCarousel from "./ImgCarousel"
import seoJobData from "./seoJobData"
import JobSummary from "./JobSummary"
import NoJobsInfo from "./NoJobsInfo"
import Footer from "../../../navigation/Footer"
import JobCard from "../jobsListPage/JobCard"

// Redux
import { useDispatch, useSelector } from "react-redux"
import * as jobsActions from "../../state/jobsActions"
import * as profileActions from "../../../profile/state/profileActions"
import * as tagManagerActions from "../../../tagManagerEvents/state/tagManagerActions"

// Firebase
import { ref, listAll, getDownloadURL } from "firebase/storage"
import { firebaseStorage } from "../../../firebase"

// Material UI
import { Container, Button, Paper, Grid, Divider } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

// Custom Hooks
import { usePathHistory } from "../../../hooks/usePathHistory"

// Styles
const useStyles = makeStyles(jobStyles)

// Component
const Job = () => {
	// Hooks
	const history = useHistory()
	const styles = useStyles()
	const { jobId } = useParams()
	const dispatch = useDispatch()
	const location = useLocation()
	const { previousPage } = usePathHistory()

	// URL Parameters
	const queryString = window.location.search
	const urlParams = new URLSearchParams(queryString)
	const affiliateId = Number(urlParams.get("a_id"))

	// Redux selectors
	const job = useSelector(state => state.jobsReducer.job)
	const profileReducer = useSelector(state => state.profileReducer)
	const authReducer = useSelector(state => state.authReducer)
	const jobsReducer = useSelector(state => state.jobsReducer)

	// Local state
	const [canGetJobs, setGetJobs] = useState(1)
	const [images, setImages] = useState([])
	const [imagesLength, setImagesLength] = useState(null)
	const [imgsLoaded, setImgsLoaded] = useState(false)
	const [companyLogo, setCompanyLogo] = useState(null)
	const [downloadedImages, setDownloadedImages] = useState([])
	const [companyPhotosAvailable, setCompanyPhotosAvailable] = useState(false)
	const [showBenefits, setShowBenefits] = useState(false)
	const [companyOverviewName, setCompanyOverviewName] = useState(false)
	const [companyOverviewBeds, setCompanyOverviewBeds] = useState(false)
	const [companyOverviewEmployees, setCompanyOverviewEmployees] =
		useState(false)
	const [recruiterData, setRecruiterData] = useState(null)
	const [recruiterDataAvailable, setRecruiterDataAvailable] = useState(false)
	const [logoSrcs, setLogoSrcs] = useState([])
	const [jobsApplied, setJobsApplied] = useState([])

	// Listen to location change to similar job
	useEffect(() => {
		setGetJobs(1)
		setCompanyLogo(null)
	}, [location])

	// Temporarily saving affiliate id if present
	useEffect(() => {
		if (
			authReducer?.userLoggedIn &&
			profileReducer?.changes?.profileDataLoaded
		) {
			if (affiliateId) {
				localStorage.setItem("affiliateId", affiliateId)
			}
		} else if (authReducer.userLoggedIn === false && affiliateId) {
			localStorage.setItem("affiliateId", affiliateId)
		}
	}, [
		affiliateId,
		profileReducer.changes,
		authReducer.userLoggedIn,
		dispatch,
	])

	// Getting job data
	useEffect(() => {
		if (
			(job === undefined ||
				job === null ||
				!document.URL.includes(job.job_opening_id)) &&
			canGetJobs &&
			authReducer.userLoggedIn !== null
		) {
			setGetJobs(0)
			dispatch(jobsActions.asyncGetSpecificJobAction(jobId))
		}
	}, [job, canGetJobs, dispatch, jobId, authReducer.userLoggedIn])

	// Block apply button if already applied
	useEffect(() => {
		if (
			profileReducer !== undefined &&
			profileReducer.changes !== undefined &&
			job !== undefined &&
			job !== null &&
			job.zoho_job_openings_id !== undefined
		) {
			let jobOpeningIds = profileReducer.changes.job_opening_ids
			let jobOpeningId = job.zoho_job_openings_id

			dispatch(
				jobsActions.blockApplyButtonAction(jobOpeningId, jobOpeningIds)
			)
		}
	}, [profileReducer, job, dispatch])

	// Get images
	useEffect(() => {
		let newImgList = []

		const getURL = (imageRef, imageType) => {
			if (Capacitor.isNativePlatform()) {
				imageRef._location.path_ = imageRef._location.path_.replaceAll(
					"/",
					"%2F"
				)
			}

			if (imageType === "companyLogo") {
				getDownloadURL(imageRef)
					.then(url => {
						setCompanyLogo(url)
					})
					.catch(error => {
						console.log(error)
						Sentry.captureException(error)
					})
			} else if (imageType === "companyPhoto") {
				getDownloadURL(imageRef)
					.then(url => {
						newImgList = [...newImgList, url]
						setImages(newImgList)
					})
					.catch(error => {
						console.log(error)
						Sentry.captureException(error)
					})
			}
		}

		if (job !== null && job !== undefined && job.zoho_client_id) {
			const storageRef = ref(
				firebaseStorage,
				`JobOpenings/${job.zoho_job_openings_id}`
			)

			const listAllWithRepeat = retries =>
				listAll(storageRef)
					.then(result => {
						if (result?.items?.length > 0) {
							const companyLogo = result.items.filter(el =>
								el.fullPath.includes("logo")
							)
							if (companyLogo.length > 0) {
								getURL(companyLogo[0], "companyLogo")
							}

							const companyPhotos = result.items.filter(el =>
								el.fullPath.includes("photo")
							)

							if (companyPhotos.length > 0) {
								setCompanyPhotosAvailable(true)
								setImagesLength(companyPhotos.length)
								companyPhotos.forEach(imageRef => {
									getURL(imageRef, "companyPhoto")
								})
							}
						}
					})
					.catch(error => {
						console.log(error)
						Sentry.captureException(error)

						if (retries > 0) {
							return listAllWithRepeat(retries - 1)
						}
					})

			listAllWithRepeat(2)
		}
	}, [job])

	// Pre-download images to avoid flickering
	useEffect(() => {
		let newDownloadedList = []

		if (imagesLength === images.length) {
			const loadImage = image => {
				return new Promise((resolve, reject) => {
					const loadImg = new Image()
					loadImg.src = image
					loadImg.onload = () => {
						newDownloadedList = [...newDownloadedList, loadImg]
						return resolve(image.url)
					}
					loadImg.onerror = err => console.log(err)
				})
			}

			Promise.all(images.map(image => loadImage(image)))
				.then(() => {
					setDownloadedImages(newDownloadedList)
					setImgsLoaded(true)
				})
				.catch(err => console.log("Failed to load images", err))
		}
	}, [imagesLength, images])

	// Load similar jobs to this current job
	useEffect(() => {
		if (job?.zoho_job_openings_id !== undefined) {
			dispatch(jobsActions.asyncGetSimilarJobsAction(job, jobsApplied))
		}
	}, [job, dispatch, jobsApplied])

	// Check if benefits & company overview available
	useEffect(() => {
		if (job !== undefined && job !== null) {
			if (job.benefit_icons?.length === 3) {
				setShowBenefits(true)
			}
			if (job.client_name) {
				setCompanyOverviewName(true)
			}
			if (job.beds_departments) {
				setCompanyOverviewBeds(true)
			}
			if (job.number_of_employees) {
				setCompanyOverviewEmployees(true)
			}
		}
	}, [job])

	// Grab and set recruiter data
	useEffect(() => {
		if (
			profileReducer &&
			profileReducer.recruiterData &&
			!recruiterDataAvailable
		) {
			setRecruiterDataAvailable(true)
			setRecruiterData(profileReducer.recruiterData)
		}
	}, [profileReducer, recruiterDataAvailable])

	// Show which jobs where applied to
	useEffect(() => {
		if (profileReducer?.changes !== undefined) {
			setJobsApplied(profileReducer.changes.job_opening_ids)
		}
	}, [profileReducer, jobsApplied])

	// Grab logos
	useEffect(() => {
		if (jobsReducer?.similarJobs?.length > 0) {
			let allLogosSrc = []

			jobsReducer.similarJobs.forEach(job => {
				const storageRef = ref(
					firebaseStorage,
					`JobOpenings/${job.zoho_job_openings_id}`
				)

				listAll(storageRef)
					.then(result => {
						if (result.items.length > 0) {
							const companyLogo = result.items.filter(el =>
								el.fullPath.includes("logo")
							)
							if (companyLogo.length > 0) {
								if (Capacitor.isNativePlatform()) {
									companyLogo[0]._location.path_ =
										companyLogo[0]._location.path_.replaceAll(
											"/",
											"%2F"
										)
								}

								getDownloadURL(companyLogo[0])
									.then(url => {
										allLogosSrc = [
											...allLogosSrc,
											{
												url: url,
												zoho_client_id:
													job.zoho_client_id,
											},
										]
										setLogoSrcs(allLogosSrc)
									})
									.catch(error => {
										// Handle any errors
									})
							}
						}
					})
					.catch(error => {
						console.log(error)
					})
			})
		}
	}, [jobsReducer.similarJobs])

	// Grab recruiter photo
	useEffect(() => {
		if (recruiterData && !recruiterData.photoUrl) {
			const storageRef = ref(
				firebaseStorage,
				`Recruiters/${recruiterData.email}`
			)

			listAll(storageRef)
				.then(result => {
					if (result.items.length > 0) {
						if (Capacitor.isNativePlatform()) {
							result.items[0]._location.path_ =
								result.items[0]._location.path_.replaceAll(
									"/",
									"%2F"
								)
						}

						getDownloadURL(result.items[0])
							.then(url => {
								dispatch(
									profileActions.setRecruiterPhotoUrl(url)
								)
							})
							.catch(error => {
								// Handle any errors
							})
					}
				})
				.catch(error => {
					console.log(error)
				})
		}
	}, [recruiterData, dispatch])

	// Send event to GTM when a user goes from jobs list to specific job
	useEffect(() => {
		if (previousPage === "/jobs") {
			dispatch(
				tagManagerActions.sendComplexCustomEvent({
					event: "URLChangedFromJobsListToSpecificJobCustomEvent",
					isUserLoggedIn: authReducer?.userLoggedIn
						? "userLoggedIn"
						: "userLoggedOut",
				})
			)
		}
	}, [previousPage, authReducer.userLoggedIn, dispatch])

	// Render
	return (
		<div className={styles.mainDiv}>
			{/* SEO */}
			<Helmet>
				<title>
					{job &&
						`Workbee - ${
							job.job_title_app
								? job.job_title_app
								: job.job_title
						}`}
				</title>
				<meta
					name="title"
					content={
						job &&
						`Workbee - ${
							job.job_title_app
								? job.job_title_app
								: job.job_title
						}`
					}
				/>
				<meta
					name="description"
					content={
						job &&
						`Bewerbe dich als ${
							job.job_title_app
								? job.job_title_app
								: job.job_title
						} mit ${job.client_name}`
					}
				/>
				<script type="application/ld+json">{seoJobData(job)}</script>
			</Helmet>

			{/* Job data */}
			{job && job !== "invalid id" && (
				<React.Fragment>
					{/* Job Header */}
					<JobHeader
						companyLogo={companyLogo}
						job={job}
						jobId={jobId}
						stickyHeader={true}
						inFunnel={false}
					/>

					<Paper elevation={2} className={styles.greyPaper}>
						<Container className={styles.containerDefault}>
							{/* Job Overview */}
							<Grid container>
								<Grid item xs={12}>
									<Grid container>
										{/* Benefits overview */}
										{showBenefits && (
											<Grid item xs={12}>
												<JobSummary
													job={job}
													type="benefits"
												/>
											</Grid>
										)}
										{/* Company overview */}
										{(companyOverviewBeds ||
											companyOverviewEmployees ||
											companyOverviewName) && (
											<Grid item xs={12}>
												<JobSummary
													job={job}
													type="companyInfo"
													companyOverviewBeds={
														companyOverviewBeds
													}
													companyOverviewEmployees={
														companyOverviewEmployees
													}
													companyOverviewName={
														companyOverviewName
													}
												/>
											</Grid>
										)}
									</Grid>
								</Grid>
							</Grid>
						</Container>
					</Paper>

					<Divider
						orientation="vertical"
						flexItem
						className={styles.divider}
					/>

					{/* Section 2 - benefits, requirements, gallery images, map location, description */}
					<Paper elevation={2} className={styles.paper}>
						<Container className={styles.containerSection2}>
							<Grid container>
								{/* Benefits Specific List */}
								<Grid item xs={12}>
									<div className={styles.jobDescriptionText}>
										{job.job_benefits ? (
											<div
												dangerouslySetInnerHTML={{
													__html: sanitizeHtml(
														job.job_benefits
													),
												}}
											></div>
										) : (
											""
										)}
									</div>
								</Grid>
							</Grid>
						</Container>
					</Paper>

					<Divider
						orientation="vertical"
						flexItem
						className={styles.divider}
					/>

					<Paper elevation={2} className={styles.greyPaper}>
						<Container className={styles.containerSection2}>
							<Grid container>
								{/* Profile & Requirements Specific List */}
								<Grid item xs={12}>
									<div className={styles.jobDescriptionText}>
										{job.job_requirements ? (
											<div
												dangerouslySetInnerHTML={{
													__html: sanitizeHtml(
														job.job_requirements
													),
												}}
											></div>
										) : (
											""
										)}
									</div>
								</Grid>
							</Grid>
						</Container>
					</Paper>

					<Divider
						orientation="vertical"
						flexItem
						className={styles.divider}
					/>

					<Paper elevation={2} className={styles.paper}>
						<Container className={styles.containerSection2}>
							<Grid container>
								{/* Job description */}
								<Grid item xs={12}>
									<h2 className={styles.jobSummaryTitleText}>
										Über den Arbeitgeber
									</h2>

									<div
										className={
											styles.jobDescriptionTextAboutEmployer
										}
									>
										{job.job_description ? (
											<div
												dangerouslySetInnerHTML={{
													__html: job.job_description,
												}}
											></div>
										) : (
											""
										)}
									</div>
								</Grid>
								{/* Job images */}
								<Grid item xs={12}>
									<ImgCarousel
										imgsLoaded={imgsLoaded}
										images={downloadedImages}
										companyPhotosAvailable={
											companyPhotosAvailable
										}
										youtubeLink={job.video_url}
									/>
								</Grid>
								<Grid item xs={12}>
									{job &&
										job.street_house_number &&
										job.zip_code &&
										job.city &&
										job.client_name && (
											<Grid
												item
												xs={12}
												className={
													styles.jobAddressContainer
												}
											>
												<h3
													className={
														styles.jobAddressClientName
													}
												>
													{job.client_name}
												</h3>

												<p>{job.street_house_number}</p>
												<p>{`${job.zip_code} ${job.city}`}</p>
											</Grid>
										)}
								</Grid>

								{/* Link to clients page */}
								<Grid item xs={12}>
									<p className={styles.moreEmployerInfoBtn}>
										<Link
											id="MoreEmployerInfoBtn"
											className={
												styles.moreEmployerInfoBtn
											}
											to={{
												pathname: `/clients/${job.zoho_client_id}`,
											}}
											onClick={() => {
												dispatch(
													tagManagerActions.sendSimpleCustomEvent(
														{
															eventName:
																"clickClientPageFromJobLinkCustomEvent",
														}
													)
												)
											}}
										>
											Mehr über den Arbeitgeber erfahren
										</Link>
									</p>
								</Grid>
							</Grid>
						</Container>

						<Divider
							orientation="vertical"
							flexItem
							className={styles.divider}
						/>

						{/* Similar Jobs */}
						{jobsReducer.similarJobCount > 0 && (
							<Paper elevation={2} className={styles.greyPaper}>
								<Container
									className={styles.containerSimilarJobs}
								>
									<Grid container>
										<Grid item xs={12}>
											<h2
												id="similarJobsTitle"
												className={
													styles.similarJobsTitleText
												}
											>
												Ähnliche Jobs
											</h2>
											<JobCard
												jobs={jobsReducer.similarJobs}
												url={"/jobs"}
												similarJob={true}
												logoSrcs={logoSrcs}
												jobsApplied={jobsApplied}
												openInNewTab={false}
												similarJobsClickEvent={() => {
													dispatch(
														tagManagerActions.sendSimpleCustomEvent(
															{
																eventName:
																	"clickSimilarJobCustomEvent",
															}
														)
													)
												}}
											/>
										</Grid>
									</Grid>
								</Container>
							</Paper>
						)}

						{/* What is workbee section & Footer (only unlogged) */}
						{authReducer && !authReducer.userLoggedIn && (
							<React.Fragment>
								<Paper elevation={2} className={styles.paper}>
									{/* What is workbee */}
									<Container
										className={styles.containerSection2}
									>
										<Grid container>
											<Grid item xs={12}>
												<Paper
													elevation={2}
													className={
														styles.whatIsWorkbeePaper
													}
												>
													<h4
														className={
															styles.whatIsWorkbeeHeader
														}
													>
														Was ist{" "}
														<img
															src={logoNoText}
															alt="workbee"
															className={
																styles.whatIsWorkbeeImg
															}
														/>
														?
													</h4>
													<p
														className={
															styles.whatIsWorkbeeParagraph
														}
													>
														Workbee ist die digitale
														Job- und
														Karriereplattform für
														Fach- und Hilfskräfte in
														den Bereichen Pflege,
														Medizin und Soziales.
													</p>
													<p>
														Unser Passt-Genau-Filter
														hilft uns, deine Wünsche
														und Bedürfnisse mit
														Hunderten von
														Jobangeboten
														abzugleichen. Entdecke
														jetzt Jobangebote auf
														Workbee.
													</p>
													<Button
														variant="contained"
														color="primary"
														id="applyBtnWorkbeeLoggedOut"
														size="large"
														className={
															styles.applyBtnWorkbeeLoggedOut
														}
														onClick={() => {
															dispatch(
																jobsActions.removeSpecificJobAction()
															)
															history.push(
																"/jobs"
															)
															dispatch(
																tagManagerActions.sendSimpleCustomEvent(
																	{
																		eventName:
																			"clickWhatIsWorkbeeMoreJobsBtnCustomEvent",
																	}
																)
															)
															window.scrollTo(
																0,
																0
															)
														}}
													>
														Mehr Jobs sehen
													</Button>
												</Paper>
											</Grid>
										</Grid>
									</Container>
								</Paper>

								{!Capacitor.isNativePlatform() && (
									<>
										<Divider
											orientation="vertical"
											flexItem
											className={styles.divider}
										/>

										{/* Footer */}
										<Footer fromJobs={true} />
									</>
								)}
							</React.Fragment>
						)}
					</Paper>
				</React.Fragment>
			)}

			{/* Non-existing jobs */}

			{job === "invalid id" && <NoJobsInfo styles={styles} />}
		</div>
	)
}

export default Job
