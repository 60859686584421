import React, { useState, useEffect } from "react"

// Capacitor
import { Capacitor } from "@capacitor/core"

// Labels & Options
import fieldOptions from "../../jobs/components/jobsListPage/jobFilterModal/fieldOptions"

// Redux
import { useDispatch, useSelector } from "react-redux"
import * as profileActions from "../state/profileActions"

// Material UI
import { makeStyles } from "@material-ui/core/styles"
import {
	OutlinedInput,
	Checkbox,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	FormControl,
	Select,
	Chip,
	Grid,
	Button,
} from "@material-ui/core"
import { useMediaQuery } from "@material-ui/core"

// Materal UI icons
import CheckIcon from "@material-ui/icons/Check"
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined"

// CSS
const useStyles = makeStyles(theme => ({
	basicDataGridItem: {
		marginLeft: 16,
		marginRight: 16,
		marginBottom: 8,
	},
	formControl: {
		width: "100%",
	},
	select: {
		minHeight: 52,
		backgroundColor: "white",
		borderRadius: 12,
		cursor: "pointer",
	},
	chips: {
		display: "flex",
		flexWrap: "wrap",
	},
	chip: {
		border: "1px solid #e8b300",
		backgroundColor: "#fff5cc",
		margin: 2,
		padding: 5,
		display: "flex",
		flexDirection: "row",
		height: "auto",
		"& span": {
			overflowWrap: "break-word",
			whiteSpace: "normal",
			textOverflow: "clip",
		},
	},
	chipSingle: {
		border: "1px solid #e8b300",
		backgroundColor: "#fff5cc",
		margin: 2,
		padding: 5,
		height: "auto",
		"& span": {
			overflowWrap: "break-word",
			whiteSpace: "normal",
			textOverflow: "clip",
		},
	},
	label: {
		display: "none",
	},
	list: {
		padding: 0,
	},
	option: {
		paddingTop: 20,
		paddingBottom: 20,
		cursor: "pointer",
	},
	optionDisabled: {
		paddingTop: 20,
		paddingBottom: 20,
		opacity: 0.3,
		pointerEvents: "none",
	},
	selectedOption: {
		backgroundColor: "rgba(245, 168, 0, 0.1) !important",
		fontWeight: 600,
	},
	selectedOptionNone: {
		backgroundColor: "rgba(255,255,255,1) !important",
	},
	invisibleIcon: {
		display: "none",
	},
	selectHeader: {
		position: "sticky",
		paddingTop: 8,
		top: 0,
		backgroundColor: "white",
		zIndex: 2,
		display: "flex",
		justifyContent: "end",
	},
	closeMultipleChoiceListBtn: {
		color: "#3a5dab",
		paddingRight: 12,
	},
}))

const useOutlinedInputStyles = makeStyles(theme => ({
	input: {
		padding: "10px 16px",
	},
}))

// Component
const SingleAndMultipleChoiceField = props => {
	// Hooks
	const styles = useStyles()
	const dispatch = useDispatch()
	const outlinedInputClasses = useOutlinedInputStyles()

	// Destructuring props
	const { fieldName, anchorRef } = props

	// Redux selectors
	const profileReducer = useSelector(state => state.profileReducer)

	// Media queries
	const mobileQuery = useMediaQuery("(max-width:445px)")

	// Local state
	const [selectOpen, setSelectOpen] = useState(false)
	const [filterFieldOptions, setFilterFieldOptions] = useState(null)
	const [displayError, setDisplayError] = useState(false)
	const [optionsDisabled, setOptionsDisabled] = useState(false)
	const [benefitsSelected, setBenefitsSelected] = useState([])

	// Set qualification/type of employer options to educator depending on work field
	useEffect(() => {
		if (
			profileReducer?.profileData?.work_field ===
				"Pädagogik & Soziale Arbeit" &&
			fieldName === "qualification"
		) {
			setFilterFieldOptions([
				"btn",
				...fieldOptions["qualification_educator"],
			])
		} else if (
			profileReducer?.profileData?.work_field ===
				"Pädagogik & Soziale Arbeit" &&
			fieldName === "ongoing_education"
		) {
			setFilterFieldOptions([
				"btn",
				...fieldOptions["ongoing_education_educator"],
			])
		} else if (
			profileReducer?.profileData?.work_field ===
				"Pädagogik & Soziale Arbeit" &&
			fieldName === "occupational_group"
		) {
			setFilterFieldOptions([
				"btn",
				...fieldOptions["occupational_group_educator"],
			])
		} else if (
			profileReducer?.profileData?.work_field ===
				"Pädagogik & Soziale Arbeit" &&
			fieldName === "type_of_employer"
		) {
			setFilterFieldOptions([
				"btn",
				...fieldOptions["type_of_employer_educator"],
			])
		} else {
			setFilterFieldOptions(["btn", ...fieldOptions[fieldName]])
		}
	}, [profileReducer.profileData.work_field, fieldName])

	// Set field validation
	useEffect(() => {
		if (
			profileReducer?.profileData?.qualification === "" &&
			fieldName === "qualification"
		) {
			setDisplayError(true)
		} else if (
			profileReducer?.profileData?.type_of_employer?.length === 0 &&
			fieldName === "type_of_employer"
		) {
			setDisplayError(true)
		} else if (
			profileReducer?.profileData?.benefits?.length === 0 &&
			fieldName === "benefits"
		) {
			setDisplayError(true)
		} else if (
			profileReducer?.profileData?.total_work_experience?.length === 0 &&
			fieldName === "total_work_experience"
		) {
			setDisplayError(true)
		} else if (
			profileReducer?.profileData?.german_level === "" &&
			fieldName === "german_level"
		) {
			setDisplayError(true)
		} else if (
			profileReducer?.profileData?.education_recognized === "" &&
			fieldName === "education_recognized"
		) {
			setDisplayError(true)
		} else if (
			profileReducer?.profileData?.ongoing_education === "" &&
			fieldName === "ongoing_education"
		) {
			setDisplayError(true)
		} else if (
			profileReducer?.profileData?.occupational_group === "" &&
			fieldName === "occupational_group"
		) {
			setDisplayError(true)
		} else if (
			profileReducer?.profileData?.occupational_group_free_text === "" &&
			fieldName === "occupational_group_free_text"
		) {
			setDisplayError(true)
		} else if (
			profileReducer?.profileData?.salary_expectation_month === "" &&
			fieldName === "salary_expectation_month"
		) {
			setDisplayError(true)
		} else if (
			profileReducer?.profileData?.departments?.length === 0 &&
			fieldName === "departments"
		) {
			setDisplayError(true)
		} else if (
			profileReducer?.profileData?.qualification_hilfskraft === "" &&
			fieldName === "qualification_hilfskraft"
		) {
			setDisplayError(true)
		} else {
			setDisplayError(false)
		}
	}, [profileReducer, fieldName])

	// Benefits-specific logic
	useEffect(() => {
		if (
			fieldName === "benefits" &&
			profileReducer.profileData.benefits.length === 3
		) {
			setBenefitsSelected(profileReducer.profileData.benefits)
			setOptionsDisabled(true)
		} else if (fieldName === "benefits") {
			setBenefitsSelected(profileReducer.profileData.benefits)
			setOptionsDisabled(false)
		}
	}, [fieldName, profileReducer.profileData.benefits])

	// Render
	if (filterFieldOptions) {
		return (
			<Grid item xs={12} className={styles.basicDataGridItem}>
				<FormControl variant="filled" className={styles.formControl}>
					<Select
						id={`multipleChoiceField_${fieldName}`}
						className={styles.select}
						open={selectOpen}
						error={displayError}
						onClose={() => {
							setSelectOpen(false)
						}}
						onOpen={() => {
							setSelectOpen(true)
						}}
						multiple={
							[
								"salary_expectation_month",
								"qualification",
								"occupational_group",
								"ongoing_education",
								"german_level",
								"education_recognized",
								"total_work_experience",
								"work_experience",
								"qualification_hilfskraft",
							].includes(fieldName)
								? false
								: true
						}
						displayEmpty
						value={
							profileReducer.profileData[fieldName]
								? profileReducer.profileData[fieldName]
								: ""
						}
						onChange={el => {
							if (!el.target.value.includes("btn")) {
								// Set value for this field in redux state
								const fieldValue = el.target.value

								dispatch(
									profileActions.changeProfileFieldAction(
										fieldName,
										fieldValue,
										"profileData"
									)
								)

								// Setting german level to native will also set education_recognized to true
								if (fieldValue === "Muttersprache") {
									dispatch(
										profileActions.changeProfileFieldAction(
											"education_recognized",
											"Ja, ist anerkannt",
											"profileData"
										)
									)
								}
							}
						}}
						input={
							<OutlinedInput
								classes={{ input: outlinedInputClasses.input }}
							/>
						}
						renderValue={selected => {
							if (selected.length === 0 || !selected) {
								return <em>zum Auswählen anklicken</em>
							}

							if (typeof selected === "string") {
								return (
									<Chip
										key={selected}
										label={selected}
										className={styles.chipSingle}
										onMouseDown={event => {
											event.stopPropagation()
										}}
										onDelete={() => {
											// Remove value from redux
											dispatch(
												profileActions.changeProfileFieldAction(
													fieldName,
													"",
													"profileData"
												)
											)
										}}
									/>
								)
							} else {
								return (
									<div className={styles.chips}>
										{selected.map(value => (
											<Chip
												key={value}
												label={value}
												className={styles.chip}
												onMouseDown={event => {
													event.stopPropagation()
												}}
												onDelete={() => {
													// Remove value from redux
													dispatch(
														profileActions.removeProfileItemMultipleChoice(
															fieldName,
															value,
															"profileData"
														)
													)
												}}
											/>
										))}
									</div>
								)
							}
						}}
						MenuProps={
							mobileQuery
								? {
										classes: { list: styles.list },
										PaperProps: {
											style: {
												maxHeight: "100vh",
												minWidth: 200,
												width: "100%",
												bottom:
													Capacitor.getPlatform() ===
													"ios"
														? "calc(16px + env(safe-area-inset-bottom))"
														: 16,
												top:
													Capacitor.getPlatform() ===
													"ios"
														? "calc(16px + env(safe-area-inset-top))"
														: 16,
											},
										},
										marginThreshold: 0,
										variant: "menu",
										getContentAnchorEl: null,
										anchorReference: "anchorPosition",
										anchorPosition: {
											top:
												Capacitor.getPlatform() ===
												"ios"
													? "calc(16px + env(safe-area-inset-top))"
													: 16,
											left: 16,
											right: 16,
										},
								  }
								: {
										classes: { list: styles.list },
										PaperProps: {
											style: {
												maxHeight: "100vh",
												minWidth: 200,
												maxWidth: 552,
												width: "100%",
												bottom:
													Capacitor.getPlatform() ===
													"ios"
														? "calc(16px + env(safe-area-inset-bottom))"
														: 16,
												top:
													Capacitor.getPlatform() ===
													"ios"
														? "calc(16px + env(safe-area-inset-top))"
														: 16,
												marginTop: 16,
											},
										},
										marginThreshold: 0,
										variant: "menu",
										getContentAnchorEl: null,
										anchorEl: anchorRef.current,
										anchorOrigin: {
											horizontal: "center",
											vertical: "center",
										},
										transformOrigin: {
											horizontal: "center",
											vertical: "center",
										},
								  }
						}
					>
						{filterFieldOptions.map((option, id) => {
							if (option === "btn") {
								return (
									<ListItem
										key={option}
										value={option}
										className={styles.selectHeader}
										classes={{
											selected: styles.selectedOptionNone,
										}}
										divider
									>
										<Button
											key="btn"
											className={
												styles.closeMultipleChoiceListBtn
											}
											id="closeMultipleChoiceListBtn"
											color="primary"
											onClick={() => {
												setSelectOpen(false)
											}}
										>
											Fertig
										</Button>
									</ListItem>
								)
							} else {
								return (
									<ListItem
										key={option}
										value={option}
										className={
											optionsDisabled &&
											fieldName === "benefits" &&
											!benefitsSelected.includes(option)
												? styles.optionDisabled
												: styles.option
										}
										classes={{
											selected: styles.selectedOption,
										}}
										divider
									>
										<ListItemText
											primary={option}
											disableTypography
										/>
										<ListItemSecondaryAction>
											<Checkbox
												color="secondary"
												icon={
													<CheckOutlinedIcon
														className={
															styles.invisibleIcon
														}
													/>
												}
												checkedIcon={<CheckIcon />}
												checked={profileReducer.profileData[
													fieldName
												].includes(option)}
											/>
										</ListItemSecondaryAction>
									</ListItem>
								)
							}
						})}
					</Select>
				</FormControl>
			</Grid>
		)
	} else {
		return null
	}
}

export default SingleAndMultipleChoiceField
