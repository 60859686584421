import React, { createContext, useState, useContext, useEffect } from "react"
import { useHistory } from "react-router-dom"

const HistoryContext = createContext()

export function HistoryProvider({ children }) {
	const [pathHistory, setPathHistory] = useState([])
	const [previousPage, setPreviousPage] = useState(window.location.pathname)
	const history = useHistory()

	useEffect(() => {
		return history.listen(location => {
			setPathHistory(oldHistory => {
				if (oldHistory.length > 0) {
					setPreviousPage(oldHistory[oldHistory.length - 1])
				}
				return [...oldHistory, location.pathname]
			})
		})
	}, [history])

	return (
		<HistoryContext.Provider value={{ pathHistory, previousPage }}>
			{children}
		</HistoryContext.Provider>
	)
}

export function usePathHistory() {
	return useContext(HistoryContext)
}
