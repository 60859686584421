import React, { useState, useEffect } from "react"

// Labels & Options
import funnelFieldOptions from "../dataStructures/funnelFieldOptions"

// Capacitor
import { Capacitor } from "@capacitor/core"

// Redux
import { useDispatch, useSelector } from "react-redux"
import * as clickFunnelActions from "../state/clickFunnelActions"

// Material UI
import { makeStyles } from "@material-ui/core/styles"
import {
	useMediaQuery,
	OutlinedInput,
	Checkbox,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	FormControl,
	Select,
	Chip,
	Grid,
	Button,
} from "@material-ui/core"

// Materal UI icons
import CheckIcon from "@material-ui/icons/Check"
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined"

// Custom Hooks
import useManageQuestions from "../funnelLogicHooks/useManageQuestions"

// CSS
const useStyles = makeStyles(theme => ({
	gridItem: {
		marginBottom: 49,
	},
	formControl: {
		width: "100%",
		marginTop: 16,
	},
	select: {
		minHeight: 52,
		backgroundColor: "white",
		borderRadius: 12,
		cursor: "pointer",
	},
	chips: {
		display: "flex",
		flexWrap: "wrap",
	},
	chip: {
		border: "1px solid #e8b300",
		backgroundColor: "#fff5cc",
		margin: 2,
		padding: 5,
		height: "100%",
		display: "flex",
		flexDirection: "row",
		"& span": {
			overflowWrap: "break-word",
			whiteSpace: "normal",
			textOverflow: "clip",
		},
	},
	label: {
		display: "none",
	},
	list: {
		padding: 0,
	},
	option: {
		paddingTop: 20,
		paddingBottom: 20,
		cursor: "pointer",
	},
	optionDisabled: {
		paddingTop: 20,
		paddingBottom: 20,
		opacity: 0.3,
		pointerEvents: "none",
	},
	selectedOption: {
		backgroundColor: "rgba(245, 168, 0, 0.1) !important",
		fontWeight: 600,
	},
	selectedOptionNone: {
		backgroundColor: "rgba(255,255,255,1) !important",
	},
	invisibleIcon: {
		display: "none",
	},
	selectHeader: {
		position: "sticky",
		paddingTop: 8,
		top: 0,
		backgroundColor: "white",
		zIndex: 2,
		display: "flex",
		justifyContent: "end",
	},
	closeMultipleChoiceListBtn: {
		color: "#3a5dab",
		paddingRight: 12,
	},
}))

const useOutlinedInputStyles = makeStyles(theme => ({
	input: {
		padding: "10px 16px",
	},
}))

// Component
const MultipleChoiceField = props => {
	// Hooks
	const styles = useStyles()
	const dispatch = useDispatch()
	const { manageQuestions } = useManageQuestions()
	const outlinedInputClasses = useOutlinedInputStyles()

	// Destructuring props
	const { id, fieldName, anchorRef } = props

	// Redux selectors
	const clickFunnelReducer = useSelector(state => state.clickFunnelReducer)

	// Media queries
	const mobileQuery = useMediaQuery("(max-width:585px)")

	// Local state
	const [madeChange, setMadeChange] = useState(false)
	const [localFieldValue, setLocalFieldValue] = useState(null)
	const [selectOpen, setSelectOpen] = useState(false)
	const [benefitsSelected, setBenefitsSelected] = useState([])
	const [optionsDisabled, setOptionsDisabled] = useState(false)

	// Field Options
	const fieldOptions = ["btn", ...funnelFieldOptions[fieldName]]

	// URL Parameters
	const queryString = window.location.search
	const urlParams = new URLSearchParams(queryString)
	const benefitParam = urlParams.get("benefit")

	// Manage questions logic
	useEffect(() => {
		if (madeChange) {
			setMadeChange(false)

			// add/hide questions, move to next slide (if necessary)
			manageQuestions({ ...props, fieldValue: localFieldValue })
		}
	}, [
		madeChange,
		clickFunnelReducer,
		props,
		manageQuestions,
		localFieldValue,
	])

	// Benefits-specific logic
	useEffect(() => {
		if (
			fieldName === "benefits" &&
			clickFunnelReducer.formData.benefits.length === 3
		) {
			setBenefitsSelected(clickFunnelReducer.formData.benefits)
			setOptionsDisabled(true)
		} else if (fieldName === "benefits") {
			setBenefitsSelected(clickFunnelReducer.formData.benefits)
			setOptionsDisabled(false)
		}
	}, [fieldName, clickFunnelReducer.formData.benefits])

	// Pre-selecting benefits from url parameters
	useEffect(() => {
		if (
			benefitParam &&
			funnelFieldOptions.benefits.includes(benefitParam) &&
			clickFunnelReducer.formData.benefits.length === 0
		) {
			setLocalFieldValue([benefitParam])

			// Set value for this field in redux state
			dispatch(
				clickFunnelActions.asyncSaveFieldAction(
					[benefitParam],
					"benefits"
				)
			).then(() => {
				setMadeChange(true)
			})
		}
		// eslint-disable-next-line
	}, [])

	// Render
	return (
		<Grid key={id} item xs={12} className={styles.gridItem}>
			<FormControl variant="filled" className={styles.formControl}>
				<Select
					id={`clickFunnelMultipleChoiceField_${fieldName}`}
					className={styles.select}
					open={selectOpen}
					onClose={() => {
						setSelectOpen(false)
					}}
					onOpen={() => {
						setSelectOpen(true)
					}}
					multiple={
						fieldName === "qualification_educator" ||
						fieldName === "qualification" ||
						fieldName === "ongoing_education" ||
						fieldName === "ongoing_education_educator" ||
						fieldName === "total_work_experience"
							? false
							: true
					}
					displayEmpty
					value={clickFunnelReducer.formData[fieldName]}
					onChange={el => {
						if (!el.target.value.includes("btn")) {
							// Set value for this field in redux state
							const fieldValue = el.target.value
							setLocalFieldValue(fieldValue)

							if (
								fieldName === "qualification_educator" ||
								fieldName === "qualification" ||
								fieldName === "ongoing_education" ||
								fieldName === "ongoing_education_educator" ||
								fieldName === "total_work_experience"
							) {
								dispatch(
									clickFunnelActions.asyncSaveFieldAction(
										fieldValue,
										fieldName
									)
								).then(() => {
									setMadeChange(true)
								})
							} else {
								dispatch(
									clickFunnelActions.asyncSaveFieldAction(
										fieldValue,
										fieldName
									)
								).then(() => {
									setMadeChange(true)
								})
							}
						}
					}}
					input={
						<OutlinedInput
							classes={{ input: outlinedInputClasses.input }}
						/>
					}
					renderValue={selected => {
						if (selected.length === 0 || !selected) {
							return <em>zum Auswählen anklicken</em>
						}

						if (
							fieldName === "qualification_educator" ||
							fieldName === "qualification" ||
							fieldName === "ongoing_education" ||
							fieldName === "ongoing_education_educator" ||
							fieldName === "total_work_experience"
						) {
							return (
								<div className={styles.chips}>
									<Chip
										key={selected}
										label={selected}
										className={styles.chip}
										onMouseDown={event => {
											event.stopPropagation()
										}}
										onDelete={() => {
											// Remove value from redux
											dispatch(
												clickFunnelActions.asyncSaveFieldAction(
													"",
													fieldName
												)
											)
										}}
									/>
								</div>
							)
						} else {
							return (
								<div className={styles.chips}>
									{selected.map(value => (
										<Chip
											key={value}
											label={value}
											className={styles.chip}
											onMouseDown={event => {
												event.stopPropagation()
											}}
											onDelete={() => {
												// Remove value from redux
												dispatch(
													clickFunnelActions.asyncRemoveCFItemMultipleChoiceAction(
														value,
														fieldName
													)
												).then(() => {
													setMadeChange(true)
												})
											}}
										/>
									))}
								</div>
							)
						}
					}}
					MenuProps={
						mobileQuery
							? {
									classes: { list: styles.list },
									PaperProps: {
										style: {
											maxHeight: "100vh",
											minWidth: 200,
											width: "100%",
											bottom:
												Capacitor.getPlatform() ===
												"ios"
													? "calc(16px + env(safe-area-inset-bottom))"
													: 16,
											top:
												Capacitor.getPlatform() ===
												"ios"
													? "calc(16px + env(safe-area-inset-top))"
													: 16,
										},
									},
									marginThreshold: 0,
									variant: "menu",
									getContentAnchorEl: null,
									anchorReference: "anchorPosition",
									anchorPosition: {
										top:
											Capacitor.getPlatform() === "ios"
												? "calc(16px + env(safe-area-inset-top))"
												: 16,
										left: 16,
										right: 16,
									},
							  }
							: {
									classes: { list: styles.list },
									PaperProps: {
										style: {
											maxHeight: "100vh",
											minWidth: 200,
											maxWidth: 552,
											width: "100%",
											bottom:
												Capacitor.getPlatform() ===
												"ios"
													? "calc(16px + env(safe-area-inset-bottom))"
													: 16,
											top:
												Capacitor.getPlatform() ===
												"ios"
													? "calc(16px + env(safe-area-inset-top))"
													: 16,
											marginTop: 16,
										},
									},
									marginThreshold: 0,
									variant: "menu",
									getContentAnchorEl: null,
									anchorEl: anchorRef.current,
									anchorOrigin: {
										horizontal: "center",
										vertical: "center",
									},
									transformOrigin: {
										horizontal: "center",
										vertical: "center",
									},
							  }
					}
				>
					{fieldOptions.map((option, id) => {
						if (option === "btn") {
							return (
								<ListItem
									key={option}
									value={option}
									className={styles.selectHeader}
									classes={{
										selected: styles.selectedOptionNone,
									}}
									divider
								>
									<Button
										key="btn"
										className={
											styles.closeMultipleChoiceListBtn
										}
										id="closeMultipleChoiceListBtn"
										color="primary"
										onClick={() => {
											setSelectOpen(false)
										}}
									>
										Fertig
									</Button>
								</ListItem>
							)
						} else {
							return (
								<ListItem
									key={option}
									value={option}
									className={
										optionsDisabled &&
										fieldName === "benefits" &&
										!benefitsSelected.includes(option)
											? styles.optionDisabled
											: styles.option
									}
									classes={{
										selected: styles.selectedOption,
									}}
									divider
								>
									<ListItemText
										primary={option}
										disableTypography
									/>
									<ListItemSecondaryAction>
										<Checkbox
											color="secondary"
											icon={
												<CheckOutlinedIcon
													className={
														styles.invisibleIcon
													}
												/>
											}
											checkedIcon={<CheckIcon />}
											checked={clickFunnelReducer.formData[
												fieldName
											].includes(option)}
										/>
									</ListItemSecondaryAction>
								</ListItem>
							)
						}
					})}
				</Select>
			</FormControl>
		</Grid>
	)
}

export default MultipleChoiceField
