import * as actions from "./clientsActions"

export const initialState = {
	loadedClientData: false,
}

export default function clientsReducer(state = initialState, action) {
	switch (action.type) {
		case actions.GET_CLIENT_DATA_SUCCESS:
			return {
				...action.payload,
				loadedClientData: true,
			}
		case actions.GET_CLIENT_DATA_INIT:
			return {
				...action.payload,
				loadedClientData: false,
			}
		case actions.GET_CHAIN_DATA_INIT:
			return {
				...action.payload,
				loadedClientData: false,
			}
		case actions.GET_CHAIN_DATA_SUCCESS:
			return {
				...action.payload,
				loadedClientData: true,
			}
		default:
			return state
	}
}
