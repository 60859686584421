import React from "react"

// Material UI
import { Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

// Styles
const useStyles = makeStyles({
	refLinkContainer: {
		marginTop: 24,
		width: "100%",
		overflowX: "auto",
		whiteSpace: "nowrap",
		scrollbarWidth: "none",
		"&::-webkit-scrollbar": {
			width: 0,
			height: 0,
		},
	},
	refLinkBtn: {
		fontSize: 12,
		marginBottom: 24,
		paddingLeft: 16,
		paddingRight: 16,
		marginRight: 16,
		borderRadius: 12,
		backgroundColor: "rgba(58, 93, 171, 0.1)",
		color: "#3a5dab",
		"&:hover": {
			backgroundColor: "#3a5dab",
			color: "white",
		},
	},
})

// Component
const ClientsNavigationMenu = props => {
	// Hooks
	const styles = useStyles()

	// Destructuring props
	const { refList, refLinkNames } = props

	// Render
	return (
		<div className={styles.refLinkContainer}>
			{refLinkNames.map((linkName, id) => (
				<Button
					key={id}
					className={styles.refLinkBtn}
					onClick={() => {
						refList[id].current.scrollIntoView({
							behavior: "smooth",
							block: "start",
							inline: "nearest",
						})
					}}
				>
					{linkName}
				</Button>
			))}
		</div>
	)
}

export default ClientsNavigationMenu
