// names
export const SEND_USER_REGISTERED_IN_APP_EVENT =
	"SEND USER REGISTERED IN APP EVENT"
export const SEND_GA_SUCCESS_PAGE_CUSTOM_EVENT =
	"SEND GA SUCCESS PAGE CUSTOM EVENT"
export const SEND_SIMPLE_CUSTOM_EVENT = "SEND SIMPLE CUSTOM EVENT"
export const SEND_COMPLEX_CUSTOM_EVENT = "SEND COMPLEX CUSTOM EVENT"

// dispatchers
export const sendUserRegisteredInAppEventAction = eventData => ({
	type: SEND_USER_REGISTERED_IN_APP_EVENT,
	payload: eventData,
})
export const sendGaSuccessPageCustomEventAction = eventData => ({
	type: SEND_GA_SUCCESS_PAGE_CUSTOM_EVENT,
	payload: eventData,
})
export const sendSimpleCustomEvent = eventData => ({
	type: SEND_SIMPLE_CUSTOM_EVENT,
	payload: eventData,
})
export const sendComplexCustomEvent = eventData => ({
	type: SEND_COMPLEX_CUSTOM_EVENT,
	payload: eventData,
})
