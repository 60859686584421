import * as actions from "./tagManagerActions"
import CryptoJS from "crypto-js"

// Capacitor
import { Capacitor } from "@capacitor/core"
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics"

export const initialState = {}

export default function notificationsReducer(state = initialState, action) {
	switch (action.type) {
		case actions.SEND_USER_REGISTERED_IN_APP_EVENT:
			window.dataLayer = window.dataLayer || []
			window.dataLayer.push({
				event: action.payload.eventName,
				email: action.payload.email,
			})

			return state
		case actions.SEND_GA_SUCCESS_PAGE_CUSTOM_EVENT:
			window.dataLayer = window.dataLayer || []
			window.dataLayer.push({
				event: action.payload.eventName,
				hashedEmail: CryptoJS.MD5(action.payload.email).toString(),
				jobId: Math.floor(Math.random() * 99999999999) + 1,
				criteoSalesConfirmationArrayFromCode: [
					{
						id: action.payload.id,
						price: "1",
						quantity: "1",
					},
				],
			})

			return state
		case actions.SEND_SIMPLE_CUSTOM_EVENT:
			window.dataLayer = window.dataLayer || []
			window.dataLayer.push({
				event: action.payload.eventName,
			})

			if (Capacitor.getPlatform() === "ios") {
				FirebaseAnalytics.logEvent({
					name: action.payload.eventName,
				})
			}

			return state
		case actions.SEND_COMPLEX_CUSTOM_EVENT:
			window.dataLayer = window.dataLayer || []
			window.dataLayer.push({
				...action.payload,
			})

			return state
		default:
			return state
	}
}
