import React, { useState } from "react"

// Form Components
import GoogleLocationAutocomplete from "./GoogleLocationAutocomplete"

// Material UI
import { Grid, Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

// Material UI icons
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"

// Redux
import { useSelector } from "react-redux"

// Custom Hooks
import useGoogleMaps from "../../../../hooks/useGoogleMaps"

// CSS
const useStyles = makeStyles({
	gridContainer: {
		marginTop: 10,
	},
	gridItem: {
		"& fieldset": {
			borderRadius: 12,
		},
		marginBottom: 12,
	},
})

// Component
const LocationField = props => {
	// Hooks
	const styles = useStyles()
	useGoogleMaps()

	// Destructuring props
	const { trackingFilteringClickEvent } = props

	// Redux selectors
	const jobsReducer = useSelector(state => state.jobsReducer)

	// Local state
	const [citiesNumber, setCitiesNumber] = useState(
		jobsReducer.filteringOptions.locations.length > 0
			? jobsReducer.filteringOptions.locations
			: [1]
	)

	// Render
	return (
		<div onClick={trackingFilteringClickEvent}>
			<Grid container className={styles.gridContainer}>
				{citiesNumber.map((city, id) => (
					<Grid item key={id} xs={12} className={styles.gridItem}>
						<GoogleLocationAutocomplete
							id={id}
							citiesNumber={citiesNumber}
							setCitiesNumber={setCitiesNumber}
							fieldName="locations"
						/>
					</Grid>
				))}

				{citiesNumber.length < 5 && (
					<Grid item xs={12}>
						<Button
							startIcon={
								<AddCircleOutlineIcon
									style={{ fontSize: 16 }}
								/>
							}
							onClick={() => {
								setCitiesNumber([...citiesNumber, 1])
							}}
						>
							{citiesNumber.length > 0
								? "Weitere Stadt angeben"
								: "Stadt angeben"}
						</Button>
					</Grid>
				)}
			</Grid>
		</div>
	)
}

export default LocationField
