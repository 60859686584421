import { createTheme } from "@material-ui/core/styles"

const workbeeTheme = createTheme({
	palette: {
		primary: {
			main: "#212121",
		},
		secondary: {
			main: "#e8b300",
		},
		action: {
			disabledBackground: "rgba(0, 0, 0, 0.8)",
			disabled: "white",
		},
	},
	overrides: {
		MuiToolbar: {
			regular: {
				"@media(min-width:600px)": {
					minHeight: 56,
				},
			},
		},
		MuiSelect: {
			select: {
				"&:focus": {
					backgroundColor: "white",
				},
				"&:active": {
					backgroundColor: "white",
				},
			},
		},
	},
})

export default workbeeTheme
