// Names
export const SET_CAN_DISPLAY = "SET CAN DISPLAY"
export const SET_OPEN = "SET OPEN"

// Dispatchers
export const setCanDisplayAction = payload => ({
	type: SET_CAN_DISPLAY,
	payload: payload,
})
export const setOpenAction = payload => ({
	type: SET_OPEN,
	payload: payload,
})
