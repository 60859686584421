import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"

// Redux
import * as notificationsActions from "../notifications/state/notificationsActions"
import { useDispatch } from "react-redux"

// Firebase
import { firebaseAuth } from "../firebase"
import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth"

// Material UI
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Paper from "@material-ui/core/Paper"

// Styles
const useStyles = makeStyles({
	container: {
		marginTop: 100,
		maxWidth: 500,
	},
	paper: {
		padding: 12,
	},
	paragraph: {
		marginBottom: 20,
	},
	emailField: {
		width: "100%",
	},
	btnGridItem: {
		marginTop: 10,
		display: "flex",
		justifyContent: "center",
	},
})

// Component
const EmailVerification = () => {
	// Hooks
	const history = useHistory()
	const styles = useStyles()
	const dispatch = useDispatch()

	// Variables
	const initialMail = window.localStorage.getItem("emailForSignIn")

	// Local state
	const [email, setEmail] = useState(
		window.localStorage.getItem("emailForSignIn") === null
			? ""
			: window.localStorage.getItem("emailForSignIn")
	)
	const [showLoginField, setShowLoginField] = useState(false)
	const [effectFlag, setEffectFlag] = useState(true)

	// Effects
	useEffect(() => {
		if (!initialMail) {
			setShowLoginField(true)
		} else if (initialMail) {
			setShowLoginField(false)
		}
	}, [initialMail])

	useEffect(() => {
		if (
			effectFlag &&
			email &&
			isSignInWithEmailLink(firebaseAuth, window.location.href)
		) {
			signInWithEmailLink(firebaseAuth, email, window.location.href)
				.then(result => {
					// URL Parameters
					const queryString = window.location.search
					const urlParams = new URLSearchParams(queryString)
					const emailVerifiedParam =
						urlParams.get("email_verification")

					// Redirect
					if (localStorage.getItem("shareJobId")) {
						const jobId = localStorage.getItem("shareJobId")
						history.push(`/jobs/${jobId}`)
						localStorage.removeItem("shareJobId")
					} else {
						history.push("/")
					}

					// Send notification
					if (emailVerifiedParam) {
						dispatch(
							notificationsActions.sendNotificationAction({
								open: true,
								message:
									"Danke, dass du deine E-Mail-Adresse bestätigt hast. Dein Profil ist nun vollständig.",
								type: "info",
								timeout: 8000,
							})
						)
					}
				})
				.catch(error => {
					console.log(error)

					history.push("/jobs")

					dispatch(
						notificationsActions.sendNotificationAction({
							open: true,
							message:
								"Link abgelaufen, melde dich erneut an, um einen neuen Link zu erhalten.",
							type: "info",
							timeout: 5000,
						})
					)
				})
		}
	})

	// Component
	if (showLoginField) {
		return (
			<Container className={styles.container}>
				<Paper elevation={3} className={styles.paper}>
					<Grid container>
						<Grid item xs={12}>
							<p className={styles.paragraph}>
								Bitte gib deine E-Mail zur Bestätigung an
							</p>
						</Grid>
						<Grid item xs={12}>
							<TextField
								label="E-Mail Adresse"
								variant="outlined"
								id="confirmEmail"
								className={styles.emailField}
								onKeyDown={e => {
									if (e.key === "Enter") {
										e.target.blur()
									}
								}}
								onChange={e => {
									setEffectFlag(false)
									setEmail(e.target.value)
								}}
								value={email}
							/>
						</Grid>
						<Grid item xs={12} className={styles.btnGridItem}>
							<Button
								variant="contained"
								color="secondary"
								id="confirmEmailBtn"
								disabled={!email}
								onClick={() => {
									signInWithEmailLink(
										firebaseAuth,
										email,
										window.location.href
									)
										.then(result => {
											history.push("/")
										})
										.catch(error => {
											if (
												error.code ===
												"auth/invalid-email"
											) {
												dispatch(
													notificationsActions.sendNotificationAction(
														{
															open: true,
															message:
																"Ungültige E-Mail, versuche es erneut.",
															type: "info",
															timeout: 5000,
														}
													)
												)
											} else if (
												error.code ===
												"auth/invalid-action-code"
											) {
												dispatch(
													notificationsActions.sendNotificationAction(
														{
															open: true,
															message:
																"Link abgelaufen, melde dich erneut an, um einen neuen Link zu erhalten.",
															type: "info",
															timeout: 5000,
														}
													)
												)

												history.push("/jobs")
											}
										})
								}}
							>
								Bestätigen
							</Button>
						</Grid>
					</Grid>
				</Paper>
			</Container>
		)
	} else {
		return null
	}
}

export default EmailVerification
