/* eslint no-undef: 0 */ // --> OFF

import React, { useState, useEffect } from "react"

// SEO
import generalSeoSchema from "../generalSeoSchema"
import { Helmet } from "react-helmet"

// Redux
import { useDispatch, useSelector } from "react-redux"
import * as profileActions from "../profile/state/profileActions"

// Material UI
import { useTheme, makeStyles } from "@material-ui/core/styles"
import {
	Button,
	Container,
	Grid,
	Divider,
	Select,
	MenuItem,
	FormControl,
	CircularProgress,
	useMediaQuery,
	Paper,
} from "@material-ui/core"

// Material UI Icons
import SaveIcon from "@material-ui/icons/Save"

// Styles
const useStyles = makeStyles({
	container: {
		display: "flex",
		justifyContent: "center",
		marginBottom: 40,
		marginTop: 80,
	},
	formField: {
		display: "flex",
		justifyContent: "center",
		marginBottom: 15,
		maxWidth: 400,
	},
	signoutBtn: {
		marginBottom: 10,
	},
	divider: {
		width: "100%",
		marginTop: 15,
		marginBottom: 15,
	},
	deleteAccountConfirmationBtns: {
		marginTop: 10,
		marginRight: 10,
	},
	saveProfileDataBtnContainer: {
		width: "100%",
		position: "sticky",
		bottom: 0,
		borderRadius: 0,
		paddingTop: 10,
		paddingBottom: 10,
		backgroundColor: "rgb(255,255,255)",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		boxShadow: "rgba(0, 0, 0, 0.87) 0px 0px 1px",
		zIndex: 1,
	},
	saveProfileDataBtn: {
		color: "#fafafa",
		backgroundColor: "#63c085",
		"&:hover": {
			color: "#fafafa",
			backgroundColor: "#2f8f58",
		},
		"&:disabled": {
			backgroundColor: "#2f8f58",
		},
	},
	contentLeft: {
		marginLeft: 200,
	},
	paper: {
		width: "100%",
		padding: 12,
		borderRadius: 12,
		fontFamily: "'Nunito', sans-serif",
	},
	deleteBtn: {
		marginTop: 20,
	},
	headermargin: {
		marginTop: 15,
	},
	buttonContainer: {
		display: "flex",
		flexDirection: "column",
		"& > *:not(:last-child)": {
			marginBottom: "16px",
		},
	},
	button: {
		width: "160px",
		textAlign: "left",
	},
})

// Component
const SettingsPage = () => {
	// Hooks
	const dispatch = useDispatch()
	const styles = useStyles()
	const theme = useTheme()

	// Media queries
	const mediaQueryMdUp = useMediaQuery(theme.breakpoints.up("md"))

	// Local state
	let [timer, setTimer] = useState(null)
	const [deleteAccountBtnClicked, setDeleteAccountBtn] = useState(false)
	const [showDeleteAccountPassField, setDeleteAccountPassField] =
		useState(false)

	// Redux selectors
	const loadingScreenReducer = useSelector(
		state => state.loadingScreenReducer
	)
	const profileReducer = useSelector(state => state.profileReducer)
	const authReducer = useSelector(state => state.authReducer)

	// Variables
	const workbeeLinks = [
		{ name: "AGB", href: "https://workbee.de/agb/" },
		{
			name: "Datenschutz",
			href: "https://workbee.de/datenschutz/#plattform",
		},
		{ name: "Impressum", href: "https://workbee.de/impressum/" },
	]
	const workbeeContactLinks = [
		{ name: "Feedback", href: "https://workbee.de/kontakt/" },
	]

	// Email opt out variables
	let fieldName = "email_opt_out"
	let fieldValue = profileReducer.profileData
		? profileReducer.profileData[fieldName]
		: ""

	// Get candidate data if necessary
	useEffect(() => {
		if (
			authReducer?.userLoggedIn &&
			authReducer?.email &&
			!profileReducer?.changes?.profileDataLoaded
		) {
			dispatch(profileActions.asyncDetermineUserType(authReducer.email))
		}
	}, [
		authReducer.email,
		authReducer.userLoggedIn,
		profileReducer.changes.profileDataLoaded,
		dispatch,
	])

	// Render
	return (
		<Container className={styles.container}>
			{/* SEO */}
			<Helmet>
				<title>Workbee - Einstellungen</title>
				<meta name="title" content="Workbee - Einstellungen" />
				<meta name="description" content="App Einstellungen" />
				<script type="application/ld+json">
					{JSON.stringify(generalSeoSchema)}
				</script>
			</Helmet>

			{/* Settings */}
			{!loadingScreenReducer.open && (
				<Grid
					container
					className={mediaQueryMdUp ? styles.contentLeft : ""}
				>
					<Paper className={styles.paper}>
						{authReducer?.userLoggedIn &&
							profileReducer?.changes?.userType ===
								"candidate" && (
								<Grid item xs={12}>
									<h3 className={styles?.headermargin}>
										Job-Empfehlungen
									</h3>
									<p>
										Hier kannst du wählen, ob du von Workbee
										mit Jobvorschlägen kontaktiert werden
										möchtest.
									</p>
									<FormControl
										className={styles.formField}
										variant="outlined"
									>
										<Select
											id={`userInputFieldSelect${fieldName}`}
											defaultValue={
												fieldValue === null
													? ""
													: fieldValue
											}
											value={
												fieldValue === null
													? ""
													: fieldValue
											}
											inputProps={{
												"aria-label": "Without label",
											}}
											onChange={el => {
												let newVal = el.target.value
												clearTimeout(timer)
												setTimer(
													setTimeout(() => {
														dispatch(
															profileActions.checkProfileChangesAction()
														)
													}, 500)
												)
												dispatch(
													profileActions.changeProfileFieldAction(
														fieldName,
														newVal,
														"profileData"
													)
												)
											}}
										>
											<MenuItem value={true}>
												Nein, ich möchte keine
												Vorschläge erhalten
											</MenuItem>
											<MenuItem value={false}>
												Ja, ich möchte Vorschläge
												erhalten
											</MenuItem>
										</Select>
									</FormControl>
									{profileReducer?.changes?.canUpdate && (
										<div
											className={
												styles.saveProfileDataBtnContainer
											}
										>
											<Button
												id="saveProfileDataBtn"
												className={
													styles.saveProfileDataBtn
												}
												variant="contained"
												color="primary"
												disabled={
													profileReducer.changes
														.isUpdating
												}
												startIcon={
													profileReducer.changes
														.isUpdating ? (
														<CircularProgress
															className={
																styles.spinner
															}
															size={20}
															thickness={3}
															color="secondary"
														/>
													) : (
														<SaveIcon />
													)
												}
												onClick={() => {
													dispatch(
														profileActions.asyncPutProfileDataAction(
															{
																prepareCandidate: false,
																verifyEmail: false,
																refreshJobs: false,
															}
														)
													)
												}}
											>
												{profileReducer.changes
													.isUpdating
													? "Daten werden gespeichert..."
													: "Neue Daten speichern"}
											</Button>
										</div>
									)}

									<Divider className={styles.divider} />
								</Grid>
							)}

						<Grid item xs={12}>
							<h3 className={styles?.headermargin}>Cookies</h3>
							<p>
								Hier kannst du deine Cookie-Einstellungen
								ändern.
							</p>
							<Button
								variant="outlined"
								color="primary"
								onClick={() => {
									Cookiebot.renew()
								}}
							>
								Cookie Einstellungen
							</Button>
							{authReducer?.userLoggedIn && (
								<Divider className={styles.divider} />
							)}
						</Grid>

						{authReducer?.userLoggedIn && (
							<Grid item xs={12}>
								<h3 className={styles?.headermargin}>Konto</h3>
								<p>Hier kannst du dein Konto löschen.</p>
								<Button
									className={styles.signoutBtn}
									variant="outlined"
									color="primary"
									id="deleteUserBtn"
									onClick={() => {
										if (deleteAccountBtnClicked) {
											setDeleteAccountBtn(false)
										} else {
											setDeleteAccountBtn(true)
										}
									}}
								>
									Konto löschen
								</Button>
								{deleteAccountBtnClicked && (
									<Grid container>
										<Grid item xs={12}>
											Möchtest du dein Konto wirklich
											löschen? ACHTUNG: Deine eventuell
											noch laufenden Bewerbungen werden
											dann ebenfalls gelöscht und der
											Bewerbungsprozess nicht weiter
											fortgeführt.
										</Grid>
										<Grid item xs={12}>
											<Button
												variant="contained"
												color="primary"
												onClick={() => {
													setDeleteAccountPassField(
														false
													)
													setDeleteAccountBtn(false)
												}}
												className={
													styles.deleteAccountConfirmationBtns
												}
											>
												Nein
											</Button>
											<Button
												variant="contained"
												color="primary"
												id="deleteUserBtnYes"
												className={
													styles.deleteAccountConfirmationBtns
												}
												onClick={() => {
													setDeleteAccountPassField(
														true
													)
												}}
											>
												Ja
											</Button>
										</Grid>
										{showDeleteAccountPassField && (
											<Grid item xs={12}>
												<Button
													variant="contained"
													color="primary"
													className={styles.deleteBtn}
													id="deleteUserBtnFinal"
													onClick={() => {
														if (
															profileReducer
																?.changes
																?.userType ===
															"candidate"
														) {
															dispatch(
																profileActions.asyncRemoveZohoProfileAction()
															)
														} else if (
															profileReducer
																?.changes
																?.userType ===
															"affiliate"
														) {
															dispatch(
																profileActions.asyncRemovePromoterProfileAction()
															)
														}
													}}
												>
													Bestätigen
												</Button>
											</Grid>
										)}
									</Grid>
								)}
								<Divider className={styles.divider} />
							</Grid>
						)}

						{!authReducer?.userLoggedIn && (
							<Divider className={styles.divider} />
						)}

						{!Capacitor.isNativePlatform() && (
							<>
								<Grid item xs={12}>
									<h3 className={styles?.headermargin}>
										Rechtliches
									</h3>
									<p>
										Hier findest du alle wichtigen
										rechtlichen Informationen
									</p>
									{/* Company related links */}
									<div className={styles.buttonContainer}>
										{workbeeLinks.map((el, id) => (
											<Button
												variant="outlined"
												color="primary"
												onClick={() => {
													window.open(
														el.href,
														"_blank"
													)
												}}
												key={id}
												className={styles.button}
											>
												{el.name}
											</Button>
										))}
									</div>
								</Grid>

								{/* Divider */}
								<Divider className={styles.divider} />

								<Grid item xs={12}>
									<h3 className={styles?.headermargin}>
										Kontakt
									</h3>
									<p>
										Hier kannst du uns dein Feedback geben{" "}
									</p>
									{/* Company related links */}
									<div className={styles.buttonContainer}>
										{workbeeContactLinks.map((el, id) => (
											<Button
												variant="outlined"
												color="primary"
												onClick={() => {
													window.open(
														el.href,
														"_blank"
													)
												}}
												key={id}
												className={styles.button}
											>
												{el.name}
											</Button>
										))}
									</div>
								</Grid>
							</>
						)}
					</Paper>
				</Grid>
			)}
		</Container>
	)
}

export default SettingsPage
