import React from "react"

// Helper function
import { loadScript } from "../helpers/loadScript"

function useGoogleMaps() {
	// Refs
	const loaded = React.useRef(false)

	if (typeof window !== "undefined" && !loaded.current) {
		if (!document.querySelector("#google-maps")) {
			const googleMapsApiKey = "AIzaSyC0U2vwxb93nHKjBN1FN05neuczSokjp3Y"

			loadScript(
				`https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places&language=de&region=de&callback=Function.prototype`,
				document.querySelector("head"),
				"google-maps"
			)
		}

		loaded.current = true
	}
}

export default useGoogleMaps
