import React, { useState, useEffect } from "react"

// Material UI
import { TextField, Grid, Typography, InputAdornment } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Autocomplete from "@material-ui/lab/Autocomplete"

// Icons
import locationIcon from "../images/wordpress/locationIcon.png"

// Utils
import parse from "autosuggest-highlight/parse"
import throttle from "lodash/throttle"

const autocompleteService = { current: null }

// Styles
const useStyles = makeStyles(theme => ({
	icon: {
		color: theme.palette.text.secondary,
		marginRight: theme.spacing(2),
	},
	deleteLocationBtn: {
		cursor: "pointer",
		position: "relative",
		left: 25,
	},
	formField: {
		display: "flex",
		justifyContent: "center",
	},
	inputField: {
		padding: 0,
		margin: 0,
		width: "100%",
		display: "flex",
		borderRadius: 12,
		"& .MuiOutlinedInput-root": {
			paddingLeft: 0,
			paddingRight: 0,
		},
		"& .MuiAutocomplete-inputRoot": {
			padding: 0,
		},
	},
	inputAdornment: {
		backgroundColor: "white",
		padding: "25px 15px",
		borderTopLeftRadius: 12,
		borderBottomLeftRadius: 12,
		marginLeft: 0,
		marginRight: 0,
	},
	locationIcon: {
		width: 24,
		height: 24,
	},
}))

const useOutlinedInputStyles = makeStyles(theme => ({
	root: {
		backgroundColor: "white",
		borderRadius: 12,
	},
}))

// Component
const GoogleLocationAutocomplete = props => {
	// Hooks
	const styles = useStyles()
	const outlinedInputClasses = useOutlinedInputStyles()

	// Destructuring props
	const { setLocation } = props

	// Local state
	const [value, setValue] = useState("")
	const [inputValue, setInputValue] = useState("")
	const [options, setOptions] = useState([])

	// Memo
	const fetch = React.useMemo(
		() =>
			throttle((request, callback) => {
				autocompleteService.current.getPlacePredictions(
					{
						...request,
						fields: ["geometry", "name"],
						types: ["locality", "sublocality", "postal_code"],
						componentRestrictions: { country: "de" },
					},
					callback
				)
			}, 200),
		[]
	)

	// Set results effects
	useEffect(() => {
		let active = true

		if (!autocompleteService.current && window.google) {
			autocompleteService.current =
				new window.google.maps.places.AutocompleteService()
		}
		if (!autocompleteService.current) {
			return undefined
		}

		if (inputValue === "") {
			setOptions(value ? [value] : [])
			return undefined
		}

		fetch({ input: inputValue }, results => {
			if (active) {
				let newOptions = []

				if (value) {
					newOptions = [value]
				}

				if (results) {
					results = results.filter(el => {
						return (
							el.types.includes("locality") ||
							el.types.includes("sublocality")
						)
					})
					newOptions = [...newOptions, ...results]
					newOptions = newOptions
						.filter(el => {
							return JSON.stringify(el) !== "[]"
						})
						.flat()
				}

				setOptions(newOptions)
			}
		})

		return () => {
			active = false
		}
	}, [value, inputValue, fetch])

	// Render
	return (
		<Autocomplete
			id="google-map-demo"
			classes={{ root: outlinedInputClasses.root }}
			getOptionLabel={option => {
				if (typeof option === "string") {
					return option
				}
				if (option.description) {
					return option.description
				}
				if (option.location_name) {
					return option.location_name
				}
				return ""
			}}
			className={styles.inputField}
			filterOptions={x => x}
			options={options}
			autoComplete
			disableClearable
			noOptionsText={"Gib deinen gewünschten Ort ein"}
			includeInputInList
			filterSelectedOptions
			value={value}
			onChange={(event, newValue) => {
				setOptions(newValue ? [newValue, ...options] : options)
				setValue(newValue)
				setLocation(newValue.description)
			}}
			onInputChange={(event, newInputValue) => {
				setInputValue(newInputValue)
			}}
			renderInput={params => (
				<TextField
					{...params}
					color="primary"
					variant="outlined"
					fullWidth
					placeholder="Ort oder Region eingeben"
					InputProps={{
						...params.InputProps,
						startAdornment: (
							<InputAdornment
								position="start"
								className={styles.inputAdornment}
							>
								<img
									className={styles.locationIcon}
									src={locationIcon}
									alt="search"
								/>
							</InputAdornment>
						),
					}}
				/>
			)}
			renderOption={option => {
				const matches =
					option.structured_formatting.main_text_matched_substrings
				const parts = parse(
					option.structured_formatting.main_text,
					matches.map(match => [
						match.offset,
						match.offset + match.length,
					])
				)

				return (
					<Grid container alignItems="center">
						<Grid item xs>
							{parts.map((part, index) => (
								<span
									key={index}
									style={{
										fontWeight: part.highlight ? 700 : 400,
									}}
								>
									{part.text}
								</span>
							))}

							<Typography variant="body2" color="textSecondary">
								{option.structured_formatting.secondary_text}
							</Typography>
						</Grid>
					</Grid>
				)
			}}
		/>
	)
}

export default GoogleLocationAutocomplete
