import React from "react"

// Material UI
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { Grid, useMediaQuery } from "@material-ui/core"

// Redux
import { useSelector } from "react-redux"

// Styles
const useStyles = makeStyles({
	benefitGridItem: {
		display: "flex",
		alignItems: "center",
		marginBottom: 12,
		marginTop: 12,
		marginLeft: 16,
		marginRight: 16,
		"&:first-child": {
			marginTop: 16,
		},
		"&:last-child": {
			marginBottom: 24,
		},
	},
	benefitGridItemPc: {
		display: "flex",
		alignItems: "center",
		marginBottom: 24,
		marginTop: 12,
		marginLeft: 16,
		marginRight: 16,
	},
	benefitSymbolContainerGridItem: {
		display: "flex",
		alignItems: "center",
	},
	benefitSymbol: {
		width: 48,
		height: 48,
		marginRight: 8,
	},
	benefitName: {
		fontSize: 14,
		fontWeight: 600,
		wordBreak: "break-word",
		margin: 0,
	},
})

// Component
const ClientsTopBenefits = (props) => {
	// Hooks
	const styles = useStyles()
	const theme = useTheme()

	// Redux selectors
	const clientsReducer = useSelector((state) => state.clientsReducer)

	// Media queries
	const mediaQueryMdUp = useMediaQuery(theme.breakpoints.up("md"))

	// Render
	return (
		<>
			{clientsReducer.benefit_icons.map((benefit, id) => {
				if (id < 3) {
					return (
						<Grid
							item
							xs={12}
							key={id}
							md
							className={
								mediaQueryMdUp
									? styles.benefitGridItemPc
									: styles.benefitGridItem
							}
						>
							<img
								className={styles.benefitSymbol}
								src={benefit.benefit_icon_link}
								alt="benefit"
							/>
							<p className={styles.benefitName}>
								{benefit.benefit_text}
							</p>
						</Grid>
					)
				} else {
					return null
				}
			})}
		</>
	)
}

export default ClientsTopBenefits
