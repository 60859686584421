// Utils
import sanitizeHtml from "sanitize-html"

const preparePromoter = (clickFunnelReducer, requestType) => {
	// Preparing a candidate payload for the backend
	let promoterPayload = { ...clickFunnelReducer.formData }

	let questionsAnswered = [
		"salutation",
		"first_name",
		"last_name",
		"phone",
		"email",
	]

	Object.keys(promoterPayload).forEach(el => {
		if (!questionsAnswered.includes(el)) {
			delete promoterPayload[el]
		}
	})

	// Removing number extension from phone (if present)
	promoterPayload.phone = promoterPayload.phone.replace(/\+49/g, "")

	// Special diverse gender case
	if (
		promoterPayload.salutation === "keine Angabe" ||
		promoterPayload.salutation === "None"
	) {
		promoterPayload.salutation = ""
	}

	// Sanitizing free text input
	for (var key in promoterPayload) {
		if (
			promoterPayload.hasOwnProperty(key) &&
			(key === "first_name" ||
				key === "last_name" ||
				key === "email" ||
				key === "occupational_group_free_text")
		) {
			promoterPayload[key] = sanitizeHtml(promoterPayload[key])
		}
	}

	if (requestType === "postRequest") {
		// Set email verified as false
		promoterPayload.email_verified = false

		// Remove email confirmation field
		delete promoterPayload.email_confirmation
	}

	return promoterPayload
}

export default preparePromoter
