import React, { useState, useEffect } from "react"

// Material UI
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import useMediaQuery from "@material-ui/core/useMediaQuery"

// Data Structures
import funnelFieldOptions from "../dataStructures/funnelFieldOptions"

// Custom Hooks
import useManageQuestions from "../funnelLogicHooks/useManageQuestions"

// Redux
import { useDispatch, useSelector } from "react-redux"
import * as clickFunnelActions from "../state/clickFunnelActions"

const useStyles = makeStyles({
	gridItem: {
		"&:last-child": { marginBottom: 49 },
	},
	option: {
		width: "100%",
		minHeight: 50,
		borderRadius: 12,
		justifyContent: "center",
		textTransform: "none",
		textAlign: "center",
		fontSize: 14,
		color: "#333333",
		backgroundColor: "white",
		border: "1px solid rgba(0,0,0,0.25)",
		wordBreak: "break-word",
		"&:hover": {
			backgroundColor: "white",
			border: "1px solid rgba(0,0,0,0.25)",
		},
	},
	optionSelected: {
		backgroundColor: "#fff5cc",
		border: "1px solid #e8b300",
		"&:hover": {
			backgroundColor: "#fff5cc",
			border: "1px solid #e8b300",
		},
	},
})

const SingleChoiceField = props => {
	// Hooks
	const dispatch = useDispatch()
	const styles = useStyles()
	const { manageQuestions } = useManageQuestions()
	const mediaQueryMdUp = useMediaQuery("(min-width:450px)")

	// Destructuring props
	const { id, clickedField, setClickedField, fieldName, fieldValue } = props

	// Redux selectors
	const clickFunnelReducer = useSelector(state => state.clickFunnelReducer)

	// Local state
	const [madeChange, setMadeChange] = useState(false)

	// Add/hide questions, move to next slide (if necessary)
	useEffect(() => {
		if (madeChange) {
			setMadeChange(false)
			manageQuestions(props)
		}
	}, [madeChange, clickFunnelReducer, props, manageQuestions])

	// Render
	return (
		<Grid
			item
			key={id}
			className={styles.gridItem}
			xs={
				(fieldName === "qualification" ||
					fieldName === "occupational_group") &&
				!mediaQueryMdUp
					? 12
					: funnelFieldOptions[fieldName].length <= 2
					? 5
					: 6
			}
		>
			<Button
				variant="outlined"
				color="primary"
				disableRipple
				disableTouchRipple
				id={`clickFunnelField_${fieldName}_${fieldValue}`}
				className={
					clickFunnelReducer.formData[fieldName] === fieldValue
						? `${styles.option} ${styles.optionSelected}`
						: styles.option
				}
				value={fieldValue}
				onClick={() => {
					// make it clear that button was un/clicked and send value to redux
					let newData = clickedField.filter(
						el => !el.includes(`clickFunnelField_${fieldName}`)
					)

					setClickedField([
						...newData,
						`clickFunnelField_${fieldName}_${fieldValue}`,
					])

					// set value for this field in redux state
					dispatch(
						clickFunnelActions.asyncSaveFieldAction(
							fieldValue,
							fieldName
						)
					).then(() => {
						setMadeChange(true)
					})
				}}
				key={id}
			>
				{fieldValue}
			</Button>
		</Grid>
	)
}

export default SingleChoiceField
