import React from "react"
import { Link } from "react-router-dom"

// Redux
import { useSelector } from "react-redux"

// Material UI
import { Container, Paper, Grid } from "@material-ui/core"

// Component
const NoJobsInfo = props => {
	// Destructuring props
	const { styles } = props

	// Redux selectors
	const authReducer = useSelector(state => state.authReducer)

	// Render
	return (
		<Container
			className={
				authReducer && authReducer.userLoggedIn
					? styles.containerLoggedIn
					: styles.containerDefault
			}
		>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<Paper elevation={2} className={styles.paper}>
						{authReducer && authReducer.userLoggedIn ? (
							<p className={styles.noJob}>
								Dieser Job existiert nicht mehr. Geh einfach auf
								die Jobs{" "}
								<Link to={"/jobs"} className={styles.link}>
									hier
								</Link>
								, um die neuesten Angebote zu erhalten!
							</p>
						) : (
							<p className={styles.noJob}>
								Dieser Job existiert nicht mehr. Melde dich
								bitte{" "}
								<Link to={"/login"} className={styles.link}>
									hier
								</Link>{" "}
								an, um die neuesten Angebote zu erhalten!
							</p>
						)}
					</Paper>
				</Grid>
			</Grid>
		</Container>
	)
}

export default NoJobsInfo
