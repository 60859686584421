import React, { useState, useEffect } from "react"

// Components
import JobCard from "../jobs/components/jobsListPage/JobCard"

// Material UI
import { makeStyles } from "@material-ui/core/styles"
import { Grid } from "@material-ui/core"

// Redux
import { useSelector } from "react-redux"

// Firebase
import { ref, listAll, getDownloadURL } from "firebase/storage"
import { firebaseStorage } from "../firebase"

// Styles
const useStyles = makeStyles({
	sectionHeader: {
		fontSize: 20,
		fontWeight: 600,
		lineHeight: 1.5,
		marginBottom: 16,
		textAlign: "center",
	},
	sectionGridContainerJobs: {
		width: "100%",
		maxWidth: 560,
		margin: "0 auto",
		marginBottom: 48,
	},
})

// Component
const ClientsCurrentJobs = props => {
	// Hooks
	const styles = useStyles()

	// Destructuring props
	const { currentJobsRef } = props

	// Local state
	const [jobsApplied, setJobsApplied] = useState([])
	const [getJobsAppliedFlag, setGetJobsAppliedFlag] = useState(true)
	const [logoSrcs, setLogoSrcs] = useState([])

	// Redux selectors
	const clientsReducer = useSelector(state => state.clientsReducer)
	const profileReducer = useSelector(state => state.profileReducer)

	// Show which jobs where applied to
	useEffect(() => {
		if (profileReducer.changes.profileDataLoaded && getJobsAppliedFlag) {
			setGetJobsAppliedFlag(false)

			if (profileReducer.changes?.job_opening_ids?.length > 0) {
				const appliedJobs = [...profileReducer.changes.job_opening_ids]

				setJobsApplied(appliedJobs)
			} else {
				setJobsApplied([])
			}
		}
	}, [profileReducer, jobsApplied, getJobsAppliedFlag])

	// Grab logos
	useEffect(() => {
		if (
			clientsReducer.job_openings &&
			clientsReducer.job_openings.length > 0
		) {
			let allLogosSrc = []

			clientsReducer.job_openings.forEach(job => {
				const storageRef = ref(
					firebaseStorage,
					`JobOpenings/${job.zoho_job_openings_id}`
				)

				listAll(storageRef)
					.then(result => {
						if (result.items.length > 0) {
							const companyLogo = result.items.filter(el =>
								el.fullPath.includes("logo")
							)
							if (companyLogo.length > 0) {
								getDownloadURL(companyLogo[0])
									.then(url => {
										allLogosSrc = [
											...allLogosSrc,
											{
												url: url,
												zoho_client_id:
													job.zoho_client_id,
											},
										]
										setLogoSrcs(allLogosSrc)
									})
									.catch(error => {
										console.log(error)
										// Handle any errors
									})
							}
						}
					})
					.catch(error => {
						console.log(error)
					})
			})
		}
	}, [clientsReducer.job_openings])

	// Render
	return (
		<>
			{clientsReducer?.job_openings?.length > 0 && (
				<h2
					id="currentJobOffersHeading"
					className={styles.sectionHeader}
					ref={currentJobsRef}
				>
					Aktuelle Stellenangebote
				</h2>
			)}

			<Grid container className={styles.sectionGridContainerJobs}>
				<Grid item xs={12}>
					{/* jobs cards */}
					<JobCard
						fromClientsPage={true}
						jobs={clientsReducer.job_openings}
						url={"/jobs"}
						logoSrcs={logoSrcs}
						jobsApplied={jobsApplied}
						openInNewTab={true}
					/>
				</Grid>
			</Grid>
		</>
	)
}

export default ClientsCurrentJobs
