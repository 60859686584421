import React from "react"

// Material UI
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"

// Redux
import { useSelector } from "react-redux"

// CSS
import clickFunnelStyles from "../clickFunnelStyles"

const useStyles = makeStyles(theme => clickFunnelStyles(theme))

// Component
const ClickFunnelFieldText = props => {
	// Hooks
	const styles = useStyles()

	// Destructuring props
	const { currentQuestionObj, fieldName, fieldType } = props

	// Redux selectors
	const clickFunnelReducer = useSelector(state => state.clickFunnelReducer)
	const jobsReducer = useSelector(state => state.jobsReducer)

	// Render
	return (
		<Grid item xs={12}>
			{currentQuestionObj.fieldName !== "total_work_experience" && (
				<p className={styles.questionTextParagraph}>
					{currentQuestionObj.fieldText}
				</p>
			)}
			{currentQuestionObj.fieldName === "total_work_experience" && (
				<p className={styles.questionTextParagraph}>
					{jobsReducer?.job?.verticals?.length === 1
						? currentQuestionObj.fieldText[
								jobsReducer.job.verticals[0]
						  ]
						: currentQuestionObj.fieldText[
								clickFunnelReducer.formData.work_field
						  ]}
				</p>
			)}
			{fieldName !== "benefits" && fieldType === "multipleChoice" && (
				<p className={styles.moreChoicesParagraph}>
					Mehrfachnennungen möglich
				</p>
			)}
			{fieldType === "uploadAttachment" && (
				<p>
					Wenn du die Dokumente gerade nicht zur Hand hast, kannst du
					diesen Schritt überspringen und später nachholen. Die
					Dokumente kannst du als Scan oder Foto hochladen.
				</p>
			)}
		</Grid>
	)
}

export default ClickFunnelFieldText
