import React from "react"

// Material UI
import { makeStyles } from "@material-ui/core/styles"
import { Grid } from "@material-ui/core"

// Redux
import { useSelector } from "react-redux"

// Styles
const useStyles = makeStyles({
	sectionGridContainer: {
		width: "100%",
		maxWidth: 1000,
		margin: "0 auto",
		marginBottom: 48,
	},
	certificateImageGridItem: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		textAlign: "center",
		marginBottom: 6,
	},
	certificateImageContainer: {
		width: 84,
		height: 84,
		backgroundColor: "#ffffff",
		borderRadius: 4,
		border: "solid 1px #e8e8e8",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	certificateImage: {
		width: "80%",
		height: "80%",
	},
	certificateText: {
		wordBreak: "break-word",
		maxWidth: 150,
	},
	aboutUsGridItem: {
		textAlign: "left",
	},
	aboutUsParagraph: {
		fontSize: 16,
	},
})

// Component
const ClientsCertificatesAboutUs = props => {
	// Hooks
	const styles = useStyles()

	// Destructuring props
	const { certificates } = props

	// Redux selectors
	const clientsReducer = useSelector(state => state.clientsReducer)

	// Render
	return (
		<>
			{certificates?.length > 0 && (
				<Grid
					container
					justifyContent="center"
					className={styles.sectionGridContainer}
				>
					{certificates.map((certificate, id) => (
						<Grid
							item
							key={id}
							xs={12}
							sm={
								id === certificates.length - 1 &&
								certificates.length % 2 !== 0
									? 12
									: 12 / certificates.length
							}
							className={styles.certificateImageGridItem}
						>
							<div className={styles.certificateImageContainer}>
								<img
									src={certificate}
									alt="certificate"
									className={styles.certificateImage}
								/>
							</div>
						</Grid>
					))}
				</Grid>
			)}

			{clientsReducer.about_the_employer_1 && (
				<Grid container className={styles.sectionGridContainer}>
					<Grid item xs={12} className={styles.aboutUsGridItem}>
						<p className={styles.aboutUsParagraph}>
							{clientsReducer.about_the_employer_1}
						</p>
						<p className={styles.aboutUsParagraph}>
							{clientsReducer.about_the_employer_2}
						</p>
					</Grid>
				</Grid>
			)}
		</>
	)
}

export default ClientsCertificatesAboutUs
