// Data Structures
import funnelFieldOptions from "../dataStructures/funnelFieldOptions"

// Utils
import sanitizeHtml from "sanitize-html"

const prepareCandidate = (
	clickFunnelReducer,
	jobsReducer,
	questionTracker,
	requestType,
	profileReducer,
	authReducer
) => {
	// Preparing a candidate payload for the backend
	let candidatePayload = { ...clickFunnelReducer.formData }

	let questionsAnswered = []
	questionTracker.forEach(slide => {
		questionsAnswered = [...questionsAnswered, slide.questions]
	})
	questionsAnswered = questionsAnswered.flat()
	questionsAnswered = [
		...questionsAnswered,
		"first_name",
		"last_name",
		"zip_code",
		"phone",
		"email",
		"salutation",
	]

	Object.keys(candidatePayload).forEach(el => {
		if (!questionsAnswered.includes(el)) {
			delete candidatePayload[el]
		}
	})

	if (!candidatePayload.work_field) {
		if (candidatePayload.qualification) {
			candidatePayload.work_field = "Pflege & Medizin"
		} else {
			candidatePayload.work_field = "Pädagogik & Soziale Arbeit"
		}
	}

	// Mapping educator fields if necessary
	if (candidatePayload.work_field === "Pädagogik & Soziale Arbeit") {
		candidatePayload.qualification = candidatePayload.qualification_educator

		if (candidatePayload.qualification === "Weiteres") {
			// Adding occupational group as qualification if necessary
			candidatePayload.qualification =
				candidatePayload.occupational_group_educator
			candidatePayload.occupational_group_educator = ""
		}

		if (candidatePayload.choice_type_of_employer === "Alle sind okay") {
			candidatePayload.type_of_employer = [
				...funnelFieldOptions.type_of_employer_educator,
			]
		} else {
			candidatePayload.type_of_employer =
				candidatePayload.type_of_employer_educator
		}

		if (candidatePayload.ongoing_education_educator) {
			candidatePayload.ongoing_education =
				candidatePayload.ongoing_education_educator
		}

		delete candidatePayload.type_of_employer_educator
		delete candidatePayload.choice_extra_qualifications
		delete candidatePayload.choice_type_of_employer
		delete candidatePayload.qualification_educator
	}

	// Data cleaning for medical staff verticle
	if (candidatePayload.work_field === "Pflege & Medizin") {
		if (candidatePayload.qualification === "Weiteres") {
			// Adding occupational group as qualification if necessary
			candidatePayload.qualification = candidatePayload.occupational_group
			candidatePayload.occupational_group = ""
		}

		// Clearing choice questions
		if (candidatePayload.choice_departments === "Alle sind okay") {
			candidatePayload.departments = [...funnelFieldOptions.departments]
		}
		if (candidatePayload.choice_type_of_employer === "Alle sind okay") {
			candidatePayload.type_of_employer = [
				...funnelFieldOptions.type_of_employer,
			]
		}
		if (candidatePayload.choice_extra_qualifications === "Nein") {
			candidatePayload.extra_qualifications = []
		}

		delete candidatePayload.choice_departments
		delete candidatePayload.choice_extra_qualifications
		delete candidatePayload.choice_type_of_employer
	}

	// Cleaning work experience entries
	if (candidatePayload.work_experience) {
		candidatePayload.work_experience.forEach((el, id) => {
			if (id === 0) {
				if (el.company.trim() === "") {
					el.company = "keine Angabe"
				}

				if (el.period_length === "") {
					el.period_length = "Unter 1 Jahr"
				}
			} else {
				if (el.company.trim() === "" && el.period_length) {
					el.company = "keine Angabe"
				} else if (el.period_length === "" && el.company.trim()) {
					el.period_length = "Unter 1 Jahr"
				}
			}
		})
	} else {
		candidatePayload.work_experience = [
			{
				company: "keine Angabe",
				period_length: "Unter 1 Jahr",
			},
		]
	}

	// Removing empty work experience entries
	if (candidatePayload.work_experience) {
		candidatePayload.work_experience =
			candidatePayload.work_experience.filter(el => {
				if (el.company && el.period_length) {
					return true
				} else {
					return false
				}
			})
	}

	// Add job_opening_ids if avaialable, otherwise send an empty array
	if (
		jobsReducer.job &&
		jobsReducer.job.zoho_job_openings_id &&
		window.location.href.includes("type=specific")
	) {
		candidatePayload.job_opening_ids = [
			jobsReducer.job.zoho_job_openings_id,
		]
	} else {
		candidatePayload.job_opening_ids = []
	}

	// Education germany false by default
	candidatePayload.education_germany = false

	// Setting the opt_out
	candidatePayload.email_opt_out = clickFunnelReducer.formData.email_opt_out

	// Removing number extension from phone (if present)
	candidatePayload.phone = candidatePayload.phone.replace(/\+49/g, "")

	// Special diverse gender case
	if (
		candidatePayload.salutation === "keine Angabe" ||
		candidatePayload.salutation === "None"
	) {
		candidatePayload.salutation = ""
	}

	// Cleaning hours_per_week, drivers_license
	candidatePayload.hours_per_week = String(
		Number(candidatePayload.hours_per_week)
	)

	if (candidatePayload.hours_per_week) {
		if (
			Number(candidatePayload.hours_per_week) === 0 ||
			isNaN(Number(candidatePayload.hours_per_week))
		) {
			candidatePayload.hours_per_week = null
		}
		if (Number(candidatePayload.hours_per_week) < 0) {
			candidatePayload.hours_per_week = String(
				Math.abs(Number(candidatePayload.hours_per_week))
			)
		}
		if (Number(candidatePayload.hours_per_week) > 40) {
			candidatePayload.hours_per_week = "40"
		}
	}

	if (candidatePayload.drivers_license === "Ja") {
		candidatePayload.drivers_license = true
	} else {
		candidatePayload.drivers_license = false
	}

	// deduplication function for new location structure
	function uniqByLocationName(locations) {
		const seen = new Set()
		return locations.filter(location => {
			const isDuplicate = seen.has(location.location_name)
			seen.add(location.location_name)
			return !isDuplicate
		})
	}

	// processing location names function
	function processLocationNames(locations) {
		const pattern = /^([^,]+),\s+Stadt$/
		const replacement = "$1"

		return locations.map(location => {
			// remove 5 digit and trim
			location.location_name = location.location_name
				.replace(/\b\d{5}\b/g, "")
				.trim()

			// clean location names from the ", Stadt"
			location.location_name = location.location_name.replace(
				pattern,
				replacement
			)
			return location
		})
	}

	// Removing duplicates, clearing empty locations and postal codes
	if (candidatePayload.locations) {
		candidatePayload.locations = uniqByLocationName(
			candidatePayload.locations
		)
		candidatePayload.locations = candidatePayload.locations.filter(el => el)
		candidatePayload.locations = processLocationNames(
			candidatePayload.locations
		)
	}

	// Sanitizing free text input
	for (var key in candidatePayload) {
		if (
			candidatePayload.hasOwnProperty(key) &&
			(key === "first_name" ||
				key === "last_name" ||
				key === "email" ||
				key === "occupational_group_free_text")
		) {
			candidatePayload[key] = sanitizeHtml(candidatePayload[key])
		}
	}

	// Adding complete profile as true
	candidatePayload.complete_profile = true

	// Education is recognised if the candidate is a native speaker
	if (candidatePayload.german_level === "Muttersprache") {
		candidatePayload.education_recognized = "Ja, ist anerkannt"
	}

	if (requestType === "postRequest") {
		if (jobsReducer.job) {
			if (jobsReducer?.job?.type_of_employer) {
				// Add fields for specific candidates from job opening
				candidatePayload.type_of_employer = [
					jobsReducer.job.type_of_employer,
				]
			}

			if (
				jobsReducer.job.qualification &&
				jobsReducer.job.qualification.length &&
				jobsReducer.job.qualification.length > 0
			) {
				candidatePayload.qualification_of_interest =
					jobsReducer.job.qualification.map(
						el => el.qualification_name
					)
			}

			if (jobsReducer.job.departments) {
				candidatePayload.departments = jobsReducer.job.departments.map(
					el => el.department_name
				)
			}

			if (jobsReducer.job.city) {
				candidatePayload.locations = [{
					location_name: jobsReducer.job.city,
					coordinates: {
						latitude: jobsReducer.job.latitude,
						longitude: jobsReducer.job.longitude,
					},
				}]
			}

			if (localStorage.getItem("affiliateId")) {
				candidatePayload.a_id = Number(
					localStorage.getItem("affiliateId")
				)
				localStorage.removeItem("affiliateId")
			}
		}

		// Set email verified as false for new candidates
		if (profileReducer?.changes?.userType !== "affiliate") {
			candidatePayload.email_verified = false
		}

		// Remove email confirmation field
		delete candidatePayload.email_confirmation

		// Checking the diploma present field
		if (clickFunnelReducer.attachment.attachmentName) {
			candidatePayload.diploma_present = true
		} else {
			candidatePayload.diploma_present = false
		}
	} else if (requestType === "putRequest") {
		// Checking the diploma present field
		if (!candidatePayload.hasOwnProperty("diploma_present")) {
			candidatePayload.diploma_present = false
		}

		// Do not send zip code if it's empty
		if (!candidatePayload.zip_code) {
			delete candidatePayload.zip_code
		}
	}

	return candidatePayload
}

export default prepareCandidate
