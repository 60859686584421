// Utils
import sanitizeHtml from "sanitize-html"

const prepareCandidate = profileReducer => {
	// Preparing a candidate payload for the backend
	let newProfileData = { ...profileReducer }

	let filteredProfileData = { ...newProfileData.profileData }

	// Data cleaning for occupational_group
	if (filteredProfileData.qualification === "Weiteres") {
		filteredProfileData.qualification =
			filteredProfileData.occupational_group
		filteredProfileData.occupational_group = ""
	}

	// Data cleaning for employment_form
	if (
		filteredProfileData.employment_form.length === 1 &&
		filteredProfileData.employment_form.includes("Minijob")
	) {
		filteredProfileData.salary_expectation_month = ""
		filteredProfileData.hours_per_week = ""
	}

	if (
		filteredProfileData.employment_form.length === 1 &&
		filteredProfileData.employment_form.includes("Vollzeit")
	) {
		filteredProfileData.hours_per_week = ""
	}

	// Data cleaning for Ausbildung case
	if (filteredProfileData.qualification !== "Noch in Ausbildung") {
		filteredProfileData.ongoing_education = null
		filteredProfileData.education_finish_date = null
	}

	// Cleaning work experience entries
	if (filteredProfileData.total_work_experience === "Noch keine") {
		filteredProfileData.work_experience = [
			{
				company: "keine Angabe",
				period_length: "Unter 1 Jahr",
			},
		]
	}

	if (filteredProfileData.work_experience) {
		filteredProfileData.work_experience.forEach((el, id) => {
			if (id === 0) {
				if (el.company.trim() === "") {
					el.company = "keine Angabe"
				}

				if (el.period_length === "") {
					el.period_length = "Unter 1 Jahr"
				}
			} else {
				if (el.company.trim() === "" && el.period_length) {
					el.company = "keine Angabe"
				} else if (el.period_length === "" && el.company.trim()) {
					el.period_length = "Unter 1 Jahr"
				}
			}
		})
	} else {
		filteredProfileData.work_experience = [
			{
				company: "keine Angabe",
				period_length: "Unter 1 Jahr",
			},
		]
	}

	// Removing empty work experience entries
	if (filteredProfileData.work_experience) {
		filteredProfileData.work_experience =
			filteredProfileData.work_experience.filter(el => {
				if (el.company && el.period_length) {
					return true
				} else {
					return false
				}
			})
	}

	// Removing number extension from phone (if present)
	filteredProfileData.phone = filteredProfileData.phone.replace(/\+49/g, "")

	// Special diverse gender case
	if (
		filteredProfileData.salutation === "keine Angabe" ||
		filteredProfileData.salutation === "None"
	) {
		filteredProfileData.salutation = ""
	}

	if (filteredProfileData.hours_per_week) {
		if (
			Number(filteredProfileData.hours_per_week) === 0 ||
			isNaN(Number(filteredProfileData.hours_per_week))
		) {
			filteredProfileData.hours_per_week = null
		}
		if (Number(filteredProfileData.hours_per_week) < 0) {
			filteredProfileData.hours_per_week = String(
				Math.abs(Number(filteredProfileData.hours_per_week))
			)
		}
		if (Number(filteredProfileData.hours_per_week) > 40) {
			filteredProfileData.hours_per_week = 40
		}
	}

	// Cleaning hours_per_week
	filteredProfileData.hours_per_week = Number(
		filteredProfileData.hours_per_week
	)

	// deduplication function for new location structure
	function uniqByLocationName(locations) {
		const seen = new Set()
		return locations.filter(location => {
			const isDuplicate = seen.has(location.location_name)
			seen.add(location.location_name)
			return !isDuplicate
		})
	}

	// processing location names function
	function processLocationNames(locations) {
		const pattern = /^([^,]+),\s+Stadt$/
		const replacement = "$1"

		return locations.map(location => {
			// remove 5 digit and trim
			location.location_name = location.location_name
				.replace(/\b\d{5}\b/g, "")
				.trim()

			// clean location names from the ", Stadt"
			location.location_name = location.location_name.replace(
				pattern,
				replacement
			)
			return location
		})
	}

	// Removing duplicates, clearing empty locations and postal codes
	filteredProfileData.locations = uniqByLocationName(
		filteredProfileData.locations
	)
	filteredProfileData.locations = filteredProfileData.locations.filter(
		el => el
	)
	filteredProfileData.locations = processLocationNames(
		filteredProfileData.locations
	)

	// Sanitizing free text input
	for (var key in filteredProfileData) {
		if (
			filteredProfileData.hasOwnProperty(key) &&
			(key === "first_name" ||
				key === "last_name" ||
				key === "email" ||
				key === "occupational_group_free_text")
		) {
			filteredProfileData[key] = sanitizeHtml(filteredProfileData[key])
		}
	}

	// Education is recognised if the candidate is a native speaker
	if (filteredProfileData.german_level === "Muttersprache") {
		filteredProfileData.education_recognized = "Ja, ist anerkannt"
	}

	// Checking the diploma present field
	if (!filteredProfileData.hasOwnProperty("diploma_present")) {
		filteredProfileData.diploma_present = false
	}

	// Do not send zip code if it's empty
	if (!filteredProfileData.zip_code) {
		delete filteredProfileData.zip_code
	}

	return filteredProfileData
}

export default prepareCandidate
