import React, { useEffect } from "react"

// Capacitor
import { PushNotifications } from "@capacitor/push-notifications"

const NativePushNotifications = () => {
	useEffect(() => {
		console.log("Initializing HomePage")

		// Request permission to use push notifications
		// iOS will prompt user and return if they granted permission or not
		// Android will just grant without prompting
		PushNotifications.requestPermissions().then(result => {
			if (result.receive === "granted") {
				// Register with Apple / Google to receive push via APNS/FCM
				PushNotifications.register()
			} else {
				// Show some error
			}
		})

		PushNotifications.addListener("registration", token => {
			console.log("Push registration success, token: " + token.value)
		})

		PushNotifications.addListener("registrationError", error => {
			console.log("Error on registration: " + JSON.stringify(error))
		})

		PushNotifications.addListener(
			"pushNotificationReceived",
			notification => {
				console.log("Push received: " + JSON.stringify(notification))
			}
		)

		PushNotifications.addListener(
			"pushNotificationActionPerformed",
			notification => {
				console.log(
					"Push action performed: " + JSON.stringify(notification)
				)
			}
		)
	}, [])

	return <div></div>
}

export default NativePushNotifications
