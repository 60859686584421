import React, { useState, useEffect } from "react"
import * as Sentry from "@sentry/browser"

// Material UI
import { TextField, Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Autocomplete from "@material-ui/lab/Autocomplete"

// Material UI icons
import HighlightOffIcon from "@material-ui/icons/HighlightOff"
import LocationOnIcon from "@material-ui/icons/LocationOn"

// Redux
import { useSelector, useDispatch } from "react-redux"
import * as profileActions from "../state/profileActions"

// Utils
import parse from "autosuggest-highlight/parse"
import throttle from "lodash/throttle"

const autocompleteService = { current: null }

// Styles
const useStyles = makeStyles(theme => ({
	icon: {
		color: theme.palette.text.secondary,
		marginRight: theme.spacing(2),
	},
	deleteLocationBtn: {
		cursor: "pointer",
		position: "relative",
		left: 25,
	},
	formField: {
		display: "flex",
		justifyContent: "center",
	},
}))

const useOutlinedInputStyles = makeStyles(theme => ({
	root: {
		backgroundColor: "white",
		borderRadius: 12,
	},
}))

// Component
export default function GoogleLocationAutocomplete(props) {
	// Hooks
	const styles = useStyles()
	const outlinedInputClasses = useOutlinedInputStyles()
	const dispatch = useDispatch()

	// Destructuring props
	let { fieldName, id, citiesNumber, setCitiesNumber } = props

	// Redux selectors
	const profileReducer = useSelector(state => state.profileReducer)

	// Local state
	const [value, setValue] = useState(
		profileReducer.profileData.locations[id]
			? profileReducer.profileData.locations[id]
			: null
	)
	const [inputValue, setInputValue] = useState("")
	const [options, setOptions] = useState([])

	// Get coordinates using place id of a location
	const handlePlaceSelect = async newValue => {
		if (!newValue) return

		const placeId = newValue.place_id
		const geocoder = new window.google.maps.Geocoder()

		try {
			const results = await new Promise((resolve, reject) => {
				geocoder.geocode({ placeId: placeId }, (results, status) => {
					if (status === "OK" && results[0]) {
						resolve(results[0])
					} else {
						reject(new Error(`Geocoder failed due to: ${status}`))
					}
				})
			})

			const location = results.geometry.location
			const lat = location.lat()
			const lng = location.lng()

			return { lat, lng }
		} catch (error) {
			console.error(error.message)
			Sentry.captureException(error)
		}
	}

	// Memo
	const fetch = React.useMemo(
		() =>
			throttle((request, callback) => {
				autocompleteService.current.getPlacePredictions(
					{
						...request,
						fields: ["geometry", "name"],
						types: ["locality", "sublocality", "postal_code"],
						componentRestrictions: { country: "de" },
					},
					callback
				)
			}, 200),
		[]
	)

	// Set results effects
	useEffect(() => {
		let active = true

		if (!autocompleteService.current && window.google) {
			autocompleteService.current =
				new window.google.maps.places.AutocompleteService()
		}
		if (!autocompleteService.current) {
			return undefined
		}

		if (inputValue === "") {
			setOptions(value ? [value] : [])
			return undefined
		}

		fetch({ input: inputValue }, results => {
			if (active) {
				let newOptions = []

				if (value) {
					newOptions = [value]
				}

				if (results) {
					results = results.filter(el => {
						return (
							el.types.includes("locality") ||
							el.types.includes("sublocality") ||
							el.types.includes("postal_code")
						)
					})
					newOptions = [...newOptions, ...results]
					newOptions = newOptions
						.filter(el => {
							return JSON.stringify(el) !== "[]"
						})
						.flat()
				}

				setOptions(newOptions)
			}
		})

		return () => {
			active = false
		}
	}, [value, inputValue, fetch])

	// Render
	return (
		<Autocomplete
			id="google-map-demo"
			classes={{ root: outlinedInputClasses.root }}
			getOptionLabel={option => {
				if (typeof option === "string") {
					return option
				}
				if (option.description) {
					return option.description
				}
				if (option.location_name) {
					return option.location_name
				}
				return ""
			}}
			filterOptions={x => x}
			options={options}
			autoComplete
			disableClearable
			noOptionsText={"Gib deinen gewünschten Ort ein"}
			includeInputInList
			filterSelectedOptions
			value={value}
			onChange={(event, newValue) => {
				setOptions(newValue ? [newValue, ...options] : options)
				setValue(newValue)
				if (newValue) {
					handlePlaceSelect(newValue)
						.then(result => {
							if (result) {
								const { lat, lng } = result
								dispatch(
									profileActions.setProfileDesiredLocationAction(
										newValue.description,
										id,
										lat,
										lng
									)
								)
								setValue(newValue)
							} else {
								console.error("Failed to get lat and lng")
							}
						})
						.catch(error => {
							console.error("Error in handlePlaceSelect:", error)
						})
				}
			}}
			onInputChange={(event, newInputValue) => {
				setInputValue(newInputValue)
			}}
			renderInput={params => (
				<TextField
					{...params}
					id={`userInputFieldFreeText${fieldName}`}
					color="primary"
					error={profileReducer?.profileData?.locations?.length === 0}
					variant="outlined"
					fullWidth
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<HighlightOffIcon
								className={styles.deleteLocationBtn}
								onClick={el => {
									el.stopPropagation()
									let filteredCities = [...citiesNumber]
									filteredCities.splice(id, 1)
									if (id > 0) {
										setCitiesNumber(filteredCities)
									}
									if (id === 0) {
										setValue(null)
									}
									dispatch(
										profileActions.removeProfileDesiredLocationAction(
											id
										)
									)
								}}
							/>
						),
					}}
				/>
			)}
			renderOption={option => {
				const matches =
					option.structured_formatting.main_text_matched_substrings
				const parts = parse(
					option.structured_formatting.main_text,
					matches.map(match => [
						match.offset,
						match.offset + match.length,
					])
				)

				return (
					<Grid container alignItems="center">
						<Grid item>
							<LocationOnIcon className={styles.icon} />
						</Grid>
						<Grid item xs>
							{parts.map((part, index) => (
								<span
									key={index}
									style={{
										fontWeight: part.highlight ? 700 : 400,
									}}
								>
									{part.text}
								</span>
							))}

							<Typography variant="body2" color="textSecondary">
								{option.structured_formatting.secondary_text}
							</Typography>
						</Grid>
					</Grid>
				)
			}}
		/>
	)
}
