import React, { useEffect, useState, useMemo } from "react"

// Material UI
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import InputAdornment from "@material-ui/core/InputAdornment"
import Checkbox from "@material-ui/core/Checkbox"

// Data Structures
import funnelFieldOptions from "../dataStructures/funnelFieldOptions"

// Labels
import standardProfileFieldNames from "../../profile/dataStructures/standardProfileFieldNames"

// Redux
import { useDispatch, useSelector } from "react-redux"
import * as clickFunnelActions from "../state/clickFunnelActions"

// Styles
const useStyles = makeStyles({
	contactDataGridContainer: {
		marginBottom: 49,
	},
	option: {
		width: "100%",
		minHeight: 50,
		borderRadius: 12,
		justifyContent: "center",
		textTransform: "none",
		textAlign: "center",
		fontSize: 14,
		color: "#333333",
		backgroundColor: "white",
		border: "1px solid rgba(0,0,0,0.25)",
		wordBreak: "break-word",
		"&:hover": {
			backgroundColor: "white",
			border: "1px solid rgba(0,0,0,0.25)",
		},
	},
	optionSelected: {
		backgroundColor: "#fff5cc",
		border: "1px solid #e8b300",
		"&:hover": {
			backgroundColor: "#fff5cc",
			border: "1px solid #e8b300",
		},
	},
	optionError: {
		border: "1px solid red",
		"&:hover": {
			border: "1px solid red",
		},
	},
	gridItem: {
		"& fieldset": {
			borderRadius: 12,
		},
	},
	input: {
		backgroundColor: "white",
		borderRadius: 12,
	},
	paragraph: {
		fontSize: 16,
		margin: 8,
		lineHeight: "24px",
		color: "#3c424f",
	},
	link: {
		color: "#3a5dab",
		textDecoration: "none",
	},
	gdprCheckboxContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "flex-start",
	},
	checkbox: {
		color: "#3a5dab !important",
	},
	missingFieldsGridItem: {
		backgroundColor: "white",
		borderRadius: 12,
		border: "1px solid red",
	},
})

// Component
const ContactsDataField = props => {
	// Hooks
	const styles = useStyles()
	const dispatch = useDispatch()

	// Destructuring props
	const { clickedField, setClickedField, fromProfile } = props

	// Local state
	const [emailErrorMsg, setEmailErrorMsg] = useState("")
	const [emailConfirmationErrorMsg, setEmailConfirmationErrorMsg] =
		useState("")
	const [checkboxGdprChecked, setCheckboxGdprChecked] = useState(false)
	const checkboxJobNotificationsChecked = true
	const [missingFields, setMissigFields] = useState(null)

	// Redux selectors
	const clickFunnelReducer = useSelector(state => state.clickFunnelReducer)
	const profileReducer = useSelector(state => state.profileReducer)

	// Variables
	const emailFieldsMapper = {
		email: emailErrorMsg,
		email_confirmation: emailConfirmationErrorMsg,
	}
	let contactDataQuestions = useMemo(
		() =>
			fromProfile || profileReducer.changes.userType === "affiliate"
				? ["salutation", "first_name", "last_name", "phone"]
				: [
						"salutation",
						"first_name",
						"last_name",
						"phone",
						"email",
						"email_confirmation",
						"gdprCheckboxChecked",
				  ],
		[fromProfile, profileReducer.changes.userType]
	)

	// Set the gdpr checkbox states in Redux
	useEffect(() => {
		dispatch(
			clickFunnelActions.setTextFieldValueAction(
				checkboxGdprChecked,
				"gdprCheckboxChecked"
			)
		)
	}, [checkboxGdprChecked, dispatch])

	// Set the job notification checkbox states in Redux
	useEffect(() => {
		if (checkboxJobNotificationsChecked) {
			dispatch(
				clickFunnelActions.setTextFieldValueAction(
					false,
					"email_opt_out"
				)
			)
		}
	}, [checkboxJobNotificationsChecked, dispatch])

	// Update missing fields
	useEffect(() => {
		if (clickFunnelReducer?.formData?.checkContactDataFields) {
			let missingFieldsLocal = contactDataQuestions.filter(
				dataQuestion => !clickFunnelReducer.formData[dataQuestion]
			)
			setMissigFields(missingFieldsLocal)
		}
	}, [clickFunnelReducer.formData, contactDataQuestions])

	// Render
	return (
		<Grid item xs={12}>
			<Grid
				container
				className={styles.contactDataGridContainer}
				spacing={1}
			>
				{contactDataQuestions.map((question, id) => {
					if (question === "salutation") {
						return funnelFieldOptions["salutation"].map(
							(option, id) => {
								return (
									<Grid item xs={4} key={id}>
										<Button
											key={id}
											className={
												clickFunnelReducer?.formData
													?.checkContactDataFields &&
												!clickFunnelReducer.formData[
													question
												]
													? `${styles.option} ${styles.optionError}`
													: clickFunnelReducer
															.formData[
															"salutation"
													  ] === option
													? `${styles.option} ${styles.optionSelected}`
													: styles.option
											}
											onClick={() => {
												// make it clear that button was un/clicked and send value to redux
												let newData =
													clickedField.filter(
														el =>
															!el.includes(
																"clickFunnelField_salutation"
															)
													)

												setClickedField([
													...newData,
													`clickFunnelField_salutation_${option}`,
												])

												// set value for this field in redux state
												dispatch(
													clickFunnelActions.asyncSaveFieldAction(
														option,
														"salutation"
													)
												)
											}}
										>
											{option}
										</Button>
									</Grid>
								)
							}
						)
					} else if (question === "gdprCheckboxChecked") {
						return (
							<Grid item xs={12} key={id}>
								<Grid container>
									<Grid
										item
										xs={1}
										className={styles.gdprCheckboxContainer}
									>
										<Checkbox
											id="gdprCheckbox"
											className={styles.checkbox}
											checked={checkboxGdprChecked}
											onChange={() => {
												setCheckboxGdprChecked(
													!checkboxGdprChecked
												)
											}}
											inputProps={{
												"aria-label":
													"primary checkbox",
											}}
										/>
									</Grid>
									<Grid item xs={11}>
										<Grid item xs={12}>
											<p className={styles.paragraph}>
												Mit der Anmeldung stimme ich den{" "}
												<a
													className={styles.link}
													href="https://workbee.de/agb/"
													rel="noopener noreferrer"
													target="_blank"
												>
													AGB
												</a>{" "}
												zu.
											</p>
											<p className={styles.paragraph}>
												Hinweise zur Datenverarbeitung
												findest du in unserer{" "}
												<a
													className={styles.link}
													href="https://workbee.de/datenschutz/#plattform"
													rel="noopener noreferrer"
													target="_blank"
												>
													Datenschutzerklärung.
												</a>{" "}
											</p>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						)
					} else {
						return (
							<Grid
								item
								key={id}
								xs={12}
								className={styles.gridItem}
							>
								<TextField
									inputProps={{
										style: {
											WebkitBoxShadow:
												"0 0 0 1000px white inset",
											borderRadius: 12,
										},
										inputMode:
											question === "phone"
												? "descimal"
												: question === "email" ||
												  question ===
														"email_confirmation"
												? "email"
												: "",
										pattern:
											question === "phone"
												? "[0-9]*"
												: "",
									}}
									onWheel={event => event.target.blur()}
									onKeyDown={e => {
										if (e.key === "Enter") {
											e.target.blur()
										}
									}}
									onKeyPress={event => {
										const charCode = event.key
										if (
											question === "phone" &&
											charCode > 31 &&
											(charCode < 48 || charCode > 57) &&
											charCode !== 190 &&
											charCode !== 69 &&
											(charCode !== 46 ||
												event.target.value.indexOf(
													"."
												) !== -1)
										) {
											event.preventDefault()
										}
									}}
									id={`userInputFieldFreeText${question}`}
									placeholder={
										standardProfileFieldNames[question]
									}
									type={
										question === "phone" ||
										question === "zip_code"
											? "number"
											: ""
									}
									className={styles.input}
									value={
										clickFunnelReducer.formData[question]
									}
									variant="outlined"
									color="secondary"
									helperText={
										Object.keys(emailFieldsMapper).includes(
											question
										)
											? emailFieldsMapper[question]
											: ""
									}
									error={
										clickFunnelReducer?.formData
											?.checkContactDataFields &&
										!clickFunnelReducer.formData[question]
											? true
											: question ===
													"email_confirmation" &&
											  emailConfirmationErrorMsg
											? true
											: false
									}
									InputProps={{
										startAdornment:
											question === "phone" ? (
												<InputAdornment position="start">
													+49
												</InputAdornment>
											) : null,
									}}
									fullWidth
									onChange={el => {
										let textVal = el.target.value

										// Sending value to reducer
										dispatch(
											clickFunnelActions.setTextFieldValueAction(
												textVal,
												question
											)
										)

										// Error message for email field
										if (question === "email") {
											let emailMatch = textVal.match(
												/^[^\s@]+@[^\s@]+\.[^\s@]+$/g
											)

											if (
												clickFunnelReducer.formData[
													"email_confirmation"
												] === textVal
											) {
												setEmailConfirmationErrorMsg("")
											} else {
												setEmailConfirmationErrorMsg(
													"Die beiden E-Mail-Adressen stimmen nicht überein."
												)
											}

											if (emailMatch) {
												setEmailErrorMsg("")
											} else {
												setEmailErrorMsg(
													"Achte darauf, dass deine E-Mail-Adresse das richtige Format hat."
												)
											}
										}

										// Error message for email confirmation field
										if (question === "email_confirmation") {
											if (
												clickFunnelReducer.formData[
													"email"
												] === textVal
											) {
												setEmailConfirmationErrorMsg("")
											} else {
												setEmailConfirmationErrorMsg(
													"Die beiden E-Mail-Adressen stimmen nicht überein."
												)
											}
										}
									}}
								/>
							</Grid>
						)
					}
				})}

				{/* Missing fields */}
				{clickFunnelReducer?.formData?.checkContactDataFields &&
					missingFields?.length > 0 && (
						<Grid
							item
							xs={12}
							className={styles.missingFieldsGridItem}
							id="missingFieldsBox"
						>
							<p className={styles.paragraph}>
								Bitte fülle auch die folgenden Felder aus:{" "}
								{missingFields
									.map(
										missingField =>
											standardProfileFieldNames[
												missingField
											]
									)
									.join(", ")}
							</p>
						</Grid>
					)}
			</Grid>
		</Grid>
	)
}

export default ContactsDataField
