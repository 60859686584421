import React from "react"

// Material UI
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { Grid, useMediaQuery } from "@material-ui/core"

// Redux
import { useSelector } from "react-redux"

// Images
import icnumberofemployees from "../images/employerBrandingIcons/icnumberofemployees.png"
import icnumberofhomes from "../images/employerBrandingIcons/icnumberofhomes.png"
import ictypeofinstitution from "../images/employerBrandingIcons/ictypeofinstitution.png"

// Styles
const useStyles = makeStyles({
	imageGridItem: {
		paddingRight: 16,
		display: "flex",
		maxWidth: 150,
		maxHeight: 116,
	},
	imageContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		marginTop: 16,
		marginLeft: 16,
		width: 80,
		height: 80,
		borderRadius: 40,
		backgroundColor: "white",
		border: "0.8px solid #e8e8e8",
	},
	imageContainerPC: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		marginTop: 16,
		marginLeft: 16,
		width: 116,
		height: 116,
		borderRadius: 58,
		backgroundColor: "white",
		border: "0.8px solid #e8e8e8",
	},
	companyLogo: {
		width: "60%",
		height: "auto",
	},
	companyTitle: {
		marginTop: 4,
		fontSize: 32,
		fontWeight: 800,
		lineHeight: 1.2,
		margin: 0,
	},
	companyTitlePC: {
		fontSize: 32,
		fontWeight: 800,
		lineHeight: 1.2,
		margin: 0,
	},
	companySubtitle: {
		fontSize: 24,
		fontWeight: "bold",
		lineHeight: 1.5,
		margin: 0,
	},
	nameSubtitleGridItem: {
		marginLeft: 16,
		marginRight: 16,
		marginBottom: 24,
	},
	nameSubtitleGridItemPC: {
		marginTop: 16,
	},
	sectionGridItemBasicData: {
		marginLeft: 12,
		marginRight: 12,
	},
	sectionGridItemBasicDataPC: {
		marginRight: 12,
	},
	sectionGridItemBasicDataPCNoSub: {
		marginRight: 12,
		marginBottom: 30,
	},
	basicInfoItem: {
		display: "flex",
		alignItems: "center",
		marginBottom: 18,
	},
	basicInfoItemPC: {
		display: "flex",
		alignItems: "center",
		marginTop: 10,
		marginBottom: 18,
		marginRight: 50,
	},
	basicInfoIcon: {
		width: 24,
		height: 24,
		marginRight: 8,
	},
	basicInfoParagraph: {
		fontSize: 14,
		fontWeight: 600,
		fontStyle: "normal",
		lineHeight: 1.5,
		margin: 0,
	},
})

// Component
const ClientsBasicData = props => {
	// Hooks
	const styles = useStyles()
	const theme = useTheme()

	// Media queries
	const mediaQueryMdUp = useMediaQuery(theme.breakpoints.up("md"))

	// Destructuring props
	const { companyLogo } = props

	// Redux selectors
	const clientsReducer = useSelector(state => state.clientsReducer)

	// Render
	// Mobile version
	if (!mediaQueryMdUp) {
		return (
			<>
				{companyLogo && (
					<Grid item xs={12} className={styles.imageGridItem}>
						<div className={styles.imageContainer}>
							<img
								src={companyLogo}
								alt="company logo"
								className={styles.companyLogo}
							/>
						</div>
					</Grid>
				)}

				<Grid item xs={12} className={styles.nameSubtitleGridItem}>
					<Grid container>
						{clientsReducer.company_name_app && (
							<Grid item xs={12}>
								<h2 className={styles.companyTitle}>
									{clientsReducer.company_name_app}
								</h2>
							</Grid>
						)}
						{clientsReducer.sub_title && (
							<Grid item xs={12}>
								<h3 className={styles.companySubtitle}>
									{clientsReducer.sub_title}
								</h3>
							</Grid>
						)}
					</Grid>
				</Grid>

				{/* Company Basic Data */}
				<Grid item xs={12} className={styles.sectionGridItemBasicData}>
					<Grid container>
						{clientsReducer.type_of_employer?.length > 0 && (
							<Grid item xs={12} className={styles.basicInfoItem}>
								<img
									src={ictypeofinstitution}
									alt="type of employer"
									className={styles.basicInfoIcon}
								/>
								<p className={styles.basicInfoParagraph}>
									{
										clientsReducer.type_of_employer[0]
											?.type_of_employer_name
									}
								</p>
							</Grid>
						)}
						<Grid item xs={12} className={styles.basicInfoItem}>
							<img
								src={icnumberofhomes}
								alt="number of homes"
								className={styles.basicInfoIcon}
							/>
							<p className={styles.basicInfoParagraph}>
								{`${clientsReducer.number_of_locations} ${
									clientsReducer.facility_type
										? clientsReducer.facility_type
										: ""
								}`}
							</p>
						</Grid>
						<Grid item xs={12} className={styles.basicInfoItem}>
							<img
								src={icnumberofemployees}
								alt="number of employees"
								className={styles.basicInfoIcon}
							/>
							<p className={styles.basicInfoParagraph}>
								{`ca. ${clientsReducer.number_of_employees} Mitarbeitende`}
							</p>
						</Grid>
					</Grid>
				</Grid>
			</>
		)
	}
	// PC version
	else {
		return (
			<>
				<Grid container>
					{companyLogo && (
						<Grid item xs className={styles.imageGridItem}>
							<div className={styles.imageContainerPC}>
								<img
									src={companyLogo}
									alt="company logo"
									className={styles.companyLogo}
								/>
							</div>
						</Grid>
					)}
					<Grid item xs className={styles.nameSubtitleGridItemPC}>
						<Grid container>
							<Grid item xs={12}>
								<h2 className={styles.companyTitlePC}>
									{clientsReducer.company_name_app}
								</h2>
							</Grid>
							{clientsReducer.sub_title && (
								<Grid item xs={12}>
									<h3 className={styles.companySubtitle}>
										{clientsReducer.sub_title}
									</h3>
								</Grid>
							)}
							<Grid
								item
								xs={12}
								className={
									clientsReducer.sub_title
										? styles.sectionGridItemBasicDataPC
										: styles.sectionGridItemBasicDataPCNoSub
								}
							>
								<Grid container>
									{clientsReducer.type_of_employer?.length >
										0 && (
										<Grid
											item
											xs="auto"
											className={styles.basicInfoItemPC}
										>
											<img
												src={ictypeofinstitution}
												alt="type of employer"
												className={styles.basicInfoIcon}
											/>
											<p
												className={
													styles.basicInfoParagraph
												}
											>
												{
													clientsReducer
														.type_of_employer[0]
														?.type_of_employer_name
												}
											</p>
										</Grid>
									)}
									<Grid
										item
										xs="auto"
										className={styles.basicInfoItemPC}
									>
										<img
											src={icnumberofhomes}
											alt="number of homes"
											className={styles.basicInfoIcon}
										/>
										<p
											className={
												styles.basicInfoParagraph
											}
										>
											{`${
												clientsReducer.number_of_locations
											} ${
												clientsReducer.facility_type
													? clientsReducer.facility_type
													: ""
											}`}
										</p>
									</Grid>
									<Grid
										item
										xs="auto"
										className={styles.basicInfoItemPC}
									>
										<img
											src={icnumberofemployees}
											alt="number of employees"
											className={styles.basicInfoIcon}
										/>
										<p
											className={
												styles.basicInfoParagraph
											}
										>
											{`ca. ${clientsReducer.number_of_employees} Mitarbeitende`}
										</p>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</>
		)
	}
}

export default ClientsBasicData
