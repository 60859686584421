// Redux imports
import { createStore, applyMiddleware, combineReducers } from "redux"
import thunk from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension"

// Reducer imports
import authReducer from "./auth/state/authReducer"
import profileReducer from "./profile/state/profileReducer"
import notificationsReducer from "./notifications/state/notificationsReducer"
import loadingScreenReducer from "./loadingScreen/state/ReduxLoadingScreenReducer"
import jobsReducer from "./jobs/state/jobsReducer"
import clickFunnelReducer from "./clickFunnel/state/clickFunnelReducer"
import clientsReducer from "./clients/state/clientsReducer"
import tagManagerReducer from "./tagManagerEvents/state/tagManagerReducer"
import tutorialReducer from "./tutorial/state/tutorialReducer"

// Create reducer
const rootReducer = combineReducers({
	profileReducer: profileReducer,
	clickFunnelReducer: clickFunnelReducer,
	notificationsReducer: notificationsReducer,
	loadingScreenReducer: loadingScreenReducer,
	jobsReducer: jobsReducer,
	clientsReducer: clientsReducer,
	authReducer: authReducer,
	tagManagerReducer: tagManagerReducer,
	tutorialReducer: tutorialReducer,
})

// Shows from which part of the code an action was dispatched
const composeEnhancers = composeWithDevTools({
	trace: true,
	traceLimit: 25,
})

// Create store
export const store = createStore(
	rootReducer,
	composeEnhancers(applyMiddleware(thunk))
)
