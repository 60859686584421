import React, { useState, useEffect } from "react"
import { useLocation, Link, useHistory } from "react-router-dom"

// Images
import logoNoHat from "../images/logoNoHat.png"

// Components
import DrawerData from "./DrawerData"

// Redux
import { useDispatch } from "react-redux"
import * as tagManagerActions from "../tagManagerEvents/state/tagManagerActions"

// Material UI
import { makeStyles, useTheme } from "@material-ui/core/styles"
import {
	Button,
	AppBar,
	Drawer,
	Toolbar,
	CssBaseline,
	IconButton,
	Hidden,
	Typography,
	useMediaQuery,
} from "@material-ui/core"

// Material UI icons
import MenuIcon from "@material-ui/icons/Menu"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import PersonOutlineIcon from "@material-ui/icons/PersonOutline"

// Redux
import { useSelector } from "react-redux"

// Styles
const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		backgroundColor: "#f8f8ff",
		display: "flex",
		paddingTop: "env(safe-area-inset-top)",
		height: "calc(56px + env(safe-area-inset-top))",
	},
	drawer: {
		width: "calc(200px + env(safe-area-inset-left))",
		flexShrink: 0,
	},
	drawerPaper: {
		width: "calc(200px + env(safe-area-inset-left))",
		paddingTop: "env(safe-area-inset-top)",
		paddingBottom: "env(safe-area-inset-bottom)",
		paddingLeft: "env(safe-area-inset-left)",
	},
	toolbar: {
		display: "flex",
		justifyContent: "space-between",
	},
	menuButton: {
		marginRight: 5,
	},
	logoNoHat: {
		display: "flex",
		height: 25,
	},
	logoHamburgerContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	currentLocationContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	backToJobsContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	backToJobs: {
		display: "flex",
		fontFamily: "'Nunito', sans-serif",
		fontSize: 14,
		fontWeight: 600,
	},
	backToJobsIcon: {
		display: "flex",
		width: 16,
		height: 16,
		marginRight: 16,
	},
	currentLocation: {
		display: "flex",
		fontFamily: "'Nunito', sans-serif",
		fontSize: 14,
		marginLeft: 16,
		fontWeight: 600,
	},
	tooltipJobsLink: {
		textDecoration: "none",
		color: "black",
	},
	loginBtn: {
		display: "flex",
		borderRadius: 12,
		borderColor: "#e8e8e8",
		backgroundColor: "white",
		color: "black",
		"&:hover": {
			borderColor: "#e8e8e8",
			backgroundColor: "white",
			color: "black",
		},
		"& .MuiButton-startIcon": {
			marginRight: 2,
		},
	},
	moreJobsBtn: {
		display: "flex",
		borderRadius: 12,
		border: "solid 1px #f5a800",
		"&:hover": {
			border: "solid 1px #f5a800",
		},
	},
	moreJobsIcon: {
		width: 18,
		height: 18,
		color: "#3a5dab",
	},
}))

// Component
const TopNavigation = () => {
	// Hooks
	const styles = useStyles()
	const location = useLocation()
	const history = useHistory()
	const theme = useTheme()
	const dispatch = useDispatch()

	// URL Parameters
	const queryString = window.location.search
	const urlParams = new URLSearchParams(queryString)
	const fromClientForm = urlParams.get("fromClientForm")

	// Media queries
	const mobileQuery = useMediaQuery(theme.breakpoints.up("md"))

	// Redux selectors
	const authReducer = useSelector(state => state.authReducer)
	const profileReducer = useSelector(state => state.profileReducer.changes)

	// Local state
	const [mobileOpen, setMobileOpen] = useState(false)
	const [currentLocation, setCurrentLocation] = useState("")

	// Setting location name upon change
	useEffect(() => {
		if (
			location.pathname.length > 14 &&
			location.pathname.includes("jobs")
		) {
			setCurrentLocation("Specific Job")
		} else if (location.pathname === "/jobs") {
			setCurrentLocation("Jobs")
		} else if (location.pathname === "/jobsWordpress") {
			setCurrentLocation("Jobs Wordpress")
		} else if (location.pathname === "/wordpressJobSearch") {
			setCurrentLocation("Wordpress Job Search")
		} else if (location.pathname === "/bewerbungen") {
			setCurrentLocation("Bewerbungen")
		} else if (location.pathname === "/profile") {
			setCurrentLocation("Profil")
		} else if (location.pathname === "/einstellungen") {
			setCurrentLocation("Einstellungen")
		} else if (location.pathname === "/form") {
			setCurrentLocation("Form")
		} else if (location.pathname === "/form/success") {
			setCurrentLocation("Form Success")
		} else if (location.pathname === "/login") {
			setCurrentLocation("Login")
		} else if (location.pathname.includes("/clients")) {
			setCurrentLocation("Clients")
		} else if (location.pathname.includes("/clientForm")) {
			setCurrentLocation("ClientsForm")
		}
	}, [location])

	// Render
	if (
		currentLocation === "Jobs Wordpress" ||
		currentLocation === "Wordpress Job Search"
	) {
		return null
	} else if (authReducer?.userLoggedIn || !authReducer?.userLoggedIn) {
		return (
			<div className={styles.root}>
				<CssBaseline />
				<AppBar position="fixed" className={styles.appBar}>
					<Toolbar className={styles.toolbar}>
						{(currentLocation !== "Specific Job" ||
							mobileQuery) && (
							<div className={styles.logoHamburgerContainer}>
								{profileReducer &&
									currentLocation !== "Form Success" && (
										<Hidden mdUp>
											<IconButton
												color="primary"
												aria-label="open drawer"
												edge="start"
												onClick={() => {
													setMobileOpen(!mobileOpen)
												}}
												className={styles.menuButton}
											>
												<MenuIcon />
											</IconButton>
										</Hidden>
									)}
								<img
									src={logoNoHat}
									className={styles.logoNoHat}
									alt="app logo"
								/>

								<div
									className={styles.currentLocationContainer}
								>
									<Hidden mdUp>
										{currentLocation === "Jobs" && (
											<Link
												to={"/jobs"}
												className={
													styles.tooltipJobsLink
												}
											>
												<Typography
													className={
														styles.currentLocation
													}
													color="primary"
												>
													{currentLocation}
												</Typography>
											</Link>
										)}
									</Hidden>
								</div>
							</div>
						)}

						{!mobileQuery && currentLocation === "Specific Job" && (
							<Link
								to={
									fromClientForm
										? `/clientForm?clientId=${fromClientForm}`
										: "/jobs"
								}
								className={styles.tooltipJobsLink}
								onClick={() => {
									dispatch(
										tagManagerActions.sendSimpleCustomEvent(
											{
												eventName:
													"clickBackToAllJobsCustomEvent",
											}
										)
									)
								}}
							>
								<div className={styles.backToJobsContainer}>
									<ArrowBackIcon
										className={styles.backToJobsIcon}
									/>
									<Typography
										className={styles.backToJobs}
										color="primary"
									>
										{fromClientForm
											? "zurück zur Jobauswahl"
											: "zurück zu allen Jobs"}
									</Typography>
								</div>
							</Link>
						)}

						{currentLocation === "Jobs" &&
							!authReducer?.userLoggedIn &&
							window.location.pathname.includes("jobs") &&
							window.location.pathname.length < 6 && (
								<Button
									id="loginBtn"
									variant="outlined"
									color="primary"
									size="small"
									className={styles.loginBtn}
									onClick={() => {
										history.push("/login")
									}}
									startIcon={<PersonOutlineIcon />}
								>
									Anmelden
								</Button>
							)}

						{currentLocation === "Clients" && (
							<Button
								variant="outlined"
								color="primary"
								className={styles.moreJobsBtn}
								onClick={() => {
									history.push("/jobs")
								}}
								startIcon={
									<img
										src={logoNoHat}
										className={styles.moreJobsIcon}
										alt="filter"
									/>
								}
							>
								Weitere Jobs
							</Button>
						)}
					</Toolbar>
				</AppBar>

				{/* PC Drawer */}

				{profileReducer && currentLocation !== "Form Success" && (
					<Hidden smDown>
						<Drawer
							className={styles.drawer}
							variant="permanent"
							classes={{
								paper: styles.drawerPaper,
							}}
						>
							<DrawerData setMobileOpen={setMobileOpen} />
						</Drawer>
					</Hidden>
				)}

				{/* Mobile / Tablet Drawer */}

				{profileReducer && (
					<Hidden smUp implementation="css">
						<Drawer
							variant="temporary"
							open={mobileOpen}
							onClose={() => {
								setMobileOpen(!mobileOpen)
							}}
							classes={{
								paper: styles.drawerPaper,
							}}
							ModalProps={{
								keepMounted: true,
							}}
						>
							<DrawerData setMobileOpen={setMobileOpen} />
						</Drawer>
					</Hidden>
				)}
			</div>
		)
	} else if (
		currentLocation === "Form" &&
		window.location.search === "?type=specific"
	) {
		return null
	} else if (
		currentLocation !== "Jobs" &&
		currentLocation !== "Form" &&
		currentLocation !== "Clients"
	) {
		return (
			<div className={styles.root}>
				<CssBaseline />
				<AppBar position="static" className={styles.appBar}>
					<Toolbar className={styles.toolbar}>
						<div className={styles.logoHamburgerContainer}>
							<img
								src={logoNoHat}
								className={styles.logoNoHat}
								alt="app logo"
							/>
						</div>
					</Toolbar>
				</AppBar>
			</div>
		)
	} else {
		return null
	}
}

export default TopNavigation
