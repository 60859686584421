import React from "react"

// Redux
import { useDispatch, useSelector } from "react-redux"
import * as jobsActions from "../../../state/jobsActions"

// Material UI
import { FormGroup, FormControlLabel, Checkbox } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

// Styles
const useStyles = makeStyles({
	checkbox: {
		color: "#3a5dab !important",
	},
	paragraph: {
		fontFamily: "'Nunito', sans-serif",
		margin: 0,
	},
})

const FilterFormGroup = props => {
	// Hooks
	const dispatch = useDispatch()
	const styles = useStyles()

	// Destructuring props
	const {
		fieldOptions,
		fieldName,
		fieldNameMapper,
		trackingFilteringClickEvent,
	} = props

	// Redux selectors
	const jobsReducer = useSelector(state => state.jobsReducer)

	// Render
	return (
		<div>
			<FormGroup row>
				{fieldOptions.map(fieldOption => (
					<FormControlLabel
						key={fieldOption}
						control={
							<Checkbox
								className={styles.checkbox}
								checked={jobsReducer.filteringOptions[
									fieldNameMapper[fieldName]
								].includes(fieldOption)}
								onChange={el => {
									let fieldValue = null

									if (
										!jobsReducer.filteringOptions[
											fieldNameMapper[fieldName]
										].includes(fieldOption)
									) {
										fieldValue = [
											...jobsReducer.filteringOptions[
												fieldNameMapper[fieldName]
											],
											fieldOption,
										]
									} else {
										fieldValue = [
											...jobsReducer.filteringOptions[
												fieldNameMapper[fieldName]
											].filter(
												item => item !== fieldOption
											),
										]
									}

									dispatch(
										jobsActions.asyncSaveFilteringFieldAction(
											fieldValue,
											fieldNameMapper[fieldName]
										)
									)

									// Tracking click events for GA
									trackingFilteringClickEvent()
								}}
								name={fieldOption}
							/>
						}
						label={
							<p className={styles.paragraph}>{fieldOption}</p>
						}
					/>
				))}
			</FormGroup>
		</div>
	)
}

export default FilterFormGroup
