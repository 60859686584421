import React, { useRef, useEffect, useState } from "react"
import { useParams, useLocation } from "react-router-dom"

// Material UI
import { Container, Divider, useMediaQuery } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"

// Capacitor
import { Capacitor } from "@capacitor/core"

// Components
import ClientsNavigationMenu from "./ClientsNavigationMenu"
import ClientsCompanyOverview from "./ClientsCompanyOverview"
import ClientsCurrentJobs from "./ClientsCurrentJobs"
import ClientsEmployeeQuote from "./ClientsEmployeeQuote"
import ClientsCertificatesAboutUs from "./ClientsCertificatesAboutUs"
import ClientsGallery from "./ClientsGallery"
import ClientsFranchiseLink from "./ClientsFranchiseLink"
import Footer from "../navigation/Footer"

// Firebase
import { ref, listAll, getDownloadURL } from "firebase/storage"
import { firebaseStorage } from "../firebase"

// Redux
import { useSelector, useDispatch } from "react-redux"
import * as clientsActions from "./state/clientsActions"
import * as profileActions from "../profile/state/profileActions"

// Styles
const useStyles = makeStyles({
	mainContainer: {
		fontFamily: "'Nunito', sans-serif",
		color: "#110c08",
	},
	mainContainerContentLeft: {
		marginTop: 90,
		fontFamily: "'Nunito', sans-serif",
		color: "#110c08",
		marginLeft: 200,
		width: "calc(100% - 200px)",
	},
	mainContainerContentMobileLogged: {
		marginTop: 90,
		fontFamily: "'Nunito', sans-serif",
		color: "#110c08",
	},
	sectionGridContainer: {
		width: "100%",
		maxWidth: 1000,
		margin: "0 auto",
		marginBottom: 48,
	},
})

// Component
const ClientsPage = () => {
	// Hooks
	const styles = useStyles()
	const theme = useTheme()
	const location = useLocation()
	const { clientId, chainId } = useParams()
	const dispatch = useDispatch()

	// Media queries
	const mediaQueryMdUp = useMediaQuery(theme.breakpoints.up("md"))

	// Redux selectors
	const clientsReducer = useSelector(state => state.clientsReducer)
	const profileReducer = useSelector(state => state.profileReducer)
	const authReducer = useSelector(state => state.authReducer)

	// Local state
	const [canGetData, setGetData] = useState(true)
	const [images, setImages] = useState([])
	const [imagesLength, setImagesLength] = useState(null)
	const [imgsLoaded, setImgsLoaded] = useState(false)
	const [companyLogo, setCompanyLogo] = useState(null)
	const [downloadedImages, setDownloadedImages] = useState([])
	const [companyPhotosAvailable, setCompanyPhotosAvailable] = useState(false)
	const [certificates, setCertificates] = useState([])

	// Refs
	const currentJobsRef = useRef(null)
	const employeeQuotesRef = useRef(null)

	const refList = [currentJobsRef, employeeQuotesRef]
	const refLinkNames = ["Aktuelle Stellenangebote", "Über den Arbeitgeber"]

	// Scrolling to top of the page
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	// User navigation
	useEffect(() => {
		setGetData(true)
	}, [location.pathname])

	// Getting client data
	useEffect(() => {
		if (clientId && canGetData) {
			setGetData(false)
			dispatch(clientsActions.asyncGetClientDataAction(clientId))
		}
	}, [canGetData, clientsReducer.client, dispatch, clientId])

	// Getting chain data
	useEffect(() => {
		if (chainId && canGetData) {
			setGetData(false)
			dispatch(clientsActions.asyncGetChainDataAction(chainId))
		}
	}, [canGetData, clientsReducer.client, dispatch, clientId, chainId])

	// Get images
	useEffect(() => {
		let newImgList = []
		let newCertificateList = []

		const getURL = (imageRef, imageType) => {
			if (Capacitor.isNativePlatform()) {
				imageRef._location.path_ = imageRef._location.path_.replaceAll(
					"/",
					"%2F"
				)
			}

			if (imageType === "companyLogo") {
				getDownloadURL(imageRef).then(url => {
					setCompanyLogo(url)
				})
			} else if (imageType === "companyPhoto") {
				getDownloadURL(imageRef).then(url => {
					newImgList = [...newImgList, url]
					setImages(newImgList)
				})
			} else if (imageType === "companyCertificate") {
				getDownloadURL(imageRef).then(url => {
					newCertificateList = [...newCertificateList, url]
					setCertificates(newCertificateList)
				})
			}
		}

		if (clientsReducer.loadedClientData) {
			const storageRef = ref(
				firebaseStorage,
				clientId
					? `Clients/${clientsReducer.zoho_client_id}`
					: `Ketten/${clientsReducer.zoho_chains_id}`
			)

			listAll(storageRef)
				.then(result => {
					if (result.items && result.items.length > 0) {
						const companyLogo = result.items.filter(el =>
							el.fullPath.includes("logo")
						)
						if (companyLogo?.length > 0) {
							getURL(companyLogo[0], "companyLogo")
						}

						const companyPhotos = result.items.filter(el =>
							el.fullPath.includes("photo")
						)

						if (companyPhotos?.length > 0) {
							setCompanyPhotosAvailable(true)
							setImagesLength(companyPhotos.length)
							companyPhotos.forEach(imageRef => {
								getURL(imageRef, "companyPhoto")
							})
						}

						const companyCertificates = result.items.filter(el =>
							el.fullPath.includes("certificate")
						)

						if (companyCertificates?.length > 0) {
							companyCertificates.forEach(imageRef => {
								getURL(imageRef, "companyCertificate")
							})
						}
					}
				})
				.catch(error => {
					console.log(error)
				})
		}
	}, [clientsReducer, clientId])

	// Pre-download images to avoid flickering
	useEffect(() => {
		let newDownloadedList = []

		if (imagesLength === images.length) {
			const loadImage = image => {
				return new Promise((resolve, reject) => {
					const loadImg = new Image()
					loadImg.src = image
					loadImg.onload = () => {
						newDownloadedList = [...newDownloadedList, loadImg]
						return resolve(image.url)
					}
					loadImg.onerror = err => console.log(err)
				})
			}

			Promise.all(images.map(image => loadImage(image)))
				.then(() => {
					setDownloadedImages(newDownloadedList)
					setImgsLoaded(true)
				})
				.catch(err => console.log("Failed to load images", err))
		}
	}, [imagesLength, images])

	// Get candidate data if necessary
	useEffect(() => {
		if (
			authReducer?.userLoggedIn &&
			!profileReducer?.changes?.profileDataLoaded
		) {
			dispatch(profileActions.asyncGetProfileDataAction())
		}
	}, [
		profileReducer?.changes?.profileDataLoaded,
		authReducer?.userLoggedIn,
		dispatch,
	])

	// Render
	if (clientsReducer.loadedClientData) {
		return (
			<>
				<Container
					maxWidth="xl"
					className={
						mediaQueryMdUp
							? styles.mainContainerContentLeft
							: styles.mainContainerContentMobileLogged
					}
				>
					{/* Navigation Menu */}
					<ClientsNavigationMenu
						refList={refList}
						refLinkNames={refLinkNames}
					/>

					<Divider />

					{/* Company overview */}
					<ClientsCompanyOverview companyLogo={companyLogo} />

					{/* Current jobs */}
					<ClientsCurrentJobs currentJobsRef={currentJobsRef} />

					{/* Employee quotes */}
					<ClientsEmployeeQuote
						employeeQuotesRef={employeeQuotesRef}
					/>

					{/* Certificates & About Us */}
					<ClientsCertificatesAboutUs certificates={certificates} />

					{/* Gallery */}
					<ClientsGallery
						companyPhotosAvailable={companyPhotosAvailable}
						imgsLoaded={imgsLoaded}
						downloadedImages={downloadedImages}
						youtubeLink={clientsReducer.video_url}
					/>

					{/* Franchise Info Link */}
					{clientsReducer?.chain &&
						location.pathname.includes("/clients") && (
							<ClientsFranchiseLink
								chain={clientsReducer.chain}
								setGetData={setGetData}
							/>
						)}
				</Container>

				{/* Footer */}
				{authReducer &&
					!authReducer.userLoggedIn &&
					!Capacitor.isNativePlatform() && <Footer />}
			</>
		)
	} else {
		return null
	}
}

export default ClientsPage
