import React from "react"

// Material UI
import { makeStyles } from "@material-ui/core/styles"

// Icons
import InfoIcon from "../../../images/infoIcon.png"
import applicationIcon from "../../../images/applicationIcon.png"

// Styles
const useStyles = makeStyles({
	bannerParagraph: {
		marginRight: 10,
		fontSize: 10,
		fontWeight: "bold",
	},
	icon: {
		marginLeft: 8,
		marginRight: 4,
		width: 16,
		height: 16,
		filter: "brightness(100) invert(1)",
		verticalAlign: "middle",
	},
	bannerWrapper: {
		right: 0,
		display: "flex",
		flexDirection: "column",
		overflow: "hidden",
		marginTop: 6,
		height: 70,
	},
	statusBanner: {
		display: "flex",
		alignItems: "center",
		alignSelf: "flex-end",
		justifyContent: "flex-end",
		position: "relative",
		right: -3,
		top: 20,
		height: 24,
		borderTopLeftRadius: 21.5,
		borderBottomLeftRadius: 21.5,
	},
	blue: {
		boxShadow: "0 0 3px 1px rgba(58, 93, 171, 0.5)",
		border: "solid 1px rgba(58, 93, 171, 0.5)",
		backgroundColor: "rgba(58, 93, 171, 0.1)",
	},
	red: {
		boxShadow: "0 0 3px 1px rgba(218, 68, 152, 0.5)",
		border: "solid 1px rgba(218, 68, 152, 0.5)",
		backgroundColor: "rgba(218, 68, 152, 0.1)",
	},
	green: {
		boxShadow: "0 0 3px 1px rgba(99, 192, 133, 0.5)",
		border: "solid 1px rgba(99, 192, 133, 0.5)",
		backgroundColor: "rgba(99, 192, 133, 0.1)",
	},
	yellow: {
		boxShadow: "0 0 3px 1px rgba(255, 223, 0, 0.7)",
		border: "solid 1px rgba(255, 223, 0, 0.9)",
		backgroundColor: "rgba(255, 223, 0, 0.1)",
	},
	dateBanner: {
		display: "flex",
		alignItems: "center",
		alignSelf: "flex-end",
		justifyContent: "flex-end",
		position: "relative",
		width: "auto",
		right: -3,
		top: 10,
		height: 24,
		borderTopLeftRadius: 21.5,
		borderBottomLeftRadius: 21.5,
		boxShadow: "0 0 3px 1px rgba(58, 93, 171, 0.5)",
		border: "solid 1px rgba(58, 93, 171, 0.5)",
		backgroundColor: "rgba(58, 93, 171, 0.1)",
	},
})

// Component
const ApplicationBanners = props => {
	// Destructuring props
	const { jobOpening } = props

	// Hooks
	const styles = useStyles()

	// Render
	return (
		<div>
			<div className={styles.bannerWrapper}>
				<div className={styles.dateBanner}>
					<p className={styles.bannerParagraph}>
						<img
							className={styles.icon}
							src={applicationIcon}
							alt="application"
						/>
						{`beworben am ${new Date(
							jobOpening.created_at
						).toLocaleDateString("de-DE", {
							day: "2-digit",
							month: "2-digit",
							year: "numeric",
						})}`}
					</p>
				</div>
				<div
					className={`${styles.statusBanner} ${
						styles[jobOpening.status_color]
					}`}
				>
					<p className={styles.bannerParagraph}>
						<img
							className={styles.icon}
							src={InfoIcon}
							alt="status"
						/>
						{`Status: ${jobOpening.status}`}
					</p>
				</div>
			</div>
		</div>
	)
}

export default ApplicationBanners
