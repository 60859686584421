import React, { useState } from "react"

// Form Components
import GoogleLocationAutocomplete from "./GoogleLocationAutocomplete"

// Material UI
import { Grid, Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

// Material UI icons
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"

// Redux
import { useSelector } from "react-redux"

// Custom Hooks
import useGoogleMaps from "../../hooks/useGoogleMaps"

// CSS
const useStyles = makeStyles({
	basicDataGridItem: {
		marginLeft: 16,
		marginRight: 16,
		marginBottom: 8,
	},
	gridItem: {
		"& fieldset": {
			borderRadius: 12,
		},
		marginBottom: 12,
	},
	addMoreCitiesBtn: {
		color: "#3a5dab",
	},
})

// Component
const LocationField = () => {
	// Hooks
	const styles = useStyles()
	useGoogleMaps()

	// Redux selectors
	const profileReducer = useSelector(state => state.profileReducer)

	// Local state
	const [citiesNumber, setCitiesNumber] = useState(
		profileReducer.profileData.locations.length > 0
			? profileReducer.profileData.locations
			: [1]
	)

	// Render
	return (
		<Grid item xs={12} className={styles.basicDataGridItem}>
			<Grid container>
				{citiesNumber.map((city, id) => (
					<Grid item key={id} xs={12} className={styles.gridItem}>
						<GoogleLocationAutocomplete
							id={id}
							citiesNumber={citiesNumber}
							setCitiesNumber={setCitiesNumber}
							fieldName="locations"
						/>
					</Grid>
				))}

				{citiesNumber.length < 5 && (
					<Grid item xs={12}>
						<Button
							className={styles.addMoreCitiesBtn}
							startIcon={
								<AddCircleOutlineIcon
									style={{ fontSize: 16 }}
								/>
							}
							onClick={() => {
								setCitiesNumber([...citiesNumber, 1])
							}}
						>
							{citiesNumber.length > 0
								? "Weitere Stadt angeben"
								: "Stadt angeben"}
						</Button>
					</Grid>
				)}
			</Grid>
		</Grid>
	)
}

export default LocationField
