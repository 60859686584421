const clickFunnelStyles = theme => {
	return {
		clickFunnelContainer: {
			display: "flex",
			flexDirection: "column",
			minHeight: "110vh",
			marginTop: 65,
		},
		clickFunnelContainerContentLeft: {
			display: "flex",
			flexDirection: "column",
			minHeight: "110vh",
			marginTop: 65,
			marginLeft: 200,
		},
		topContainer: {
			"& h2": {
				fontSize: 28,
				lineHeight: 1.43,
				color: "#110c08",
				fontFamily: "'Nunito', sans-serif",
				fontWeight: 800,
				textAlign: "center",
				marginBottom: 24,
			},
			"& [stylestarget*='secondaryText']": {
				color: "#110c08",
				fontSize: 16,
				marginTop: 0,
				textAlign: "center",
				fontFamily: "'Nunito', sans-serif",
				fontWeight: 600,
				lineHeight: 1.75,
				marginBottom: 9,
			},
		},
		fieldsContainer: {
			"& span": {
				fontFamily: "'Nunito', sans-serif",
			},
		},
		questionTextParagraph: {
			fontFamily: "'Nunito', sans-serif",
			fontSize: 16,
			marginTop: 0,
			marginBottom: 9,
			fontWeight: 600,
			lineHeight: 1.75,
			color: "#110c08",
			textAlign: "center",
		},
		moreChoicesParagraph: {
			margin: "9px 0 0",
			fontFamily: "'Nunito', sans-serif",
			fontSize: 14,
			lineHeight: 1.43,
			textAlign: "center",
			color: "#110c08",
		},
		clientFormJobCardGridContainer: {
			marginTop: 64,
			fontFamily: "'Nunito', sans-serif",
		},
	}
}

export default clickFunnelStyles
