import React, { useState, useEffect, useMemo } from "react"
import { Link } from "react-router-dom"

// Hooks
import useGoogleMaps from "../hooks/useGoogleMaps"

// Utils
import uniq from "lodash/uniq"

// Field options
import funnelFieldOptions from "../clickFunnel/dataStructures/funnelFieldOptions"

// Components
import GoogleLocationAutocomplete from "./GoogleLocationAutocomplete"

// Material UI
import { makeStyles } from "@material-ui/core/styles"
import {
	TextField,
	InputAdornment,
	Grid,
	Button,
	useMediaQuery,
} from "@material-ui/core"

// Icons
import searchIcon from "../images/wordpress/searchIcon.png"
import searchIconBlue from "../images/wordpress/searchIconBlue.png"

// Styles
const useStyles = makeStyles({
	gridContainer: {
		maxWidth: 895,
		width: "100%",
		margin: "0 auto",
	},
	gridContainerRow: {
		margin: "0 auto",
		display: "flex",
		flexDirection: "row",
	},
	btnGridItem: {
		marginRight: 4,
	},
	btnGridItemMobile: {
		marginBottom: 8,
		width: "100%",
		"&:last-child": { marginBottom: 0 },
	},
	btnContainer: {
		width: "100%",
	},
	gridItem: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		"& fieldset": {
			borderRadius: 12,
		},
	},
	inputField: {
		width: "100%",
		display: "flex",
		borderRadius: 12,
		"& .MuiOutlinedInput-root": {
			paddingLeft: 0,
			paddingRight: 0,
		},
	},
	inputAdornment: {
		backgroundColor: "white",
		padding: "25px 15px",
		borderTopLeftRadius: 12,
		borderBottomLeftRadius: 12,
		marginLeft: 0,
		marginRight: 0,
	},
	searchJobsBtn: {
		width: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		textDecoration: "none",
		textTransform: "uppercase",
		fontFamily: "'Nunito', sans-serif",
		height: 50,
		borderRadius: 12,
		color: "white",
		backgroundColor: "#3a5dab",
		"&:hover": {
			backgroundColor: "#3a5dab",
		},
	},
	icon: {
		width: 24,
		height: 24,
	},
	searchQueriesHeaderText: {
		marginTop: 48,
		width: "100%",
		fontFamily: "Nunito, sans-serif",
		fontSize: 16,
		color: "#435058",
		textAlign: "center",
	},
	searchQueryBtn: {
		width: "100%",
		display: "flex",
		justifyContent: "flex-start",
		marginRight: 8,
		height: 48,
		borderRadius: 12,
		border: "solid 1px #acbce0",
		backgroundColor: "#fff",
		"& .MuiButton-label": {
			textTransform: "none",
			fontSize: 15,
			fontWeight: "normal",
		},
	},
	searchQueryBtnMobile: {
		width: "100%",
		display: "flex",
		minWidth: 0,
		justifyContent: "flex-start",
		marginRight: 8,
		height: 48,
		borderRadius: 12,
		border: "solid 1px #acbce0",
		backgroundColor: "#fff",
		"& .MuiButton-label": {
			textTransform: "none",
			fontSize: 12,
			fontWeight: "normal",
			"& .ellipsisText": {
				overflow: "hidden",
				whiteSpace: "nowrap",
				textOverflow: "ellipsis",
			},
		},
	},
	btnLink: {
		textDecoration: "none",
	},
})

// Component
const WordpressJobSearch = () => {
	// Hooks
	const styles = useStyles()
	useGoogleMaps()

	// Utils
	const closest_match = require("closest-match")

	// Media queries
	const mediaQueryMdUp = useMediaQuery("(min-width:1005px)")

	// URL Parameters
	const queryString = window.location.search
	const urlParams = new URLSearchParams(queryString)
	const socialVertical = urlParams.get("socialVertical")
	const medicalVertical = urlParams.get("medicalVertical")
	const mainPageOptions = urlParams.get("mainPageOptions")

	// Local state
	const [jobKeyword, setJobKeyword] = useState("")
	const [location, setLocation] = useState("")
	const [dynamicLink, setDynamicLink] = useState("/jobs")

	// Qualification array for both verticals
	const qualificationArray = useMemo(() => {
		return uniq([
			...funnelFieldOptions.qualification,
			...funnelFieldOptions.qualification_educator,
			...funnelFieldOptions.occupational_group,
			...funnelFieldOptions.occupational_group_educator,
		])
	}, [])

	// Set the dynamic url
	useEffect(() => {
		let qualificationQueryParams = "?qualifications="
		let locationQueryParams = "&locations="

		if (jobKeyword) {
			let closestMatch = encodeURIComponent(
				closest_match.closestMatch(jobKeyword, qualificationArray)
			)

			if (closestMatch !== "Weiteres") {
				qualificationQueryParams =
					qualificationQueryParams + closestMatch
			}
		}

		if (location) {
			locationQueryParams = locationQueryParams + location
		}

		setDynamicLink(`/jobs${qualificationQueryParams}${locationQueryParams}`)
	}, [closest_match, jobKeyword, location, qualificationArray])

	// Render
	return (
		<>
			<Grid container className={styles.gridContainer} spacing={1}>
				<Grid
					item
					className={styles.gridItem}
					xs={mediaQueryMdUp ? 5 : 12}
				>
					<TextField
						placeholder="Beruf oder Stichwort eingeben"
						variant="outlined"
						autoComplete="on"
						value={jobKeyword}
						className={styles.inputField}
						onKeyDown={e => {
							if (e.key === "Enter") {
								e.target.blur()
							}
						}}
						onChange={e => {
							setJobKeyword(e.target.value)
						}}
						InputProps={{
							startAdornment: (
								<InputAdornment
									position="start"
									className={styles.inputAdornment}
								>
									<img
										className={styles.icon}
										src={searchIcon}
										alt="search"
									/>
								</InputAdornment>
							),
						}}
						inputProps={{
							style: {
								WebkitBoxShadow: "0 0 0 1000px white inset",
								borderRadius: 12,
								padding: 15.5,
								paddingLeft: 0,
							},
						}}
					></TextField>
				</Grid>
				<Grid
					item
					className={styles.gridItem}
					xs={mediaQueryMdUp ? 5 : 12}
				>
					<GoogleLocationAutocomplete setLocation={setLocation} />
				</Grid>
				<Grid
					item
					className={styles.gridItem}
					xs={mediaQueryMdUp ? 2 : 12}
				>
					<Link
						to={dynamicLink}
						className={styles.searchJobsBtn}
						target="_top"
					>
						Jobs Finden
					</Link>
				</Grid>
			</Grid>

			{!socialVertical && (
				<Grid container className={styles.gridContainer} spacing={1}>
					<h2 className={styles.searchQueriesHeaderText}>
						Häufige Suchanfragen
					</h2>
				</Grid>
			)}

			{medicalVertical && (
				<Grid container className={styles.gridContainer} spacing={1}>
					<Grid item xs={12}>
						<Grid container className={styles.btnContainer}>
							<Grid
								item
								xs="auto"
								className={
									mediaQueryMdUp
										? styles.btnGridItem
										: styles.btnGridItemMobile
								}
							>
								<Link
									target="_top"
									to="/jobs?qualifications=Pflegefachfrau%2F-mann&locations=Berlin"
									className={styles.btnLink}
								>
									<Button
										className={
											mediaQueryMdUp
												? styles.searchQueryBtn
												: styles.searchQueryBtnMobile
										}
										startIcon={
											<img
												className={styles.icon}
												src={searchIconBlue}
												alt="search"
											/>
										}
									>
										<span className="ellipsisText">
											Pflegefachfrau/-mann in Berlin
										</span>
									</Button>
								</Link>
							</Grid>
							<Grid
								item
								xs="auto"
								className={
									mediaQueryMdUp
										? styles.btnGridItem
										: styles.btnGridItemMobile
								}
							>
								<Link
									target="_top"
									to="/jobs?qualifications=Exam.%20Gesundheits-%20und%20Krankenpfleger%2Fin&locations=Berlin"
									className={styles.btnLink}
								>
									<Button
										className={
											mediaQueryMdUp
												? styles.searchQueryBtn
												: styles.searchQueryBtnMobile
										}
										startIcon={
											<img
												className={styles.icon}
												src={searchIconBlue}
												alt="search"
											/>
										}
									>
										<span className="ellipsisText">
											Exam. Gesundheits- und
											Krankenpfleger/in in Berlin
										</span>
									</Button>
								</Link>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Grid container className={styles.btnContainer}>
							<Grid
								item
								xs="auto"
								className={
									mediaQueryMdUp
										? styles.btnGridItem
										: styles.btnGridItemMobile
								}
							>
								<Link
									target="_top"
									to="/jobs?qualifications=Exam.%20Gesundheits-%20und%20Krankenpfleger%2Fin&locations=D%C3%BCsseldorf"
									className={styles.btnLink}
								>
									<Button
										className={
											mediaQueryMdUp
												? styles.searchQueryBtn
												: styles.searchQueryBtnMobile
										}
										startIcon={
											<img
												className={styles.icon}
												src={searchIconBlue}
												alt="search"
											/>
										}
									>
										<span className="ellipsisText">
											Exam. Gesundheits- und
											Krankenpfleger/in in Düsseldorf
										</span>
									</Button>
								</Link>
							</Grid>
							<Grid
								item
								xs="auto"
								className={
									mediaQueryMdUp
										? styles.btnGridItem
										: styles.btnGridItemMobile
								}
							>
								<Link
									target="_top"
									to="/jobs?qualifications=Pflegefachfrau%2F-mann&locations=Hamburg"
									className={styles.btnLink}
								>
									<Button
										className={
											mediaQueryMdUp
												? styles.searchQueryBtn
												: styles.searchQueryBtnMobile
										}
										startIcon={
											<img
												className={styles.icon}
												src={searchIconBlue}
												alt="search"
											/>
										}
									>
										<span className="ellipsisText">
											Pflegefachfrau/-mann in Hamburg
										</span>
									</Button>
								</Link>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Grid container className={styles.btnContainer}>
							<Grid
								item
								xs="auto"
								className={
									mediaQueryMdUp
										? styles.btnGridItem
										: styles.btnGridItemMobile
								}
							>
								<Link
									target="_top"
									to="/jobs?qualifications=Exam.%20Gesundheits-%20und%20Krankenpfleger%2Fin&locations=Bonn"
									className={styles.btnLink}
								>
									<Button
										className={
											mediaQueryMdUp
												? styles.searchQueryBtn
												: styles.searchQueryBtnMobile
										}
										startIcon={
											<img
												className={styles.icon}
												src={searchIconBlue}
												alt="search"
											/>
										}
									>
										<span className="ellipsisText">
											Exam. Gesundheits- und
											Krankenpfleger/in in Bonn
										</span>
									</Button>
								</Link>
							</Grid>
							<Grid
								item
								xs="auto"
								className={
									mediaQueryMdUp
										? styles.btnGridItem
										: styles.btnGridItemMobile
								}
							>
								<Link
									target="_top"
									to="/jobs?qualifications=Exam.%20Gesundheits-%20und%20Krankenpfleger%2Fin&locations=Bonn"
									className={styles.btnLink}
								>
									<Button
										className={
											mediaQueryMdUp
												? styles.searchQueryBtn
												: styles.searchQueryBtnMobile
										}
										startIcon={
											<img
												className={styles.icon}
												src={searchIconBlue}
												alt="search"
											/>
										}
									>
										<span className="ellipsisText">
											Exam. Gesundheits- und
											Kinderkrankenpfleger/in in Bonn
										</span>
									</Button>
								</Link>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)}

			{mainPageOptions && (
				<Grid container className={styles.gridContainer} spacing={1}>
					<Grid item xs={12}>
						<Grid container className={styles.btnContainer}>
							<Grid
								item
								xs="auto"
								className={
									mediaQueryMdUp
										? styles.btnGridItem
										: styles.btnGridItemMobile
								}
							>
								<Link
									target="_top"
									to="/jobs?qualifications=Pflegefachfrau%2F-mann&locations=Berlin"
									className={styles.btnLink}
								>
									<Button
										className={
											mediaQueryMdUp
												? styles.searchQueryBtn
												: styles.searchQueryBtnMobile
										}
										startIcon={
											<img
												className={styles.icon}
												src={searchIconBlue}
												alt="search"
											/>
										}
									>
										<span className="ellipsisText">
											Pflegefachfrau/-mann in Berlin
										</span>
									</Button>
								</Link>
							</Grid>
							<Grid
								item
								xs="auto"
								className={
									mediaQueryMdUp
										? styles.btnGridItem
										: styles.btnGridItemMobile
								}
							>
								<Link
									target="_top"
									to="/jobs?qualifications=Exam.%20Gesundheits-%20und%20Krankenpfleger%2Fin&locations=Berlin"
									className={styles.btnLink}
								>
									<Button
										className={
											mediaQueryMdUp
												? styles.searchQueryBtn
												: styles.searchQueryBtnMobile
										}
										startIcon={
											<img
												className={styles.icon}
												src={searchIconBlue}
												alt="search"
											/>
										}
									>
										<span className="ellipsisText">
											Exam. Gesundheits- und
											Krankenpfleger/in in Berlin
										</span>
									</Button>
								</Link>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Grid container className={styles.btnContainer}>
							<Grid
								item
								xs="auto"
								className={
									mediaQueryMdUp
										? styles.btnGridItem
										: styles.btnGridItemMobile
								}
							>
								<Link
									target="_top"
									to="/jobs?qualifications=Exam.%20Gesundheits-%20und%20Krankenpfleger%2Fin&locations=D%C3%BCsseldorf"
									className={styles.btnLink}
								>
									<Button
										className={
											mediaQueryMdUp
												? styles.searchQueryBtn
												: styles.searchQueryBtnMobile
										}
										startIcon={
											<img
												className={styles.icon}
												src={searchIconBlue}
												alt="search"
											/>
										}
									>
										<span className="ellipsisText">
											Exam. Gesundheits- und
											Krankenpfleger/in in Düsseldorf
										</span>
									</Button>
								</Link>
							</Grid>
							<Grid
								item
								xs="auto"
								className={
									mediaQueryMdUp
										? styles.btnGridItem
										: styles.btnGridItemMobile
								}
							>
								<Link
									target="_top"
									to="/jobs?qualifications=Pflegefachfrau%2F-mann&locations=Hamburg"
									className={styles.btnLink}
								>
									<Button
										className={
											mediaQueryMdUp
												? styles.searchQueryBtn
												: styles.searchQueryBtnMobile
										}
										startIcon={
											<img
												className={styles.icon}
												src={searchIconBlue}
												alt="search"
											/>
										}
									>
										<span className="ellipsisText">
											Pflegefachfrau/-mann in Hamburg
										</span>
									</Button>
								</Link>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Grid container className={styles.btnContainer}>
							<Grid
								item
								xs="auto"
								className={
									mediaQueryMdUp
										? styles.btnGridItem
										: styles.btnGridItemMobile
								}
							>
								<Link
									target="_top"
									to="/jobs?qualifications=Exam.%20Gesundheits-%20und%20Krankenpfleger%2Fin&locations=Bonn"
									className={styles.btnLink}
								>
									<Button
										className={
											mediaQueryMdUp
												? styles.searchQueryBtn
												: styles.searchQueryBtnMobile
										}
										startIcon={
											<img
												className={styles.icon}
												src={searchIconBlue}
												alt="search"
											/>
										}
									>
										<span className="ellipsisText">
											Exam. Gesundheits- und
											Krankenpfleger/in in Bonn
										</span>
									</Button>
								</Link>
							</Grid>
							<Grid
								item
								xs="auto"
								className={
									mediaQueryMdUp
										? styles.btnGridItem
										: styles.btnGridItemMobile
								}
							>
								<Link
									target="_top"
									to="/jobs?qualifications=Erzieher%2Fin&locations=Hamburg"
									className={styles.btnLink}
								>
									<Button
										className={
											mediaQueryMdUp
												? styles.searchQueryBtn
												: styles.searchQueryBtnMobile
										}
										startIcon={
											<img
												className={styles.icon}
												src={searchIconBlue}
												alt="search"
											/>
										}
									>
										<span className="ellipsisText">
											Erzieher/in in Hamburg
										</span>
									</Button>
								</Link>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)}
		</>
	)
}

export default WordpressJobSearch
