import React from "react"

// Components
import FilePicker from "./FilePicker"

// Material UI
import { makeStyles } from "@material-ui/core/styles"
import { Grid, IconButton, Dialog } from "@material-ui/core"

// Material UI icons
import CloseIcon from "@material-ui/icons/Close"

// Styles
const useStyles = makeStyles({
	container: {
		padding: 16,
	},
	gridItem: {
		width: "100%",
	},
	closeGridItem: {
		width: "100%",
		display: "flex",
		justifyContent: "flex-end",
	},
	closeButton: {
		padding: 0,
	},
})

// Component
const DialogUploadScan = props => {
	// Hooks
	const styles = useStyles()

	// Destructuring props
	const { dialogOpen, setDialogOpen, disableApplyBtn } = props

	// Render
	return (
		<Dialog
			onClose={() => {
				setDialogOpen(false)
			}}
			open={dialogOpen}
			className={styles.dialog}
		>
			<Grid container className={styles.container}>
				<Grid item className={styles.closeGridItem}>
					<IconButton
						className={styles.closeButton}
						onClick={() => {
							setDialogOpen(false)
						}}
					>
						<CloseIcon color="primary" />
					</IconButton>
				</Grid>
				<Grid item className={styles.gridItem}>
					<FilePicker
						setDialogOpen={setDialogOpen}
						disableApplyBtn={disableApplyBtn}
					/>
				</Grid>
			</Grid>
		</Dialog>
	)
}

export default DialogUploadScan
