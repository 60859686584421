import React from "react"

// Form Components
import LocationGoogleMaps from "./LocationGoogleMaps"

// Material UI
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"

// Redux
import { useDispatch, useSelector } from "react-redux"
import * as clickFunnelActions from "../state/clickFunnelActions"

// Custom Hooks
import useGoogleMaps from "../../hooks/useGoogleMaps"

// CSS
const useStyles = makeStyles({
	gridContainer: {
		marginBottom: 49,
	},
	gridItem: {
		"& fieldset": {
			borderRadius: 12,
		},
		marginBottom: 12,
	},
})

// Component
const LocationField = props => {
	// Hooks
	const dispatch = useDispatch()
	const styles = useStyles()
	useGoogleMaps()

	// Redux selectors
	const clickFunnelReducer = useSelector(state => state.clickFunnelReducer)

	// Destructuring props
	const {
		citiesNumber,
		setCitiesNumber,
		fieldName,
		formSlide,
		setSlide,
		clickedField,
		setClickedField,
		question,
		currentQuestionObj,
		questionTracker,
		modifyQuestionTracker,
		funnelType,
	} = props

	// Render
	return (
		<Grid container className={styles.gridContainer}>
			{citiesNumber.map((city, id) => (
				<Grid item key={id} xs={12} className={styles.gridItem}>
					<LocationGoogleMaps
						id={id}
						citiesNumber={citiesNumber}
						setCitiesNumber={setCitiesNumber}
						fieldName={fieldName}
						formSlide={formSlide}
						setSlide={setSlide}
						clickedField={clickedField}
						setClickedField={setClickedField}
						question={question}
						currentQuestionObj={currentQuestionObj}
						questionTracker={questionTracker}
						modifyQuestionTracker={modifyQuestionTracker}
						funnelType={funnelType}
					/>
				</Grid>
			))}

			{clickFunnelReducer.formData.locations.length > 0 &&
				clickFunnelReducer.formData.locations[
					clickFunnelReducer.formData.locations.length - 1
				] !== null &&
				citiesNumber.length < 5 && (
					<Grid item xs={12}>
						<Button
							startIcon={
								<AddCircleOutlineIcon
									style={{ fontSize: 16 }}
								/>
							}
							onClick={() => {
								setCitiesNumber([...citiesNumber, 1])
								dispatch(
									clickFunnelActions.setDesiredLocationAction(
										null,
										citiesNumber.length
									)
								)
							}}
						>
							Weitere Stadt angeben
						</Button>
					</Grid>
				)}
		</Grid>
	)
}

export default LocationField
