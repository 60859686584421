import React, { useEffect, useState } from "react"

// Material UI
import { makeStyles } from "@material-ui/core/styles"
import { IconButton } from "@material-ui/core"

// Redux
import { useDispatch, useSelector } from "react-redux"
import * as jobsActions from "../../state/jobsActions"

// Icons
import filterIcon from "../../../images/filterIcon.png"

// Styles
const useStyles = makeStyles(() => ({
	locationFilterContainer: {
		position: "relative",
	},
	filteringChip: {
		width: 56,
		height: 32,
		fontSize: 10,
		borderRadius: 32,
		color: "#3a5dab",
		backgroundColor: "#fafafa",
		border: "1px solid #3a5dab",
		"&:hover": {
			color: "#3a5dab",
			backgroundColor: "#fafafa",
			border: "1px solid #20335e",
		},
		"&:focus": {
			color: "#3a5dab",
			backgroundColor: "#fafafa",
		},
		"&:active": {
			color: "#3a5dab",
			backgroundColor: "#fafafa",
		},
	},
	chip: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	filterIcon: {
		width: 18,
		height: 18,
	},
	filterText: {
		margin: 2,
	},
	activeFilterNumber: {
		position: "absolute",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		cursor: "pointer",
		border: "1px solid white",
		paddingTop: 1,
		top: 17,
		right: 4,
		fontSize: 9,
		backgroundColor: "#da4498",
		height: 16,
		width: 16,
		color: "white",
		borderRadius: 8,
	},
}))

// Component
const OpenFilterModalChip = () => {
	// Hooks
	const styles = useStyles()
	const dispatch = useDispatch()

	// Redux selectors
	const jobsReducer = useSelector(state => state.jobsReducer)

	// Local state
	const [activeFilters, setActiveFilters] = useState(null)

	// Counting the amount of active filters
	useEffect(() => {
		let count = Object.entries(jobsReducer.filteringOptions).filter(
			([key, value]) =>
				key !== "locations" &&
				key !== "qualifications" &&
				key !== "work_field" &&
				key !== "salary_expectation_month" &&
				key !== "qualifications" &&
				Array.isArray(value) &&
				value.length > 0
		).length

		if (jobsReducer?.filteringOptions?.salary_expectation_month) {
			count += 1
		}

		setActiveFilters(count)
	}, [jobsReducer.filteringOptions])

	return (
		<div className={styles.locationFilterContainer}>
			<IconButton
				id="filterJobsModalBtn"
				variant="outlined"
				color="primary"
				size="small"
				className={styles.filteringChip}
				onClick={() => {
					dispatch(
						jobsActions.setFilterJobsModalAction({
							isModalOpen: true,
						})
					)
					window.scrollTo(0, 0)
				}}
			>
				<img
					src={filterIcon}
					alt="filter"
					className={styles.filterIcon}
				/>
				<p className={styles.filterText}>Filter</p>
			</IconButton>
			{activeFilters ? (
				<div
					onClick={() => {
						dispatch(
							jobsActions.setFilterJobsModalAction({
								isModalOpen: true,
							})
						)
						window.scrollTo(0, 0)
					}}
					className={styles.activeFilterNumber}
				>
					{activeFilters}
				</div>
			) : null}
		</div>
	)
}

export default OpenFilterModalChip
