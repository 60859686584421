import { backendURL } from "../../environment"
import { history } from "../../index"

// Firebase
import { firebaseAuth } from "../../firebase"

// Other actions
import * as notificationsActions from "../../notifications/state/notificationsActions"
import * as profileActions from "../../profile/state/profileActions"

// Names
export const GET_JOBS_INIT = "GET JOBS INIT"
export const GET_JOBS_SUCCESS = "GET JOBS SUCCESS"
export const GET_JOBS_ERROR = "GET JOBS ERROR"

export const GET_SIMILAR_JOBS_INIT = "GET SIMILAR JOBS INIT"
export const GET_SIMILAR_JOBS_SUCCESS = "GET SIMILAR JOBS SUCCESS"
export const GET_SIMILAR_JOBS_ERROR = "GET SIMILAR JOBS ERROR"

export const GET_SPECIFIC_JOB_INIT = "GET SPECIFIC JOB INIT"
export const GET_SPECIFIC_JOB_SUCCESS = "GET SPECIFIC JOB SUCCESS"
export const GET_SPECIFIC_JOB_ERROR = "GET SPECIFIC JOB ERROR"

export const GET_APPLIED_JOBS_INIT = "GET APPLIED JOBS INIT"
export const GET_APPLIED_JOBS_SUCCESS = "GET APPLIED JOBS SUCCESS"
export const GET_APPLIED_JOBS_ERROR = "GET APPLIED JOBS ERROR"

export const GET_SPECIFIC_MATCHING_SCORE_INIT =
	"GET SPECIFIC MATCHING SCORE INIT"
export const GET_SPECIFIC_MATCHING_SCORE_SUCCESS =
	"GET SPECIFIC MATCHING SCORE SUCCESS"
export const GET_SPECIFIC_MATCHING_SCORE_ERROR =
	"GET SPECIFIC MATCHING SCORE ERROR"

export const ASSOCIATE_CANDIDATE_INIT = "ASSOCIATE CANDIDATE INIT"
export const ASSOCIATE_CANDIDATE_SUCCESS = "ASSOCIATE CANDIDATE SUCCESS"
export const ASSOCIATE_CANDIDATE_ERROR = "ASSOCIATE CANDIDATE ERROR"

export const REMOVE_JOBS = "REMOVE JOBS"
export const REMOVE_SPECIFIC_JOB = "REMOVE SPECIFIC JOB"

export const BLOCK_APPLY_BUTTON = "BLOCK APPLY BUTTON"

export const SET_JOB_SHARE_AUTH_MODAL = "SET JOB SHARE AUTH MODAL"
export const SET_JOB_SHARE_PC_MODAL = "SET JOB SHARE PC MODAL"

export const SET_FILTER_JOBS_MODAL = "SET FILTER JOBS MODAL"
export const SET_FILTERING_OPTIONS = "SET FILTERING OPTIONS"
export const REMOVE_ALL_FILTERING_OPTIONS = "REMOVE ALL FILTERING OPTIONS"

export const SAVE_FILTERING_FIELD = "SAVE FILTERING FIELD"
export const REMOVE_ITEM_MULTIPLE_CHOICE = "REMOVE ITEM MULTIPLE CHOICE"
export const REMOVE_ITEM_SINGLE_CHOICE = "REMOVE ITEM SINGLE CHOICE"

export const SET_DESIRED_LOCATION_FILTER = "SET DESIRED LOCATION FILTER"
export const REMOVE_DESIRED_LOCATION_FILTER = "REMOVE DESIRED LOCATION FILTER"
export const REMOVE_DESIRED_LOCATION_BY_CITY_FILTER =
	"REMOVE DESIRED LOCATION BY CITY FILTER"
export const REMOVE_ALL_DESIRED_LOCATIONS_FILTER =
	"REMOVE ALL DESIRED LOCATIONS FILTER"

// Dispatchers
export const getJobsInitAction = () => ({ type: GET_JOBS_INIT })
export const getJobsSuccessAction = (jobsData, jobCount) => ({
	type: GET_JOBS_SUCCESS,
	payload: jobsData,
	jobCount: jobCount,
})
export const getJobsErrorAction = () => ({ type: GET_JOBS_ERROR })

export const getSimilarJobsInitAction = () => ({ type: GET_SIMILAR_JOBS_INIT })
export const getSimilarJobsSuccessAction = (
	jobsData,
	jobCount,
	jobId,
	jobsApplied
) => ({
	type: GET_SIMILAR_JOBS_SUCCESS,
	payload: jobsData,
	jobCount: jobCount,
	jobId: jobId,
	jobsApplied: jobsApplied,
})
export const getSimilarJobsErrorAction = () => ({
	type: GET_SIMILAR_JOBS_ERROR,
})

export const getSpecificJobInitAction = () => ({ type: GET_SPECIFIC_JOB_INIT })
export const getSpecificJobSuccessAction = jobData => ({
	type: GET_SPECIFIC_JOB_SUCCESS,
	payload: jobData,
})
export const getSpecificJobErrorAction = () => ({
	type: GET_SPECIFIC_JOB_ERROR,
})

export const getAppliedJobsInitAction = () => ({ type: GET_APPLIED_JOBS_INIT })
export const getAppliedJobsSuccessAction = appliedJobsData => ({
	type: GET_APPLIED_JOBS_SUCCESS,
	payload: appliedJobsData,
})
export const getAppliedJobsErrorAction = () => ({
	type: GET_APPLIED_JOBS_ERROR,
})

export const getInitialMatchingScoreInitAction = () => ({
	type: GET_SPECIFIC_MATCHING_SCORE_INIT,
})
export const getInitialMatchingScoreSuccessAction = score => ({
	type: GET_SPECIFIC_MATCHING_SCORE_SUCCESS,
	score: score,
})
export const getInitialMatchingScoreErrorAction = () => ({
	type: GET_SPECIFIC_MATCHING_SCORE_ERROR,
})

export const associateCandidateInitAction = () => ({
	type: ASSOCIATE_CANDIDATE_INIT,
})
export const associateCandidateSuccessAction = () => ({
	type: ASSOCIATE_CANDIDATE_SUCCESS,
})
export const associateCandidateErrorAction = () => ({
	type: ASSOCIATE_CANDIDATE_ERROR,
})

export const removeJobsAction = () => ({ type: REMOVE_JOBS })
export const removeSpecificJobAction = () => ({ type: REMOVE_SPECIFIC_JOB })

export const blockApplyButtonAction = (jobId, jobIds) => ({
	type: BLOCK_APPLY_BUTTON,
	jobId: jobId,
	jobIds: jobIds,
})

export const setJobShareAuthModalAction = isModalOpen => ({
	type: SET_JOB_SHARE_AUTH_MODAL,
	isModalOpen: isModalOpen.isModalOpen,
})
export const setJobSharePcModalAction = config => ({
	type: SET_JOB_SHARE_PC_MODAL,
	isModalOpen: config.isModalOpen,
	url: config.url,
	logoSrcs: config.logoSrcs,
	jobTitle: config.jobTitle,
	withInstructions: config.withInstructions,
})

export const setFilterJobsModalAction = isModalOpen => ({
	type: SET_FILTER_JOBS_MODAL,
	isModalOpen: isModalOpen.isModalOpen,
})
export const setFilteringOptionsAction = dataSource => ({
	type: SET_FILTERING_OPTIONS,
	dataSource: dataSource,
})
export const removeAllFilteringOptionsAction = () => ({
	type: REMOVE_ALL_FILTERING_OPTIONS,
})

export const saveFilteringFieldAction = (fieldValue, fieldName) => ({
	type: SAVE_FILTERING_FIELD,
	fieldValue: fieldValue,
	fieldName: fieldName,
})
export const removeItemMultipleChoiceAction = (fieldValue, fieldName) => ({
	type: REMOVE_ITEM_MULTIPLE_CHOICE,
	fieldValue: fieldValue,
	fieldName: fieldName,
})
export const removeItemSingleChoiceAction = (fieldValue, fieldName) => ({
	type: REMOVE_ITEM_SINGLE_CHOICE,
	fieldValue: fieldValue,
	fieldName: fieldName,
})

export const setDesiredLocationFilterAction = (fieldValue, id, lat, lng) => ({
	type: SET_DESIRED_LOCATION_FILTER,
	fieldValue: fieldValue,
	id: id,
	lat: lat,
	lng: lng,
})
export const removeDesiredLocationFilterAction = id => ({
	type: REMOVE_DESIRED_LOCATION_FILTER,
	id: id,
})
export const removeDesiredLocationByCityFilterAction = city => ({
	type: REMOVE_DESIRED_LOCATION_BY_CITY_FILTER,
	city: city,
})
export const removeAllDesiredLocationsFilterAction = id => ({
	type: REMOVE_ALL_DESIRED_LOCATIONS_FILTER,
	id: id,
})

// Async thunks
export const asyncGetJobsAction = config => {
	return (dispatch, getState) => {
		dispatch(getJobsInitAction())

		// Redux selectors
		const profileReducer = getState().profileReducer
		const authReducer = getState().authReducer

		// Determine which version of the endpoint should be used
		let usePublicEndpoint = true

		if (
			authReducer.userLoggedIn &&
			profileReducer.changes.userType === "candidate"
		) {
			usePublicEndpoint = false
		}

		// Filtering params
		let candidateQueryParams = null
		if (config.filteringOptions) {
			candidateQueryParams = { ...config.filteringOptions }

			// cleaning up the salary field to send it as an integer
			if (candidateQueryParams.salary_expectation_month) {
				let numericString =
					candidateQueryParams.salary_expectation_month.replace(
						/[^\d]/g,
						""
					)
				candidateQueryParams.salary_expectation_month = parseInt(
					numericString,
					10
				)

				candidateQueryParams.salary_from =
					candidateQueryParams.salary_expectation_month
				delete candidateQueryParams.salary_expectation_month
			}
		} else {
			candidateQueryParams = {
				qualifications: [],
				locations: [],
			}
		}

		if (candidateQueryParams.salary_expectation_month) {
			let numericString =
				candidateQueryParams.salary_expectation_month.replace(
					/[^\d]/g,
					""
				)
			candidateQueryParams.salary_expectation_month = parseInt(
				numericString,
				10
			)
			candidateQueryParams.salary_from =
				candidateQueryParams.salary_expectation_month
			delete candidateQueryParams.salary_expectation_month
		}

		// Removing duplicates
		if (candidateQueryParams?.locations?.length > 1) {
			const uniqueLocations = new Set()
			candidateQueryParams.locations =
				candidateQueryParams.locations.filter(location => {
					if (!uniqueLocations.has(location.location_name)) {
						uniqueLocations.add(location.location_name)
						return true
					}
					return false
				})
		}

		// Changing object into valid query params
		let filteredCandidateQueryParams = Object.keys(
			candidateQueryParams
		).reduce(
			(acc, key) =>
				Array.isArray(candidateQueryParams[key]) &&
				candidateQueryParams[key].length === 0
					? acc
					: { ...acc, [key]: candidateQueryParams[key] },
			{}
		)

		// Encoding query params just in case
		const queryString = Object.keys(filteredCandidateQueryParams)
			.map(key => {
				let value = filteredCandidateQueryParams[key]
				if (key === "locations" && Array.isArray(value)) {
					// Convert locations array to JSON string
					value = JSON.stringify(value)
				}
				return encodeURIComponent(key) + "=" + encodeURIComponent(value)
			})
			.join("&")

		if (usePublicEndpoint) {
			fetch(
				`${backendURL}/job_openings/matches/v2/public?page=${config.page}&${queryString}`,
				{
					headers: new Headers({
						"X-APP-KEY": "sdfkjlh2394823ufhn2390nfsdfkj",
					}),
				}
			)
				.then(response => response.json())
				.then(data => {
					if (data.jobCount === 0 || data.jobCount === null) {
						dispatch(getJobsErrorAction())
					} else if (data.jobCount > 0) {
						dispatch(getJobsSuccessAction(data.jobs, data.jobCount))
					} else {
						dispatch(getJobsErrorAction())
					}
				})
				.catch(error => {
					console.log(error)
				})
		} else {
			firebaseAuth.currentUser
				.getIdToken(true)
				.then(token => {
					fetch(
						`${backendURL}/job_openings/matches/v2?page=${config.page}&${queryString}`,
						{
							headers: new Headers({
								"X-FIREBASE-KEY": token,
							}),
						}
					)
						.then(response => response.json())
						.then(data => {
							if (data.jobCount === 0 || data.jobCount === null) {
								dispatch(getJobsErrorAction())
							} else if (data.jobCount > 0) {
								dispatch(
									getJobsSuccessAction(
										data.jobs,
										data.jobCount
									)
								)
							} else {
								dispatch(getJobsErrorAction())
							}
						})
						.catch(error => {
							console.log(error)
						})
				})
				.catch(error => {
					console.log("error with fetching token", error)
				})
		}
	}
}

export const asyncGetAppliedJobsAction = config => {
	return dispatch => {
		dispatch(getAppliedJobsInitAction())

		firebaseAuth.currentUser
			.getIdToken(true)
			.then(token => {
				fetch(`${backendURL}/applications/`, {
					headers: new Headers({
						"X-FIREBASE-KEY": token,
					}),
				})
					.then(response => response.json())
					.then(data => {
						if (data?.applications?.length > 0) {
							dispatch(
								getAppliedJobsSuccessAction(data.applications)
							)
						} else {
							dispatch(getAppliedJobsErrorAction())
						}
					})
					.catch(error => {
						console.log(error)
					})
			})
			.catch(error => {
				console.log("error with fetching token", error)
			})
	}
}

export const asyncGetSimilarJobsAction = (job, jobsApplied) => {
	return async (dispatch, getState) => {
		dispatch(getSimilarJobsInitAction())

		// Redux selectors
		const profileReducer = getState().profileReducer
		const authReducer = getState().authReducer

		// Determine which version of the endpoint should be used
		let usePublicEndpoint = true

		if (
			authReducer.userLoggedIn &&
			profileReducer.changes.userType === "candidate"
		) {
			usePublicEndpoint = false
		}

		// Create the new location object
		let locationObject = {
			location_name: job.city,
			coordinates: {},
		}

		let jobQueryParams = {
			locations: job?.city ? [locationObject] : [],
			qualifications:
				job?.qualification?.length > 0
					? job.qualification.map(qual => qual.qualification_name)
					: [],
		}

		// Removing duplicates
		if (jobQueryParams?.locations?.length > 1) {
			const uniqueLocations = new Set()
			jobQueryParams.locations = jobQueryParams.locations.filter(
				location => {
					if (!uniqueLocations.has(location.location_name)) {
						uniqueLocations.add(location.location_name)
						return true
					}
					return false
				}
			)
		}

		// Encoding query params just in case
		const queryString = Object.keys(jobQueryParams)
			.map(key => {
				let value = jobQueryParams[key]
				if (key === "locations" && Array.isArray(value)) {
					// Convert locations array to JSON string
					value = JSON.stringify(value)
				}
				return encodeURIComponent(key) + "=" + encodeURIComponent(value)
			})
			.join("&")
		if (usePublicEndpoint) {
			fetch(
				`${backendURL}/job_openings/matches/v2/public?page=1&${queryString}`,
				{
					headers: new Headers({
						"X-APP-KEY": "sdfkjlh2394823ufhn2390nfsdfkj",
					}),
				}
			)
				.then(response => response.json())
				.then(data => {
					if (data.jobCount === 0 || data.jobCount === null) {
						dispatch(getSimilarJobsErrorAction())
					} else if (data.jobCount > 0) {
						dispatch(
							getSimilarJobsSuccessAction(
								data.jobs,
								data.jobCount,
								job.zoho_job_openings_id,
								jobsApplied
							)
						)
					} else {
						dispatch(getSimilarJobsErrorAction())
					}
				})
				.catch(error => {
					console.log(error)
				})
		} else {
			firebaseAuth.currentUser
				.getIdToken(true)
				.then(token => {
					fetch(
						`${backendURL}/job_openings/matches/v2?page=1&${queryString}`,
						{
							headers: new Headers({
								"X-FIREBASE-KEY": token,
							}),
						}
					)
						.then(response => response.json())
						.then(data => {
							if (data.jobCount === 0 || data.jobCount === null) {
								dispatch(getSimilarJobsErrorAction())
							} else if (data.jobCount > 0) {
								dispatch(
									getSimilarJobsSuccessAction(
										data.jobs,
										data.jobCount,
										job.zoho_job_openings_id,
										jobsApplied
									)
								)
							} else {
								dispatch(getSimilarJobsErrorAction())
							}
						})
						.catch(error => {
							console.log(error)
						})
				})
				.catch(error => {
					console.log("error with fetching token", error)
				})
		}
	}
}

export const asyncGetSpecificJobAction = job_id => {
	return (dispatch, getState) => {
		dispatch(getSpecificJobInitAction())
		const appKey = "sdfkjlh2394823ufhn2390nfsdfkj"

		// Redux selectors
		const authReducer = getState().authReducer

		// Determine which version of the endpoint should be used
		let usePublicEndpoint = true

		if (authReducer.userLoggedIn) {
			usePublicEndpoint = false
		}

		// URL Parameters
		const queryString = window.location.search
		const urlParams = new URLSearchParams(queryString)
		const affiliateId = urlParams.get("a_id")

		// Query param for affiliates
		const affiliateQueryParam = affiliateId ? `&a_id=${affiliateId}` : ""

		if (usePublicEndpoint) {
			fetch(
				`${backendURL}/job_openings/?zoho_job_openings_id=${job_id}${affiliateQueryParam}`,
				{
					headers: new Headers({
						"X-APP-KEY": appKey,
					}),
				}
			)
				.then(response => {
					if (response.status === 500) {
						dispatch(notificationsActions.setAppDownAction(true))
					} else {
						return response.json()
					}
				})
				.then(data => {
					if (data.message === "Job found") {
						if (data.jobOpening.job_opening_status === "Inaktiv") {
							dispatch(
								notificationsActions.sendNotificationAction({
									open: true,
									message:
										"Dieses Stellenangebot befindet sich nicht mehr auf unserer Plattform. Auf dieser Seite findest du alle verfügbaren Stellen.",
									type: "info",
									timeout: 5000,
								})
							)
							history.push("/jobs")
						} else {
							dispatch(
								getSpecificJobSuccessAction(data.jobOpening)
							)
						}
					} else {
						dispatch(getSpecificJobErrorAction())
					}
				})
				.catch(error => {
					dispatch(getSpecificJobErrorAction())
				})
		} else {
			firebaseAuth.currentUser
				.getIdToken(true)
				.then(token => {
					fetch(
						`${backendURL}/job_openings/?zoho_job_openings_id=${job_id}${affiliateQueryParam}`,
						{
							headers: new Headers({
								"X-APP-KEY": appKey,
								"X-FIREBASE-KEY": token,
							}),
						}
					)
						.then(response => {
							if (response.status === 500) {
								dispatch(
									notificationsActions.setAppDownAction(true)
								)
							} else {
								return response.json()
							}
						})
						.then(data => {
							if (data.message === "Job found") {
								if (
									data.jobOpening.job_opening_status ===
									"Inaktiv"
								) {
									dispatch(
										notificationsActions.sendNotificationAction(
											{
												open: true,
												message:
													"Dieses Stellenangebot befindet sich nicht mehr auf unserer Plattform. Auf dieser Seite findest du alle verfügbaren Stellen.",
												type: "info",
												timeout: 5000,
											}
										)
									)
									history.push("/jobs")
								} else {
									dispatch(
										getSpecificJobSuccessAction(
											data.jobOpening
										)
									)
								}
							} else {
								dispatch(getSpecificJobErrorAction())
							}
						})
						.catch(error => {
							console.log(error)
							dispatch(getSpecificJobErrorAction())
						})
				})
				.catch(error => {
					console.log("error with fetching token", error)
				})
		}
	}
}

export const asyncAssociateCandidateAction = (
	jobId,
	setDiplomaPresent = false
) => {
	return (dispatch, getState) => {
		dispatch(associateCandidateInitAction())

		let currentProfileJobIds =
			getState().profileReducer.changes.job_opening_ids

		let newProfileJobIds = []

		if (currentProfileJobIds === null) {
			newProfileJobIds = [jobId]
		} else {
			newProfileJobIds = [...currentProfileJobIds, jobId]
		}

		let newProfileData = getState().profileReducer

		let profileData = {
			...newProfileData.profileData,
			job_opening_ids: newProfileJobIds,
		}

		if (setDiplomaPresent) {
			profileData = {
				...profileData,
				diploma_present: true,
			}
		}

		// Special diverse gender case
		if (
			profileData.salutation === "keine Angabe" ||
			profileData.salutation === "None"
		) {
			profileData.salutation = ""
		}

		// Data cleaning for Ausbildung case
		if (profileData.qualification !== "Noch in Ausbildung") {
			profileData.ongoing_education = null
			profileData.education_finish_date = null
		}

		// Setting affiliate id
		if (localStorage.getItem("affiliateId")) {
			profileData.a_id = Number(localStorage.getItem("affiliateId"))
			localStorage.removeItem("affiliateId")
		}
		// Query param for affiliates
		const affiliateQueryParam = profileData.a_id
			? `?a_id=${profileData.a_id}`
			: ""

		firebaseAuth.currentUser
			.getIdToken(true)
			.then(token => {
				fetch(`${backendURL}/candidates/${affiliateQueryParam}`, {
					method: "PUT",
					body: JSON.stringify(profileData),
					headers: {
						"Content-Type": "application/json",
						"X-FIREBASE-KEY": token,
					},
				})
					.then(response => {
						if (response.status > 199 && response.status < 300) {
							return response.json()
						} else {
							dispatch(
								notificationsActions.setAppDownAction(true)
							)
						}
					})
					.then(data => {
						if (
							data.message.includes(
								"Candidate successfully updated"
							)
						) {
							dispatch(
								profileActions.asyncGetProfileDataAction({
									comingFromJobs: true,
								})
							)
							dispatch(associateCandidateSuccessAction())
							dispatch(
								notificationsActions.sendNotificationAction({
									open: true,
									message:
										"Du hast dich erfolgreich beworben. Dein persönlicher Recruiter wird sich umgehend mit dir in Verbindung setzen.",
									type: "success",
									timeout: 5000,
								})
							)
							dispatch(asyncGetAppliedJobsAction())
						} else {
							dispatch(associateCandidateErrorAction())
							dispatch(
								notificationsActions.sendNotificationAction({
									open: true,
									message: "Netzwerk Fehler",
									type: "error",
								})
							)
						}
					})
					.catch(error => {
						dispatch(associateCandidateErrorAction())
						dispatch(
							notificationsActions.sendNotificationAction({
								open: true,
								message: "Netzwerk Fehler",
								type: "error",
							})
						)
					})
			})
			.catch(error => {
				console.log("error with fetching token", error)
			})
	}
}

export const asyncSaveFilteringFieldAction =
	(fieldValue, fieldName) => dispatch => {
		dispatch({
			type: SAVE_FILTERING_FIELD,
			fieldValue: fieldValue,
			fieldName: fieldName,
		})
		return Promise.resolve()
	}
