import React, { useState, useEffect } from "react"

// Redux
import { useDispatch, useSelector } from "react-redux"
import * as jobsActions from "../../state/jobsActions"
import * as tagManagerActions from "../../../tagManagerEvents/state/tagManagerActions"

// Material UI
import { makeStyles } from "@material-ui/core/styles"
import { Grid } from "@material-ui/core"

// Icons
import jobCardIconShare from "../../../images/jobCardIcons/jobCardIconShare.png"

// Styles
const useStyles = makeStyles({
	shareIcon: {
		width: 25,
		marginRight: 5,
	},
	lessSpacing: {
		maxWidth: 300,
		margin: "0 auto",
		paddingTop: "1px !important",
		paddingBottom: "1px !important",
		cursor: "pointer",
	},
	divider: {
		width: "100%",
	},
	shareLinkJobslist: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		marginTop: 4,
		marginBottom: 10,
		fontSize: 14,
		fontWeight: 600,
		color: "#3a5dab",
	},
	shareLinkJobPage: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		marginTop: 16,
		marginBottom: 16,
		fontSize: 14,
		fontWeight: 600,
		color: "#3a5dab",
	},
})

// Component
const JobShareBtn = props => {
	// Hooks
	const styles = useStyles()
	const dispatch = useDispatch()

	// Destructuring props
	const { job, url, location, logoSrcs, jobTitle } = props

	// Redux selectors
	const authReducer = useSelector(state => state.authReducer)
	const profileReducer = useSelector(state => state.profileReducer)

	// Local state
	const [affiliateIdUrlParameter, setAffiliateIdUrlParameter] = useState("")

	// Determine the affiliate id url parameter
	useEffect(() => {
		if (profileReducer?.changes?.affiliateId) {
			setAffiliateIdUrlParameter(
				`?a_id=${profileReducer.changes.affiliateId}`
			)
		} else {
			setAffiliateIdUrlParameter("")
		}
	}, [profileReducer.changes])

	// Render
	return (
		<Grid
			item
			xs={12}
			className={styles.lessSpacing}
			onClick={e => {
				e.preventDefault()

				// sending an event to gtag and ga about the click
				dispatch(
					tagManagerActions.sendComplexCustomEvent({
						event: "jobShareModalOpened",
						isUserLoggedIn: authReducer?.userLoggedIn
							? "userLoggedIn"
							: "userLoggedOut",
						userType: profileReducer?.changes?.userType
							? profileReducer.changes.userType
							: "userLoggedOut",
					})
				)

				if (authReducer.userLoggedIn) {
					if (navigator.share) {
						if (localStorage.getItem("hideSharingInstructions")) {
							navigator
								.share({
									title: job.job_title_app
										? job.job_title_app
										: job.job_title,
									text: "Hallo, dieser Job könnte gut zu dir passen. Schau ihn dir an...",
									url:
										location === "jobsList"
											? `${window.location.href}/${job.zoho_job_openings_id}${affiliateIdUrlParameter}`
											: `${url.replace(
													/[?&]a_id=[^&]+(?:&)?(?![^?]*\?)/,
													""
											  )}${affiliateIdUrlParameter}`,
								})
								.then(() => {})
								.catch(error => {})
						} else {
							dispatch(
								jobsActions.setJobSharePcModalAction({
									isModalOpen: true,
									url:
										location === "jobsList"
											? `${window.location.href}/${job.zoho_job_openings_id}${affiliateIdUrlParameter}`
											: `${url.replace(
													/[?&]a_id=[^&]+(?:&)?(?![^?]*\?)/,
													""
											  )}${affiliateIdUrlParameter}`,
									logoSrcs: logoSrcs,
									jobTitle: jobTitle,
									withInstructions: true,
								})
							)
						}
					} else {
						dispatch(
							jobsActions.setJobSharePcModalAction({
								isModalOpen: true,
								url:
									location === "jobsList"
										? `${window.location.href}/${job.zoho_job_openings_id}${affiliateIdUrlParameter}`
										: `${url.replace(
												/[?&]a_id=[^&]+(?:&)?(?![^?]*\?)/,
												""
										  )}${affiliateIdUrlParameter}`,
								logoSrcs: logoSrcs,
								jobTitle: jobTitle,
								withInstructions: false,
							})
						)
					}
				} else {
					// opening the modal and saving the job id
					dispatch(
						jobsActions.setJobShareAuthModalAction({
							isModalOpen: true,
						})
					)
					localStorage.setItem("shareJobId", job.zoho_job_openings_id)
				}
			}}
		>
			<p
				className={
					location === "jobsList"
						? styles.shareLinkJobslist
						: styles.shareLinkJobPage
				}
				onClick={() => {
					dispatch(
						tagManagerActions.sendSimpleCustomEvent({
							eventName: "clickJobShareModalJobPageCustomEvent",
						})
					)
				}}
			>
				<img
					alt="location"
					src={jobCardIconShare}
					className={styles.shareIcon}
				/>
				Job teilen und 500 € verdienen
			</p>
		</Grid>
	)
}

export default JobShareBtn
