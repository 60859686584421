import * as actions from "./clickFunnelActions"

export const initialState = {
	formData: {
		salutation: "",
		first_name: "",
		last_name: "",
		phone: "",
		email: "",
		email_confirmation: "",
		qualification: "",
		occupational_group: "",
		occupational_group_free_text: "",
		city: "",
		commute_time: "",
		country: "",
		zip_code: "",
		availability: [],
		contract_type: [],
		departments: [],
		locations: [],
		earliest_start_date: "",
		employment_form: "",
		hours_per_week: "",
		salary_expectation_month: "",
		shifts: [],
		type_of_employer: [],
		email_opt_out: "",
		country_of_education: "",
		drivers_license: "",
		education_germany: "",
		education_recognized: "",
		extra_qualifications: [],
		qualification_of_interest: [],
		german_level: "",
		total_work_experience: "",
		work_experience: [{ company: "", period_length: "" }],
		benefits: [],
		transportation: "",
		want_more_jobs: "",
		choice_type_of_employer: "",
		choice_departments: "",
		qualification_hilfskraft: "",
		ongoing_education: "",
		ongoing_education_freetext: "",
		education_finish_date: null,
		choice_extra_qualifications: "",
		gdprCheckboxChecked: false,
		work_field: null,
		qualification_educator: "",
		occupational_group_educator: "",
		ongoing_education_educator: "",
		qualification_hilfskraft_freetext: "",
		type_of_employer_educator: [],
		checkContactDataFields: false,
	},
	attachment: {
		attachmentFile: "",
		attachmentName: "",
	},
}

export default function clickFunnelReducer(state = initialState, action) {
	switch (action.type) {
		case actions.SAVE_FIELD:
			return {
				...state,
				formData: {
					...state.formData,
					[action.fieldName]: action.fieldValue,
				},
			}
		case actions.SAVE_MULTIPLE_CHOICE_FIELD:
			return {
				...state,
				formData: {
					...state.formData,
					[action.fieldName]: action.fieldValue,
				},
			}
		case actions.REMOVE_CF_ITEM_MULTIPLE_CHOICE:
			let mcFieldOld1 = state.formData[action.fieldName]
			let filteredData1 = mcFieldOld1.filter(
				el => el !== action.fieldValue
			)
			let mcFieldNew1 = [...filteredData1]

			return {
				...state,
				formData: {
					...state.formData,
					[action.fieldName]: mcFieldNew1,
				},
			}
		case actions.CLEAR_MULTIPLE_CHOICE:
			return {
				...state,
				formData: {
					...state.formData,
					[action.fieldName]: [],
				},
			}
		case actions.SET_TEXT_FIELD_VALUE:
			return {
				...state,
				formData: {
					...state.formData,
					[action.fieldName]: action.fieldValue,
				},
			}
		case actions.SET_DESIRED_LOCATION:
			let currentLocations = [...state.formData.locations]
			let newLocations = [...currentLocations]

			let fieldValue = action.fieldValue ? action.fieldValue : null
			let lat = action.lat ? action.lat : null
			let lng = action.lng ? action.lng : null

			// Create the new location object
			let newLocation = {
				location_name: fieldValue,
				coordinates: {
					latitude: lat,
					longitude: lng,
				},
			}

			if (newLocations[action.id] === null) {
				newLocations.splice(action.id, 1, newLocation)
			} else {
				newLocations.splice(action.id, 1, newLocation)
			}

			return {
				...state,
				formData: {
					...state.formData,
					locations: newLocations,
				},
			}
		case actions.REMOVE_DESIRED_LOCATION:
			let removeCurrentLocations = [...state.formData.locations]
			let removeNewLocations = [...removeCurrentLocations]
			removeNewLocations.splice(action.id, 1)

			return {
				...state,
				formData: {
					...state.formData,
					locations: removeNewLocations,
				},
			}
		case actions.SET_ATTACHMENT:
			return {
				...state,
				attachment: {
					attachmentFile: action.attachmentFile,
					attachmentName: action.attachmentName,
				},
			}
		case actions.ADD_EXPERIENCE_FIELD:
			return {
				...state,
				formData: {
					...state.formData,
					work_experience: [
						...state.formData.work_experience,
						{ company: "", period_length: "" },
					],
				},
			}
		case actions.CHANGE_EXPERIENCE_FIELD:
			let { experienceNewValue, type, id } = action.payload
			let oldSFWorkExperience = [...state.formData.work_experience]
			oldSFWorkExperience[id][type] = experienceNewValue
			let newSFWorkExperience = [...oldSFWorkExperience]

			return {
				...state,
				formData: {
					...state.formData,
					work_experience: newSFWorkExperience,
				},
			}
		case actions.REMOVE_EXPERIENCE_FIELD:
			let removedSFExperienceField = [...state.formData.work_experience]
			removedSFExperienceField.splice(action.id, 1)

			return {
				...state,
				formData: {
					...state.formData,
					work_experience: removedSFExperienceField,
				},
			}
		case actions.IMPORT_PROFILE_DATA:
			return {
				...state,
				formData: { ...state.formData, ...action.profileData },
			}
		case actions.CLEAR_REDUCER:
			return initialState
		case actions.CHECK_CONTACT_DATA_FIELDS:
			return {
				...state,
				formData: {
					...state.formData,
					checkContactDataFields: true,
				},
			}
		default:
			return state
	}
}
