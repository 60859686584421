import React, { useState, useEffect } from "react"

// Material UI
import { makeStyles } from "@material-ui/core/styles"
import { FormControl, Chip } from "@material-ui/core"

// CSS
const useStyles = makeStyles(theme => ({
	filteringChipActive: {
		fontFamily: "Nunito",
		height: 32,
		fontSize: 12,
		backgroundColor: "#3a5dab",
		borderRadius: 12,
		padding: "6px 8px",
		"&:hover": {
			color: "#fafafa",
			backgroundColor: "#20335e",
		},
		"&:focus": {
			color: "#fafafa",
			backgroundColor: "#3a5dab",
		},
		"&:active": {
			color: "#fafafa",
			backgroundColor: "#3a5dab",
		},
	},
	filteringChipInactive: {
		fontFamily: "Nunito",
		height: 32,
		fontSize: 12,
		backgroundColor: "rgba(58, 93, 171, 0.1)",
		color: "#3a5dab",
		borderRadius: 12,
		padding: "6px 8px",
		"&:hover": {
			color: "#fafafa",
			backgroundColor: "#20335e",
		},
		"&:focus": {
			color: "#fafafa",
			backgroundColor: "#3a5dab",
		},
		"&:active": {
			color: "#fafafa",
			backgroundColor: "#3a5dab",
		},
	},
}))

// Component
const ApplicationFilterChip = props => {
	// Hooks
	const styles = useStyles()

	// Destructuring props
	const { fieldName, setApplicationStatusFilter, applicationStatusFilter } =
		props

	// Local state
	const [chipActive, setChipActive] = useState(null)

	// Determine whether filter is active
	useEffect(() => {
		if (
			fieldName === "ALLE BEWERBUNGEN" &&
			applicationStatusFilter === "all"
		) {
			setChipActive(true)
		} else if (
			fieldName === "AKTIV" &&
			applicationStatusFilter === "active"
		) {
			setChipActive(true)
		} else if (
			fieldName === "INAKTIV" &&
			applicationStatusFilter === "inactive"
		) {
			setChipActive(true)
		} else {
			setChipActive(false)
		}
	}, [fieldName, applicationStatusFilter])

	// Render
	return (
		<FormControl variant="filled" className={styles.formControl}>
			<Chip
				size="small"
				className={
					chipActive
						? styles.filteringChipActive
						: styles.filteringChipInactive
				}
				label={<p>{fieldName}</p>}
				clickable
				onClick={() => {
					if (fieldName === "ALLE BEWERBUNGEN") {
						setApplicationStatusFilter("all")
					} else if (fieldName === "AKTIV") {
						setApplicationStatusFilter("active")
					} else if (fieldName === "INAKTIV") {
						setApplicationStatusFilter("inactive")
					}
				}}
				color="primary"
			/>
		</FormControl>
	)
}

export default ApplicationFilterChip
