import { backendURL } from "../../environment"

// Redux
import * as notificationsActions from "../../notifications/state/notificationsActions"
import * as loadingScreenActions from "../../loadingScreen/state/ReduxLoadingScreenActions"

// Firebase
import { firebaseAuth } from "../../firebase"
import { signInWithCustomToken } from "firebase/auth"

// Names
export const SEND_EMAIL_LOGIN_INIT = "SEND EMAIL LOGIN INIT"
export const SEND_EMAIL_LOGIN_SUCCESS = "SEND EMAIL LOGIN SUCCESS"
export const SEND_EIMAIL_LOGIN_ERROR = "SEND EMAIL LOGIN ERROR"

export const SET_USER_LOGGED_IN = "SET USER LOGGED IN"
export const SET_USER_LOGGED_OUT = "SET USER LOGGED OUT"

export const REGISTER_CUSTOM_TOKEN_INIT = "REGISTER CUSTOM TOKEN INIT"
export const REGISTER_CUSTOM_TOKEN_SUCCESS = "REGISTER CUSTOM TOKEN SUCCESS"
export const REGISTER_CUSTOM_TOKEN_ERROR = "REGISTER CUSTOM TOKEN ERROR"

export const LOGIN_CUSTOM_TOKEN_INIT = "LOGIN CUSTOM TOKEN INIT"
export const LOGIN_CUSTOM_TOKEN_SUCCESS = "LOGIN CUSTOM TOKEN SUCCESS"
export const LOGIN_CUSTOM_TOKEN_ERROR = "LOGIN CUSTOM TOKEN ERROR"

export const SET_AB_TEST = "SET AB TEST"

// Dispatchers
export const sendEmailLoginInitAction = () => ({ type: SEND_EMAIL_LOGIN_INIT })
export const sendEmailLoginSuccessAction = () => ({
	type: SEND_EMAIL_LOGIN_SUCCESS,
})
export const sendEmailLoginErrorAction = () => ({
	type: SEND_EIMAIL_LOGIN_ERROR,
})

export const setUserLoggedInAction = email => ({
	type: SET_USER_LOGGED_IN,
	email: email,
})
export const setUserLoggedOutAction = () => ({
	type: SET_USER_LOGGED_OUT,
})

export const registerCustomTokenInitAction = () => ({
	type: REGISTER_CUSTOM_TOKEN_INIT,
})
export const registerCustomTokenSuccessAction = () => ({
	type: REGISTER_CUSTOM_TOKEN_SUCCESS,
})
export const registerCustomTokenErrorAction = () => ({
	type: REGISTER_CUSTOM_TOKEN_ERROR,
})

export const loginCustomTokenInitAction = () => ({
	type: LOGIN_CUSTOM_TOKEN_INIT,
})
export const loginCustomTokenSuccessAction = () => ({
	type: LOGIN_CUSTOM_TOKEN_SUCCESS,
})
export const loginCustomTokenErrorAction = () => ({
	type: LOGIN_CUSTOM_TOKEN_ERROR,
})

export const setAbTestAction = config => ({
	type: SET_AB_TEST,
	config: config,
})

// Async thunks
export const asyncSendEmailLoginAction = config => {
	return dispatch => {
		dispatch(sendEmailLoginInitAction())

		window.localStorage.setItem("emailForSignIn", config.email)

		const applyJobIdQueryParam = config.jobOpeningId
			? `&zoho_job_openings_id=${config.jobOpeningId}`
			: ""
		const appKey = "sdfkjlh2394823ufhn2390nfsdfkj"

		fetch(
			`${backendURL}/email/${
				config.userType
			}_login?email=${encodeURIComponent(config.email)}&email_type=${
				config.emailType
			}${applyJobIdQueryParam}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"X-APP-KEY": appKey,
				},
			}
		)
			.then(response => {
				if (response.status > 199 && response.status < 300) {
					return response.json()
				} else {
					dispatch(notificationsActions.setAppDownAction(true))
				}
			})
			.then(data => {
				if (data.message.includes("email successfully sent")) {
					dispatch(sendEmailLoginSuccessAction())
				} else {
					dispatch(sendEmailLoginErrorAction())
					dispatch(notificationsActions.setAppDownAction(true))
				}
			})
			.catch(error => {
				dispatch(sendEmailLoginErrorAction())
			})
	}
}

export const asyncRegisterCustomTokenAction = config => {
	return dispatch => {
		dispatch(registerCustomTokenInitAction())

		window.localStorage.setItem("emailForSignIn", config.email)

		const appKey = "sdfkjlh2394823ufhn2390nfsdfkj"
		fetch(`${backendURL}/authentication/firebase`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-APP-KEY": appKey,
			},
			body: JSON.stringify({
				email: config.email,
			}),
		})
			.then(response => {
				if (response.status > 199 && response.status < 300) {
					return response.json()
				} else {
					dispatch(notificationsActions.setAppDownAction(true))
				}
			})
			.then(data => {
				if (data?.firebase_key) {
					dispatch(registerCustomTokenSuccessAction())
					dispatch(asyncLoginCustomTokenAction(data.firebase_key))
				} else {
					dispatch(registerCustomTokenErrorAction())
					dispatch(notificationsActions.setAppDownAction(true))
				}
			})
			.catch(error => {
				dispatch(registerCustomTokenErrorAction())
			})
	}
}

export const asyncLoginCustomTokenAction = customToken => {
	return dispatch => {
		dispatch(loginCustomTokenInitAction())

		signInWithCustomToken(firebaseAuth, customToken)
			.then(userCredential => {
				// Signed in
				dispatch(loadingScreenActions.closeLoadingScreenAction())
				dispatch(loginCustomTokenSuccessAction())
			})
			.catch(error => {
				dispatch(loginCustomTokenErrorAction())
			})
	}
}
