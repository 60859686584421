const questionsStructure = {
	benefits: {
		fieldName: "benefits",
		fieldText: "Welche 3 Jobvorteile sind dir besonders wichtig?",
		fieldType: "multipleChoice",
		slide: 0,
		logicJumps: {
			jumpType: "valueBased",
			nextQuestion: "work_field",
			hideQuestions: false,
		},
	},
	work_field: {
		fieldName: "work_field",
		fieldText: "Zu welchem Berufsfeld gehörst du?",
		fieldType: "singleChoice",
		slide: 0,
		logicJumps: {
			jumpType: "optionsBased",
			"Pflege & Medizin": {
				nextQuestion: "qualification",
				hideQuestions: [
					"qualification_educator",
					"occupational_group_educator",
					"occupational_group_free_text",
					"ongoing_education_educator",
					"ongoing_education_freetext",
					"education_finish_date",
					"choice_type_of_employer",
					"type_of_employer_educator",
				],
			},
			"Pädagogik & Soziale Arbeit": {
				nextQuestion: "qualification_educator",
				hideQuestions: [
					"qualification",
					"occupational_group",
					"occupational_group_free_text",
					"qualification_hilfskraft",
					"ongoing_education",
					"ongoing_education_freetext",
					"education_finish_date",
					"choice_type_of_employer",
					"type_of_employer",
				],
			},
		},
	},
	qualification_educator: {
		fieldName: "qualification_educator",
		fieldText: "Welche Qualifikation hast du?",
		fieldType: "singleChoiceSelect",
		slide: 0,
		logicJumps: {
			jumpType: "optionsBasedQualification",
			Weiteres: {
				nextQuestion: "occupational_group_educator",
				hideQuestions: [
					"ongoing_education_educator",
					"education_finish_date",
				],
			},
			"Noch in Ausbildung": {
				nextQuestion: "ongoing_education_educator",
				hideQuestions: [
					"occupational_group_educator",
					"occupational_group_free_text",
				],
			},
			other: {
				nextQuestion: "choice_type_of_employer",
				hideQuestions: [
					"ongoing_education_educator",
					"ongoing_education_freetext",
					"education_finish_date",
					"occupational_group_educator",
					"occupational_group_free_text",
					"type_of_employer",
				],
			},
		},
	},
	occupational_group_educator: {
		fieldName: "occupational_group_educator",
		fieldText: "Welcher Berufsgruppe bist du am besten zuzuordnen?",
		fieldType: "singleChoice",
		slide: 0,
		logicJumps: {
			jumpType: "optionsBasedOccupationalGroup",
			Weiteres: {
				nextQuestion: "occupational_group_free_text",
				hideQuestions: false,
			},
			other: {
				nextQuestion: "choice_type_of_employer",
				hideQuestions: ["occupational_group_free_text"],
			},
		},
	},
	qualification: {
		fieldName: "qualification",
		fieldText: "Welche Qualifikation hast du?",
		fieldType: "singleChoiceSelect",
		slide: 0,
		logicJumps: {
			jumpType: "optionsBasedQualification",
			Weiteres: {
				nextQuestion: "occupational_group",
				hideQuestions: [
					"qualification_hilfskraft",
					"ongoing_education",
					"education_finish_date",
				],
			},
			"Pflegehilfskraft/-assistenz": {
				nextQuestion: "qualification_hilfskraft",
				hideQuestions: [
					"occupational_group",
					"ongoing_education",
					"ongoing_education_freetext",
					"education_finish_date",
					"occupational_group_free_text",
				],
			},
			"Noch in Ausbildung": {
				nextQuestion: "ongoing_education",
				hideQuestions: [
					"qualification_hilfskraft",
					"occupational_group",
					"occupational_group_free_text",
				],
			},
			other: {
				nextQuestion: "choice_type_of_employer",
				hideQuestions: [
					"qualification_hilfskraft",
					"ongoing_education",
					"ongoing_education_freetext",
					"education_finish_date",
					"occupational_group",
					"occupational_group_free_text",
					"type_of_employer",
				],
			},
		},
	},
	occupational_group: {
		fieldName: "occupational_group",
		fieldText: "Welcher Berufsgruppe bist du am besten zuzuordnen?",
		fieldType: "singleChoice",
		slide: 0,
		logicJumps: {
			jumpType: "optionsBasedOccupationalGroup",
			Weiteres: {
				nextQuestion: "occupational_group_free_text",
				hideQuestions: false,
			},
			other: {
				nextQuestion: "choice_type_of_employer",
				hideQuestions: ["occupational_group_free_text"],
			},
		},
	},
	occupational_group_free_text: {
		fieldName: "occupational_group_free_text",
		fieldText: "Okay, welche genau?",
		fieldType: "freeText",
		slide: 0,
		logicJumps: {
			jumpType: "valueBased",
			nextQuestion: "choice_type_of_employer",
			hideQuestions: false,
		},
	},
	qualification_hilfskraft: {
		fieldName: "qualification_hilfskraft",
		fieldText: "Welche Qualifizierung bringst du mit?",
		fieldType: "singleChoice",
		slide: 0,
		logicJumps: {
			jumpType: "optionsBasedHilfskraft",
			Weiteres: {
				nextQuestion: "qualification_hilfskraft_freetext",
				hideQuestions: false,
			},
			other: {
				nextQuestion: "choice_type_of_employer",
				hideQuestions: ["qualification_hilfskraft_freetext"],
			},
		},
	},
	qualification_hilfskraft_freetext: {
		fieldName: "qualification_hilfskraft_freetext",
		fieldText: "Okay, welche genau?",
		fieldType: "freeText",
		slide: 0,
		logicJumps: {
			jumpType: "valueBased",
			nextQuestion: "choice_type_of_employer",
			hideQuestions: false,
		},
	},
	ongoing_education: {
		fieldName: "ongoing_education",
		fieldText: "Welche?",
		fieldType: "singleChoiceSelect",
		slide: 0,
		logicJumps: {
			jumpType: "optionsBasedOngoingEducation",
			Weiteres: {
				nextQuestion: "ongoing_education_freetext",
				hideQuestions: false,
			},
			other: {
				nextQuestion: "education_finish_date",
				hideQuestions: ["ongoing_education_freetext"],
			},
		},
	},
	ongoing_education_educator: {
		fieldName: "ongoing_education_educator",
		fieldText: "Welche?",
		fieldType: "singleChoiceSelect",
		slide: 0,
		logicJumps: {
			jumpType: "optionsBasedOngoingEducation",
			Weiteres: {
				nextQuestion: "ongoing_education_freetext",
				hideQuestions: false,
			},
			other: {
				nextQuestion: "education_finish_date",
				hideQuestions: ["ongoing_education_freetext"],
			},
		},
	},
	ongoing_education_freetext: {
		fieldName: "ongoing_education_freetext",
		fieldText: "Okay, welche genau?",
		fieldType: "freeText",
		slide: 0,
		logicJumps: {
			jumpType: "valueBased",
			nextQuestion: "education_finish_date",
			hideQuestions: false,
		},
	},
	education_finish_date: {
		fieldName: "education_finish_date",
		fieldText: "Wann bist du (ungefähr) fertig?",
		fieldType: "datePicker",
		slide: 0,
		logicJumps: {
			jumpType: "valueBased",
			nextQuestion: "choice_type_of_employer",
			hideQuestions: false,
		},
	},
	choice_type_of_employer: {
		fieldName: "choice_type_of_employer",
		fieldText: "In welchen Einrichtungen möchtest du am liebsten arbeiten?",
		fieldType: "singleChoice",
		slide: 1,
		logicJumps: {
			jumpType: "workFieldBased",
			"Pflege & Medizin": {
				"Nur in Bestimmten": {
					nextQuestion: "type_of_employer",
					hideQuestions: ["choice_departments", "departments"],
				},
				"Alle sind okay": {
					nextQuestion: "choice_departments",
					hideQuestions: ["type_of_employer"],
				},
			},
			"Pädagogik & Soziale Arbeit": {
				"Nur in Bestimmten": {
					nextQuestion: "type_of_employer_educator",
					hideQuestions: false,
				},
				"Alle sind okay": {
					nextQuestion: "locations",
					hideQuestions: ["type_of_employer_educator"],
				},
			},
		},
	},
	type_of_employer: {
		fieldName: "type_of_employer",
		fieldText: "Okay, welche genau?",
		fieldType: "multipleChoice",
		slide: 1,
		logicJumps: {
			jumpType: "optionsBasedTypeOfEmployer",
			showDepartments: {
				nextQuestion: "choice_departments",
				hideQuestions: false,
			},
			other: {
				nextQuestion: "locations",
				hideQuestions: ["choice_departments", "departments"],
			},
		},
	},
	type_of_employer_educator: {
		fieldName: "type_of_employer_educator",
		fieldText: "Okay, welche genau?",
		fieldType: "multipleChoice",
		slide: 1,
		logicJumps: {
			jumpType: "valueBased",
			nextQuestion: "locations",
			hideQuestions: false,
		},
	},
	choice_departments: {
		fieldName: "choice_departments",
		fieldText: "In welchem Fachbereich möchtest du arbeiten?",
		fieldType: "singleChoice",
		slide: 1,
		logicJumps: {
			jumpType: "optionsBased",
			"Nur in Bestimmten": {
				nextQuestion: "departments",
				hideQuestions: false,
			},
			"Alle sind okay": {
				nextQuestion: "locations",
				hideQuestions: ["departments"],
			},
		},
	},
	departments: {
		fieldName: "departments",
		fieldText: "Okay, welche genau?",
		fieldType: "multipleChoice",
		slide: 1,
		logicJumps: {
			jumpType: "valueBased",
			nextQuestion: "locations",
			hideQuestions: false,
		},
	},
	locations: {
		fieldName: "locations",
		fieldText: "In welchen Städten möchtest du arbeiten?",
		fieldType: "locationField",
		slide: 1,
		logicJumps: {
			jumpType: "valueBased",
			nextQuestion: "employment_form",
			hideQuestions: false,
		},
	},
	employment_form: {
		fieldName: "employment_form",
		fieldText: "Wie möchtest du arbeiten?",
		fieldType: "singleChoice",
		slide: 2,
		logicJumps: {
			jumpType: "optionsBased",
			Teilzeit: {
				nextQuestion: "hours_per_week",
				hideQuestions: false,
			},
			Vollzeit: {
				nextQuestion: "salary_expectation_month",
				hideQuestions: ["hours_per_week"],
			},
			Minijob: {
				nextQuestion: "german_level",
				hideQuestions: ["hours_per_week", "salary_expectation_month"],
			},
		},
	},
	hours_per_week: {
		fieldName: "hours_per_week",
		fieldText: "Wie viele Stunden pro Woche möchtest du arbeiten?",
		fieldType: "freeText",
		slide: 2,
		logicJumps: {
			jumpType: "valueBased",
			nextQuestion: "salary_expectation_month",
			hideQuestions: false,
		},
	},
	salary_expectation_month: {
		fieldName: "salary_expectation_month",
		fieldText:
			"Was möchtest du mindestens verdienen? (Bruttomonatsgehalt, Vollzeit, ohne Zuschläge) ",
		fieldType: "singleChoice",
		slide: 2,
		logicJumps: {
			jumpType: "valueBased",
			nextQuestion: "german_level",
			hideQuestions: false,
		},
	},
	german_level: {
		fieldName: "german_level",
		fieldText: "Wie schätzt du deine Deutschkenntnisse ein?",
		fieldType: "singleChoice",
		slide: 3,
		logicJumps: {
			jumpType: "optionsBasedGermanLevel",
			nativeAndDiplomaRequired: {
				nextQuestion: "total_work_experience",
				hideQuestions: ["education_recognized"],
			},
			nativeAndDiplomaNotRequired: {
				nextQuestion: "total_work_experience",
				hideQuestions: ["education_recognized", "attachment"],
			},
			notNativeAndNotPfegehilfskraft: {
				nextQuestion: "education_recognized",
				hideQuestions: ["attachment"],
			},
			notNativePflegehilfskraftAndDiplomaRequired: {
				nextQuestion: "total_work_experience",
				hideQuestions: ["education_recognized"],
			},
			notNativePflegehilfskraftAndDiplomaNotRequired: {
				nextQuestion: "total_work_experience",
				hideQuestions: ["education_recognized", "attachment"],
			},
		},
	},
	education_recognized: {
		fieldName: "education_recognized",
		fieldText: "Ist deine Ausbildung in Deutschland anerkannt?",
		fieldType: "singleChoice",
		slide: 3,
		logicJumps: {
			jumpType: "stateBasedEducationRecognized",
			diplomaRequired: {
				nextQuestion: "total_work_experience",
				hideQuestions: false,
			},
			diplomaNotRequired: {
				nextQuestion: "total_work_experience",
				hideQuestions: false,
			},
		},
	},
	attachment: {
		fieldName: "attachment",
		fieldText:
			"Bitte lade hier deine Examensurkunde oder einen anderen Qualifikationsnachweis hoch (z.B. Pflegebasiskurs).",
		fieldType: "uploadAttachment",
		slide: 3,
		logicJumps: {
			jumpType: "valueBased",
			nextQuestion: "total_work_experience",
			hideQuestions: false,
		},
	},
	total_work_experience: {
		fieldName: "total_work_experience",
		fieldText: {
			"Pflege & Medizin":
				"Wie viele Jahre Berufserfahrung hast du in dem Bereich Pflege & Medizin?",
			"Pädagogik & Soziale Arbeit":
				"Wie viele Jahre Berufserfahrung hast du in dem Bereich Pädagogik & Soziale Arbeit?",
		},
		fieldType: "singleChoiceSelect",
		slide: 4,
		logicJumps: {
			jumpType: "optionsBasedTotalWorkExperience",
			"Noch keine": {
				nextQuestion: "contact_data",
				hideQuestions: ["work_experience"],
			},
			other: {
				nextQuestion: "work_experience",
			},
		},
	},
	work_experience: {
		fieldName: "work_experience",
		fieldText: "Wer waren deine Arbeitgeber in den letzten 5 Jahren?",
		fieldType: "uploadWorkExperience",
		slide: 4,
		logicJumps: {
			jumpType: "valueBased",
			nextQuestion: "contact_data",
			hideQuestions: false,
		},
	},
	contact_data: {
		fieldName: "contact_data",
		fieldType: "contactData",
		slide: 5,
	},
}

// helpful variable to determine what is the highest
// slide int, used later in ProgressBar and NextPreviousBtns
export const slideHighestValue = Math.max(
	...[...Object.values(questionsStructure).map(el => el.slide)]
)

export default questionsStructure
