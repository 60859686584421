import React from "react"

// Components
import ClientsTopBenefits from "./ClientsTopBenefits"
import ClientsBasicData from "./ClientsBasicData"

// Material UI
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Divider } from "@material-ui/core"

// Styles
const useStyles = makeStyles({
	sectionGridContainerBasicData: {
		borderRadius: 12,
		border: "solid 1px #e8e8e8",
		backgroundColor: "#fff",
		width: "100%",
		maxWidth: 1000,
		margin: "0 auto",
		marginTop: 24,
		marginBottom: 88,
	},

	divider: {
		width: "100%",
		marginTop: 6,
		marginBottom: 12,
	},
})

// Component
const ClientsCompanyOverview = props => {
	// Hooks
	const styles = useStyles()

	// Destructuring props
	const { companyLogo } = props

	// Render
	return (
		<Grid container className={styles.sectionGridContainerBasicData}>
			{/* Company Name, Logo, Motto, Basic Data */}
			<ClientsBasicData companyLogo={companyLogo} />

			{/* Divider */}
			<Divider className={styles.divider} />

			{/* Benefits */}
			<ClientsTopBenefits />
		</Grid>
	)
}

export default ClientsCompanyOverview
