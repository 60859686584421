import { backendURL } from "../../environment"
import { history } from "../../index"

// Firebase
import { firebaseAuth } from "../../firebase"
import { signOut } from "firebase/auth"

// other actions
import * as notificationsActions from "../../notifications/state/notificationsActions"
import * as loadingScreenActions from "../../loadingScreen/state/ReduxLoadingScreenActions"
import * as jobsActions from "../../jobs/state/jobsActions"

// helpers
import prepareCandidate from "../helpers/prepareCandidate"

// names
export const DETERMINE_USER_TYPE_INIT = "DETERMINE USER TYPE INIT"
export const DETERMINE_USER_TYPE_SUCCESS = "DETERMINE USER TYPE SUCCESS"
export const DETERMINE_USER_TYPE_ERROR = "DETERMINE USER TYPE ERROR"

export const GET_PROFILE_DATA_INIT = "GET PROFILE DATA INIT"
export const GET_PROFILE_DATA_SUCCESS = "GET PROFILE DATA SUCCESS"
export const GET_PROFILE_DATA_ERROR = "GET PROFILE DATA ERROR"

export const GET_PROMOTER_PROFILE_DATA_INIT = "GET PROMOTER PROFILE DATA INIT"
export const GET_PROMOTER_PROFILE_DATA_SUCCESS =
	"GET PROMOTER PROFILE DATA SUCCESS"
export const GET_PROMOTER_PROFILE_DATA_ERROR = "GET PROMOTER PROFILE DATA ERROR"

export const PUT_PROFILE_DATA_INIT = "PUT PROFILE DATA INIT"
export const PUT_PROFILE_DATA_SUCCESS = "PUT PROFILE DATA SUCCESS"
export const PUT_PROFILE_DATA_ERROR = "PUT PROFILE DATA ERROR"

export const PUT_PROMOTER_PROFILE_DATA_INIT = "PUT PROMOTER PROFILE DATA INIT"
export const PUT_PROMOTER_PROFILE_DATA_SUCCESS = "PUT PROMOTER DATA SUCCESS"
export const PUT_PROMOTER_PROFILE_DATA_ERROR = "PUT PROMOTER DATA ERROR"

export const DELETE_ZOHO_PROFILE_INIT = "DELETE ZOHO PROFILE INIT"
export const DELETE_ZOHO_PROFILE_SUCCESS = "DELETE ZOHO PROFILE SUCCESS"
export const DELETE_ZOHO_PROFILE_ERROR = "DELETE ZOHO PROFILE ERROR"

export const DELETE_PROMOTER_PROFILE_INIT = "DELETE PROMOTER PROFILE INIT"
export const DELETE_PROMOTER_PROFILE_SUCCESS = "DELETE PROMOTER PROFILE SUCCESS"
export const DELETE_PROMOTER_PROFILE_ERROR = "DELETE PROMOTER PROFILE ERROR"

export const POST_ATTACHMENT_INIT = "POST ATTACHMENT INIT"
export const POST_ATTACHMENT_SUCCESS = "POST ATTACHMENT SUCCESS"
export const POST_ATTACHMENT_ERROR = "POST ATTACHMENT ERROR"

export const PUT_ATTACHMENT_INIT = "PUT ATTACHMENT INIT"
export const PUT_ATTACHMENT_SUCCESS = "PUT ATTACHMENT SUCCESS"
export const PUT_ATTACHMENT_ERROR = "PUT ATTACHMENT ERROR"

export const CHANGE_PROFILE_FIELD = "CHANGE PROFILE FIELD"
export const REMOVE_PROFILE_ITEM_MULTIPLE_CHOICE =
	"REMOVE PROFILE ITEM MULTIPLE CHOICE"
export const CHECK_PROFILE_CHANGES = "CHECK PROFILE CHANGES"
export const SET_PROFILE_DESIRED_LOCATION = "SET PROFILE DESIRED LOCATION"
export const REMOVE_PROFILE_DESIRED_LOCATION = "REMOVE PROFILE DESIRED LOCATION"
export const LOGOUT_CLEAN_DATA = "LOGOUT CLEAN DATA"
export const SET_RECRUITER_PHOTO_URL = "SET RECRUITER PHOTO URL"
export const SET_RECRUITER_ACCORDION = "SET RECRUITER ACCORDION"
export const SET_WANTS_TAB = "SET WANTS TAB"

export const ADD_EXPERIENCE_PROFILE_FIELD = "ADD EXPERIENCE PROFILE FIELD"
export const REMOVE_EXPERIENCE_PROFILE_FIELD = "REMOVE EXPERIENCE PROFILE FIELD"
export const CHANGE_EXPERIENCE_PROFILE_FIELD = "CHANGE EXPERIENCE PROFILE FIELD"

export const SET_PROFILE_DATA_FROM_COPY = "SET PROFILE DATA FROM COPY"
export const SET_FIRST_REGISTRATION = "SET FIRST REGISTRATION"

// dispatchers

// determine user type
export const determineUserTypeInitAction = () => ({
	type: DETERMINE_USER_TYPE_INIT,
})
export const determineUserTypeSuccessAction = () => ({
	type: DETERMINE_USER_TYPE_SUCCESS,
})
export const determineUserTypeErrorAction = () => ({
	type: DETERMINE_USER_TYPE_ERROR,
})

// GET candidate
export const getProfileDataInitAction = () => ({ type: GET_PROFILE_DATA_INIT })
export const getProfileDataSuccessAction = profileData => ({
	type: GET_PROFILE_DATA_SUCCESS,
	payload: profileData,
})
export const getProfileDataErrorAction = () => ({
	type: GET_PROFILE_DATA_ERROR,
})

// GET promoter
export const getPromoterProfileDataInitAction = () => ({
	type: GET_PROMOTER_PROFILE_DATA_INIT,
})
export const getPromoterProfileDataSuccessAction = profileData => ({
	type: GET_PROMOTER_PROFILE_DATA_SUCCESS,
	payload: profileData,
})
export const getPromoterProfileDataErrorAction = () => ({
	type: GET_PROMOTER_PROFILE_DATA_ERROR,
})

// PUT candidate
export const putProfileDataInitAction = () => ({ type: PUT_PROFILE_DATA_INIT })
export const putProfileDataSuccessAction = () => ({
	type: PUT_PROFILE_DATA_SUCCESS,
})
export const putProfileDataErrorAction = () => ({
	type: PUT_PROFILE_DATA_ERROR,
})

// PUT promoter
export const putPromoterProfileDataInitAction = () => ({
	type: PUT_PROMOTER_PROFILE_DATA_INIT,
})
export const putPromoterProfileDataSuccessAction = () => ({
	type: PUT_PROMOTER_PROFILE_DATA_SUCCESS,
})
export const putPromoterProfileDataErrorAction = () => ({
	type: PUT_PROMOTER_PROFILE_DATA_ERROR,
})

// change/check/remove profile fields
export const changeProfileFieldAction = (
	fieldName,
	fieldNewValue,
	profileCategory
) => ({
	type: CHANGE_PROFILE_FIELD,
	payload: {
		fieldName: fieldName,
		fieldNewValue: fieldNewValue,
		profileCategory: profileCategory,
	},
})
export const removeProfileItemMultipleChoice = (
	fieldName,
	fieldToRemove,
	profileCategory
) => ({
	type: REMOVE_PROFILE_ITEM_MULTIPLE_CHOICE,
	payload: {
		fieldName: fieldName,
		fieldToRemove: fieldToRemove,
		profileCategory: profileCategory,
	},
})
export const setProfileDesiredLocationAction = (fieldValue, id, lat, lng) => ({
	type: SET_PROFILE_DESIRED_LOCATION,
	fieldValue: fieldValue,
	id: id,
	lat: lat,
	lng: lng,
})
export const removeProfileDesiredLocationAction = id => ({
	type: REMOVE_PROFILE_DESIRED_LOCATION,
	id: id,
})
export const checkProfileChangesAction = () => ({
	type: CHECK_PROFILE_CHANGES,
})

// logout
export const logoutCleanDataAction = () => ({
	type: LOGOUT_CLEAN_DATA,
})

// DELETE candidate
export const deleteZohoProfileInitAction = () => ({
	type: DELETE_ZOHO_PROFILE_INIT,
})
export const deleteZohoProfileSuccessdAction = () => ({
	type: DELETE_ZOHO_PROFILE_SUCCESS,
})
export const deleteZohoProfileErrorAction = () => ({
	type: DELETE_ZOHO_PROFILE_ERROR,
})

// DELETE promoter
export const deletePromoterProfileInitAction = () => ({
	type: DELETE_PROMOTER_PROFILE_INIT,
})
export const deletePromoterProfileSuccessdAction = () => ({
	type: DELETE_PROMOTER_PROFILE_SUCCESS,
})
export const deletePromoterProfileErrorAction = () => ({
	type: DELETE_PROMOTER_PROFILE_ERROR,
})

// POST attachment
export const postAttachmentInitAction = () => ({
	type: POST_ATTACHMENT_INIT,
})
export const postAttachmentSuccessAction = () => ({
	type: POST_ATTACHMENT_SUCCESS,
})
export const postAttachmentErrorAction = () => ({
	type: POST_ATTACHMENT_ERROR,
})

// PUT attachment
export const putAttachmentInitAction = () => ({
	type: PUT_ATTACHMENT_INIT,
})
export const putAttachmentSuccessAction = () => ({
	type: PUT_ATTACHMENT_SUCCESS,
})
export const putAttachmentErrorAction = () => ({
	type: PUT_ATTACHMENT_ERROR,
})

// recruiter accordion
export const setRecruiterPhotoUrl = photoUrl => ({
	type: SET_RECRUITER_PHOTO_URL,
	photoUrl: photoUrl,
})
export const setRecruiterAccordion = (isOpen, componentType) => ({
	type: SET_RECRUITER_ACCORDION,
	isOpen: isOpen,
	componentType: componentType,
})

// setting wants tab
export const setWantsTab = isSet => ({
	type: SET_WANTS_TAB,
	isSet: isSet,
})

// set profile data from copy
export const setProfileDataFromCopy = profileData => ({
	type: SET_PROFILE_DATA_FROM_COPY,
	profileData: profileData,
})

// experience
export const addExperienceProfileFieldAction = () => ({
	type: ADD_EXPERIENCE_PROFILE_FIELD,
})
export const removeExperienceProfileFieldAction = id => ({
	type: REMOVE_EXPERIENCE_PROFILE_FIELD,
	id: id,
})
export const changeExperienceProfileFieldAction = (
	experienceProfileNewValue,
	type,
	id
) => ({
	type: CHANGE_EXPERIENCE_PROFILE_FIELD,
	payload: {
		experienceProfileNewValue: experienceProfileNewValue,
		type: type,
		id: id,
	},
})

// First registration
export const setFirstRegistration = () => ({
	type: SET_FIRST_REGISTRATION,
})

// async thunks
// Check if user exists
export const asyncDetermineUserType = email => {
	return (dispatch, getState) => {
		dispatch(determineUserTypeInitAction())

		let appKey = "sdfkjlh2394823ufhn2390nfsdfkj"

		fetch(
			`${backendURL}/authentication/is_there?email=${encodeURIComponent(
				email
			)}`,
			{
				headers: new Headers({
					"X-APP-KEY": appKey,
				}),
			}
		)
			.then(response => {
				if (response.status > 199 && response.status < 300) {
					return response.json()
				} else {
					dispatch(notificationsActions.setAppDownAction(true))
				}
			})
			.then(data => {
				// user found
				dispatch(determineUserTypeSuccessAction())
				if (data.is_there_candidate) {
					dispatch(asyncGetProfileDataAction())
				} else if (data.is_there_promoter) {
					dispatch(asyncGetPromoterProfileDataAction())
				}
			})
			.catch(error => {
				console.log(error)
				dispatch(determineUserTypeErrorAction())
			})
	}
}

export const asyncGetPromoterProfileDataAction = () => {
	return dispatch => {
		dispatch(getPromoterProfileDataInitAction())

		let user = firebaseAuth.currentUser

		if (user) {
			firebaseAuth.currentUser
				.getIdToken(true)
				.then(token => {
					fetch(`${backendURL}/promoters`, {
						headers: new Headers({
							"X-FIREBASE-KEY": token,
						}),
					})
						.then(response => {
							if (
								response.status > 199 &&
								response.status < 300
							) {
								return response.json()
							} else {
								dispatch(
									notificationsActions.setAppDownAction(true)
								)
							}
						})
						.then(data => {
							if (data.message === "Promoter found") {
								dispatch(
									getPromoterProfileDataSuccessAction(
										data.promoter
									)
								)
								dispatch(
									loadingScreenActions.closeLoadingScreenAction()
								)

								if (
									window.location.href.includes("clients") ||
									window.location.href.includes(
										"einstellungen"
									) ||
									window.location.href.includes("chains") ||
									window.location.pathname.includes("jobs") ||
									window.location.pathname.includes("profile")
								) {
									if (localStorage.getItem("shareJobId")) {
										const jobId =
											localStorage.getItem("shareJobId")
										history.push(`/jobs/${jobId}`)
										localStorage.removeItem("shareJobId")
									}
								} else {
									history.push("/jobs")
								}
							} else {
								dispatch(getPromoterProfileDataErrorAction())
								signOut(firebaseAuth).then(() => {
									dispatch(logoutCleanDataAction())
									dispatch(jobsActions.removeJobsAction())
									history.push("/")
								})
							}
						})
						.catch(error => {
							console.log(error)
							dispatch(getPromoterProfileDataErrorAction())
						})
				})
				.catch(error => {
					console.log(error)
					console.log("error with fetching token", error)
				})
		}
	}
}

export const asyncGetProfileDataAction = () => {
	return (dispatch, getState) => {
		dispatch(getProfileDataInitAction())

		let user = firebaseAuth.currentUser

		if (user) {
			firebaseAuth.currentUser
				.getIdToken(true)
				.then(token => {
					fetch(`${backendURL}/candidates/`, {
						headers: new Headers({
							"X-FIREBASE-KEY": token,
						}),
					})
						.then(response => {
							if (
								response.status > 199 &&
								response.status < 300
							) {
								return response.json()
							} else {
								dispatch(
									notificationsActions.setAppDownAction(true)
								)
							}
						})
						.then(data => {
							if (data.message === "Candidate found") {
								dispatch(
									getProfileDataSuccessAction(data.candidate)
								)
								dispatch(
									loadingScreenActions.closeLoadingScreenAction()
								)

								if (
									window.location.href.includes("clients") ||
									window.location.href.includes(
										"einstellungen"
									) ||
									window.location.href.includes("chains") ||
									window.location.pathname.includes("jobs") ||
									window.location.pathname.includes(
										"bewerbungen"
									)
								) {
									if (localStorage.getItem("shareJobId")) {
										const jobId =
											localStorage.getItem("shareJobId")
										history.push(`/jobs/${jobId}`)
										localStorage.removeItem("shareJobId")
									}
								} else {
									history.push("/jobs")
								}
							} else {
								dispatch(getProfileDataErrorAction())
								signOut(firebaseAuth).then(() => {
									dispatch(logoutCleanDataAction())
									dispatch(jobsActions.removeJobsAction())
									history.push("/")
								})
							}
						})
						.catch(error => {
							console.log(error)
							dispatch(getProfileDataErrorAction())
						})
				})
				.catch(error => {
					console.log(error)
					console.log("error with fetching token", error)
				})
		}
	}
}

export const asyncPutProfileDataAction = config => {
	return (dispatch, getState) => {
		dispatch(putProfileDataInitAction())
		let filteredProfileData = null

		if (config.prepareCandidate) {
			filteredProfileData = prepareCandidate(getState().profileReducer)
		} else {
			let newProfileData = getState().profileReducer

			filteredProfileData = { ...newProfileData.profileData }

			// Set email as verified
			if (config?.verifyEmail) {
				filteredProfileData.email_verified = true
			}

			// Do not send zip code if it's empty
			if (!filteredProfileData.zip_code) {
				delete filteredProfileData.zip_code
			}

			// Data cleaning for Ausbildung case
			if (filteredProfileData.qualification !== "Noch in Ausbildung") {
				filteredProfileData.ongoing_education = null
				filteredProfileData.education_finish_date = null
			}

			// Data cleaning for Hilfskraft case
			if (
				filteredProfileData.qualification !==
				"Pflegehilfskraft/-assistenz"
			) {
				filteredProfileData.qualification_hilfskraft = null
				filteredProfileData.qualification_hilfskraft_freetext = null
			}
		}

		// Do not send dates if they're empty
		if (!filteredProfileData.earliest_start_date) {
			delete filteredProfileData.earliest_start_date
		}
		if (!filteredProfileData.education_finish_date) {
			delete filteredProfileData.education_finish_date
		}

		firebaseAuth.currentUser
			.getIdToken(true)
			.then(token => {
				fetch(`${backendURL}/candidates/`, {
					method: "PUT",
					body: JSON.stringify(filteredProfileData),
					headers: {
						"Content-Type": "application/json",
						"X-FIREBASE-KEY": token,
					},
				})
					.then(response => {
						if (response.status > 199 && response.status < 300) {
							return response.json()
						} else {
							dispatch(
								notificationsActions.setAppDownAction(true)
							)
						}
					})
					.then(data => {
						if (data.message === "Candidate successfully updated") {
							dispatch(putProfileDataSuccessAction())
							dispatch(asyncGetProfileDataAction())

							if (config.refreshJobs !== false) {
								dispatch(jobsActions.removeJobsAction())
								dispatch(
									jobsActions.asyncGetJobsAction({
										page: 1,
									})
								)

								if (config.verifyEmail) {
									dispatch(
										notificationsActions.sendNotificationAction(
											{
												open: true,
												message:
													"Änderungen gespeichert.",
												type: "success",
											}
										)
									)
								}
							}
						} else {
							dispatch(putProfileDataErrorAction())
						}
					})
					.catch(error => {
						dispatch(putProfileDataErrorAction())
					})
			})
			.catch(error => {
				console.log("error with fetching token", error)
			})
	}
}

export const asyncPutPromoterProfileDataAction = config => {
	return (dispatch, getState) => {
		dispatch(putPromoterProfileDataInitAction())

		// Redux selectors
		const profileReducer = getState().profileReducer

		// Preparing a candidate payload for the backend
		const promoterPayload = {
			salutation: profileReducer.profileData.salutation,
			first_name: profileReducer.profileData.first_name,
			last_name: profileReducer.profileData.last_name,
			phone: profileReducer.profileData.phone,
			email: profileReducer.profileData.email,
		}

		// Set email as verified
		if (config.verifyEmail) {
			promoterPayload.email_verified = true
		}

		firebaseAuth.currentUser
			.getIdToken(true)
			.then(token => {
				fetch(`${backendURL}/promoters`, {
					method: "PUT",
					body: JSON.stringify(promoterPayload),
					headers: {
						"Content-Type": "application/json",
						"X-FIREBASE-KEY": token,
					},
				})
					.then(response => {
						if (response.status > 199 && response.status < 300) {
							return response.json()
						} else {
							dispatch(
								notificationsActions.setAppDownAction(true)
							)
						}
					})
					.then(data => {
						if (data.message === "Successfully updated promoter") {
							dispatch(putPromoterProfileDataSuccessAction())
							dispatch(asyncGetPromoterProfileDataAction())

							if (config.refreshJobs !== false) {
								dispatch(jobsActions.removeJobsAction())
								dispatch(
									jobsActions.asyncGetJobsAction({
										page: 1,
									})
								)
							}

							if (config.verifyEmail) {
								dispatch(
									notificationsActions.sendNotificationAction(
										{
											open: true,
											message: "Änderungen gespeichert.",
											type: "success",
										}
									)
								)
							}
						} else {
							dispatch(putPromoterProfileDataErrorAction())
						}
					})
					.catch(error => {
						dispatch(putPromoterProfileDataErrorAction())
					})
			})
			.catch(error => {
				console.log("error with fetching token", error)
			})
	}
}

export const asyncRemoveZohoProfileAction = () => {
	return (dispatch, getState) => {
		// Redux selectors
		const jobsReducer = getState().jobsReducer

		dispatch(
			loadingScreenActions.openLoadingScreenAction({
				open: true,
				message: "Dein Konto wird gelöscht.",
				type: "",
			})
		)
		dispatch(deleteZohoProfileInitAction())

		const signout = () => {
			signOut(firebaseAuth).then(() => {
				dispatch(logoutCleanDataAction())
				dispatch(jobsActions.removeJobsAction())
				dispatch(
					jobsActions.asyncGetJobsAction({
						page: 1,
						filteringOptions: jobsReducer.filteringOptions,
					})
				)
				history.push("/")
			})
		}

		firebaseAuth.currentUser
			.getIdToken(true)
			.then(token => {
				fetch(`${backendURL}/candidates/`, {
					method: "DELETE",
					headers: new Headers({
						"X-FIREBASE-KEY": token,
					}),
				})
					.then(response => {
						if (response.status > 199 && response.status < 300) {
							return response.json()
						} else {
							dispatch(
								notificationsActions.setAppDownAction(true)
							)
						}
					})
					.then(data => {
						if (data.message === "Candidate successfully deleted") {
							dispatch(deleteZohoProfileSuccessdAction())
							signout()

							dispatch(
								loadingScreenActions.closeLoadingScreenAction()
							)
							dispatch(
								notificationsActions.sendNotificationAction({
									open: true,
									message: "Dein Konto wurde gelöscht.",
									type: "info",
									pushToRight: false,
									clickAway: true,
								})
							)
							dispatch(
								jobsActions.removeAllFilteringOptionsAction()
							)
						} else {
							dispatch(
								loadingScreenActions.closeLoadingScreenAction()
							)
							dispatch(deleteZohoProfileErrorAction())
							dispatch(
								notificationsActions.sendNotificationAction({
									open: true,
									message:
										"Netzwerk-Fehler. Probier's nochmal.",
									type: "error",
								})
							)
						}
					})
					.catch(error => {
						dispatch(deleteZohoProfileErrorAction())
					})
			})
			.catch(error => {
				console.log("error with fetching token", error)
			})
	}
}

export const asyncRemovePromoterProfileAction = () => {
	return (dispatch, getState) => {
		// Redux selectors
		const jobsReducer = getState().jobsReducer

		dispatch(
			loadingScreenActions.openLoadingScreenAction({
				open: true,
				message: "Dein Konto wird gelöscht.",
				type: "",
			})
		)
		dispatch(deletePromoterProfileInitAction())

		const signout = () => {
			signOut(firebaseAuth).then(() => {
				dispatch(logoutCleanDataAction())
				dispatch(jobsActions.removeJobsAction())
				dispatch(
					jobsActions.asyncGetJobsAction({
						page: 1,
						filteringOptions: jobsReducer.filteringOptions,
					})
				)
				history.push("/")
			})
		}

		firebaseAuth.currentUser
			.getIdToken(true)
			.then(token => {
				fetch(`${backendURL}/promoters`, {
					method: "DELETE",
					headers: new Headers({
						"X-FIREBASE-KEY": token,
					}),
				})
					.then(response => {
						if (response.status > 199 && response.status < 300) {
							return response.json()
						} else {
							dispatch(
								notificationsActions.setAppDownAction(true)
							)
						}
					})
					.then(data => {
						if (data.message === "Successfully deleted promoter") {
							dispatch(deletePromoterProfileSuccessdAction())
							signout()

							dispatch(
								loadingScreenActions.closeLoadingScreenAction()
							)
							dispatch(
								notificationsActions.sendNotificationAction({
									open: true,
									message: "Dein Konto wurde gelöscht.",
									type: "info",
									pushToRight: false,
									clickAway: true,
								})
							)
							dispatch(
								jobsActions.removeAllFilteringOptionsAction()
							)
						} else {
							dispatch(
								loadingScreenActions.closeLoadingScreenAction()
							)
							dispatch(deletePromoterProfileErrorAction())
							dispatch(
								notificationsActions.sendNotificationAction({
									open: true,
									message:
										"Netzwerk-Fehler. Probier's nochmal.",
									type: "error",
								})
							)
						}
					})
					.catch(error => {
						dispatch(deletePromoterProfileErrorAction())
					})
			})
			.catch(error => {
				console.log("error with fetching token", error)
			})
	}
}

export const asyncPostAttachmentAction = (
	file,
	fileName,
	comingFromSecondSmartForm = false,
	comingFromJob = false,
	jobId = null
) => {
	return dispatch => {
		dispatch(postAttachmentInitAction())

		firebaseAuth.currentUser
			.getIdToken(true)
			.then(token => {
				let formData = new FormData()
				formData.append("upload_file", file, fileName)

				fetch(`${backendURL}/attachments/candidates?category=diploma`, {
					method: "PUT",
					body: formData,
					headers: new Headers({
						"X-FIREBASE-KEY": token,
					}),
				})
					.then(response => {
						if (response.status > 199 && response.status < 300) {
							return response.json()
						} else {
							dispatch(
								notificationsActions.setAppDownAction(true)
							)
						}
					})
					.then(data => {
						if (
							data.message ===
							"Upserted to firebase and zoho successful."
						) {
							dispatch(postAttachmentSuccessAction())

							if (comingFromSecondSmartForm) {
								dispatch(asyncGetProfileDataAction())
							} else if (comingFromJob) {
								dispatch(
									jobsActions.asyncAssociateCandidateAction(
										jobId,
										true
									)
								)
							} else {
								dispatch(asyncGetProfileDataAction())
								dispatch(
									notificationsActions.sendNotificationAction(
										{
											open: true,
											message: "Änderungen gespeichert.",
											type: "success",
										}
									)
								)
							}
						} else {
							dispatch(postAttachmentErrorAction())
						}
					})
					.catch(error => {
						dispatch(putProfileDataErrorAction())
					})
			})
			.catch(error => {
				console.log("error with fetching token", error)
			})
	}
}

export const asyncPutAttachmentAction = (file, fileName) => {
	return dispatch => {
		dispatch(putAttachmentInitAction())

		firebaseAuth.currentUser
			.getIdToken(true)
			.then(token => {
				let formData = new FormData()
				formData.append("upload_file", file, fileName)

				fetch(`${backendURL}/attachments/candidates?category=diploma`, {
					method: "POST",
					body: formData,
					headers: new Headers({
						"X-FIREBASE-KEY": token,
					}),
				})
					.then(response => {
						if (response.status > 199 && response.status < 300) {
							return response.json()
						} else {
							dispatch(
								notificationsActions.setAppDownAction(true)
							)
						}
					})
					.then(data => {
						if (data.message === "upload successful") {
							dispatch(asyncPutProfileDataAction())
							dispatch(putAttachmentSuccessAction())
						} else {
							dispatch(putAttachmentErrorAction())
						}
					})
					.catch(error => {
						dispatch(putProfileDataErrorAction())
					})
			})
			.catch(error => {
				console.log("error with fetching token", error)
			})
	}
}
