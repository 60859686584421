import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { backendURL } from "./environment"

// Capacitor
import { Capacitor } from "@capacitor/core"
import { App } from "@capacitor/app"
import { Keyboard } from "@capacitor/keyboard"

const AppUrlListener = () => {
	let history = useHistory()

	useEffect(() => {
		if (Capacitor.isNativePlatform()) {
			// Listen for keyboard events
			Keyboard.addListener("keyboardWillShow", () => {
				document.body.classList.add("ios-keyboard-is-open")
			})

			Keyboard.addListener("keyboardWillHide", () => {
				document.body.classList.remove("ios-keyboard-is-open")
			})

			return () => {
				// Clean up the listeners
				Keyboard.removeAllListeners()
			}
		}
	}, [])

	// Follow redirects
	useEffect(() => {
		App.addListener("appUrlOpen", async event => {
			const urlToCheck = event.url

			if (urlToCheck.includes("email.workbee.de")) {
				let pattern = /^https?:\/\/[^/]+(\/auth.*)/
				try {
					let appKey = "sdfkjlh2394823ufhn2390nfsdfkj"
					const response = await fetch(
						`${backendURL}/authentication/follow_redirect?url=${urlToCheck}`,
						{
							headers: new Headers({
								"X-APP-KEY": appKey,
							}),
						}
					)
					if (response.ok) {
						const data = await response.json()
						if (data) {
							let redirectUrl = data.replace(pattern, "$1")
							history.push(redirectUrl)
						} else {
							console.error(
								"No redirect URL found in response:",
								data
							)
						}
					} else {
						console.error(
							"Failed to fetch redirect URL:",
							response.status
						)
					}
				} catch (error) {
					console.error("Error checking redirect:", error)
				}
			}

			const url = new URL(event.url);
			const pathWithQuery = url.pathname + url.search + url.hash;

			if (pathWithQuery) {
				history.push(pathWithQuery);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []); // Empty dependency array ensures this effect runs only once

	return null
}

export default AppUrlListener
