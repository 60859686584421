import * as actions from "./jobsActions"
import { markdownToHtml } from "../../helpers/markdownToHtml"

export const initialState = {
	jobApplied: false,
	filterJobsModalOpen: false,
	jobShareAuthModalOpen: false,
	jobSharePcModalOpen: false,
	jobSharePcModalLink: "",
	jobSharePcModalLogoUrl: "",
	jobSharePcModalJobTitle: "",
	jobCount: null,
	similarJobs: null,
	similarJobCount: null,
	filteringOptions: {
		contract_types: [],
		employment_forms: [],
		shifts: [],
		type_of_employer: [],
		qualifications: [],
		benefits: [],
		locations: [],
		salary_expectation_month: "",
		work_field: "",
	},
}

// unbiased shuffle algorithm (Fisher Yates)
function shuffle(array) {
	let currentIndex = array.length,
		randomIndex

	// While there remain elements to shuffle.
	while (currentIndex > 0) {
		// Pick a remaining element.
		randomIndex = Math.floor(Math.random() * currentIndex)
		currentIndex--

		// And swap it with the current element.
		;[array[currentIndex], array[randomIndex]] = [
			array[randomIndex],
			array[currentIndex],
		]
	}

	return array
}

export default function jobsReducer(state = initialState, action) {
	switch (action.type) {
		case actions.GET_JOBS_INIT:
			return state
		case actions.GET_JOBS_ERROR:
			return {
				...state,
				jobCount: null,
				jobs: "no jobs",
			}
		case actions.GET_JOBS_SUCCESS:
			if (state.jobs) {
				return {
					...state,
					jobs: [...state.jobs, ...action.payload].filter(
						(
							s => o =>
								!s.has(o.zoho_job_openings_id) &&
								s.add(o.zoho_job_openings_id)
						)(new Set())
					),
					jobCount: action.jobCount,
				}
			} else {
				return {
					...state,
					jobs: action.payload,
					jobCount: action.jobCount,
				}
			}
		case actions.GET_SIMILAR_JOBS_INIT:
			return state
		case actions.GET_SIMILAR_JOBS_ERROR:
			return {
				...state,
				similarJobs: [],
				similarJobCount: null,
			}
		case actions.GET_SIMILAR_JOBS_SUCCESS:
			let filteredJobs = action.payload.filter(
				job => job.zoho_job_openings_id !== action.jobId
			)
			if (Array.isArray(action.jobsApplied)) {
				filteredJobs = filteredJobs.filter(
					job =>
						!action.jobsApplied.includes(job.zoho_job_openings_id)
				)
			}
			// apply shuffle and then get the first three jobs in array
			let top3shuffledJobs = shuffle(filteredJobs).slice(0, 3)
			return {
				...state,
				similarJobs: top3shuffledJobs,
				similarJobCount: filteredJobs.length,
			}
		case actions.GET_APPLIED_JOBS_INIT:
			return state
		case actions.GET_APPLIED_JOBS_ERROR:
			return {
				...state,
				applications: [],
			}
		case actions.GET_APPLIED_JOBS_SUCCESS:
			return {
				...state,
				applications: action.payload,
			}
		case actions.GET_SPECIFIC_JOB_INIT:
			return state
		case actions.GET_SPECIFIC_JOB_ERROR:
			return {
				...state,
				job: "invalid id",
			}
		case actions.GET_SPECIFIC_JOB_SUCCESS:
			let fetchedJobData = action.payload
			let oldJobDescription = action.payload.job_description
				? action.payload.job_description
				: ""

			// clearing zoho h3 elements
			if (oldJobDescription.includes("<h3>Requirements</h3>")) {
				oldJobDescription = oldJobDescription.replace(
					"<h3>Requirements</h3>",
					""
				)
			}
			if (oldJobDescription.includes("<h3>Voraussetzungen</h3>")) {
				oldJobDescription = oldJobDescription.replace(
					"<h3>Voraussetzungen</h3>",
					""
				)
			}
			if (oldJobDescription.includes("<h3>Benefits</h3>")) {
				oldJobDescription = oldJobDescription.replace(
					"<h3>Benefits</h3>",
					""
				)
			}
			if (oldJobDescription.includes("<h3>Vorteile</h3>")) {
				oldJobDescription = oldJobDescription.replace(
					"<h3>Vorteile</h3>",
					""
				)
			}

			let jobDescription = ""
			let jobRequirements = ""
			let jobBenefits = ""

			if (
				action.payload.chatgpt_benefits_output &&
				action.payload.chatgpt_profile_output &&
				action.payload.chatgpt_tasks_output &&
				action.payload.chatgpt_employer_output
			) {
				if (action.payload.chatgpt_benefits_output) {
					fetchedJobData.job_benefits =
						"<h2>Deine Vorteile</h2><br>" +
						markdownToHtml(action.payload.chatgpt_benefits_output)
				}

				if (
					action.payload.chatgpt_tasks_output &&
					action.payload.chatgpt_profile_output
				) {
					let profileRequirements = markdownToHtml(
						action.payload.chatgpt_profile_output
					)
					profileRequirements =
						"<h2>Dein Profil</h2><br>" + profileRequirements

					let jobRequirements = markdownToHtml(
						action.payload.chatgpt_tasks_output
					)
					jobRequirements =
						"<h2>Deine Aufgaben</h2><br>" + jobRequirements

					// order of data
					fetchedJobData.job_requirements =
						jobRequirements + "<br>" + profileRequirements
				}

				if (action.payload.chatgpt_employer_output) {
					fetchedJobData.job_description = markdownToHtml(
						action.payload.chatgpt_employer_output
					)
				}

				return {
					...state,
					job: fetchedJobData,
					jobApplied: false,
				}
			} else if (oldJobDescription) {
				if (
					oldJobDescription.includes("spandesc") &&
					oldJobDescription.includes("spanreq") &&
					oldJobDescription.includes("spanben")
				) {
					let regex1 =
						/<span id="spandesc".+(?=<br \/><span id="spanreq">)/gms
					jobDescription = oldJobDescription.match(regex1)[0]

					let regex2 =
						/<span id="spanreq".+(?=<br \/><span id="spanben">)/gms
					jobRequirements = oldJobDescription.match(regex2)[0]
					jobRequirements = jobRequirements.replace(
						/(Deine Aufgaben:?)/g,
						"<h2>$1</h2>"
					)
					jobRequirements = jobRequirements.replace(
						/(Dein Profil:?)/g,
						"<h2>$1</h2>"
					)

					let regex3 = /<span id="spanben".+/gms
					jobBenefits = oldJobDescription.match(regex3)[0]
					jobBenefits = jobBenefits.replace(
						/(Deine Vorteile:?)/g,
						"<h2>$1</h2>"
					)

					fetchedJobData.job_description = jobDescription
					fetchedJobData.job_requirements = jobRequirements
					fetchedJobData.job_benefits = jobBenefits

					return {
						...state,
						job: fetchedJobData,
						jobApplied: false,
					}
				} else if (
					oldJobDescription.includes("spandesc") &&
					!oldJobDescription.includes("spanreq") &&
					oldJobDescription.includes("spanben")
				) {
					let regex1 =
						/<span id="spandesc".+(?=<br \/><span id="spanben">)/gms
					jobDescription = oldJobDescription.match(regex1)[0]

					let regex2 = /<span id="spanben".+/gms
					jobBenefits = oldJobDescription.match(regex2)[0]

					fetchedJobData.job_description = jobDescription
					fetchedJobData.job_benefits = jobBenefits

					return {
						...state,
						job: fetchedJobData,
						jobApplied: false,
					}
				} else if (
					oldJobDescription.includes("spandesc") &&
					oldJobDescription.includes("spanreq") &&
					!oldJobDescription.includes("spanben")
				) {
					let regex1 =
						/<span id="spandesc".+(?=<br \/><span id="spanreq">)/gms
					jobDescription = oldJobDescription.match(regex1)[0]

					let regex2 = /<span id="spanreq".+/gms
					jobRequirements = oldJobDescription.match(regex2)[0]

					fetchedJobData.job_description = jobDescription
					fetchedJobData.job_requirements = jobRequirements

					return {
						...state,
						job: fetchedJobData,
						jobApplied: false,
					}
				} else if (
					!oldJobDescription.includes("spandesc") &&
					oldJobDescription.includes("spanreq") &&
					oldJobDescription.includes("spanben")
				) {
					let regex1 =
						/<span id="spanreq".+(?=<br \/><span id="spanben">)/gms
					jobRequirements = oldJobDescription.match(regex1)[0]

					let regex2 = /<span id="spanben".+/gms
					jobBenefits = oldJobDescription.match(regex2)[0]

					fetchedJobData.job_benefits = jobBenefits
					fetchedJobData.job_requirements = jobRequirements
					delete fetchedJobData.job_description

					return {
						...state,
						job: fetchedJobData,
						jobApplied: false,
					}
				} else {
					return {
						...state,
						job: action.payload,
						jobApplied: false,
					}
				}
			} else {
				return {
					...state,
					job: action.payload,
					jobApplied: false,
				}
			}
		case actions.REMOVE_SPECIFIC_JOB:
			return {
				...state,
				job: null,
			}
		case actions.REMOVE_JOBS:
			return { ...state, job: null, jobs: undefined }
		case actions.ASSOCIATE_CANDIDATE_INIT:
			return state
		case actions.ASSOCIATE_CANDIDATE_SUCCESS:
			return {
				...state,
				jobApplied: true,
			}
		case actions.ASSOCIATE_CANDIDATE_ERROR:
			return state
		case actions.BLOCK_APPLY_BUTTON:
			if (
				action.jobIds &&
				action.jobId &&
				action.jobIds.includes(action.jobId)
			) {
				return {
					...state,
					jobApplied: true,
				}
			} else {
				return {
					...state,
				}
			}
		case actions.GET_SPECIFIC_MATCHING_SCORE_SUCCESS:
			return {
				...state,
				matchingScore: action.score,
			}
		case actions.SET_JOB_SHARE_AUTH_MODAL:
			return {
				...state,
				jobShareAuthModalOpen: action.isModalOpen,
			}
		case actions.SET_JOB_SHARE_PC_MODAL:
			return {
				...state,
				jobSharePcModalOpen: action.isModalOpen,
				jobSharePcModalLink: action.url,
				jobSharePcModalLogoUrl: action.logoSrcs,
				jobSharePcModalJobTitle: action.jobTitle,
				jobSharePcModalWithInstructions: action.withInstructions,
			}
		case actions.SET_FILTER_JOBS_MODAL:
			return {
				...state,
				filterJobsModalOpen: action.isModalOpen,
			}
		case actions.SET_FILTERING_OPTIONS:
			return {
				...state,
				filteringOptions: action.dataSource,
			}
		case actions.REMOVE_ALL_FILTERING_OPTIONS:
			return {
				...state,
				filteringOptions: {
					contract_types: [],
					employment_forms: [],
					shifts: [],
					type_of_employer: [],
					qualifications: [],
					benefits: [],
					locations: [],
					salary_expectation_month: "",
					work_field: "",
				},
			}
		case actions.SAVE_FILTERING_FIELD:
			return {
				...state,
				filteringOptions: {
					...state.filteringOptions,
					[action.fieldName]: action.fieldValue,
				},
			}
		case actions.REMOVE_ITEM_MULTIPLE_CHOICE:
			let mcFieldOld = state.filteringOptions[action.fieldName]
			let filteredData = mcFieldOld.filter(el => el !== action.fieldValue)
			let mcFieldNew = [...filteredData]

			return {
				...state,
				filteringOptions: {
					...state.filteringOptions,
					[action.fieldName]: mcFieldNew,
				},
			}
		case actions.REMOVE_ITEM_SINGLE_CHOICE:
			return {
				...state,
				filteringOptions: {
					...state.filteringOptions,
					[action.fieldName]: "",
				},
			}
		case actions.SET_DESIRED_LOCATION_FILTER:
			let currentLocations = [...state.filteringOptions.locations]
			let newLocations = [...currentLocations]

			let fieldValue = action.fieldValue ? action.fieldValue : null
			let lat = action.lat ? action.lat : null
			let lng = action.lng ? action.lng : null

			// Create the new location object
			let newLocation = {
				location_name: fieldValue,
				coordinates: {
					latitude: lat,
					longitude: lng,
				},
			}

			if (newLocations[action.id] === null) {
				newLocations.splice(action.id, 1, newLocation)
			} else {
				newLocations.splice(action.id, 1, newLocation)
			}

			return {
				...state,
				filteringOptions: {
					...state.filteringOptions,
					locations: newLocations,
				},
			}
		case actions.REMOVE_DESIRED_LOCATION_FILTER:
			let removeCurrentLocations = [...state.filteringOptions.locations]
			let removeNewLocations = [...removeCurrentLocations]
			removeNewLocations.splice(action.id, 1)

			return {
				...state,
				filteringOptions: {
					...state.filteringOptions,
					locations: removeNewLocations,
				},
			}
		case actions.REMOVE_DESIRED_LOCATION_BY_CITY_FILTER:
			let removedCityLocations = state.filteringOptions.locations.filter(
				city => city.location_name !== action.city
			)

			return {
				...state,
				filteringOptions: {
					...state.filteringOptions,
					locations: removedCityLocations,
				},
			}
		case actions.REMOVE_ALL_DESIRED_LOCATIONS_FILTER:
			return {
				...state,
				filteringOptions: {
					...state.filteringOptions,
					locations: [],
				},
			}
		default:
			return state
	}
}
