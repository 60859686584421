import { backendURL } from "../../environment"
import { history } from "../../index"

// Firebase
import { firebaseAuth } from "../../firebase"

// Helpers
import prepareCandidate from "../helpers/prepareCandidate"
import preparePromoter from "../helpers/preparePromoter"

// Other actions
import * as notificationsActions from "../../notifications/state/notificationsActions"
import * as profileActions from "../../profile/state/profileActions"
import * as authActions from "../../auth/state/authActions"
import * as loadingScreenActions from "../../loadingScreen/state/ReduxLoadingScreenActions"
import * as tagManagerActions from "../../tagManagerEvents/state/tagManagerActions"
import * as tutorialActions from "../../tutorial/state/tutorialActions"

// Names
export const SAVE_FIELD = "SAVE FIELD"
export const SET_TEXT_FIELD_VALUE = "SET TEXT FIELD VALUE"
export const IMPORT_PROFILE_DATA = "IMPORT PROFILE DATA"

export const SAVE_MULTIPLE_CHOICE_FIELD = "SAVE MULTIPLE CHOICE FIELD"
export const REMOVE_CF_ITEM_MULTIPLE_CHOICE = "REMOVE CF ITEM MULTIPLE CHOICE"
export const CLEAR_MULTIPLE_CHOICE = "CLEAR MULTIPLE CHOICE"

export const POST_PROFILE_DATA_INIT = "POST PROFILE DATA INIT"
export const POST_PROFILE_DATA_SUCCESS = "POST PROFILE DATA SUCCESS"
export const POST_PROFILE_DATA_ERROR = "POST PROFILE DATA ERROR"

export const POST_PROMOTER_PROFILE_DATA_INIT = "POST PROMOTER PROFILE DATA INIT"
export const POST_PROMOTER_PROFILE_DATA_SUCCESS =
	"POST PROMOTER PROFILE DATA SUCCESS"
export const POST_PROMOTER_PROFILE_DATA_ERROR =
	"POST PROMOTER PROFILE DATA ERROR"

export const PROMOTER_TO_CANDIDATE_TRANSITION_INIT =
	"PROMOTER TO CANDIDATE TRANSITION INIT"
export const PROMOTER_TO_CANDIDATE_TRANSITION_SUCCESS =
	"PROMOTER TO CANDIDATE TRANSITION SUCCESS"
export const PROMOTER_TO_CANDIDATE_TRANSITION_ERROR =
	"PROMOTER TO CANDIDATE TRANSITION ERROR"

export const PUT_PROFILE_DATA_INIT = "PUT PROFILE DATA INIT"
export const PUT_PROFILE_DATA_SUCCESS = "PUT PROFILE DATA SUCCESS"
export const PUT_PROFILE_DATA_ERROR = "PUT PROFILE DATA ERROR"

export const CHECK_USER_EXISTS_INIT = "CHECK USER EXISTS INIT"
export const CHECK_USER_EXISTS_SUCCESS = "CHECK USER EXISTS SUCCESS"
export const CHECK_USER_EXISTS_ERROR = "CHECK USER EXISTS ERROR"

export const SET_DESIRED_LOCATION = "SET DESIRED LOCATION"
export const REMOVE_DESIRED_LOCATION = "REMOVE DESIRED LOCATION"
export const SET_ATTACHMENT = "SET ATTACHMENT"

export const CHANGE_EXPERIENCE_FIELD = "CHANGE SF EXPERIENCE FIELD"
export const ADD_EXPERIENCE_FIELD = "ADD SF EXPERIENCE FIELD"
export const REMOVE_EXPERIENCE_FIELD = "REMOVE SF EXPERIENCE FIELD"

export const POST_ATTACHMENT_INIT = "POST ATTACHMENT INIT"
export const POST_ATTACHMENT_SUCCESS = "POST ATTACHMENT SUCCESS"
export const POST_ATTACHMENT_ERROR = "POST ATTACHMENT ERROR"

export const CLEAR_REDUCER = "CLEAR REDUCER"
export const CHECK_CONTACT_DATA_FIELDS = "CHECK CONTACT DATA FIELDS"

//
// Dispatchers
//

export const saveFieldAction = (fieldValue, fieldName) => ({
	type: SAVE_FIELD,
	fieldValue: fieldValue,
	fieldName: fieldName,
})
export const saveMultipleChoiceFieldAction = (fieldValue, fieldName) => ({
	type: SAVE_MULTIPLE_CHOICE_FIELD,
	fieldValue: fieldValue,
	fieldName: fieldName,
})
export const clearMultipleChoiceAction = fieldName => ({
	type: CLEAR_MULTIPLE_CHOICE,
	fieldName: fieldName,
})
export const setTextFieldValueAction = (fieldValue, fieldName) => ({
	type: SET_TEXT_FIELD_VALUE,
	fieldValue: fieldValue,
	fieldName: fieldName,
})
export const importProfileDataAction = profileData => ({
	type: IMPORT_PROFILE_DATA,
	profileData: profileData,
})
// post candidate
export const postProfileDataInitAction = () => ({
	type: POST_PROFILE_DATA_INIT,
})
export const postProfileDataSuccessAction = () => ({
	type: POST_PROFILE_DATA_SUCCESS,
})
export const postProfileDataErrorAction = () => ({
	type: POST_PROFILE_DATA_ERROR,
})
// post promoter
export const postPromoterProfileDataInitAction = () => ({
	type: POST_PROMOTER_PROFILE_DATA_INIT,
})
export const postPromoterProfileDataSuccessAction = () => ({
	type: POST_PROMOTER_PROFILE_DATA_SUCCESS,
})
export const postPromoterProfileDataErrorAction = () => ({
	type: POST_PROMOTER_PROFILE_DATA_ERROR,
})
// promoter to candidate transition
export const promoterToCandidateTranstionInitAction = () => ({
	type: PROMOTER_TO_CANDIDATE_TRANSITION_INIT,
})
export const promoterToCandidateTranstionSuccessAction = () => ({
	type: PROMOTER_TO_CANDIDATE_TRANSITION_SUCCESS,
})
export const promoterToCandidateTranstionErrorAction = () => ({
	type: PROMOTER_TO_CANDIDATE_TRANSITION_ERROR,
})

// put
export const putProfileDataInitAction = () => ({
	type: PUT_PROFILE_DATA_INIT,
})
export const putProfileDataSuccessAction = () => ({
	type: PUT_PROFILE_DATA_SUCCESS,
})
export const putProfileDataErrorAction = () => ({
	type: PUT_PROFILE_DATA_ERROR,
})
// existing user
export const checkUserExistsInitAction = () => ({
	type: CHECK_USER_EXISTS_INIT,
})
export const checkUserExistsSuccessAction = () => ({
	type: CHECK_USER_EXISTS_SUCCESS,
})
export const checkUserExistsErrorAction = () => ({
	type: CHECK_USER_EXISTS_ERROR,
})
// desired location
export const setDesiredLocationAction = (fieldValue, id, lat, lng) => ({
	type: SET_DESIRED_LOCATION,
	fieldValue: fieldValue,
	id: id,
	lat: lat,
	lng: lng,
})
export const removeDesiredLocationAction = id => ({
	type: REMOVE_DESIRED_LOCATION,
	id: id,
})
export const setAttachmentAction = (attachmentFile, attachmentName) => ({
	type: SET_ATTACHMENT,
	attachmentFile: attachmentFile,
	attachmentName: attachmentName,
})
// experience
export const addExperienceFieldAction = () => ({
	type: ADD_EXPERIENCE_FIELD,
})
export const removeExperienceFieldAction = id => ({
	type: REMOVE_EXPERIENCE_FIELD,
	id: id,
})
export const changeExperienceFieldAction = (experienceNewValue, type, id) => ({
	type: CHANGE_EXPERIENCE_FIELD,
	payload: {
		experienceNewValue: experienceNewValue,
		type: type,
		id: id,
	},
})
// post attachment
export const postAttachmentInitAction = () => ({
	type: POST_ATTACHMENT_INIT,
})
export const postAttachmentSuccessAction = () => ({
	type: POST_ATTACHMENT_SUCCESS,
})
export const postAttachmentErrorAction = () => ({
	type: POST_ATTACHMENT_ERROR,
})
// clear reducer
export const clearReducerAction = () => ({
	type: CLEAR_REDUCER,
})
// check contact data fields
export const checkContactDataFieldsAction = () => ({
	type: CHECK_CONTACT_DATA_FIELDS,
})

//
// Async thunks
//

export const asyncImportProfileDataAction = profileData => dispatch => {
	dispatch({
		type: IMPORT_PROFILE_DATA,
		profileData,
	})
	return Promise.resolve()
}

export const asyncSaveFieldAction = (fieldValue, fieldName) => dispatch => {
	dispatch({
		type: SAVE_FIELD,
		fieldValue: fieldValue,
		fieldName: fieldName,
	})
	return Promise.resolve()
}

export const asyncRemoveCFItemMultipleChoiceAction =
	(fieldValue, fieldName) => dispatch => {
		dispatch({
			type: REMOVE_CF_ITEM_MULTIPLE_CHOICE,
			fieldValue: fieldValue,
			fieldName: fieldName,
		})
		return Promise.resolve()
	}

// POST candidate
export const asyncPostProfileDataAction = questionTracker => {
	return (dispatch, getState) => {
		dispatch(postProfileDataInitAction())

		// Redux selectors
		const clickFunnelReducer = getState().clickFunnelReducer
		const jobsReducer = getState().jobsReducer
		const profileReducer = getState().profileReducer
		const authReducer = getState().authReducer

		// Preparing a candidate payload for the backend
		const candidatePayload = prepareCandidate(
			clickFunnelReducer,
			jobsReducer,
			questionTracker,
			"postRequest",
			profileReducer,
			authReducer
		)

		// Query param for affiliates
		const affiliateQueryParam = candidatePayload.a_id
			? `?a_id=${candidatePayload.a_id}`
			: ""

		// API call
		const appKey = "sdfkjlh2394823ufhn2390nfsdfkj"

		fetch(`${backendURL}/candidates/${affiliateQueryParam}`, {
			method: "POST",
			body: JSON.stringify(candidatePayload),
			headers: {
				"Content-Type": "application/json",
				"X-APP-KEY": appKey,
			},
		})
			.then(response => {
				if (response.status > 199 && response.status < 300) {
					return response.json()
				} else {
					dispatch(notificationsActions.setAppDownAction(true))
				}
			})
			.then(data => {
				if (data.message.includes("Successfully inserted candidate")) {
					dispatch(postProfileDataSuccessAction())
					dispatch(profileActions.setFirstRegistration())

					// Sending gtag events
					// Google Ads
					dispatch(
						tagManagerActions.sendUserRegisteredInAppEventAction({
							eventName: "UserRegisteredInApp",
							email: candidatePayload.email,
						})
					)

					// Facebook
					dispatch(
						tagManagerActions.sendSimpleCustomEvent({
							eventName: "fbWorkbeeAppSuccessCustomEvent",
						})
					)

					// Criteo and our GA
					if (jobsReducer?.job?.zoho_job_openings_id) {
						dispatch(
							tagManagerActions.sendGaSuccessPageCustomEventAction(
								{
									eventName: "gaSuccessPageCustomEvent",
									email: candidatePayload.email,
									id: jobsReducer.job.zoho_job_openings_id,
								}
							)
						)
						dispatch(
							tagManagerActions.sendSimpleCustomEvent({
								eventName:
									"specificUserRegistrationCustomEvent",
							})
						)

						// Bing Ads custom conversion
						window.uetq = window.uetq || []
						window.uetq.push(
							"event",
							"specificUserRegistrationCustomEvent",
							{
								event_label: "specificUserRegistration",
								event_value: 50,
								event_category: "Bewerbung (specific)",
							}
						)
					} else {
						dispatch(
							tagManagerActions.sendSimpleCustomEvent({
								eventName:
									"aspecificUserRegistrationCustomEvent",
							})
						)
					}

					// sending magic link email
					dispatch(
						authActions.asyncSendEmailLoginAction({
							email: candidatePayload.email,
							emailType: "signup",
							userType: "candidate",
						})
					)

					// register user using custom token
					dispatch(
						authActions.asyncRegisterCustomTokenAction({
							email: candidatePayload.email,
						})
					)

					// setting the after-signup tutorial depending on candidate specificity
					if (jobsReducer?.job?.zoho_job_openings_id) {
						dispatch(
							tutorialActions.setCanDisplayAction({
								canDisplay: true,
								type: "specificCandidateRegistered",
							})
						)
					} else {
						dispatch(
							tutorialActions.setCanDisplayAction({
								canDisplay: true,
								type: "aspecificCandidateRegistered",
							})
						)
					}

					// sending attachment
					if (candidatePayload.diploma_present) {
						dispatch(asyncPostAttachment())
					}
				} else {
					dispatch(postProfileDataErrorAction())
					dispatch(notificationsActions.setAppDownAction(true))
				}
			})
			.catch(error => {
				dispatch(loadingScreenActions.closeLoadingScreenAction())
				dispatch(postProfileDataErrorAction())
			})
	}
}

// POST promoter
export const asyncPostPromoterProfileDataAction = () => {
	return (dispatch, getState) => {
		dispatch(postPromoterProfileDataInitAction())

		// Redux selectors
		const clickFunnelReducer = getState().clickFunnelReducer

		// Preparing a candidate payload for the backend
		const promoterPayload = preparePromoter(
			clickFunnelReducer,
			"postRequest"
		)

		// API call
		const appKey = "sdfkjlh2394823ufhn2390nfsdfkj"

		fetch(`${backendURL}/promoters`, {
			method: "POST",
			body: JSON.stringify(promoterPayload),
			headers: {
				"Content-Type": "application/json",
				"X-APP-KEY": appKey,
			},
		})
			.then(response => {
				if (response.status > 199 && response.status < 300) {
					return response.json()
				} else {
					dispatch(notificationsActions.setAppDownAction(true))
				}
			})
			.then(data => {
				if (data.message.includes("Successfully inserted promoter")) {
					dispatch(postPromoterProfileDataSuccessAction())

					// sending magic link email
					dispatch(
						authActions.asyncSendEmailLoginAction({
							email: promoterPayload.email,
							emailType: "signup",
							userType: "promoter",
						})
					)

					// register user using custom token
					dispatch(
						authActions.asyncRegisterCustomTokenAction({
							email: promoterPayload.email,
						})
					)
				} else {
					dispatch(postPromoterProfileDataErrorAction())
					dispatch(notificationsActions.setAppDownAction(true))
				}
			})
			.catch(error => {
				dispatch(loadingScreenActions.closeLoadingScreenAction())
				dispatch(postProfileDataErrorAction())
			})
	}
}

// Promoter to candidate transition
export const asyncPromoterToCandidateTransitionAction = questionTracker => {
	return (dispatch, getState) => {
		dispatch(promoterToCandidateTranstionInitAction())

		// Redux selectors
		const clickFunnelReducer = getState().clickFunnelReducer
		const jobsReducer = getState().jobsReducer
		const profileReducer = getState().profileReducer
		const authReducer = getState().authReducer

		// Preparing a candidate payload for the backend
		const candidatePayload = prepareCandidate(
			clickFunnelReducer,
			jobsReducer,
			questionTracker,
			"postRequest",
			profileReducer
		)

		// Query param for affiliates
		const affiliateQueryParam = candidatePayload.a_id
			? `?a_id=${candidatePayload.a_id}`
			: ""

		// API call
		firebaseAuth.currentUser.getIdToken(true).then(token => {
			fetch(
				`${backendURL}/candidates/promoter_to_candidate${affiliateQueryParam}`,
				{
					method: "POST",
					body: JSON.stringify(candidatePayload),
					headers: {
						"Content-Type": "application/json",
						"X-FIREBASE-KEY": token,
					},
				}
			)
				.then(response => {
					if (response.status > 199 && response.status < 300) {
						return response.json()
					} else {
						dispatch(notificationsActions.setAppDownAction(true))
					}
				})
				.then(data => {
					if (
						data.message.includes("Successfully inserted candidate")
					) {
						dispatch(promoterToCandidateTranstionSuccessAction())

						dispatch(profileActions.asyncGetProfileDataAction())

						dispatch(
							notificationsActions.sendNotificationAction({
								open: true,
								message:
									"Du hast dich erfolgreich beworben. Dein persönlicher Recruiter wird sich umgehend mit dir in Verbindung setzen.",
								type: "success",
								timeout: 5000,
							})
						)

						// Send job application email confirmation
						dispatch(
							authActions.asyncSendEmailLoginAction({
								email: authReducer.email,
								emailType: "apply",
								userType: "candidate",
								jobOpeningId:
									jobsReducer.job.zoho_job_openings_id,
							})
						)
					} else {
						dispatch(promoterToCandidateTranstionErrorAction())
						dispatch(notificationsActions.setAppDownAction(true))
					}
				})
				.catch(error => {
					dispatch(loadingScreenActions.closeLoadingScreenAction())
					dispatch(promoterToCandidateTranstionErrorAction())
				})
		})
	}
}

// Check if user exists
export const asyncCheckUserExistsAction = config => {
	return (dispatch, getState) => {
		dispatch(checkUserExistsInitAction())

		let formData = getState().clickFunnelReducer.formData

		// set email to lowercase and remove all whitespace
		formData.email = formData.email.trim().toLowerCase()
		formData.email = formData.email.replace(/ /g, "")

		let appKey = "sdfkjlh2394823ufhn2390nfsdfkj"

		fetch(`${backendURL}/authentication/is_there?email=${formData.email}`, {
			headers: new Headers({
				"X-APP-KEY": appKey,
			}),
		})
			.then(response => {
				if (response.status > 199 && response.status < 300) {
					return response.json()
				} else {
					dispatch(notificationsActions.setAppDownAction(true))
				}
			})
			.then(data => {
				// user found in Zoho
				if (data.is_there_candidate || data.is_there_promoter) {
					dispatch(checkUserExistsSuccessAction())
					dispatch(
						notificationsActions.sendNotificationAction({
							open: true,
							message:
								"Du hast schon ein Profil in Workbee, bitte einloggen.",
							type: "info",
							pushToRight: false,
						})
					)
					history.push("/jobs")

					// sending gtag event
					dispatch(
						tagManagerActions.sendSimpleCustomEvent({
							eventName: "accountAlreadyExistsCustomEvent",
						})
					)
				}
				// user not found in Zoho
				else {
					if (config.funnelType !== "beero") {
						dispatch(
							asyncPostProfileDataAction(config.questionTracker)
						)
					} else {
						dispatch(asyncPostPromoterProfileDataAction())
					}

					dispatch(
						loadingScreenActions.openLoadingScreenAction({
							open: true,
						})
					)
				}
			})
			.catch(error => {
				console.log(error)
				dispatch(checkUserExistsErrorAction())
			})
	}
}

// PUT
export const asyncPutProfileDataAction = questionTracker => {
	return (dispatch, getState) => {
		dispatch(putProfileDataInitAction())

		// Redux selectors
		const clickFunnelReducer = getState().clickFunnelReducer
		const jobsReducer = getState().jobsReducer

		// Preparing a candidate payload for the backend
		const candidatePayload = prepareCandidate(
			clickFunnelReducer,
			jobsReducer,
			questionTracker,
			"putRequest"
		)

		// API call
		firebaseAuth.currentUser
			.getIdToken(true)
			.then(token => {
				fetch(`${backendURL}/candidates/`, {
					method: "PUT",
					body: JSON.stringify(candidatePayload),
					headers: {
						"Content-Type": "application/json",
						"X-FIREBASE-KEY": token,
					},
				})
					.then(response => {
						if (response.status > 199 && response.status < 300) {
							return response.json()
						} else {
							dispatch(
								notificationsActions.setAppDownAction(true)
							)
						}
					})
					.then(data => {
						if (data.message === "Candidate successfully updated") {
							dispatch(profileActions.asyncGetProfileDataAction())

							dispatch(putProfileDataSuccessAction())

							dispatch(
								notificationsActions.sendNotificationAction({
									open: true,
									message: "Änderungen gespeichert.",
									type: "success",
								})
							)
						} else {
							dispatch(putProfileDataErrorAction())
						}
					})
					.catch(error => {
						dispatch(putProfileDataErrorAction())
					})
			})
			.catch(error => {
				console.log("error with fetching token", error)
			})
	}
}

// POST attachment
export const asyncPostAttachment = () => {
	return (dispatch, getState) => {
		dispatch(postAttachmentInitAction())

		let clickFunnelReducer = getState().clickFunnelReducer

		let formData = new FormData()
		let fileName = clickFunnelReducer.attachment.attachmentName
		let file = clickFunnelReducer.attachment.attachmentFile
		formData.append("upload_file", file, fileName)

		// API call
		const appKey = "sdfkjlh2394823ufhn2390nfsdfkj"

		fetch(
			`${backendURL}/attachments/candidates?email=${clickFunnelReducer.formData.email}&category=diploma`,
			{
				method: "POST",
				body: formData,
				headers: new Headers({
					"X-APP-KEY": appKey,
				}),
			}
		)
			.then(response => {
				if (response.status > 199 && response.status < 300) {
					return response.json()
				} else {
					dispatch(notificationsActions.setAppDownAction(true))
				}
			})
			.then(data => {
				dispatch(postAttachmentSuccessAction())
			})
			.catch(error => {
				console.log(error)
				dispatch(postAttachmentErrorAction())
			})
	}
}
