import React from "react"

// Redux
import { useDispatch, useSelector } from "react-redux"
import * as jobsActions from "../../state/jobsActions"
import * as notificationsActions from "../../../notifications/state/notificationsActions"

// Material UI
import { makeStyles } from "@material-ui/core/styles"
import { Modal, Grid, useMediaQuery, Divider, Button } from "@material-ui/core"

// Material UI icons
import CloseIcon from "@material-ui/icons/Close"

// Images
import shareModalCopyClipboard from "../../../images/shareModalCopyClipboard.png"
import shareModalSendEmail from "../../../images/shareModalSendEmail.png"

// Styles
const useStyles = makeStyles({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: "rgba(0, 0, 0, 0.3)",
		fontFamily: "Nunito",
	},
	modalHeader: {
		margin: 0,
		fontSize: 16,
		fontWeight: 600,
	},
	modalGridItem: {
		display: "flex",
		alignItems: "center",
		padding: 0,
		paddingLeft: 16,
		paddingRight: 16,
	},
	modalGridItemInstructions: {
		display: "flex",
		flexDirection: "column",
		padding: 0,
		paddingLeft: 16,
		paddingRight: 16,
	},
	modalParagraphAGB: {
		fontSize: 12,
		fontWeight: 600,
		margin: 0,
		marginBottom: 10,
	},
	agbLink: {
		textDecoration: "none",
		color: "#3a5dab",
	},
	optionGridContainerItem: {
		cursor: "pointer",
		display: "flex",
		alignItems: "center",
		padding: 0,
	},
	optionGridItem: {
		cursor: "pointer",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		padding: 0,
		paddingTop: 12,
		paddingBottom: 12,
		"&:first-child": {
			borderRight: "1px solid rgba(0, 0, 0, 0.12)",
		},
	},
	modalCompanyLogoContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: 36,
		height: 36,
		borderRadius: 16,
		border: "solid 0.5px #e8e8e8",
		marginRight: 8,
	},
	modalCompanyLogo: {
		maxWidth: 22,
		maxHeight: 22,
	},
	modalJobTitle: {
		width: 280,
		fontSize: 12,
		fontWeight: 600,
	},
	modalGridItemClose: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		padding: 16,
		marginTop: 6,
	},
	closeIcon: {
		cursor: "pointer",
		width: 20,
	},
	optionParagraph: {
		margin: 0,
		textDecoration: "none",
		color: "#3a5dab",
		fontSize: 14,
		fontWeight: 600,
	},
	optionIcon: {
		marginRight: 4,
		width: 24,
		height: 24,
	},
	divider: {
		marginTop: 8,
		marginBottom: 0,
		width: "100%",
	},
	paperSmall: {
		width: "90%",
		backgroundColor: "#fff",
		borderRadius: 12,
		border: "solid 1px #e8e8e8",
		"&:focus": {
			outline: "none",
		},
	},
	paper: {
		width: 360,
		backgroundColor: "#fff",
		borderRadius: 12,
		border: "solid 1px #e8e8e8",
		"&:focus": {
			outline: "none",
		},
	},
	shareBtn: {
		width: "100%",
		height: 42,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		margin: 15,
		borderRadius: 12,
		backgroundColor: "#3a5dab",
		color: "white",
		"&:disabled": {
			backgroundColor: "grey",
		},
		"&:hover": {
			backgroundColor: "#3a5dab",
		},
	},
})

// Component
const JobSharePcModal = () => {
	// Hooks
	const styles = useStyles()
	const dispatch = useDispatch()

	// Media queries
	const mediaQueryMdUp = useMediaQuery("(min-width:380px)")

	// Redux selectors
	const jobsReducer = useSelector(state => state.jobsReducer)

	// Render
	return (
		<Modal
			open={jobsReducer.jobSharePcModalOpen}
			onClose={() => {
				dispatch(
					jobsActions.setJobSharePcModalAction({
						isModalOpen: false,
						url: "",
						logoSrcs: "",
						jobTitle: "",
						withInstructions: null,
					})
				)

				localStorage.setItem(
					"hideSharingInstructions",
					"hideSharingInstructions"
				)
			}}
			className={styles.modal}
		>
			<div className={mediaQueryMdUp ? styles.paper : styles.paperSmall}>
				<Grid container>
					<Grid item xs={12} className={styles.modalGridItemClose}>
						<h3 className={styles.modalHeader}>
							Job teilen und 500 € Prämie erhalten
						</h3>
						<CloseIcon
							className={styles.closeIcon}
							onClick={() => {
								dispatch(
									jobsActions.setJobSharePcModalAction({
										isModalOpen: false,
										url: "",
										logoSrcs: "",
										jobTitle: "",
										withInstructions: null,
									})
								)

								localStorage.setItem(
									"hideSharingInstructions",
									"hideSharingInstructions"
								)
							}}
						/>
					</Grid>
					{/* Instructions */}
					{!localStorage.getItem("hideSharingInstructions") && (
						<Grid
							item
							xs={12}
							className={styles.modalGridItemInstructions}
						>
							<p className={styles.modalParagraph}>
								In 2 einfachen Schritten <strong>500 €</strong>
								-Wunschgutschein erhalten mit dem
								Workbee-Empfehlungsprogramm: <br /> 1: Teile die
								Jobs über WhatsApp, Social Media oder wo auch
								immer! <br /> 2: <strong>500 €</strong>
								-Wunschgutschein erhalten, wenn ein/e
								Bewerber/in über deinen Link eingestellt wird
							</p>
							<p className={styles.modalParagraphAGB}>
								Klicke{" "}
								<a
									className={styles.agbLink}
									href="https://workbee.de/agb/#empfehlungsprogramm"
									target="_blank"
									rel="noreferrer"
								>
									hier
								</a>
								, um die AGB zu lesen.
							</p>
						</Grid>
					)}

					{/* Job Data */}
					<Grid item xs={12} className={styles.modalGridItem}>
						{jobsReducer.jobSharePcModalLogoUrl && (
							<div className={styles.modalCompanyLogoContainer}>
								<img
									className={styles.modalCompanyLogo}
									src={jobsReducer.jobSharePcModalLogoUrl}
									alt="company logo"
								/>
							</div>
						)}
						<p className={styles.modalJobTitle}>
							{jobsReducer.jobSharePcModalJobTitle}
						</p>
					</Grid>

					<Divider className={styles.divider} />

					{/* Share Functionality */}
					{jobsReducer.jobSharePcModalWithInstructions === false && (
						<Grid
							item
							xs={12}
							className={styles.optionGridContainerItem}
						>
							<Grid container>
								<Grid
									item
									xs={6}
									className={styles.optionGridItem}
								>
									<img
										className={styles.optionIcon}
										src={shareModalSendEmail}
										alt="send email"
									/>{" "}
									<a
										href={`mailto:?body=${jobsReducer.jobSharePcModalLink}&subject=Hallo, dieser Job könnte gut zu dir passen. Schau ihn dir an...`}
										className={styles.optionParagraph}
									>
										via E-Mail teilen
									</a>
								</Grid>

								<Grid
									item
									xs={6}
									className={styles.optionGridItem}
									onClick={() => {
										navigator.clipboard.writeText(
											`Hallo, dieser Job könnte gut zu dir passen. Schau ihn dir an... ${jobsReducer.jobSharePcModalLink}`
										)
										dispatch(
											notificationsActions.sendNotificationAction(
												{
													open: true,
													message:
														"Link in die Zwischenablage kopiert.",
													type: "success",
													timeout: 3000,
												}
											)
										)
									}}
								>
									<img
										className={styles.optionIcon}
										src={shareModalCopyClipboard}
										alt="copy to clipboard"
									/>{" "}
									<p className={styles.optionParagraph}>
										Link kopieren
									</p>
								</Grid>
							</Grid>
						</Grid>
					)}

					{/* Share btn for first time logged in mobile users */}
					{jobsReducer.jobSharePcModalWithInstructions && (
						<Grid
							item
							xs={12}
							className={styles.optionGridContainerItem}
						>
							<Button
								variant="contained"
								color="secondary"
								className={styles.shareBtn}
								onClick={() => {
									dispatch(
										jobsActions.setJobSharePcModalAction({
											isModalOpen: false,
											url: "",
											logoSrcs: "",
											jobTitle: "",
											withInstructions: null,
										})
									)

									localStorage.setItem(
										"hideSharingInstructions",
										"hideSharingInstructions"
									)

									navigator
										.share({
											title: jobsReducer.jobSharePcModalJobTitle,
											text: "",
											url: jobsReducer.jobSharePcModalLink,
										})
										.then(() => {})
										.catch(error => {})
								}}
							>
								Job teilen und 500 € Prämie erhalten
							</Button>
						</Grid>
					)}
				</Grid>
			</div>
		</Modal>
	)
}

export default JobSharePcModal
