import React from "react"

// Redux
import { useDispatch, useSelector } from "react-redux"
import * as profileActions from "../state/profileActions"

// Labels & Options
import fieldOptions from "../../jobs/components/jobsListPage/jobFilterModal/fieldOptions"

// Material UI
import { FormGroup, FormControlLabel, Checkbox, Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

// Styles
const useStyles = makeStyles({
	basicDataGridItem: {
		marginLeft: 16,
		marginRight: 16,
		marginBottom: 8,
	},
	checkbox: {
		color: "#3a5dab !important",
	},
	chebkoxError: {
		color: "red",
	},
	paragraph: {
		fontFamily: "'Nunito', sans-serif",
		margin: 0,
	},
})

const FilterFormGroup = props => {
	// Hooks
	const dispatch = useDispatch()
	const styles = useStyles()

	// Destructuring props
	const { fieldName } = props

	// Redux selectors
	const profileReducer = useSelector(state => state.profileReducer)

	// Render
	return (
		<Grid item xs={12} className={styles.basicDataGridItem}>
			<FormGroup row>
				{fieldOptions[fieldName].map(fieldOption => (
					<FormControlLabel
						key={fieldOption}
						control={
							<Checkbox
								id={`profilePageCheckbox${fieldName}${fieldOption}`}
								className={
									profileReducer.profileData.employment_form
										?.length === 0
										? styles.chebkoxError
										: styles.checkbox
								}
								checked={profileReducer.profileData[
									fieldName
								].includes(fieldOption)}
								onChange={el => {
									let fieldValue = null

									if (
										!profileReducer.profileData[
											fieldName
										].includes(fieldOption)
									) {
										fieldValue = [
											...profileReducer.profileData[
												fieldName
											],
											fieldOption,
										]
									} else {
										fieldValue = [
											...profileReducer.profileData[
												fieldName
											].filter(
												item => item !== fieldOption
											),
										]
									}

									dispatch(
										profileActions.changeProfileFieldAction(
											fieldName,
											fieldValue,
											"profileData"
										)
									)
								}}
								name={fieldOption}
							/>
						}
						label={
							<p className={styles.paragraph}>{fieldOption}</p>
						}
					/>
				))}
			</FormGroup>
		</Grid>
	)
}

export default FilterFormGroup
