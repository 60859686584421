import React, { useState, useEffect } from "react"

// Data structures
import { slideHighestValue } from "./dataStructures/questionsStructure"
import { slideHighestValueSpecific } from "./dataStructures/questionsStructureSpecific"

// Images
import logoNoHat from "../images/logoNoHat.png"

// Material UI
import { makeStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import Grid from "@material-ui/core/Grid"

// Styles
const useStyles = makeStyles(theme => ({
	root: {
		maxWidth: 600,
		margin: "25px auto",
	},
	progressBarContainer: {},
	progressBarGridItem: {
		padding: 1.5,
		position: "relative",
	},
	progressBarElementCurrent: {
		width: "auto",
		height: 8,
		textAlign: "center",
		backgroundColor: "#acbce0",
		borderRadius: 3,
	},
	progressBarElementPrevious: {
		width: "auto",
		height: 8,
		textAlign: "center",
		backgroundColor: "#acbce0",
		borderRadius: 3,
		background:
			"linear-gradient(to right, rgb(58, 93, 171) 50%, transparent 0)",
		backgroundSize: "200% 100%",
		backgroundPosition: "right",
		animation: `$makeItfadeIn 1500ms forwards`,
	},
	"@keyframes makeItfadeIn": {
		"100%": {
			backgroundPosition: "left",
		},
	},
	progressBarElementNext: {
		width: "auto",
		height: 8,
		textAlign: "center",
		backgroundColor: "#e8e8e8",
		borderRadius: 3,
	},
	workbeeImage: {
		position: "absolute",
		top: "50%",
		left: "50%",
		width: "50%",
		marginTop: "-25%",
		marginLeft: "-25%",
	},
}))

// Component
const ProgressBar = props => {
	// Hooks
	const styles = useStyles()

	// Destructuring props
	const { formSlide, funnelType } = props

	// Local state
	const [slideHighestValueDynamic, setSlideHighestValueDynamic] =
		useState(slideHighestValue)

	// Setting slide highest value, depending on funnel type
	useEffect(() => {
		if (funnelType === "specific") {
			setSlideHighestValueDynamic(slideHighestValueSpecific)
		}
	}, [setSlideHighestValueDynamic, funnelType])

	// Render
	if (funnelType !== "beero") {
		return (
			<div className={styles.root}>
				<Grid container className={styles.progressBarContainer}>
					{[...Array(slideHighestValueDynamic + 1).keys()].map(
						(el, id) => {
							let className = null

							if (formSlide === id) {
								className = "progressBarElementCurrent"
							} else if (formSlide > id) {
								className = "progressBarElementPrevious"
							} else {
								className = "progressBarElementNext"
							}

							return (
								<Grid
									key={id}
									item
									xs
									className={styles.progressBarGridItem}
								>
									{className ===
										"progressBarElementCurrent" && (
										<img
											className={styles.workbeeImage}
											alt="workbee"
											src={logoNoHat}
										/>
									)}
									<Paper
										elevation={0}
										className={styles[className]}
									/>
								</Grid>
							)
						}
					)}
				</Grid>
			</div>
		)
	} else {
		return null
	}
}

export default ProgressBar
