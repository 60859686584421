import React from "react"

// Material UI
import { makeStyles } from "@material-ui/core/styles"
import { Paper, Grid, useMediaQuery } from "@material-ui/core"

// Icons images
import bedsIcon from "../../../images/jobSummaryIcons/companyOverviewBedsIcon.png"
import employeesIcon from "../../../images/jobSummaryIcons/companyOverviewEmployeesIcon.png"
import nameIcon from "../../../images/jobSummaryIcons/companyOverviewNameIcon.png"

// Styles
const useStyles = makeStyles({
	topBenefitsBlickPaper: {
		padding: 16,
		marginBottom: 8,
		marginTop: 8,
		borderRadius: 12,
		border: "solid 1px #dee0e3",
	},
	topBenefitsBlickTxt: {
		margin: "0 0 16px",
		fontSize: 20,
		fontWeight: 600,
		textAlign: "center",
	},
	benefitGridItem: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		paddingBottom: 16,
		"&:last-child": { paddingBottom: 0 },
	},
	benefitGridItemMobile: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		paddingBottom: 16,
		"&:last-child": { paddingBottom: 0 },
	},
	benefitIcon: {
		width: 36,
		height: 36,
	},
	benefitText: {
		display: "flex",
		margin: 0,
		marginTop: 4,
		marginBottom: 24,
		wordBreak: "break-word",
		"&:last-child": { marginBottom: 0 },
	},
	benefitTextMobile: {
		display: "flex",
		margin: 0,
		marginLeft: 8,
		marginTop: 4,
		marginBottom: 24,
		wordBreak: "break-word",
		"&:last-child": { marginBottom: 0 },
	},
	summaryIconStandard: {
		width: 36,
		height: 36,
	},
	summaryIconGreen: {
		color: "#12a749",
		width: 36,
		height: 36,
	},
	summaryIconYellow: {
		color: "#e8b300",
		width: 36,
		height: 36,
	},
	companyOverviewIcon: {
		width: 36,
		height: 36,
	},
})

// Component
const JobSummary = props => {
	// Hooks
	const styles = useStyles()
	const mediaQueryMobiledUp = useMediaQuery("(min-width:450px)")

	// Destructuring props
	const {
		job,
		type,
		companyOverviewBeds,
		companyOverviewEmployees,
		companyOverviewName,
	} = props

	// Render
	return (
		<Paper elevation={0} className={styles.topBenefitsBlickPaper}>
			<h2 className={styles.topBenefitsBlickTxt}>
				{type === "companyInfo"
					? "Arbeitgeber auf einen Blick"
					: "Die Top-Vorteile auf einen Blick"}
			</h2>
			<Grid container>
				{/* highlighted benefits */}
				{type === "benefits" &&
					job.benefit_icons.map((benefit, id) => (
						<Grid
							item
							key={id}
							xs={12}
							className={
								mediaQueryMobiledUp
									? styles.benefitGridItem
									: styles.benefitGridItemMobile
							}
						>
							<img
								className={styles.benefitIcon}
								src={benefit.benefit_icon_link}
								alt={benefit.benefit_text}
							/>
							<p
								className={
									mediaQueryMobiledUp
										? styles.benefitText
										: styles.benefitTextMobile
								}
							>
								{benefit.benefit_text}
							</p>
						</Grid>
					))}
				{/* company overview */}
				{type === "companyInfo" && (
					<React.Fragment>
						{companyOverviewName && (
							<Grid
								item
								xs={12}
								className={
									mediaQueryMobiledUp
										? styles.benefitGridItem
										: styles.benefitGridItemMobile
								}
							>
								<img
									className={styles.companyOverviewIcon}
									src={nameIcon}
									alt=""
								/>
								<p
									className={
										mediaQueryMobiledUp
											? styles.benefitText
											: styles.benefitTextMobile
									}
								>
									{job.client_name}
								</p>
							</Grid>
						)}
						{companyOverviewBeds && (
							<Grid
								item
								xs={12}
								className={
									mediaQueryMobiledUp
										? styles.benefitGridItem
										: styles.benefitGridItemMobile
								}
							>
								<img
									className={styles.companyOverviewIcon}
									src={bedsIcon}
									alt=""
								/>
								<p
									className={
										mediaQueryMobiledUp
											? styles.benefitText
											: styles.benefitTextMobile
									}
								>
									{job.beds_departments}
								</p>
							</Grid>
						)}
						{companyOverviewEmployees && (
							<Grid
								item
								xs={12}
								className={
									mediaQueryMobiledUp
										? styles.benefitGridItem
										: styles.benefitGridItemMobile
								}
							>
								<img
									className={styles.companyOverviewIcon}
									src={employeesIcon}
									alt=""
								/>
								<p
									className={
										mediaQueryMobiledUp
											? styles.benefitText
											: styles.benefitTextMobile
									}
								>
									{job.number_of_employees}
								</p>
							</Grid>
						)}
					</React.Fragment>
				)}
			</Grid>
		</Paper>
	)
}

export default JobSummary
