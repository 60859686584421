const jobStyles = {
	link: {
		textDecoration: "none",
	},
	containerLoggedIn: {
		paddingTop: 80,
		maxWidth: 800,
		display: "flex",
		flexDirection: "column",
		flexGrow: 1,
		paddingBottom: 20,
	},
	containerDefault: {
		paddingTop: 16,
		maxWidth: 800,
		display: "flex",
		flexDirection: "column",
		flexGrow: 1,
		paddingBottom: 20,
	},
	containerSimilarJobs: {
		paddingTop: 16,
		maxWidth: 600,
		display: "flex",
		flexDirection: "column",
		flexGrow: 1,
		paddingBottom: 20,
	},
	containerSection2: {
		maxWidth: 800,
		display: "flex",
		flexGrow: 1,
	},
	greyPaper: {
		borderRadius: 0,
		backgroundColor: "#f6f6f6",
		color: "#3C424F !important",
	},
	paperBlack: {
		padding: 16,
		borderRadius: 4,
		backgroundColor: "#3c424f",
	},
	paperBlackTxt: {
		margin: 0,
		fontSize: 16,
		fontWeight: "normal",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: 1.38,
		letterSpacing: "normal",
		color: "#ffffff",
	},
	jobSummaryTitleText: {
		fontSize: 20,
		fontWeight: 800,
		marginTop: 24,
		marginBottom: 0,
		textAlign: "center",
	},
	jobSummaryText: {
		fontSize: 14,
		lineHeight: 1.38,
		letterSpacing: "normal",
		marginBottom: 24,
	},
	jobDescriptionText: {
		paddingTop: 24,
		fontSize: 14,
		textAlign: "center",
		"& b": {
			fontWeight: 600,
		},
		"& ul": {
			paddingInlineStart: "20px !important",
			fontWeight: "normal !important",
		},
		"& li": {
			lineHeight: 1.23,
			marginBottom: 8,
			textAlign: "left !important",
			fontSize: 14,
			"& span": {
				fontSize: 14,
			},
		},
		"& p": {
			margin: 0,
			fontSize: 14,
			textAlign: "left",
		},
		"& h2": {
			fontSize: 20,
			marginBottom: 0,
		},
	},
	jobDescriptionTextAboutEmployer: {
		paddingTop: 24,
		fontSize: 14,
		textAlign: "center",
		"& b": {
			fontWeight: 600,
		},
		"& ul": {
			paddingInlineStart: "20px !important",
			fontWeight: "normal !important",
		},
		"& li": {
			lineHeight: 1.23,
			marginBottom: 8,
			textAlign: "left !important",
			fontSize: 14,
			"& span": {
				fontSize: 14,
			},
		},
		"& p": {
			margin: 0,
			fontSize: 14,
			textAlign: "left",
		},
		"& span": {
			textAlign: "left",
			fontFamily: "'Nunito', sans-serif",
			fontSize: "14px !important",
		},
		"& h2": {
			fontSize: 20,
			marginBottom: 0,
		},
	},
	noJob: {
		marginTop: 80,
		padding: 12,
	},
	divider: {
		width: "100%",
		height: 1,
		backgroundColor: "#dee0e3",
	},
	mainDiv: {
		minHeight: "100vh",
		position: "relative",
		display: "flex",
		flexDirection: "column",
		color: "#3C424F !important",
		fontFamily: "'Nunito', sans-serif",
	},
	googleMapContainer: {
		position: "relative",
		width: "100%",
		height: 400,
	},
	gridItemCenterContent: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		margin: 0,
	},
	applyJobSeoTxt: {
		marginBottom: 2,
	},
	paper: {
		borderRadius: 0,
		color: "#3C424F !important",
	},
	jobAddressContainer: {
		marginTop: 8,
		marginBottom: 24,
		fontSize: 14,
		fontWeight: "normal",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: 1.47,
		letterSpacing: "normal",
		"& p": {
			margin: 0,
		},
	},
	jobAddressClientName: {
		fontSize: 14,
		margin: 0,
		marginBottom: 4,
		fontWeight: "bold",
	},
	whatIsWorkbeePaper: {
		marginTop: 24,
		marginBottom: 24,
		borderRadius: 16,
		padding: 16,
		color: "#3c424f",
		fontSize: 16,
		fontWeight: "normal",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: 1.38,
		letterSpacing: "normal",
		"& p": {
			margin: 0,
		},
	},
	whatIsWorkbeeHeader: {
		display: "flex",
		marginTop: 0,
		marginBottom: 16,
		fontFamily: "Nunito",
		fontSize: 18,
		fontWeight: 500,
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: 1.44,
		letterSpacing: "normal",
	},
	whatIsWorkbeeImg: {
		display: "inline-flex",
		height: 21,
		marginRight: 8,
		marginLeft: 8,
	},
	whatIsWorkbeeParagraph: {
		paddingBottom: 15,
	},
	applyBtnWorkbeeLoggedOut: {
		width: "100%",
		color: "#fafafa",
		marginTop: 16,
		backgroundColor: "#3a5dab",
		borderRadius: 16,
		"&:hover": {
			color: "#fafafa",
			backgroundColor: "#3a5dab",
		},
	},
	moreEmployerInfoBtn: {
		marginBottom: 24,
		textAlign: "center",
		fontWeight: "bold",
		"& a": {
			textDecoration: "none",
			color: "#3a5dab",
		},
	},
	similarJobsTitleText: {
		fontSize: 20,
		fontWeight: 800,
		marginTop: 24,
		marginBottom: 24,
		textAlign: "center",
	},
}

export default jobStyles
