import * as actions from "./notificationsActions"

export const initialState = {
	open: false,
	message: "",
	type: "",
	timeout: null,
	pushToRight: true,
	isAppDown: false,
	clickAway: false,
}

export default function notificationsReducer(state = initialState, action) {
	switch (action.type) {
		case actions.SEND_NOTIFICATION:
			let { message, type, timeout, pushToRight, clickAway } =
				action.payload

			if (timeout === null || timeout === undefined) {
				timeout = 5000
			}

			if (pushToRight === null || pushToRight === undefined) {
				pushToRight = true
			}

			if (clickAway === null || clickAway === undefined) {
				clickAway = false
			}

			return {
				...state,
				open: true,
				message: message,
				type: type,
				timeout: timeout,
				pushToRight: pushToRight,
				clickAway: clickAway,
			}
		case actions.CLOSE_NOTIFICATION:
			return {
				...state,
				open: false,
				message: "",
				type: "",
				timeout: null,
				pushToRight: true,
			}
		case actions.SET_APP_DOWN:
			return {
				...state.isAppDown,
				isAppDown: action.payload,
			}
		default:
			return state
	}
}
