import { backendURL } from "../../environment"
import { history } from "../../index"

// names
export const GET_CLIENT_DATA_INIT = "GET CLIENT DATA INIT"
export const GET_CLIENT_DATA_SUCCESS = "GET CLIENT DATA SUCCESS"
export const GET_CLIENT_DATA_ERROR = "GET CLIENT DATA ERROR"

export const GET_CHAIN_DATA_INIT = "GET CHAIN DATA INIT"
export const GET_CHAIN_DATA_SUCCESS = "GET CHAIN DATA SUCCESS"
export const GET_CHAIN_DATA_ERROR = "GET CHAIN DATA ERROR"

// dispatchers
export const getClientDataInitAction = () => ({ type: GET_CLIENT_DATA_INIT })
export const getClientDataSuccessAction = data => ({
	type: GET_CLIENT_DATA_SUCCESS,
	payload: data,
})
export const getClientDataErrorAction = () => ({ type: GET_CLIENT_DATA_ERROR })

export const getChainDataInitAction = () => ({ type: GET_CHAIN_DATA_INIT })
export const getChainDataSuccessAction = data => ({
	type: GET_CHAIN_DATA_SUCCESS,
	payload: data,
})
export const getChainDataErrorAction = () => ({ type: GET_CHAIN_DATA_ERROR })

// async thunks
export const asyncGetClientDataAction = clientId => {
	return dispatch => {
		dispatch(getClientDataInitAction())

		const appKey = "sdfkjlh2394823ufhn2390nfsdfkj"

		fetch(`${backendURL}/clients/?zoho_client_id=${clientId}`, {
			headers: new Headers({
				"X-APP-KEY": appKey,
			}),
		})
			.then(response => {
				return response.json()
			})
			.then(data => {
				if (data?.client) {
					dispatch(getClientDataSuccessAction(data.client))
				} else {
					dispatch(getClientDataErrorAction())
					history.push("/")
				}
			})
			.catch(error => {
				dispatch(getClientDataErrorAction())
			})
	}
}

export const asyncGetChainDataAction = chainId => {
	return dispatch => {
		dispatch(getChainDataInitAction())

		const appKey = "sdfkjlh2394823ufhn2390nfsdfkj"

		fetch(`${backendURL}/chains/?zoho_chains_id=${chainId}`, {
			headers: new Headers({
				"X-APP-KEY": appKey,
			}),
		})
			.then(response => {
				return response.json()
			})
			.then(data => {
				if (data?.chain) {
					dispatch(getChainDataSuccessAction(data.chain))
				} else {
					dispatch(getClientDataErrorAction())
					history.push("/")
				}
			})
			.catch(error => {
				dispatch(getChainDataErrorAction())
			})
	}
}
