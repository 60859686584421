import React from "react"

// Redux
import { useSelector } from "react-redux"

// Material UI
import { makeStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"

// Media
import logoOutlined from "../images/logoOutlined.png"

// Styles
const useStyles = makeStyles({
	appDownScreen: {
		display: "flex",
		flexDirection: "column",
		width: "100vw",
		height: "100vh",
		position: "absolute",
		top: 0,
		zIndex: 3000,
		backgroundColor: "#e8b300",
		justifyContent: "center",
		alignItems: "center",
		opacity: 1,
	},
	paper: {
		marginTop: "20px",
		padding: "2px 10px",
		maxWidth: "80%",
	},
	logo: {
		display: "flex",
		marginBottom: "30px",
	},
})

// Component
const AppDown = () => {
	// Hooks
	const styles = useStyles()

	// Redux Selectors
	const isAppDownLocal = useSelector(
		state => state.notificationsReducer.isAppDown
	)

	if (isAppDownLocal) {
		return (
			<div className={styles.appDownScreen}>
				<img
					src={logoOutlined}
					className={styles.logo}
					alt="app logo"
				/>
				<Paper elevation={1} className={styles.paper}>
					<p>
						Wir haben im Moment technische Schwierigkeiten, wir sind
						bald wieder da.
					</p>
				</Paper>
			</div>
		)
	} else {
		return null
	}
}

export default AppDown
