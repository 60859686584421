import React, { useState } from "react"

// Material UI
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { useMediaQuery, Grid, Button, Container } from "@material-ui/core"

// Styles
const useStyles = makeStyles(theme => ({
	profileCLickFunnelLayer: {
		height: "100%",
		width: "100%",
		position: "absolute",
		zIndex: 2,
		display: "flex",
		backgroundColor: "#fcfcfc",
	},
	profileCLickFunnelLayerLeft: {
		height: "100%",
		width: "calc(100% - 200px)",
		position: "absolute",
		zIndex: 2,
		display: "flex",
		backgroundColor: "#fcfcfc",
	},
	profileCLickFunnelLayerNotDisplayed: {
		display: "none",
	},
	gridItemContainer: {
		marginTop: 100,
		textAlign: "center",
	},
}))

// Component
const ProfileLayer = () => {
	// Hooks
	const styles = useStyles()
	const theme = useTheme()

	// Media Query
	const mediaQueryMdUp = useMediaQuery(theme.breakpoints.up("md"))

	// Local state
	const [visible, setVisible] = useState(true)

	// Render
	return (
		<div
			className={
				visible && mediaQueryMdUp
					? styles.profileCLickFunnelLayerLeft
					: visible && !mediaQueryMdUp
					? styles.profileCLickFunnelLayer
					: styles.profileCLickFunnelLayerNotDisplayed
			}
		>
			<Container>
				<Grid container className={styles.gridItemContainer}>
					<Grid item xs={12}>
						<p>
							Um deine Daten zu ändern, klicke bitte auf den Knopf
							unten.
						</p>
					</Grid>
					<Grid item xs={12}>
						<Button
							id="changeProfileDataOverlayBtn"
							onClick={() => {
								setVisible(false)
							}}
							variant="outlined"
						>
							Daten ändern
						</Button>
					</Grid>
				</Grid>
			</Container>
		</div>
	)
}

export default ProfileLayer
