import sanitizeHtml from "sanitize-html"

const seoJobData = job =>
	JSON.stringify({
		"@context": "https://schema.org/",
		"@type": "JobPosting",
		title: `${job && job.job_title}`,
		description: `${
			job && job.job_description && sanitizeHtml(job.job_description)
		}`,
		hiringOrganization: {
			"@type": "Organization",
			name: `${job && job.client_name}`,
		},

		industry: `${job && job.industry}`,
		employmentType: `${
			job &&
			job.contract_type &&
			job.contract_type.includes("Festanstellung")
				? "FULL_TIME"
				: "OTHER"
		}`,
		baseSalary: "",
		workHours: "shift",
		datePosted: `${job && job.created_on}`,
		validThrough: "",
		jobLocation: {
			"@type": "Place",
			address: {
				"@type": "PostalAddress",
				streetAddress: `${job && job.street_house_number}`,
				addressLocality: `${job && job.city}`,
				postalCode: `${job && job.zip_code && Number(job.zip_code)}`,
				addressCountry: "DE",
			},
		},
	})

export default seoJobData
