import * as actions from "./tutorialActions"

export const initialState = {
	canDisplay: false,
	open: false,
	type: "",
}

export default function tutorialReducer(state = initialState, action) {
	switch (action.type) {
		case actions.SET_CAN_DISPLAY:
			return {
				...state,
				...action.payload,
			}
		case actions.SET_OPEN:
			return {
				...state,
				...action.payload,
			}
		default:
			return state
	}
}
