import React, { useEffect, useState } from "react"
import Carousel from "react-material-ui-carousel"

// Redux
import { useDispatch } from "react-redux"
import * as tagManagerActions from "../../../tagManagerEvents/state/tagManagerActions"

// MaterialUI
import { makeStyles } from "@material-ui/core/styles"
import { Grid, CircularProgress } from "@material-ui/core"

// Material UI Icons
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"

// Styles
const useStyles = makeStyles({
	carouselContainer: {
		paddingTop: 48,
		marginBottom: 24,
	},
	imageCarousel: {
		marginTop: 40,
		overflow: "visible",
	},
	spinnerContainer: {
		display: "flex",
		justifyContent: "center",
		width: "100%",
	},
	carouselImg: {
		width: "100%",
		borderRadius: 6,
	},
	youtubeWrapper: {
		borderRadius: 12,
		overflow: "hidden",
		paddingBottom: "56.25%",
		position: "relative",
		height: 0,
		display: "flex",
		justifyContent: "center",
		"& iframe": {
			left: 0,
			top: 0,
			height: "100%",
			width: "100%",
			position: "absolute",
		},
		"& img": {
			borderRadius: 12,
			top: 0,
			height: "100%",
			width: "auto",
			position: "absolute",
		},
	},
})

// Component
const ImgCarousel = props => {
	// Hooks
	const styles = useStyles()
	const dispatch = useDispatch()

	// Destructuring props
	const { imgsLoaded, images, companyPhotosAvailable, youtubeLink } = props

	// Local state
	const [galleryMedia, setGalleryMedia] = useState(
		imgsLoaded && companyPhotosAvailable ? images : []
	)

	// Adding youtube link before photos
	useEffect(() => {
		const validateYoutubeRegex =
			/^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/

		if (youtubeLink?.match(validateYoutubeRegex)) {
			setGalleryMedia([
				{
					nodeName: "Video",
					src: youtubeLink?.includes("watch")
						? youtubeLink.replace("watch?v=", "embed/")
						: youtubeLink,
				},
				...images,
			])
		} else {
			setGalleryMedia(images)
		}
	}, [youtubeLink, images])

	// Render
	return companyPhotosAvailable || youtubeLink ? (
		<div className={styles.carouselContainer}>
			{imgsLoaded || youtubeLink ? (
				<Carousel
					className={styles.imageCarousel}
					IndicatorIcon={null}
					NextIcon={<ArrowForwardIcon />}
					PrevIcon={<ArrowBackIcon />}
					next={() => {
						dispatch(
							tagManagerActions.sendSimpleCustomEvent({
								eventName: "clickGalleryCustomEvent",
							})
						)
					}}
					prev={() => {
						dispatch(
							tagManagerActions.sendSimpleCustomEvent({
								eventName: "clickGalleryCustomEvent",
							})
						)
					}}
					navButtonsProps={{
						style: {
							backgroundColor: "#3a5dab",
							pointerEvents: "auto",
						},
					}}
					navButtonsWrapperProps={{
						style: {
							height: "100%",
							pointerEvents: "none",
						},
					}}
					indicatorContainerProps={{
						style: {
							zIndex: 1,
							top: "-40px",
							position: "absolute",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							width: "100%",
						},
					}}
					indicatorIconButtonProps={{
						style: {
							maxWidth: 34,
							height: 8,
							borderRadius: 3,
							backgroundColor: "#acbce0",
							margin: "0 4px",
							flex: "1 1 auto",
						},
					}}
					activeIndicatorIconButtonProps={{
						style: {
							backgroundColor: "#3a5dab",
						},
					}}
					navButtonsAlwaysVisible
					autoPlay={false}
					timeout={{
						appear: 300,
						enter: 300,
						exit: 300,
					}}
				>
					{galleryMedia.map(media => (
						<Grid container key={media}>
							<Grid item xs={12}>
								{/* Videos */}
								{media.nodeName === "Video" && (
									<div className={styles.youtubeWrapper}>
										<iframe
											width="853"
											height="480"
											src={`${media.src}`}
											frameBorder="0"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
											allowFullScreen
											title="Embedded youtube"
										/>
									</div>
								)}
								{/* Images */}
								{media.nodeName === "IMG" && (
									<div className={styles.youtubeWrapper}>
										<img
											style={{
												height: "100%",
												borderRadius: 6,
											}}
											src={media.src}
											alt="company"
										/>
									</div>
								)}
							</Grid>
						</Grid>
					))}
				</Carousel>
			) : (
				<div className={styles.spinnerContainer}>
					<CircularProgress style={{ margin: 40 }} />
				</div>
			)}
		</div>
	) : null
}

export default ImgCarousel
