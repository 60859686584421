// names
export const SEND_NOTIFICATION = "SEND NOTIFICATION"
export const CLOSE_NOTIFICATION = "CLOSE NOTIFICATION"

export const SET_APP_DOWN = "SET APP DOWN"

// dispatchers
export const sendNotificationAction = notificationData => ({
	type: SEND_NOTIFICATION,
	payload: notificationData,
})

export const closeNotificationAction = () => ({
	type: CLOSE_NOTIFICATION,
})

export const setAppDownAction = payload => ({
	type: SET_APP_DOWN,
	payload: payload,
})
