import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"

// Redux
import { useDispatch, useSelector } from "react-redux"
import * as profileActions from "./state/profileActions"
import * as jobsActions from "../jobs/state/jobsActions"

// Firebase
import { firebaseStorage } from "../firebase"
import { ref, listAll, getDownloadURL } from "firebase/storage"

// MaterialUI
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import Paper from "@material-ui/core/Paper"

// Icons
import mailIcon from "../images/recruiterField/mailIcon.png"
import phoneIcon from "../images/recruiterField/phoneIcon.png"

// Styles
const useStyles = makeStyles({
	accordion: {
		userSelect: "text !important",
		cursor: "auto !important",
	},
	recruiterFieldContainer: {
		borderRadius: "12px !important ",
	},
	paper: {
		marginTop: 10,
		padding: 15,
		width: "100%",
	},
	title: {
		margin: 0,
		marginLeft: 5,
		fontSize: 10,
		fontWeight: "bold",
	},
	photoContainer: {
		display: "flex",
		justifyContent: "center",
	},
	photoContainerMobile: {
		display: "flex",
		justifyContent: "center",
		marginBottom: 10,
	},
	recruiterPhoto: {
		height: 100,
		borderRadius: 100,
	},
	recruiterName: {
		marginTop: 0,
		marginLeft: 5,
		marginBottom: 4,
		fontSize: 18,
		fontWeight: "bold",
	},
	contactDataTxt: {
		width: "fit-content",
		marginTop: 0,
		marginLeft: 5,
		marginBottom: 8,
		padding: "4px 12px 4px 8px",
		fontSize: 10,
		display: "flex",
		alignItems: "center",
		borderRadius: 21.5,
		backgroundColor: "rgba(58, 93, 171, 0.1)",
	},
	contactIcon: {
		width: 16,
		height: 16,
		marginRight: 7,
	},
	emailLink: {
		color: "#3a5dab",
		fontWeight: "bold",
		textDecoration: "none",
	},
	profileLink: {
		color: "#e8b300",
	},
})

// Component
const YourRecruiterField = () => {
	// Hooks
	const styles = useStyles()
	const dispatch = useDispatch()

	// Media queries
	const recruiterMediaQuery = useMediaQuery("(max-width:350px)")

	// Redux selectors
	const profileData = useSelector(state => state.profileReducer)

	// Local state
	const [recruiterData, setRecruiterData] = useState(null)
	const [recruiterDataReady, setRecruiterDataReady] = useState(false)

	// Grab and set recruiter data
	useEffect(() => {
		if (profileData && profileData.recruiterData) {
			setRecruiterData(profileData.recruiterData)
			setRecruiterDataReady(true)
		}
	}, [profileData])

	// Grab recruiter photo
	useEffect(() => {
		// Checking if the photo needs to be either downloaded for the first time or updated
		if (recruiterData) {
			if (
				!recruiterData?.photoUrl?.includes(
					recruiterData?.last_name?.toLowerCase()
				)
			) {
				const storageRef = ref(
					firebaseStorage,
					`Recruiters/${recruiterData.email}`
				)

				listAll(storageRef)
					.then(result => {
						if (result.items.length > 0) {
							getDownloadURL(result.items[0])
								.then(url => {
									dispatch(
										profileActions.setRecruiterPhotoUrl(url)
									)
								})
								.catch(error => {
									// Handle any errors
								})
						}
					})
					.catch(error => {
						console.log(error)
					})
			}
		}
	}, [recruiterData, dispatch])

	// Render
	return (
		<Accordion
			expanded={profileData.changes.accordionIsOpen}
			className={styles.recruiterFieldContainer}
		>
			<AccordionSummary
				aria-controls="panel1a-content"
				id="panel1a-header"
				className={styles.accordion}
			>
				<Grid container>
					{profileData.recruiterData &&
						profileData.recruiterData.photoUrl &&
						profileData.recruiterData.photoUrl.includes(
							profileData.recruiterData.last_name?.toLowerCase()
						) && (
							<Grid
								item
								xs={recruiterMediaQuery ? 12 : 4}
								className={
									recruiterMediaQuery
										? styles.photoContainerMobile
										: styles.photoContainer
								}
							>
								<img
									src={
										profileData.recruiterData &&
										profileData.recruiterData.photoUrl
									}
									className={styles.recruiterPhoto}
									alt="recruiter"
								/>
							</Grid>
						)}
					<Grid item xs={recruiterMediaQuery ? 12 : 8}>
						<Grid container>
							<Grid item xs={12}>
								{recruiterDataReady && (
									<h4 className={styles.title}>
										{recruiterData.gender === "male"
											? "Dein persönlicher Berater"
											: "Deine persönliche Beraterin"}
									</h4>
								)}
							</Grid>
							<Grid item xs={12}>
								<p className={styles.recruiterName}>
									{recruiterDataReady &&
										`${recruiterData.first_name} ${recruiterData.last_name}`}
								</p>
							</Grid>
							<Grid item xs={12}>
								<div className={styles.contactDataTxt}>
									<img
										src={phoneIcon}
										alt="phone"
										className={styles.contactIcon}
									/>
									{recruiterDataReady && (
										<a
											href={`tel:${recruiterData.phone}`}
											className={styles.emailLink}
										>
											{recruiterData.phone}
										</a>
									)}
								</div>
							</Grid>
							<Grid item xs={12}>
								<div className={styles.contactDataTxt}>
									<img
										src={mailIcon}
										alt="email"
										className={styles.contactIcon}
									/>
									{recruiterDataReady && (
										<a
											href={`mailto:${recruiterData.email}`}
											className={styles.emailLink}
										>
											{recruiterData.email}
										</a>
									)}
								</div>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</AccordionSummary>
			<AccordionDetails>
				{profileData.changes.accordionComponentType ===
					"noJobsInfo" && (
					<Paper elevation={2} className={styles.paper}>
						<p id="noJobsInformation">
							Ich konnte leider keine Jobs finden. Du kannst deine{" "}
							<Link
								to={"/jobs"}
								className={styles.profileLink}
								onClick={() => {
									dispatch(
										jobsActions.setFilterJobsModalAction({
											isModalOpen: true,
										})
									)
									window.scrollTo(0, 0)
								}}
							>
								Filter
							</Link>{" "}
							ändern oder wir informieren dich, sobald wir Jobs
							für dich gefunden haben.
						</p>
					</Paper>
				)}

				{profileData.changes.accordionComponentType === "jobsInfo" && (
					<Paper elevation={3} className={styles.paper}>
						<p id="jobsInformation">
							Dies sind meine Job-Empfehlungen basierend auf
							deinen Bewerberwünschen. Klicke{" "}
							<Link
								to="/profile"
								className={styles.profileLink}
								onClick={() => {
									dispatch(profileActions.setWantsTab(true))
									dispatch(
										profileActions.setRecruiterAccordion(
											false,
											null
										)
									)
								}}
							>
								hier
							</Link>
							, um sie zu ändern.
						</p>
					</Paper>
				)}
			</AccordionDetails>
		</Accordion>
	)
}

export default YourRecruiterField
