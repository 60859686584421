import React from "react"

// Components
import QualificationFilterChip from "./QualificationFilterChip"
import LocationFilterChip from "./LocationFilterChip"
import OpenFilterModalChip from "./OpenFilterModalChip"

// Material UI
import { makeStyles } from "@material-ui/core/styles"
import { Grid } from "@material-ui/core"

// Hooks
import useGoogleMaps from "../../../hooks/useGoogleMaps"

// Styles
const useStyles = makeStyles({
	filteringChipGridContainer: {
		flexWrap: "nowrap",
		overflowX: "auto",
		scrollbarWidth: "none",
		msOverflowStyle: "none",
		"&::-webkit-scrollbar": {
			display: "none",
		},
	},
	filteringChipGridItem: {
		marginRight: 5,
	},
})

// Component
const FilterChips = props => {
	// Hooks
	const styles = useStyles()
	useGoogleMaps()

	// Destructuring props
	const { setMatchingJobsPage, anchorRef } = props

	// Render
	return (
		<Grid container className={styles.filteringChipGridContainer}>
			<Grid item className={styles.filteringChipGridItem}>
				<OpenFilterModalChip />
			</Grid>
			<Grid item className={styles.filteringChipGridItem}>
				<LocationFilterChip />
			</Grid>
			<Grid item className={styles.filteringChipGridItem}>
				<QualificationFilterChip
					id={1}
					fieldName="qualification"
					setMatchingJobsPage={setMatchingJobsPage}
					anchorRef={anchorRef}
				/>
			</Grid>
		</Grid>
	)
}

export default FilterChips
