import React, { useEffect } from "react"

// Image
import logoNoHat from "../images/logoNoHat.png"

// Redux
import { useSelector, useDispatch } from "react-redux"
import * as notificationsActions from "../notifications/state/notificationsActions"

// Material UI
import { useTheme, makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

// Custom Hooks
import { useOutsideClick } from "../hooks/useOutsideClick"

// Styles
const useStyles = makeStyles({
	notificationContainer: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
		position: "fixed",
		bottom: 70,
		zIndex: 1301,
		justifyContent: "center",
		paddingRight: "5%",
		paddingLeft: "5%",
	},
	marginLeft: {
		marginLeft: 100,
	},
	sideImgContainer: {
		display: "flex",
		position: "absolute",
		right: -35,
		bottom: -40,
	},
	sideImg: {
		width: 80,
	},
	notification: {
		display: "flex",
		width: "auto",
		maxWidth: 600,
		borderRadius: 4,
		padding: 10,
		position: "relative",
		paddingRight: 50,
		overflow: "hidden",
		color: "white",
	},
	success: {
		backgroundColor: "#2f8f58",
	},
	error: {
		backgroundColor: "#e53935",
	},
	info: {
		backgroundColor: "#3f51b5",
	},
})

// Component
const Notification = () => {
	// Hooks
	const styles = useStyles()
	const dispatch = useDispatch()
	const theme = useTheme()

	// Media Queries
	const mediaQueryMdUp = useMediaQuery(theme.breakpoints.up("md"))

	// Redux Selectors
	const notificationReducer = useSelector(state => state.notificationsReducer)

	// Closing notification
	useEffect(() => {
		if (notificationReducer.open && !notificationReducer.clickAway) {
			setTimeout(() => {
				dispatch(notificationsActions.closeNotificationAction())
			}, notificationReducer.timeout)
		}
	})

	const closeNotification = () => {
		dispatch(notificationsActions.closeNotificationAction())
	}

	// Clicking outside the ref will close the notification
	let ref = useOutsideClick(closeNotification)

	if (!notificationReducer.clickAway) {
		ref = null
	}

	// Render
	if (notificationReducer.open) {
		return (
			<div
				id="notification"
				className={
					mediaQueryMdUp && notificationReducer.pushToRight
						? `${styles.notificationContainer} ${styles.marginLeft}`
						: styles.notificationContainer
				}
			>
				<div
					ref={ref}
					className={`${styles.notification} ${
						styles[notificationReducer.type]
					}`}
				>
					{notificationReducer.message}
					<div id="notification" className={styles.sideImgContainer}>
						<img
							className={styles.sideImg}
							src={logoNoHat}
							alt="website bee logo"
						/>
					</div>
				</div>
			</div>
		)
	} else {
		return null
	}
}

export default Notification
