import React from "react"
import ReactDOM from "react-dom"
import "./index.scss"
import * as serviceWorker from "./serviceWorker"
import { env, useSentry } from "./environment"

// React Router
import { Router, Switch, Route, Redirect } from "react-router-dom"
import { createBrowserHistory } from "history"

// Pages & Components
import AppUrlListener from "./AppUrlListener"
import AuthState from "./auth/AuthState"
import ProfilePage from "./profile/ProfilePage"
import ApplicationPage from "./jobs/components/applicationPage/ApplicationPage"
import AuthPage from "./auth/AuthPage"
import TopNavigation from "./navigation/TopNavigation"
import LoadingScreen from "./loadingScreen/LoadingScreen"
import SettingsPage from "./auth/SettingsPage"
import Notification from "./notifications/Notification"
import ReduxLoadingScreen from "./loadingScreen/ReduxLoadingScreen"
import ClickFunnel from "./clickFunnel/ClickFunnel"
import ClickFunnelClient from "./clickFunnel/ClickFunnelClient"
import JobsPage from "./jobs/components/jobsListPage/JobsPage"
import AppDown from "./notifications/AppDown"
import EmailVerification from "./auth/EmailVerification"
import ClientsPage from "./clients/ClientsPage"
import TutorialScreen from "./tutorial/TutorialScreen"
import JobShareAuthModal from "./jobs/components/jobPage/JobShareAuthModal"
import JobSharePcModal from "./jobs/components/jobPage/JobSharePcModal"
import { HistoryProvider } from "./hooks/usePathHistory"
import WordpressJobSearch from "./wordpress/wordpressJobSearch"

// Redux
import { Provider, useSelector } from "react-redux"
import { store } from "./store"

// Sentry
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"

// Theme
import { MuiThemeProvider } from "@material-ui/core/styles"
import workbeeTheme from "./theme"

// Native
import { Capacitor } from "@capacitor/core"
import NativePushNotifications from "./notifications/NativePushNotifications"

export const history = createBrowserHistory()

if (useSentry && env) {
	Sentry.init({
		dsn: "https://80b888742d6f41b6b4af12d76a89c6f8@o442633.ingest.sentry.io/5414961",
		integrations: [new Integrations.BrowserTracing()],
		tracesSampleRate: 1.0,
		environment: env,
		ignoreErrors: [
			/^([a-z]|[A-Z])$/,
			/^O`I0_.*$/,
			/.*\(storage\/object-not-found\).*/,
			"Firebase Storage: An unknown error occurred, please check the error payload for server response. (storage/unknown)",
			"Can't find variable: _AutofillCallbackHandler",
			"CookieControl.Cookie.runScripts(uc)",
			"Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
			"null is not an object (evaluating 'g.readyState')",
			"Cannot read properties of null (reading 'readyState')",
			"Failed to fetch",
			"Tracker._xhr.onerror",
			"undefined is not an object (evaluating 'a.O')",
			"undefined is not an object (evaluating 'a.L')",
			"undefined is not an object (evaluating 'a.M')",
			"undefined is not an object (evaluating 'a.N')",
			"undefined is not an object (evaluating 'b.container[a]')",
			/O`\[object HTMLIFrameElement\]`I\d+_\d+`\[object Window\]/,
			"Cannot use 'in' operator to search for 'postMessage' in null",
		],
	})
}

// Private route
function PrivateRoute({ children, ...rest }) {
	const authReducer = useSelector(state => state.authReducer)
	return (
		<Route
			{...rest}
			render={({ location }) =>
				authReducer && authReducer.userLoggedIn ? (
					children
				) : (
					<Redirect
						to={{
							pathname: "/",
							state: { from: location },
						}}
					/>
				)
			}
		/>
	)
}

// First render
ReactDOM.render(
	<Provider store={store}>
		<MuiThemeProvider theme={workbeeTheme}>
			<Router history={history}>
				<HistoryProvider>
					<AppUrlListener />
					<TopNavigation />
					<AuthState />
					<TutorialScreen />
					{Capacitor.isNativePlatform() && (
						<NativePushNotifications />
					)}

					<div className="iosContainer">
						<Switch>
							<Route path="/auth">
								<EmailVerification />
							</Route>
							<Route path="/login">
								<AuthPage />
								<ReduxLoadingScreen />
								<AppDown />
							</Route>
							<Route path="/jobs">
								<JobShareAuthModal />
								<JobSharePcModal />
								<JobsPage />
								<AppDown />
							</Route>
							<Route path="/jobsWordpress">
								<JobsPage forWordpress={true} />
								<AppDown />
							</Route>
							<Route path="/clients/:clientId">
								<ClientsPage />
								<JobShareAuthModal />
								<JobSharePcModal />
							</Route>
							<Route path="/chains/:chainId">
								<ClientsPage />
								<JobShareAuthModal />
								<JobSharePcModal />
							</Route>
							<Route path="/form">
								<ClickFunnel />

								<ReduxLoadingScreen />
								<AppDown />
							</Route>
							<Route path="/clientForm">
								<ClickFunnelClient />

								<AppDown />
							</Route>
							<PrivateRoute path="/bewerbungen">
								<ApplicationPage />
								<AppDown />
							</PrivateRoute>
							<PrivateRoute path="/profile">
								<ProfilePage />
								<AppDown />
							</PrivateRoute>
							<Route path="/einstellungen">
								<SettingsPage />
								<ReduxLoadingScreen />
								<AppDown />
							</Route>
							<Route path="/wordpressJobSearch">
								<WordpressJobSearch />
							</Route>
							<Route path="/">
								<LoadingScreen />
							</Route>
						</Switch>
					</div>

					<Notification />
				</HistoryProvider>
			</Router>
		</MuiThemeProvider>
	</Provider>,
	document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
