import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { backendURL } from "../environment"
import logoNoHat from "../images/logoNoHat.png"

// Redux
import { useDispatch } from "react-redux"

// Redux actions
import * as notificationsActions from "../notifications/state/notificationsActions"
import * as authActions from "./state/authActions"

// Material UI
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"

// Styles
const useStyles = makeStyles(theme => ({
	noAccountContainer: {
		fontFamily: "Nunito",
		display: "flex",
		justifyContent: "center",
	},
	gridItem: {
		"& fieldset": {
			borderRadius: 12,
		},
	},
	noAccountText: {
		marginLeft: 15,
		marginRight: 15,
		fontSize: 13,
	},
	noAccountLink: {
		color: "#e8b300",
	},
	emailFormField: {
		display: "flex",
		marginLeft: 15,
		marginRight: 15,
	},
	emailFormFieldJobShare: {
		display: "flex",
		marginLeft: 0,
		marginRight: 0,
		borderRadius: 12,
	},
	btnContainer: {
		marginTop: 4,
		marginBottom: 10,
		display: "flex",
		justifyContent: "center",
	},
	btnContainerJobShare: {
		marginTop: -8,
		marginBottom: 0,
		display: "flex",
		justifyContent: "center",
	},
	loginBtn: {
		width: "100%",
		height: 42,
		display: "flex",
		marginLeft: 15,
		marginRight: 15,
		borderRadius: 12,
		backgroundColor: "#3a5dab",
		color: "white",
		"&:disabled": {
			backgroundColor: "grey",
		},
		"&:hover": {
			backgroundColor: "#3a5dab",
		},
	},
	loginBtnJobShare: {
		width: "100%",
		height: 42,
		display: "flex",
		borderRadius: 12,
		backgroundColor: "#3a5dab",
		color: "white",
		"&:disabled": {
			backgroundColor: "grey",
		},
		"&:hover": {
			backgroundColor: "#3a5dab",
		},
	},
	gridContainer: {
		position: "relative",
	},
	overlay: {
		position: "absolute",
		borderRadius: 12,
		backgroundColor: "rgba(255,255,255,0.8)",
		width: "98%",
		zIndex: 2,
		height: "98%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	logoNoHatContainer: {
		display: "flex",
		height: 80,
		animation: `$pulse 4000ms ${theme.transitions.easing.easeInOut}`,
		animationIterationCount: 4,
	},
	"@keyframes pulse": {
		"0%": {
			transform: "scale(0.90)",
		},
		"70%": {
			transform: "scale(1)",
		},
		"100%": {
			transform: "scale(0.90)",
		},
	},
}))

// Component
const LoginElement = props => {
	// Hooks
	const styles = useStyles()
	const dispatch = useDispatch()

	// Destructuring props
	let { showRegisterText, setShowEmailSentInfo, location } = props

	// Local state
	const [email, setEmail] = useState("")
	const [error, setError] = useState(false)
	const [errorMsg, setErrorMsg] = useState("")
	const [loginBtnDisabled, setLoginBtnDisabled] = useState(true)
	const [overlay, setOverlay] = useState(false)

	// Logic for disabling the login btn
	useEffect(() => {
		if (email.trim() !== "") {
			setLoginBtnDisabled(false)
		} else {
			setLoginBtnDisabled(true)
		}
	}, [email])

	// Render
	return (
		<Grid
			container
			spacing={location === "shareModal" ? 2 : 1}
			justifyContent="center"
			className={styles.gridContainer}
		>
			{/* Overlay */}
			{overlay && (
				<div className={styles.overlay}>
					<div>
						<img
							className={styles.logoNoHatContainer}
							src={logoNoHat}
							alt=""
						/>
					</div>
				</div>
			)}

			{/* Login Screen */}
			{showRegisterText && (
				<Grid item xs={12} className={styles.noAccountContainer}>
					<p className={styles.noAccountText}>
						Du hast noch kein Workbee-Konto?{" "}
						<Link to={"/form"} className={styles.noAccountLink}>
							Hier registrieren!
						</Link>
					</p>
				</Grid>
			)}
			<Grid item xs={12} className={styles.gridItem}>
				<TextField
					inputProps={{
						style: {
							WebkitBoxShadow: "0 0 0 1000px white inset",
							borderRadius: 12,
						},
					}}
					error={error}
					className={
						location === "shareModal"
							? styles.emailFormFieldJobShare
							: styles.emailFormField
					}
					label="E-Mail-Adresse"
					type="email"
					id="email"
					variant="outlined"
					autoComplete="on"
					onKeyDown={e => {
						if (e.key === "Enter") {
							e.target.blur()
						}
					}}
					onChange={e => {
						setEmail(e.target.value)
						setLoginBtnDisabled(false)
					}}
					value={email}
					helperText={error ? errorMsg : ""}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				className={
					location === "shareModal"
						? styles.btnContainerJobShare
						: styles.btnContainer
				}
			>
				<Button
					className={
						location === "shareModal"
							? styles.loginBtnJobShare
							: styles.loginBtn
					}
					disabled={loginBtnDisabled}
					variant="contained"
					id="loginBtn"
					color="secondary"
					onClick={e => {
						let appKey = "sdfkjlh2394823ufhn2390nfsdfkj"

						fetch(
							`${backendURL}/authentication/is_there?email=${email}`,
							{
								headers: new Headers({
									"X-APP-KEY": appKey,
								}),
							}
						)
							.then(response => {
								if (
									response.status > 199 &&
									response.status < 300
								) {
									return response.json()
								} else {
									dispatch(
										notificationsActions.setAppDownAction(
											true
										)
									)
								}
							})
							.then(data => {
								// user not found in Zoho
								if (
									!data.is_there_candidate &&
									!data.is_there_promoter
								) {
									dispatch(
										notificationsActions.sendNotificationAction(
											{
												open: true,
												message:
													"Diese E-Mail-Adresse ist uns nicht bekannt. Hast du vielleicht eine andere Mailadresse für Workbee benutzt? Oder gibt es einen Tippfehler?",
												type: "info",
												pushToRight: false,
											}
										)
									)
								} else if (
									data.is_there_candidate ||
									data.is_there_promoter
								) {
									setError(false)
									setLoginBtnDisabled(true)
									setShowEmailSentInfo(true)
									setOverlay(true)

									dispatch(
										authActions.asyncSendEmailLoginAction({
											email: email,
											emailType: "login",
											userType: data.is_there_candidate
												? "candidate"
												: "promoter",
										})
									)
								}
							})
							.catch(error => {
								setError(true)

								if (
									error.message ===
										"The email address is badly formatted." ||
									error.message ===
										"There is no user record corresponding to this identifier. The user may have been deleted."
								) {
									setErrorMsg(
										"Diese E-Mail-Adresse ist uns nicht bekannt. Hast du vielleicht eine andere Mailadresse für Workbee benutzt? Oder gibt es einen Tippfehler?"
									)
								}
							})
					}}
				>
					Anmelden
				</Button>
			</Grid>
		</Grid>
	)
}

export default LoginElement
