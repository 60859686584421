import * as actions from "./ReduxLoadingScreenActions"

export const initialState = {
  open: false,
  message: "",
  type: "",
}

export default function loadingScreenReducer(state = initialState, action) {
  switch (action.type) {
    case actions.OPEN_LOADING_SCREEN:
      let { message, type } = action.payload
      return {
        open: true,
        message: message,
        type: type,
      }
    case actions.CLOSE_LOADING_SCREEN:
      return {
        open: false,
        message: "",
        type: "",
      }
    default:
      return state
  }
}
