import React from "react"

// Redux
import { useSelector } from "react-redux"

// Material UI
import { makeStyles } from "@material-ui/core/styles"

// Icons
import jobCardIconAdvantage from "../../../images/jobCardIcons/jobCardIconAdvantage.png"

// Styles
const useStyles = makeStyles({
	jobOpening: {
		border: "solid 1px #e8e8e8",
		borderRadius: 12,
	},
	jobOpeningWithOpacity: {
		border: "solid 1px #e8e8e8;",
		borderRadius: 12,
		"&:last-child": {
			opacity: 0.4,
		},
	},
	link: {
		textDecoration: "none",
	},
	logoAndBannersContainer: {
		display: "flex",
		justifyContent: "space-between",
	},
	companyLogoWrapper: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: 72,
		height: 72,
		borderRadius: 36,
		marginTop: 16,
		marginLeft: 16,
		border: "solid 0.9px #e8e8e8",
	},
	companyLogo: {
		maxWidth: 50,
		maxHeight: 50,
	},
	bannersWrapper: {
		display: "flex",
		flexDirection: "column",
		overflow: "hidden",
		marginTop: 6,
		height: 70,
	},
	bannerWrapperJobOpening: {
		right: 0,
		display: "flex",
		flexDirection: "column",
		overflow: "visible",
		marginTop: 6,
		height: 70,
	},
	advantageIcon: {
		marginLeft: 8,
		marginRight: 4,
	},
	benefitBanner: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		position: "relative",
		marginBottom: 18,
		right: -5,
		top: 10,
		height: 24,
		borderTopLeftRadius: 21.5,
		borderBottomLeftRadius: 21.5,
		boxShadow: "0 0 3px 1px rgba(99, 192, 133, 0.5)",
		border: "solid 1px rgba(99, 192, 133, 0.5)",
		backgroundColor: "rgba(99, 192, 133, 0.1)",
	},
	benefitBannerJobOpening: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		position: "relative",
		marginBottom: 18,
		right: -16,
		top: -5,
		height: 24,
		borderTopLeftRadius: 21.5,
		borderBottomLeftRadius: 21.5,
		boxShadow: "0 0 3px 1px rgba(99, 192, 133, 0.5)",
		border: "solid 1px rgba(99, 192, 133, 0.5)",
		borderRight: "none",
		backgroundColor: "rgba(99, 192, 133, 0.1)",
	},
	flameEmoji: {
		fontSize: 9,
		marginLeft: 12,
		marginBottom: 0,
		marginRight: 16,
	},
	flameEmojiBig: {
		fontSize: 29,
		marginLeft: 4,
		marginBottom: 8,
		marginRight: 6,
	},
	bannerParagraph: {
		display: "flex",
		alignItems: "center",
		margin: 0,
		fontSize: 10,
		fontWeight: "bold",
	},
	bannerParagraphOneBenefit: {
		display: "flex",
		alignItems: "center",
		margin: 0,
		fontSize: 10,
		fontWeight: "bold",
		marginRight: 14,
	},
})

// Component
const JobCardBanners = props => {
	// Hooks
	const styles = useStyles()

	// Destructuring props
	const { jobOpening, location } = props

	// Redux selectors
	const profileReducer = useSelector(state => state.profileReducer)

	// Variables
	const matchingBenefitsAmount = jobOpening.count_matching_benefits
	let profileBenefitsAmount = 0
	if (
		profileReducer.profileData !== undefined &&
		profileReducer.profileData.benefits !== undefined
	) {
		profileBenefitsAmount = profileReducer.profileData.benefits.length
	}

	// Render
	return (
		<div
			className={
				location === "jobOpening"
					? styles.bannerWrapperJobOpening
					: styles.bannersWrapper
			}
		>
			{matchingBenefitsAmount > 0 && (
				<div
					className={
						location === "jobOpening"
							? styles.benefitBannerJobOpening
							: styles.benefitBanner
					}
				>
					<p
						className={
							matchingBenefitsAmount === 1 &&
							profileBenefitsAmount === 3
								? styles.bannerParagraphOneBenefit
								: styles.bannerParagraph
						}
					>
						<img
							className={styles.advantageIcon}
							src={jobCardIconAdvantage}
							alt="advantage"
						/>
						{`${matchingBenefitsAmount}/${profileBenefitsAmount} deiner Top-Vorteile`}
						{/* Only in case of 1 of 3 matching benefits there is no flame */}
						{!(
							matchingBenefitsAmount === 1 &&
							profileBenefitsAmount === 3
						) && (
							<span
								className={
									matchingBenefitsAmount ===
									profileBenefitsAmount
										? styles.flameEmojiBig
										: styles.flameEmoji
								}
								role="img"
								aria-label="flame"
							>
								🔥
							</span>
						)}
					</p>
				</div>
			)}
		</div>
	)
}

export default JobCardBanners
