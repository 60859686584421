import React from "react"

// MaterialUI
import { Grid, IconButton, useMediaQuery } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"

// Images
import logoNoText from "../images/logoNoText.png"
import xingIcon from "../images/xingIcon.png"

// Social Icons
import FacebookIcon from "@material-ui/icons/Facebook"
import InstagramIcon from "@material-ui/icons/Instagram"
import LinkedInIcon from "@material-ui/icons/LinkedIn"

// Redux
import { useDispatch } from "react-redux"

// Actions
import * as tagManagerActions from "../tagManagerEvents/state/tagManagerActions"

// Styles
const useStyles = makeStyles(theme => ({
	footer: {
		display: "flex",
		padding: 16,
		backgroundColor: "rgb(255,255,255)",
		fontFamily: "'Nunito', sans-serif",
		color: "#110c08",
	},
	footerWideScreen: {
		display: "flex",
		flexDirection: "row",
		padding: 16,
		marginLeft: 200,
		width: "calc(100% - 200px)",
		backgroundColor: "rgb(255,255,255)",
		fontFamily: "'Nunito', sans-serif",
		color: "#110c08",
	},
	footerSectionCompany: {
		flexDirection: "column",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	footerSectionCompanyLogo: {
		width: 130,
	},
	footerSectionCompanyYear: {
		margin: 0,
		marginTop: 5,
		color: "grey",
		fontSize: 13,
	},
	footerSectionLinks: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		justifyContent: "center",
		marginTop: 30,
		marginBottom: 30,
	},
	footerLink: {
		color: "grey",
		textDecoration: "none",
		fontWeight: 400,
		padding: 8,
	},
	footerSectionSocial: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	footerSocialIcon: {
		color: "#3a3a3a",
		width: 25,
		height: 25,
	},
	iconButton: {
		padding: "0px 8px",
	},
	xingIcon: {
		width: 25,
		height: 25,
		opacity: 0.75,
	},
}))

// Component
const Footer = props => {
	// Hooks
	const styles = useStyles()
	const theme = useTheme()
	const dispatch = useDispatch()

	// Destructuring props
	const { fromJobs } = props

	// Media queries
	const mediaQueryMdUp = useMediaQuery(theme.breakpoints.up("md"))

	// Variables
	const workbeeLinks = [
		{
			name: "Über uns",
			href: "https://workbee.de/ueber-uns/unser-unternehmen/",
		},
		{ name: "Unser Team", href: "https://workbee.de/unser-team/" },
		{
			name: "Jobs bei Workbee",
			href: "https://workbee.de/karriere-bei-workbee/",
		},
		{
			name: "Für Arbeitgeber",
			href: "https://workbee.de/arbeitgeber/",
		},
		{ name: "Impressum", href: "https://workbee.de/impressum/" },
		{ name: "AGB", href: "https://workbee.de/agb/" },
		{
			name: "Datenschutzerklärung",
			href: "https://workbee.de/datenschutz/#plattform",
		},
		{ name: "Kontakt", href: "https://workbee.de/kontakt/" },
		{ name: "Login", href: "https://workbee.de/login/" },
	]

	// Render
	return (
		<Grid
			container
			className={
				mediaQueryMdUp && fromJobs
					? styles.footer
					: mediaQueryMdUp
					? styles.footerWideScreen
					: styles.footer
			}
		>
			<Grid
				item
				xs={mediaQueryMdUp ? 3 : 12}
				className={styles.footerSectionCompany}
			>
				<img
					className={styles.footerSectionCompanyLogo}
					src={logoNoText}
					alt="workbee"
				/>
				<p
					className={styles.footerSectionCompanyYear}
				>{`© ${new Date().getFullYear()}`}</p>
			</Grid>
			<Grid
				item
				xs={mediaQueryMdUp ? 6 : 12}
				className={styles.footerSectionLinks}
			>
				{workbeeLinks.map((link, id) => (
					<a
						key={id}
						href={link.href}
						target="_blank"
						className={styles.footerLink}
						rel="noopener noreferrer"
						onClick={() => {
							dispatch(
								tagManagerActions.sendSimpleCustomEvent({
									eventName: "clickFooterLinkCustomEvent",
								})
							)
						}}
					>
						{link.name}
					</a>
				))}
			</Grid>
			<Grid
				item
				xs={mediaQueryMdUp ? 3 : 12}
				className={styles.footerSectionSocial}
			>
				<a
					href="https://de-de.facebook.com/workbee.de"
					target="_blank"
					rel="noopener noreferrer"
					onClick={() => {
						dispatch(
							tagManagerActions.sendSimpleCustomEvent({
								eventName: "clickFooterLinkCustomEvent",
							})
						)
					}}
				>
					<IconButton className={styles.iconButton}>
						<FacebookIcon
							color="primary"
							className={styles.footerSocialIcon}
						/>
					</IconButton>
				</a>

				<a
					href="https://www.linkedin.com/company/workbee-germany"
					target="_blank"
					rel="noopener noreferrer"
					onClick={() => {
						dispatch(
							tagManagerActions.sendSimpleCustomEvent({
								eventName: "clickFooterLinkCustomEvent",
							})
						)
					}}
				>
					<IconButton className={styles.iconButton}>
						<LinkedInIcon
							color="primary"
							className={styles.footerSocialIcon}
						/>
					</IconButton>
				</a>

				<a
					href="https://www.instagram.com/workbee.de/"
					target="_blank"
					rel="noopener noreferrer"
					onClick={() => {
						dispatch(
							tagManagerActions.sendSimpleCustomEvent({
								eventName: "clickFooterLinkCustomEvent",
							})
						)
					}}
				>
					<IconButton className={styles.iconButton}>
						<InstagramIcon
							color="primary"
							className={styles.footerSocialIcon}
						/>
					</IconButton>
				</a>

				<a
					href="https://www.xing.com/pages/workbee"
					target="_blank"
					rel="noopener noreferrer"
					onClick={() => {
						dispatch(
							tagManagerActions.sendSimpleCustomEvent({
								eventName: "clickFooterLinkCustomEvent",
							})
						)
					}}
				>
					<IconButton className={styles.iconButton}>
						<img
							className={styles.xingIcon}
							src={xingIcon}
							alt="xing"
						/>
					</IconButton>
				</a>
			</Grid>
		</Grid>
	)
}

export default Footer
