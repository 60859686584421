import React, { useState, useEffect } from "react"
import * as Sentry from "@sentry/browser"

// Material UI
import { makeStyles, useTheme } from "@material-ui/core/styles"
import {
	Chip,
	Popover,
	TextField,
	List,
	ListItem,
	ListItemSecondaryAction,
	InputAdornment,
	useMediaQuery,
} from "@material-ui/core"

// Redux
import { useSelector, useDispatch } from "react-redux"
import * as jobsActions from "../../state/jobsActions"
import * as tagManagerActions from "../../../tagManagerEvents/state/tagManagerActions"

// Icons
import jobListIconLocation from "../../../images/jobListIcons/ic-location.png"

// Material UI icons
import CheckIcon from "@material-ui/icons/Check"
import SearchIcon from "@material-ui/icons/Search"

// Utils
import throttle from "lodash/throttle"

// Styles
const useStyles = makeStyles(() => ({
	paperMobile: {
		borderRadius: 6,
		border: "solid 1px #3a5dab",
		position: "relative",
		maxHeight: 400,
	},
	paper: {
		borderRadius: 6,
		border: "solid 1px #3a5dab",
	},
	popoverMobile: {
		borderRadius: 6,
	},
	popover: {
		width: 550,
		borderRadius: 6,
	},
	filteringChip: {
		height: 32,
		fontSize: 10,
		backgroundColor: "#3a5dab",
		"&:hover": {
			color: "#fafafa",
			backgroundColor: "#20335e",
		},
		"&:focus": {
			color: "#fafafa",
			backgroundColor: "#3a5dab",
		},
		"&:active": {
			color: "#fafafa",
			backgroundColor: "#3a5dab",
		},
	},
	locationInputField: {
		marginTop: 10,
		width: "100%",
		"& .MuiFormLabel-root": {
			paddingLeft: 15,
		},
		"& .MuiInputBase-input": {
			paddingLeft: 5,
		},
	},
	optionList: {
		fontFamily: "Nunito",
		fontSize: 16,
		paddingTop: 0,
		paddingBottom: 0,
	},
	searchIcon: {
		marginLeft: 10,
	},
	checkIcon: {
		color: "#f5a800",
		marginRight: 5,
	},
	option: {
		width: "100%",
		paddingTop: 22,
		paddingBottom: 22,
	},
	optionSelected: {
		paddingTop: 22,
		paddingBottom: 22,
		backgroundColor: "rgba(245, 168, 0, 0.1)",
	},
	input: {
		"&::placeholder": {
			fontStyle: "italic",
		},
		padding: "12px",
	},
}))

const autocompleteService = { current: null }

// Component
const LocationFilterChip = () => {
	// Hooks
	const styles = useStyles()
	const dispatch = useDispatch()
	const theme = useTheme()

	// Media queries
	const mediaQuerySmUp = useMediaQuery(theme.breakpoints.up("sm"))

	// Redux selectors
	const jobsReducer = useSelector(state => state.jobsReducer)
	const authReducer = useSelector(state => state.authReducer)
	const profileReducer = useSelector(state => state.profileReducer)

	// Local state
	const [anchorEl, setAnchorEl] = useState(null)
	const [locationFilterString, setLocationFilterString] = useState(null)
	const [inputValue, setInputValue] = useState("")
	const [options, setOptions] = useState([])
	const [predefinedOptions, setPredefinedOptions] = useState([
		"Deutschlandweit",
	])
	const [madeChange, setMadeChange] = useState(false)
	const [canSendTagEvent, setCanSendTagEvent] = useState(false)

	// Get coordinates using place id of a location
	const handlePlaceSelect = async newValue => {
		if (!newValue) return

		const placeId = newValue.place_id
		const geocoder = new window.google.maps.Geocoder()

		try {
			const results = await new Promise((resolve, reject) => {
				geocoder.geocode({ placeId: placeId }, (results, status) => {
					if (status === "OK" && results[0]) {
						resolve(results[0])
					} else {
						reject(new Error(`Geocoder failed due to: ${status}`))
					}
				})
			})

			const location = results.geometry.location
			const lat = location.lat()
			const lng = location.lng()

			return { lat, lng }
		} catch (error) {
			console.error(error.message)
			Sentry.captureException(error)
		}
	}

	// Get coordinates using the location name (or address) of a location
	const handlePlaceSelectWithLocationName = async locationName => {
		if (!locationName) return

		const geocoder = new window.google.maps.Geocoder()

		try {
			const results = await new Promise((resolve, reject) => {
				geocoder.geocode(
					{ address: locationName },
					(results, status) => {
						if (status === "OK" && results[0]) {
							resolve(results[0])
						} else {
							reject(
								new Error(`Geocoder failed due to: ${status}`)
							)
						}
					}
				)
			})

			const location = results.geometry.location
			const lat = location.lat()
			const lng = location.lng()

			return { lat, lng }
		} catch (error) {
			console.error(error.message)
			Sentry.captureException(error)
		}
	}

	// Memo
	const fetch = React.useMemo(
		() =>
			throttle((request, callback) => {
				autocompleteService.current.getPlacePredictions(
					{
						...request,
						fields: ["geometry", "name"],
						types: ["locality", "sublocality", "postal_code"],
						componentRestrictions: { country: "de" },
					},
					callback
				)
			}, 200),
		[]
	)

	// Set the location string
	useEffect(() => {
		if (jobsReducer?.filteringOptions?.locations?.length > 0) {
			if (jobsReducer.filteringOptions.locations.length === 1) {
				setLocationFilterString(
					jobsReducer.filteringOptions.locations[0].location_name
				)
			} else {
				setLocationFilterString(
					jobsReducer.filteringOptions.locations[0].location_name +
						" (+" +
						(jobsReducer.filteringOptions.locations.length - 1) +
						")"
				)
			}
		}
	}, [jobsReducer.filteringOptions.locations])

	// Set results effects
	useEffect(() => {
		let active = true

		if (!autocompleteService.current && window.google) {
			autocompleteService.current =
				new window.google.maps.places.AutocompleteService()
		}
		if (!autocompleteService.current) {
			return undefined
		}

		fetch({ input: inputValue }, results => {
			if (active) {
				let newOptions = []

				if (results) {
					results = results.filter(el => {
						return (
							el.types.includes("locality") ||
							el.types.includes("sublocality") ||
							el.types.includes("postal_code")
						)
					})
					newOptions = [...newOptions, ...results]
					newOptions = newOptions
						.filter(el => {
							return JSON.stringify(el) !== "[]"
						})
						.flat()
				}

				setOptions(newOptions)
			}
		})

		return () => {
			active = false
		}
	}, [inputValue, fetch])

	// Grab new matches
	useEffect(() => {
		if (madeChange) {
			setMadeChange(false)
			setCanSendTagEvent(true)

			dispatch(jobsActions.removeJobsAction())
			dispatch(
				jobsActions.asyncGetJobsAction({
					page: 1,
					filteringOptions: jobsReducer.filteringOptions,
				})
			)
		}
	}, [madeChange, dispatch, jobsReducer.filteringOptions])

	const handleClick = event => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
		setInputValue("")
	}

	// Determine predefined options
	useEffect(() => {
		if (jobsReducer.filteringOptions.locations.length > 0) {
			const filteredOptions = [
				...new Set([
					...jobsReducer.filteringOptions.locations.map(
						location => location.location_name
					),
				]),
			]
			const newPredefinedOptions = ["Deutschlandweit", ...filteredOptions]

			setPredefinedOptions(newPredefinedOptions)
		}
	}, [jobsReducer.filteringOptions.locations])

	// Send tag event after location filter change
	useEffect(() => {
		if (canSendTagEvent) {
			setCanSendTagEvent(false)

			dispatch(
				tagManagerActions.sendComplexCustomEvent({
					event: "locationFilterChipChanged",
					isUserLoggedIn: authReducer?.userLoggedIn
						? "userLoggedIn"
						: "userLoggedOut",
					filterLocation:
						jobsReducer?.filteringOptions?.locations?.length > 0
							? jobsReducer.filteringOptions.locations
									.map(location => location.location_name)
									.join(", ")
							: "Deutschlandweit",
					zohoCandidateId: authReducer?.userLoggedIn
						? profileReducer.changes.zoho_candidate_id
						: "userLoggedOut",
				})
			)
		}
	}, [
		canSendTagEvent,
		authReducer.userLoggedIn,
		profileReducer.changes,
		jobsReducer.filteringOptions.locations,
		dispatch,
	])

	const open = Boolean(anchorEl)
	const id = open ? "simple-popover" : undefined

	// helper function to check whether a string is in the locations array
	function isLocationNameInArray(locationsArray, searchString) {
		return locationsArray.some(
			location => location.location_name === searchString
		)
	}

	return (
		<div className={styles.locationFilterContainer}>
			<Chip
				size="small"
				icon={
					<img
						alt="location"
						src={jobListIconLocation}
						className={styles.jobPillDataIcon}
					/>
				}
				className={styles.filteringChip}
				label={
					<p>
						{jobsReducer.filteringOptions.locations.length > 0
							? `Ort: ${locationFilterString}`
							: `Ort: Deutschlandweit`}
					</p>
				}
				clickable
				onClick={handleClick}
				color="primary"
			/>

			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 40,
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				classes={{
					paper: mediaQuerySmUp ? styles.paper : styles.paperMobile,
				}}
			>
				<div
					className={
						mediaQuerySmUp ? styles.popover : styles.popoverMobile
					}
				>
					<TextField
						label={"Suche nach Orten"}
						value={inputValue}
						className={styles.locationInputField}
						placeholder="Berlin"
						onChange={e => setInputValue(e.target.value)}
						onKeyDown={e => {
							if (e.key === "Enter") {
								e.target.blur()
							}
						}}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon className={styles.searchIcon} />
								</InputAdornment>
							),
							classes: { input: styles.input },
						}}
					/>
					{inputValue === "" && (
						<List className={styles.optionList}>
							{predefinedOptions.map(option => {
								if (
									jobsReducer.filteringOptions.locations
										.length === 0 &&
									option === "Deutschlandweit"
								) {
									return (
										<ListItem
											className={styles.optionSelected}
											key={option}
										>
											{option}
											<ListItemSecondaryAction>
												<CheckIcon
													className={styles.checkIcon}
												/>
											</ListItemSecondaryAction>
										</ListItem>
									)
								} else
									return (
										<ListItem
											className={
												jobsReducer.filteringOptions.locations.includes(
													option
												)
													? styles.optionSelected
													: styles.option
											}
											key={option}
											onClick={() => {
												setMadeChange(true)

												if (
													option === "Deutschlandweit"
												) {
													dispatch(
														jobsActions.removeAllDesiredLocationsFilterAction()
													)
												} else if (
													isLocationNameInArray(
														jobsReducer
															.filteringOptions
															.locations,
														option
													)
												) {
													dispatch(
														jobsActions.removeDesiredLocationByCityFilterAction(
															option
														)
													)
												} else if (
													!isLocationNameInArray(
														jobsReducer
															.filteringOptions
															.locations,
														option
													)
												) {
													handlePlaceSelectWithLocationName(
														option
													)
														.then(result => {
															if (result) {
																const {
																	lat,
																	lng,
																} = result

																dispatch(
																	jobsActions.setDesiredLocationFilterAction(
																		option,
																		jobsReducer
																			.filteringOptions
																			.locations
																			.length,
																		lat,
																		lng
																	)
																)
															} else {
																console.error(
																	"Failed to get lat and lng"
																)
															}
														})
														.catch(error => {
															console.error(
																"Error in handlePlaceSelect:",
																error
															)
														})
												}
											}}
										>
											{option}
											{isLocationNameInArray(
												jobsReducer.filteringOptions
													.locations,
												option
											) && (
												<ListItemSecondaryAction>
													<CheckIcon
														className={
															styles.checkIcon
														}
													/>
												</ListItemSecondaryAction>
											)}
										</ListItem>
									)
							})}
						</List>
					)}
					{inputValue !== "" && (
						<List>
							{options.map(option => (
								<ListItem
									key={option.description}
									onClick={() => {
										setInputValue("")

										if (option) {
											handlePlaceSelect(option)
												.then(result => {
													if (result) {
														const { lat, lng } =
															result

														dispatch(
															jobsActions.setDesiredLocationFilterAction(
																option.description
																	.replace(
																		/\b\d{5}\b/g,
																		""
																	)
																	.trim(),
																jobsReducer
																	.filteringOptions
																	.locations
																	.length,

																lat,
																lng
															)
														)

														setMadeChange(true)
													} else {
														console.error(
															"Failed to get lat and lng"
														)
													}
												})
												.catch(error => {
													console.error(
														"Error in handlePlaceSelect:",
														error
													)
												})
										}
									}}
								>
									{option.description}
								</ListItem>
							))}
						</List>
					)}
				</div>
			</Popover>
		</div>
	)
}

export default LocationFilterChip
