import * as actions from "./authActions"

export const initialState = {
	userLoggedIn: null,
	email: null,
	ab_test: null,
}

export default function authReducer(state = initialState, action) {
	switch (action.type) {
		case actions.SET_USER_LOGGED_IN:
			return {
				...state,
				userLoggedIn: true,
				email: action.email,
			}
		case actions.SET_USER_LOGGED_OUT:
			return {
				...state,
				userLoggedIn: false,
				email: "",
			}
		case actions.SET_AB_TEST:
			return {
				...state,
				ab_test: action.config,
			}
		default:
			return state
	}
}
