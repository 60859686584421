import React, { useEffect } from "react"

// Material UI
import Grid from "@material-ui/core/Grid"
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from "@material-ui/pickers"
import { makeStyles } from "@material-ui/core/styles"

// Date Components
import "date-fns"
import { de } from "date-fns/locale"
import DateFnsUtils from "@date-io/date-fns"

// Form Components
import standardProfileFieldNames from "../dataStructures/standardProfileFieldNames"

// Redux
import { useDispatch, useSelector } from "react-redux"
import * as profileActions from "../state/profileActions"

// Styles
const useStyles = makeStyles({
	basicDataGridItem: {
		marginLeft: 16,
		marginRight: 16,
		marginBottom: 8,
		"& fieldset": {
			borderRadius: 12,
		},
	},
	input: {
		width: "100%",
	},
})

// Component
const DatePickerField = props => {
	// Hooks
	const dispatch = useDispatch()
	const styles = useStyles()

	// Redux selectors
	const profileReducer = useSelector(state => state.profileReducer)

	// Destructuring props
	const { fieldName } = props

	// Send the current date
	useEffect(() => {
		dispatch(
			profileActions.changeProfileFieldAction(
				"education_finish_date",
				new Date(),
				"profileData"
			)
		)
		// eslint-disable-next-line
	}, [])

	// Render
	return (
		<Grid item xs={12} className={styles.basicDataGridItem}>
			<MuiPickersUtilsProvider utils={DateFnsUtils} locale={de}>
				<KeyboardDatePicker
					inputProps={{
						style: {
							WebkitBoxShadow: "0 0 0 1000px white inset",
							borderRadius: 12,
						},
					}}
					className={styles.input}
					inputVariant="outlined"
					okLabel="Ok"
					clearLabel="Clear"
					error={!profileReducer.profileData.education_finish_date}
					value={profileReducer.profileData.education_finish_date}
					invalidDateMessage=""
					cancelLabel="Cancel"
					id={`userInputField${fieldName}`}
					label={standardProfileFieldNames[fieldName]}
					format="dd/MM/yyyy"
					onChange={date => {
						// Sending value to reducer
						dispatch(
							profileActions.changeProfileFieldAction(
								fieldName,
								date,
								"profileData"
							)
						)
					}}
					KeyboardButtonProps={{
						"aria-label": "change date",
					}}
				/>
			</MuiPickersUtilsProvider>
		</Grid>
	)
}

export default DatePickerField
