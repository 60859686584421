import React, { useEffect, useState } from "react"

// Components
import LoginElement from "./LoginElement"

// SEO
import { Helmet } from "react-helmet"
import generalSeoSchema from "../generalSeoSchema"

// Redux
import { useDispatch, useSelector } from "react-redux"
import * as profileActions from "../profile/state/profileActions"

// Material UI
import { makeStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"

// Material UI icons
import EmailIcon from "@material-ui/icons/Email"

// Styles
const useStyles = makeStyles(theme => ({
	authContainer: {
		marginTop: 100,
		maxWidth: 600,
	},
	authPaper: {
		borderRadius: 12,
	},
	authHeight: {
		position: "relative",
		display: "flex",
		flexGrow: 1,
	},
	emailIconGridItem: {
		display: "flex",
		justifyContent: "center",
		marginTop: 10,
	},
	emailSentContainer: {
		marginTop: 20,
		borderRadius: 12,
	},
	emailSentText: {
		padding: 12,
		margin: 0,
	},
}))

// Component
const AuthPage = () => {
	// Hooks
	const dispatch = useDispatch()
	const styles = useStyles()

	// Redux
	const authReducer = useSelector(state => state.authReducer)

	// Local state
	const [showEmailSentInfo, setShowEmailSentInfo] = useState(false)

	// Redirecting to profile if already logged in
	useEffect(() => {
		if (authReducer?.userLoggedIn && authReducer?.email) {
			dispatch(profileActions.asyncDetermineUserType(authReducer.email))
		}
	}, [authReducer, dispatch])

	// Render
	return (
		<div className={styles.authHeight}>
			{/* SEO */}
			<Helmet>
				<title>Workbee</title>
				<meta name="title" content="Workbee" />
				<meta name="description" content="App Login" />
				<script type="application/ld+json">
					{JSON.stringify(generalSeoSchema)}
				</script>
			</Helmet>

			{/* Login */}
			<Container className={styles.authContainer}>
				<Paper elevation={2} className={styles.authPaper}>
					<LoginElement
						showRegisterText={true}
						setShowEmailSentInfo={setShowEmailSentInfo}
					/>
				</Paper>
				{showEmailSentInfo && (
					<Paper elevation={2} className={styles.emailSentContainer}>
						<Grid container spacing={1}>
							<Grid
								item
								xs={12}
								className={styles.emailIconGridItem}
							>
								<EmailIcon />
							</Grid>
							<Grid item xs={12}>
								<p className={styles.emailSentText}>
									Wir haben dir eine E-Mail geschickt. Öffne
									sie und klicke auf den Link, um dich
									einzuloggen.
								</p>
							</Grid>
						</Grid>
					</Paper>
				)}
			</Container>
		</div>
	)
}

export default AuthPage
