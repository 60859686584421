import { useEffect, useState } from "react"

// Env
//import { env } from "../environment"

// Firebase
import { onAuthStateChanged, getAuth } from "firebase/auth"
//import { fetchAndActivate, getValue } from "firebase/remote-config"
//import { firebaseAuth, remoteConfig } from "../firebase"
import { firebaseAuth } from "../firebase"

// Field options
import fieldOptions from "../jobs/components/jobsListPage/jobFilterModal/fieldOptions"
import {
	firstVertical,
	secondVertical,
} from "../jobs/components/jobsListPage/jobFilterModal/fieldOptions"

// Redux
import { useDispatch, useSelector } from "react-redux"
import * as authActions from "../auth/state/authActions"
import * as jobsActions from "../jobs/state/jobsActions"
import * as profileActions from "../profile/state/profileActions"

// Component
const AuthState = () => {
	// Hooks
	const dispatch = useDispatch()

	// Local state
	const [emailVerified, setEmailVerified] = useState(false)
	const [triggerEmailVerificationCheck, setTriggerEmailVerificationCheck] =
		useState(false)
	const [updateEmailStatus, setUpdateEmailStatus] = useState(true)

	// Redux selectors
	const authReducer = useSelector(state => state.authReducer)
	const profileReducer = useSelector(state => state.profileReducer)

	// Setting the auth status for the user
	useEffect(() => {
		let mounted = true
		onAuthStateChanged(firebaseAuth, user => {
			if (user && mounted) {
				if (user.emailVerified) {
					setEmailVerified(true)
				} else {
					setTriggerEmailVerificationCheck(true)
				}
				dispatch(authActions.setUserLoggedInAction(user.email))
			} else if (mounted) {
				dispatch(authActions.setUserLoggedOutAction())
			}
		})

		return () => {
			mounted = false
		}
	}, [dispatch])

	// Check if email has been verified
	useEffect(() => {
		if (triggerEmailVerificationCheck) {
			setTriggerEmailVerificationCheck(false)

			setTimeout(() => {
				const auth = getAuth()
				const user = auth.currentUser

				if (user.emailVerified) {
					setEmailVerified(true)
				}
			}, 4000)
		}
	}, [triggerEmailVerificationCheck])

	// Setting the email as verified after first login with magic link
	useEffect(() => {
		if (
			updateEmailStatus &&
			emailVerified &&
			profileReducer?.changes?.profileDataLoaded &&
			(profileReducer?.changes?.emailVerified === false ||
				profileReducer?.changes?.emailVerified === "False")
		) {
			setUpdateEmailStatus(false)
			if (profileReducer?.changes?.userType === "candidate") {
				dispatch(
					profileActions.asyncPutProfileDataAction({
						verifyEmail: true,
						refreshJobs: false,
					})
				)
			} else if (profileReducer?.changes?.userType === "affiliate") {
				dispatch(
					profileActions.asyncPutPromoterProfileDataAction({
						verifyEmail: true,
						refreshJobs: false,
					})
				)
			}
		}
	}, [
		emailVerified,
		profileReducer.changes,
		authReducer.userType,
		updateEmailStatus,
		dispatch,
	])

	// Retrieving filtering preferences
	useEffect(() => {
		if (
			window?.location?.href?.includes("qualifications") ||
			window?.location?.href?.includes("locations") ||
			window?.location?.href?.includes("vertical")
		) {
			const queryString = window.location.search
			const params = new URLSearchParams(queryString)
			const qualificationsParameterValue = params.get("qualifications")
			const locationsParameterValue = params.get("locations")
			const verticalParameterValue = params.get("vertical")
			let verticalQualiArray = []
			let verticalName = ""

			if (
				verticalParameterValue === "1" ||
				verticalParameterValue === "2"
			) {
				verticalQualiArray =
					verticalParameterValue === "1"
						? firstVertical
						: secondVertical
				verticalName =
					verticalParameterValue === "1"
						? "Pflege & Medizin"
						: "Pädagogik & Soziale Arbeit"
			}

			if (
				qualificationsParameterValue ||
				locationsParameterValue ||
				verticalParameterValue
			) {
				dispatch(
					jobsActions.setFilteringOptionsAction({
						contract_types: [],
						employment_forms: [],
						shifts: [],
						departments: [],
						type_of_employer: [],
						qualifications: verticalParameterValue
							? verticalQualiArray
							: qualificationsParameterValue
							? qualificationsParameterValue.split(",")
							: [],
						benefits: [],
						locations: locationsParameterValue
							? locationsParameterValue.split(",").map(city => {
									return {
										location_name: city.trim(),
										coordinates: {},
									}
							  })
							: [],
						work_field: verticalName ? verticalName : "",
						salary_expectation_month: "",
					})
				)
			}
		} else if (
			authReducer.userLoggedIn ||
			authReducer.userLoggedIn === false
		) {
			if (authReducer.userLoggedIn) {
				if (profileReducer.changes.profileDataLoaded) {
					dispatch(
						jobsActions.setFilteringOptionsAction({
							contract_types: [],
							employment_forms: [],
							shifts: [],
							departments: [],
							type_of_employer: [],
							qualifications: [],
							benefits: [],
							locations: profileReducer.profileData.locations,
							work_field: fieldOptions.qualification.includes(
								profileReducer.profileData.qualification
							)
								? "Pflege & Medizin"
								: "Pädagogik & Soziale Arbeit",
							salary_expectation_month: "",
						})
					)
				}
			}
		}
		// eslint-disable-next-line
	}, [
		window.location,
		authReducer.userLoggedIn,
		profileReducer.changes.profileDataLoaded,
		dispatch,
	])

	return null
}

export default AuthState
