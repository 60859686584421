const generalSeoSchema = {
  "@context": "https://schema.org",
  "@type": "Organization",
  name: "Workbee",
  alternateName: "Workbee - Bessere Jobs in der Pflege",
  url: "https://app.workbee.de",
  logo: "https://app.workbee.de/static/media/logo.10ea5e7c.png",
  sameAs: [
    "https://www.facebook.com/workbee.de/",
    "https://www.instagram.com/workbee.de/",
    "https://www.linkedin.com/company/workbee-germany/about/",
  ],
}

export default generalSeoSchema
