import React from "react"

// Material UI
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"

// Redux
import { useSelector } from "react-redux"

// Styles
const useStyles = makeStyles({
	companyHeaderGridContainer: {
		marginTop: 16,
		marginBottom: 32,
		display: "flex",
	},
	imageGridItem: {
		paddingRight: 16,
		display: "flex",
		alignItems: "center",
	},
	imageContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: 64,
		height: 64,
		borderRadius: 32,
		backgroundColor: "white",
		border: "0.8px solid #e8e8e8",
	},
	gridItem: {
		display: "flex",
		alignItems: "center",
	},
	companyLogo: {
		width: "80%",
		height: "auto",
	},
	jobTitle: {
		fontSize: 14,
		fontWeight: 600,
	},
})

// Component
const CompanyHeader = props => {
	// Hooks
	const styles = useStyles()

	// Destructuring props
	const { companyLogo } = props

	// Redux selectors
	const jobsReducer = useSelector(state => state.jobsReducer)

	// Render
	return (
		<Grid container className={styles.companyHeaderGridContainer}>
			{companyLogo && (
				<Grid item xs="auto" className={styles.imageGridItem}>
					<div className={styles.imageContainer}>
						<img
							src={companyLogo}
							alt="company logo"
							className={styles.companyLogo}
						/>
					</div>
				</Grid>
			)}
			{jobsReducer?.job?.job_title && (
				<Grid item xs className={styles.gridItem}>
					<p className={styles.jobTitle}>
						{jobsReducer?.job?.job_title_app
							? jobsReducer?.job?.job_title_app
							: jobsReducer.job.job_title}
					</p>
				</Grid>
			)}
		</Grid>
	)
}

export default CompanyHeader
