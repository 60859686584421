const fieldOptions = {
	qualification: [
		"Exam. Gesundheits- und Krankenpfleger/in",
		"Exam. Altenpfleger/in",
		"Pflegehilfskraft/-assistenz",
		"Pflegefachfrau/-mann",
		"Exam. Gesundheits- und Kinderkrankenpfleger/in",
		"Noch in Ausbildung",
		"Anästhesietechnische Assistenz",
		"Ergotherapeut/in",
		"Hebamme / Entbindungshelfer/in",
		"Kunsttherapeut/in",
		"Logopäde/in",
		"Medizinische/r Fachangestellte/r",
		"Medizinisch-technische Assistenz",
		"Medizinisch-technische Radiologieassistenz",
		"Operationstechnische Assistenz",
		"Pflegedienstleiter/in",
		"Physiotherapeut/in",
		"Rettungsdienst",
		"Stationsleiter/in",
		"Wohnbereichsleiter/in",
		"Zahnmedizinische/r Fachangestellte/r",
		"Weiteres",
	],
	salary_expectation_month: [
		"1600 €",
		"2000 €",
		"2400 €",
		"2600 €",
		"2800 €",
		"3200 €",
		"3600 €",
		"4000 €",
	],
	contract_type: ["Festanstellung", "Zeitarbeit", "Freiberuflich"],
	employment_form: ["Vollzeit", "Teilzeit", "Minijob"],
	shifts: [
		"Frühschicht",
		"Spätschicht",
		"Nachtschicht",
		"Wochenende",
		"Feiertag",
		"Tagdienst",
		"Bereitschaftsdienst",
		"Rufdienst",
		"Nachtbereitschaft",
	],
	departments: [
		"Allgemein- und Visceralchirurgie",
		"Ambulante Pflege",
		"Angiologie",
		"Anästhesie",
		"Augenheilkunde",
		"Außerklinische Beatmung",
		"Behindertenhilfe",
		"Brustzentrum",
		"Chirurgie",
		"Dermatologie",
		"Dialyse",
		"Endoskopie",
		"Ergotherapie",
		"Flex-Pool/Mitarbeiter-Pool",
		"Funktionsdienst",
		"Gastroenterologie",
		"Geburtshilfe",
		"Geriatrie",
		"Gynäkologie",
		"Hals-Nasen-Ohren-Heilkunde",
		"Herz-, Gefäß- und Thoraxchirurgie",
		"IMC",
		"Infektiologie",
		"Innere Medizin",
		"Intensivmedizin",
		"Interdisziplinäre Überwachungsstation",
		"Kardiologie",
		"Kinderkrankenpflege",
		"Komfort-/Privatstation",
		"Nephrologie",
		"Neurologie",
		"Notaufnahme",
		"Notfallmedizin",
		"Onkologie",
		"OP",
		"Orthopädie",
		"Palliativmedizin",
		"Phlebologie",
		"Physiotherapie",
		"Pneumologie",
		"Psychiatrie",
		"Psychosomatik",
		"Pädiatrie",
		"Radiologie",
		"Reha",
		"Rettungsdienst",
		"Stroke Unit",
		"Traumatologie",
		"Unfallchirurgie",
		"Urologie",
		"Zahnmedizin",
		"Weiteres",
	],
	type_of_employer: [
		"1:1-Pflege",
		"Ambulanter Pflegedienst",
		"Arztpraxis / MVZ",
		"Behindertenhilfe",
		"Ergotherapiepraxis",
		"Homecare",
		"Kinderkrankenhäuser",
		"Krankenhaus / Klinik",
		"Pflege-Wohngemeinschaft",
		"Pflegeheim",
		"Physiotherapiepraxis",
		"Rettungsdienst",
		"Sozialdienstleister",
		"Tagespflege",
		"Zeitarbeit",
	],
	benefits: [
		"13. Monatsgehalt / Urlaubs- und Weihnachtsgeld",
		"30+ Urlaubstage",
		"Arbeiten im Tagdienst / Elternschichten",
		"Arbeiten ohne Zeitdruck",
		"Betriebliche Altersvorsorge / Vermögenswirksame Leistungen",
		"Dienstwagen",
		"Einspringprämie / Schichtzuschläge",
		"Firmenfitnesstraining",
		"Flexible Arbeitszeiten",
		"Hilfe bei Wohnungssuche / Vergünstigste Wohnungen",
		"Hohes Gehalt",
		"Jobticket",
		"Kita auf dem Betriebsgelände / Kitazuschuss",
		"Kostenfreie Mitarbeiterparkplätze",
		"Kurze Pendelzeit",
		"Mitarbeiterrabatte",
		"Überstundenbegrenzung",
		"Unbefristeter Vertrag",
		"Weiterbildungsangebote / Aufstiegsmöglichkeiten",
		"Willkommensprämie",
		"Zusatzkrankenversicherung",
	],
	qualification_educator: [
		"Erzieher/in",
		"Sozialpädagogische Assistenz / Kinderpfleger/in",
		"Heilerziehungspfleger/in",
		"Heilpädagoge/in",
		"Kinderpfleger/in",
		"Kunsttherapeut/in",
		"Sozialassistenz",
		"Sozialpädagog/in",
		"Noch in Ausbildung",
		"Erzieher für Jugend und Heimerziehung",
		"Kinderbetreuer",
		"Heilerziehungspflegehelfer/in",
		"Sozialbetreuer/in",
		"Haus- und Familienpfleger/in",
		"Arbeitserzieher/in",
		"Weiteres",
	],
	type_of_employer_educator: [
		"Ambulanter Pflegedienst",
		"Arztpraxis / MVZ",
		"Beratungsstellen",
		"Hochschulen & Öffentliche Verwaltung",
		"Kinder- und Jugendheime",
		"Kinderkrankenhäuser",
		"Kitas, Kindergärten und Horte",
		"Pflegeheim",
		"Schulen",
		"Sozialdienstleister",
		"Streetworker",
		"Werkstätten für Menschen mit Behinderungen",
		"Wohnheime für Menschen mit Behinderungen",
		"Zeitarbeit",
	],
	ongoing_education: [
		"Anästhesietechnische Assistenz",
		"Exam. Altenpfleger/in",
		"Exam. Gesundheits - und Kinderkrankenpfleger/in",
		"Medizinisch-technische Assistenz",
		"Exam. Gesundheits - und Krankenpfleger/in",
		"Hebamme / Entbindungshelfer/in",
		"Medizinische/r Fachangestellte/r",
		"Operationstechnische Assistenz",
		"Pflegefachfrau/-mann",
		"Weiteres",
	],
	ongoing_education_educator: [
		"Erzieher/in",
		"Sozialpädagogische Assistenz / Kinderpfleger/in",
		"Heilerziehungspfleger/in",
		"Heilpädagoge/in",
		"Kinderpfleger/in",
		"Sozialassistenz",
		"Erzieher/in mit Schwerpunkt Jugend und Heimerziehung",
		"Kurs zur Tagesmutter/Tagesvater",
		"Heilerziehungspflegehelfer/in",
		"Sozialbetreuer/in",
		"Haus- und Familienpfleger/in",
		"Arbeitserzieher/in",
		"Weiteres",
	],
	occupational_group: [
		"Anästhesietechnische Assistenz",
		"Ergotherapeut/in",
		"Hebamme / Entbindungshelfer/in",
		"Kunsttherapeut/in",
		"Medizinische/r Fachangestellte/r",
		"Medizinisch-technische Assistenz",
		"Medizinisch-technische Radiologieassistenz",
		"Operationstechnische Assistenz",
		"Pflegedienstleiter/in",
		"Physiotherapeut/in",
		"Rettungsdienst",
		"Stationsleiter/in",
		"Wohnbereichsleiter/in",
		"Weiteres",
	],
	occupational_group_educator: [
		"Erzieher für Jugend und Heimerziehung",
		"Kinderbetreuer",
		"Heilerziehungspflegehelfer/in",
		"Kunsttherapeut/in",
		"Sozialbetreuer/in",
		"Haus- und Familienpfleger/in",
		"Arbeitserzieher/in",
		"Weiteres",
	],
	german_level: [
		"Muttersprache",
		"Grundkenntnisse (A1, A2)",
		"Gut (B1, B2)",
		"Sehr gut (C1, C2)",
	],
	education_recognized: [
		"Ja, ist anerkannt",
		"Nein, ist nicht anerkannt",
		"Nein, aber im Anerkennungsverfahren",
	],
	total_work_experience: [
		"Noch keine",
		"Bis 1 Jahr",
		"Bis 2 Jahre",
		"Bis 3 Jahre",
		"Bis 4 Jahre",
		"Bis 5 Jahre",
		"Bis 6 Jahre",
		"Bis 7 Jahre",
		"Bis 8 Jahre",
		"Bis 9 Jahre",
		"Bis 10 Jahre",
		"Bis 11 Jahre",
		"Bis 12 Jahre",
		"Bis 13 Jahre",
		"Bis 14 Jahre",
		"Bis 15 Jahre",
		"Bis 16 Jahre",
		"Bis 17 Jahre",
		"Bis 18 Jahre",
		"Bis 19 Jahre",
		"Bis 20 Jahre",
		"Mehr als 20 Jahre",
	],
	work_experience: [
		"Unter 1 Jahr",
		"Mehr als 1 Jahr",
		"Mehr als 2 Jahre",
		"Mehr als 3 Jahre",
		"Mehr als 4 Jahre",
		"Mehr als 5 Jahre",
		"Mehr als 6 Jahre",
		"Mehr als 7 Jahre",
		"Mehr als 8 Jahre",
		"Mehr als 9 Jahre",
		"Mehr als 10 Jahre",
		"Mehr als 11 Jahre",
		"Mehr als 12 Jahre",
		"Mehr als 13 Jahre",
		"Mehr als 14 Jahre",
		"Mehr als 15 Jahre",
		"Mehr als 16 Jahre",
		"Mehr als 17 Jahre",
		"Mehr als 18 Jahre",
		"Mehr als 19 Jahre",
		"Mehr als 20 Jahre",
	],
	qualification_hilfskraft: [
		"Quereinsteiger",
		"Pflegebasiskurs",
		"LG1 / LG2",
		"einjährig examiniert",
		"zweijährig examiniert",
		"Betreuungsassistent §§ 43b, 53c SGB XI",
		"Weiteres",
	],
}

export const firstVertical = [...fieldOptions.qualification].filter(el => {
	return el !== "Weiteres" && el !== "Noch in Ausbildung"
})
export const secondVertical = [...fieldOptions.qualification_educator].filter(
	el => {
		return el !== "Weiteres" && el !== "Noch in Ausbildung"
	}
)

export default fieldOptions
