import React, { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"

// Images
import logoNoText from "../images/logoNoText.png"
import applicationIcon from "../images/applicationIcon.png"

// Material UI
import { makeStyles } from "@material-ui/core/styles"
import {
	Toolbar,
	List,
	Divider,
	ListItem,
	ListItemIcon,
	ListItemText,
	Hidden,
	Grid,
	IconButton,
	Link,
} from "@material-ui/core"

// Material UI Icons
import FacebookIcon from "@material-ui/icons/Facebook"
import InstagramIcon from "@material-ui/icons/Instagram"
import PersonIcon from "@material-ui/icons/Person"
import SettingsIcon from "@material-ui/icons/Settings"
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter"
import LinkedInIcon from "@material-ui/icons/LinkedIn"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"

// Redux
import { useDispatch, useSelector } from "react-redux"
import * as profileActions from "../profile/state/profileActions"
import * as clickFunnelActions from "../clickFunnel/state/clickFunnelActions"
import * as jobsActions from "../jobs/state/jobsActions"

// Firebase
import { firebaseAuth } from "../firebase"
import { signOut } from "firebase/auth"

// Styles
const useStyles = makeStyles(theme => ({
	drawerContainer: {
		display: "flex",
		flex: 1,
		flexDirection: "column",
		fontFamily: "'Nunito', sans-serif",
	},
	fillerDiv: {
		display: "flex",
		flexGrow: 1,
	},
	toolbar: {
		display: "flex",
		justifyContent: "center",
	},
	logo: {
		width: 170,
		marginTop: 10,
		marginBottom: 8,
	},
	navLinkItem: {
		fontFamily: "'Nunito', sans-serif",
	},
	workbeeLinksText: {
		fontSize: 12,
	},
	socialContainer: {
		height: 62,
	},
	socialIcon: {
		width: 35,
		height: 35,
	},
	applicationIconSize: {
		width: 24,
		height: 24,
	},
	list: {
		paddingTop: 0,
	},
}))

// Component
const DrawerData = props => {
	// Hooks
	const styles = useStyles()
	const history = useHistory()
	const location = useLocation()
	const dispatch = useDispatch()

	// Destructuring props
	const { setMobileOpen } = props

	// Redux selectors
	const authReducer = useSelector(state => state.authReducer)
	const jobsReducer = useSelector(state => state.jobsReducer)

	// Route Change
	const routeChange = path => {
		dispatch(clickFunnelActions.clearReducerAction())
		history.push(path)
	}

	// Local state
	const [selectedIndex, setSelectedIndex] = useState(null)
	const [menuItems, setMenuItems] = useState([
		"Jobs",
		"Bewerbungen",
		"Profil",
		"Einstellungen",
	])

	// Effects
	useEffect(() => {
		if (
			location.pathname === "/jobs" ||
			(location.pathname.length > 14 &&
				location.pathname.includes("jobs"))
		) {
			setSelectedIndex(0)
		} else if (location.pathname === "/bewerbungen") {
			setSelectedIndex(1)
		} else if (location.pathname === "/profile") {
			setSelectedIndex(2)
		} else if (location.pathname === "/einstellungen") {
			setSelectedIndex(3)
		} else if (location.pathname === "/clients") {
			setSelectedIndex(null)
		}
	}, [location])

	// Listen for location changes
	useEffect(() => {
		return history.listen(location => {
			if (
				history.location.pathname === "/profile" ||
				history.location.pathname.includes("form")
			) {
				setSelectedIndex(1)
			} else if (history.location.pathname.includes("/jobs")) {
				setSelectedIndex(0)
			} else {
				setSelectedIndex(null)
			}
		})
	}, [history])

	// Set menu options based on auth state
	useEffect(() => {
		if (authReducer?.userLoggedIn) {
			setMenuItems(["Jobs", "Bewerbungen", "Profil", "Einstellungen"])
		} else {
			setMenuItems(["Jobs"])
		}
	}, [authReducer.userLoggedIn])

	// Render
	return (
		<>
			<Toolbar className={styles.toolbar}>
				<Hidden mdUp>
					<img src={logoNoText} className={styles.logo} alt="" />
				</Hidden>
			</Toolbar>

			<div className={styles.drawerContainer}>
				{/* Main nav items */}

				<List className={styles.list}>
					{menuItems.map((currentLocation, index) => {
						return (
							<ListItem
								selected={selectedIndex === index}
								button
								key={currentLocation}
								onClick={() => {
									dispatch(
										profileActions.setRecruiterAccordion(
											false,
											null
										)
									)

									let urls = [
										"jobs",
										"bewerbungen",
										"profile",
										"einstellungen",
										"clients",
									]
									routeChange(`/${urls[index]}`)
									setSelectedIndex(index)

									setMobileOpen(false)
								}}
							>
								<ListItemIcon>
									{index === 0 ? (
										<BusinessCenterIcon />
									) : index === 1 ? (
										<img
											src={applicationIcon}
											className={
												styles.applicationIconSize
											}
											alt=""
										/>
									) : index === 2 ? (
										<PersonIcon />
									) : (
										<SettingsIcon />
									)}
								</ListItemIcon>
								<ListItemText
									primary={currentLocation}
									classes={{
										primary: styles.navLinkItem,
									}}
								/>
							</ListItem>
						)
					})}
					{/* Log out btn for logged in users */}
					{authReducer?.userLoggedIn && (
						<ListItem
							button
							key={4}
							onClick={() => {
								signOut(firebaseAuth).then(() => {
									dispatch(
										profileActions.logoutCleanDataAction()
									)

									dispatch(jobsActions.removeJobsAction())
									dispatch(
										jobsActions.asyncGetJobsAction({
											page: 1,
											filteringOptions:
												jobsReducer.filteringOptions,
										})
									)
									history.push("/")
									setMobileOpen(false)
								})
							}}
						>
							<ListItemIcon>
								<ExitToAppIcon />
							</ListItemIcon>
							<ListItemText
								id="logoutBtn"
								primary={"Abmelden"}
								classes={{
									primary: styles.navLinkItem,
								}}
							/>
						</ListItem>
					)}
					{/* Register btn for logged out users */}
					{!authReducer?.userLoggedIn && (
						<>
							<ListItem
								selected={selectedIndex === 1}
								button
								key={1}
								onClick={() => {
									history.push("/form")
									setMobileOpen(false)
								}}
							>
								<ListItemIcon>
									<ExitToAppIcon />
								</ListItemIcon>
								<ListItemText
									id="registerBtn"
									primary={"Registrieren"}
									classes={{
										primary: styles.navLinkItem,
									}}
								/>
							</ListItem>
							<ListItem
								selected={selectedIndex === 2}
								button
								key={2}
								onClick={() => {
									history.push("/einstellungen")
									setMobileOpen(false)
								}}
							>
								<ListItemIcon>
									<SettingsIcon />
								</ListItemIcon>
								<ListItemText
									id="settingsBtn"
									primary={"Einstellungen"}
									classes={{
										primary: styles.navLinkItem,
									}}
								/>
							</ListItem>
							<ListItem
								button
								key={3}
								onClick={() => {
									history.push("/login")
									setMobileOpen(false)
								}}
							>
								<ListItemIcon>
									<PersonIcon />
								</ListItemIcon>
								<ListItemText
									id="loginBtn"
									primary={"Anmelden"}
									classes={{
										primary: styles.navLinkItem,
									}}
								/>
							</ListItem>
						</>
					)}
				</List>
				<Divider />

				{/* Filler Div */}

				<div className={styles.fillerDiv}></div>

				{/* Social Media Links */}

				<Divider />
				<Grid
					container
					justifyContent="space-evenly"
					className={styles.socialContainer}
				>
					<Grid item>
						<Link
							href="https://de-de.facebook.com/workbee.de"
							target="_blank"
						>
							<IconButton>
								<FacebookIcon
									color="primary"
									className={styles.socialIcon}
								/>
							</IconButton>
						</Link>
					</Grid>
					<Grid item>
						<Link
							href="https://www.linkedin.com/company/workbee-germany"
							target="_blank"
						>
							<IconButton>
								<LinkedInIcon
									color="primary"
									className={styles.socialIcon}
								/>
							</IconButton>
						</Link>
					</Grid>
					<Grid item>
						<Link
							href="https://www.instagram.com/workbee.de/"
							target="_blank"
						>
							<IconButton>
								<InstagramIcon
									color="primary"
									className={styles.socialIcon}
								/>
							</IconButton>
						</Link>
					</Grid>
				</Grid>
			</div>
		</>
	)
}

export default DrawerData
