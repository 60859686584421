import React, { useState } from "react"
import { Link } from "react-router-dom"

// Redux
import { useSelector, useDispatch } from "react-redux"
import * as jobsActions from "../../state/jobsActions"
import * as tagManagerActions from "../../../tagManagerEvents/state/tagManagerActions"

// Components
import JobCardBanners from "./JobCardBanners"
import JobCardPills from "./JobCardPills"
import JobShareBtn from "../jobPage/JobShareBtn"
import SalaryRibbon from "./SalaryRibbon"
import ApplicationBanners from "../applicationPage/ApplicationBanners"

// Material UI
import { makeStyles } from "@material-ui/core/styles"
import { Paper, Grid, Button, Divider } from "@material-ui/core"

// Material UI Icons
import CheckIcon from "@material-ui/icons/Check"

// Icons
import jobCardIconLocation from "../../../images/jobCardIcons/jobCardIconLocation.png"

// Styles
const useStyles = makeStyles({
	jobCardGridItem: {
		paddingTop: "4px !important",
		paddingBottom: "4px !important",
	},
	buttonGridItem: {
		maxWidth: 500,
		margin: "0 auto",
		paddingTop: "18px !important",
		paddingBottom: "48px !important",
	},
	loadMoreJobsButton: {
		display: "flex",
		borderRadius: 12,
		border: "2px solid #3a5dab",
		color: "#3a5dab",
		fontSize: 16,
		width: "100%",
		backgroundColor: "rgba(255, 255, 255, 0)",
		"&:hover": {
			border: "2px solid #3a5dab",
			color: "#3a5dab",
			backgroundColor: "rgba(255, 255, 255, 0)",
		},
	},
	jobOpening: {
		border: "solid 1px #e8e8e8",
		borderRadius: 12,
		position: "relative",
	},
	jobOpeningWithOpacity: {
		border: "solid 1px #e8e8e8;",
		borderRadius: 12,
		"&:last-child": {
			opacity: 0.4,
		},
	},
	link: {
		textDecoration: "none",
	},
	logoAndBannersContainer: {
		display: "flex",
		justifyContent: "space-between",
	},
	companyLogoWrapper: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: 72,
		height: 72,
		borderRadius: 36,
		marginTop: 16,
		marginLeft: 16,
		border: "solid 0.9px #e8e8e8",
	},
	companyLogo: {
		maxWidth: 50,
		maxHeight: 50,
	},
	bannersWrapper: {
		display: "flex",
		flexDirection: "column",
		overflow: "hidden",
		marginTop: 6,
		height: 70,
	},
	benefitBanner: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		position: "relative",
		marginBottom: 18,
		right: -5,
		top: 10,
		height: 24,
		borderTopLeftRadius: 21.5,
		borderBottomLeftRadius: 21.5,
		boxShadow: "0 0 3px 1px rgba(99, 192, 133, 0.5)",
		border: "solid 1px rgba(99, 192, 133, 0.5)",
		backgroundColor: "rgba(99, 192, 133, 0.1)",
	},
	driversLicenseBanner: {
		width: 130,
		display: "flex",
		alignItems: "center",
		alignSelf: "flex-end",
		justifyContent: "center",
		height: 24,
		borderTopLeftRadius: 21.5,
		borderBottomLeftRadius: 21.5,
		backgroundColor: "rgba(245, 168, 0, 0.17)",
	},
	driversLicenseBannerWithMargin: {
		width: 130,
		display: "flex",
		alignItems: "center",
		alignSelf: "flex-end",
		justifyContent: "center",
		height: 24,
		marginTop: 12,
		borderTopLeftRadius: 21.5,
		borderBottomLeftRadius: 21.5,
		backgroundColor: "rgba(245, 168, 0, 0.17)",
	},
	warningEmoji: {
		marginRight: 4,
		marginTop: 2,
	},
	flameEmoji: {
		fontSize: 9,
		marginLeft: 12,
		marginBottom: 0,
		marginRight: 16,
	},
	flameEmojiBig: {
		fontSize: 29,
		marginLeft: 4,
		marginBottom: 8,
		marginRight: 6,
	},
	bannerParagraph: {
		display: "flex",
		alignItems: "center",
		margin: 0,
		fontSize: 10,
		fontWeight: "bold",
	},
	bannerParagraphOneBenefit: {
		display: "flex",
		alignItems: "center",
		margin: 0,
		fontSize: 10,
		fontWeight: "bold",
		marginRight: 14,
		marginLeft: 10,
	},
	jobTitle: {
		fontSize: 18,
		fontWeight: "bold",
		paddingLeft: 16,
		paddingRight: 16,
		margin: 0,
		marginTop: 12,
	},
	companyName: {
		fontSize: 14,
		fontWeight: 600,
		paddingLeft: 16,
		margin: 0,
	},
	location: {
		display: "flex",
		alignItems: "center",
		fontSize: 14,
		fontWeight: 600,
		paddingLeft: 14,
		margin: 0,
		marginBottom: 16,
	},
	locationIcon: {
		width: 18,
	},
	shareIcon: {
		width: 25,
		marginRight: 5,
	},
	progressContainer: {
		justifyContent: "flex-start",
		alignItems: "center",
		display: "flex",
		width: 22,
	},
	progressParagraph: {
		margin: "5px 0",
	},
	progress: {
		height: "15px !important",
		width: "15px !important",
	},
	jobAppliedContainer: {
		paddingLeft: 20,
		fontSize: 13,
		marginBottom: 12,
	},
	marginBottomer: {
		width: "100%",
		marginBottom: 12,
	},
	contentLeft: {
		marginLeft: 200,
	},
	containerSkeleton: {
		paddingTop: 20,
		paddingLeft: 10,
		paddingRight: 10,
	},
	containerMoreJobs: {
		width: "100%",
		justifyContent: "center",
		display: "flex",
	},
	lessSpacing: {
		paddingTop: "1px !important",
		paddingBottom: "1px !important",
	},
	divider: {
		width: "100%",
		marginBottom: 0,
	},
	shareLink: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		marginTop: 8,
		marginBottom: 16,
		fontSize: 14,
		fontWeight: 600,
		color: "#3a5dab",
	},
	jobPillDataParagraph: {
		fontSize: 10,
		fontWeight: "bold",
		margin: 0,
		lineHeight: 1.6,
		display: "flex",
		alignItems: "center",
	},
	jobPillDataIcon: {
		height: "15px !important",
		width: "15px !important",
		marginRight: 3,
	},
	jobPillSalaryDataGridItem: {
		width: "auto",
		marginRight: 8,
		marginBottom: 4,
		marginLeft: 14,
		padding: "4px 12px",
		borderRadius: 21.5,
		backgroundColor: "rgba(58, 93, 171, 0.1)",
	},
	space: {
		width: "100%",
		marginBottom: 12,
	},
})

// Component
const JobCard = props => {
	// Hooks
	const dispatch = useDispatch()
	const styles = useStyles()

	// Destructuring props
	const {
		jobs,
		url,
		logoSrcs,
		jobsApplied,
		openInNewTab,
		fromClientsPage,
		forWordpress,
		similarJobsClickEvent,
		similarJob,
		forApplicationList,
		clientFunnelPage,
	} = props

	// Local state
	const [jobsToShow, setJobsToShow] = useState(2)

	// Redux selectors
	const profileReducer = useSelector(state => state.profileReducer)

	// Render
	return (
		<>
			{jobs !== undefined &&
				jobs !== "no jobs" &&
				Object.keys(jobs).map((job, id) => {
					if (id <= jobsToShow || !fromClientsPage) {
						return (
							<React.Fragment key={id}>
								<Grid
									item
									xs={12}
									sm={12}
									className={styles.jobCardGridItem}
									onClick={() => {
										if (similarJob) {
											similarJobsClickEvent()
										}
									}}
								>
									<Link
										to={
											forWordpress
												? `jobs/${jobs[job].zoho_job_openings_id}`
												: similarJob
												? `${url}/${jobs[job].zoho_job_openings_id}?similarJob=true`
												: clientFunnelPage
												? `form?type=specific&jobId=${jobs[job].zoho_job_openings_id}`
												: `${url}/${jobs[job].zoho_job_openings_id}`
										}
										className={styles.link}
										target={
											openInNewTab ? "_blank" : "_self"
										}
										onClick={() => {
											dispatch(
												jobsActions.removeSpecificJobAction()
											)

											// sending gtm event
											dispatch(
												tagManagerActions.sendSimpleCustomEvent(
													{
														eventName:
															"jobCardClickedCustomEvent",
													}
												)
											)
											if (jobs[job]?.top_salary_seal) {
												dispatch(
													tagManagerActions.sendSimpleCustomEvent(
														{
															eventName:
																"topSalaryJobCardClickedCustomEvent",
														}
													)
												)
											} else {
												dispatch(
													tagManagerActions.sendSimpleCustomEvent(
														{
															eventName:
																"noTopSalaryJobCardNoTopSalaryClickedCustomEvent",
														}
													)
												)
											}

											// sending amount of benefits on card
											if (
												jobs[job]
													?.count_matching_benefits
											) {
												dispatch(
													tagManagerActions.sendSimpleCustomEvent(
														{
															eventName: `${jobs[job].count_matching_benefits}BenefitsJobCardClickedCustomEvent`,
														}
													)
												)
											}

											// sending 1/1, 1/2 or 2/2 benefits on cards
											if (
												(jobs[job]
													.count_matching_benefits ===
													1 ||
													jobs[job]
														.count_matching_benefits ===
														2) &&
												(profileReducer.profileData
													.benefits.length === 1 ||
													profileReducer.profileData
														.benefits.length === 2)
											) {
												dispatch(
													tagManagerActions.sendSimpleCustomEvent(
														{
															eventName: `${jobs[job].count_matching_benefits}OutOf${profileReducer.profileData.benefits.length}BenefitsJobCardClickedCustomEvent`,
														}
													)
												)
											}
										}}
									>
										<Paper
											className={styles.jobOpening}
											elevation={0}
										>
											{/* ribbon */}
											{!forApplicationList && (
												<SalaryRibbon
													jobId={
														jobs[job]
															.zoho_job_openings_id
													}
													showRibbon={
														jobs[job]
															.top_salary_seal
													}
												/>
											)}

											<Grid container spacing={1}>
												{/* logo & banners */}
												<Grid
													item
													xs={12}
													className={
														styles.logoAndBannersContainer
													}
												>
													{/* logo */}
													<div
														className={
															styles.companyLogoWrapper
														}
													>
														{logoSrcs.filter(el =>
															el.url.includes(
																jobs[job]
																	.zoho_job_openings_id
															)
														)[0] && (
															<img
																className={
																	styles.companyLogo
																}
																src={
																	logoSrcs.filter(
																		el =>
																			el.url.includes(
																				jobs[
																					job
																				]
																					.zoho_job_openings_id
																			)
																	)[0].url
																}
																alt="logo"
															/>
														)}
													</div>
													{/* banners */}
													{!forWordpress &&
														!forApplicationList && (
															<JobCardBanners
																jobOpening={
																	jobs[job]
																}
															/>
														)}
													{/* application banners*/}
													{forApplicationList && (
														<ApplicationBanners
															jobOpening={
																jobs[job]
															}
														/>
													)}
												</Grid>

												{/* job title */}
												<Grid item xs={12}>
													<h3
														className={
															styles.jobTitle
														}
													>
														{jobs[job].job_title_app
															? jobs[job]
																	.job_title_app
															: jobs[job]
																	.job_title}
													</h3>
												</Grid>
												{/* company name */}
												<Grid
													item
													xs={12}
													className={
														styles.lessSpacing
													}
												>
													<p
														className={
															styles.companyName
														}
													>
														{jobs[job].client_name}
													</p>
												</Grid>
												{/* location */}
												<Grid
													item
													xs={12}
													className={
														styles.lessSpacing
													}
												>
													<p
														className={
															styles.location
														}
													>
														<img
															alt="location"
															src={
																jobCardIconLocation
															}
															className={
																styles.locationIcon
															}
														/>{" "}
														{jobs[job].city}
													</p>
												</Grid>

												{/* job card pills */}
												<JobCardPills
													jobs={jobs}
													job={job}
												/>

												{/* already applied */}
												{!forWordpress &&
													jobsApplied &&
													jobsApplied.includes(
														jobs[job]
															.zoho_job_openings_id
													) && (
														<Grid
															container
															className={
																styles.jobAppliedContainer
															}
														>
															<Grid
																item
																className={
																	styles.progressContainer
																}
															>
																<CheckIcon
																	className={
																		styles.progress
																	}
																/>
															</Grid>
															<Grid item xs>
																<p
																	className={
																		styles.progressParagraph
																	}
																>
																	Beworben
																</p>
															</Grid>
														</Grid>
													)}

												{/* CSS fix for Wordpress iframes */}
												{(forWordpress ||
													forApplicationList) && (
													<div
														className={
															styles.marginBottomer
														}
													/>
												)}

												{/* Sharing button */}
												{!forWordpress &&
													!forApplicationList &&
													!clientFunnelPage && (
														<>
															<Grid item xs={12}>
																<Divider
																	className={
																		styles.divider
																	}
																/>
															</Grid>

															<JobShareBtn
																job={jobs[job]}
																url={url}
																location="jobsList"
																logoSrcs={
																	logoSrcs?.length >
																	0
																		? logoSrcs[0]
																				?.url
																		: null
																}
																jobTitle={
																	jobs[job]
																		.job_title_app
																		? jobs[
																				job
																		  ]
																				.job_title_app
																		: jobs[
																				job
																		  ]
																				.job_title
																}
															/>
														</>
													)}

												{/* CSS Fix for hidden share btn */}
												{clientFunnelPage && (
													<div
														className={styles.space}
													></div>
												)}
											</Grid>
										</Paper>
									</Link>
								</Grid>
								{fromClientsPage &&
									id === jobsToShow &&
									jobsToShow < jobs?.length &&
									jobs?.length > 3 && (
										<Grid
											item
											xs={12}
											className={styles.buttonGridItem}
										>
											<Button
												variant="outlined"
												color="primary"
												className={
													styles.loadMoreJobsButton
												}
												onClick={() => {
													setJobsToShow(
														jobsToShow + 5
													)
												}}
											>
												Weitere Jobs Laden
											</Button>
										</Grid>
									)}
							</React.Fragment>
						)
					} else {
						return null
					}
				})}
		</>
	)
}

export default JobCard
