import React, { useState } from "react"

// Labels & Options
import fieldOptions from "./fieldOptions"

// Capacitor
import { Capacitor } from "@capacitor/core"

// Redux
import { useDispatch, useSelector } from "react-redux"
import * as jobsActions from "../../../state/jobsActions"

// Material UI
import { makeStyles } from "@material-ui/core/styles"
import {
	OutlinedInput,
	Checkbox,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	FormControl,
	Select,
	Chip,
	Grid,
	Button,
} from "@material-ui/core"
import { useMediaQuery } from "@material-ui/core"

// Materal UI icons
import CheckIcon from "@material-ui/icons/Check"
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined"

// CSS
const useStyles = makeStyles(theme => ({
	gridItem: {
		marginBottom: 12,
	},
	formControl: {
		width: "100%",
		marginTop: 16,
	},
	select: {
		minHeight: 52,
		backgroundColor: "white",
		borderRadius: 12,
		cursor: "pointer",
	},
	chips: {
		display: "flex",
		flexWrap: "wrap",
	},
	chip: {
		border: "1px solid #e8b300",
		backgroundColor: "#fff5cc",
		margin: 2,
		padding: 5,
		height: "100%",
		display: "flex",
		flexDirection: "row",
		"& span": {
			overflowWrap: "break-word",
			whiteSpace: "normal",
			textOverflow: "clip",
		},
	},
	label: {
		display: "none",
	},
	list: {
		padding: 0,
	},
	option: {
		paddingTop: 20,
		paddingBottom: 20,
		cursor: "pointer",
	},
	optionDisabled: {
		paddingTop: 20,
		paddingBottom: 20,
		opacity: 0.3,
		pointerEvents: "none",
	},
	selectedOption: {
		backgroundColor: "rgba(245, 168, 0, 0.1) !important",
		fontWeight: 600,
	},
	selectedOptionNone: {
		backgroundColor: "rgba(255,255,255,1) !important",
	},
	invisibleIcon: {
		display: "none",
	},
	selectHeader: {
		position: "sticky",
		paddingTop: 8,
		top: 0,
		backgroundColor: "white",
		zIndex: 2,
		display: "flex",
		justifyContent: "end",
	},
	closeMultipleChoiceListBtn: {
		color: "#3a5dab",
		paddingRight: 12,
	},
}))

const useOutlinedInputStyles = makeStyles(theme => ({
	input: {
		padding: "10px 16px",
	},
}))

// Component
const MultipleChoiceField = props => {
	// Hooks
	const styles = useStyles()
	const dispatch = useDispatch()
	const outlinedInputClasses = useOutlinedInputStyles()

	// Destructuring props
	const {
		id,
		fieldName,
		fieldNameMapper,
		anchorRef,
		trackingFilteringClickEvent,
	} = props

	// Redux selectors
	const jobsReducer = useSelector(state => state.jobsReducer)

	// Media queries
	const mobileQuery = useMediaQuery("(max-width:585px)")

	// Local state
	const [selectOpen, setSelectOpen] = useState(false)

	// Field Options
	const filterFieldOptions = ["btn", ...fieldOptions[fieldName]]

	// Render
	return (
		<div onClick={trackingFilteringClickEvent}>
			<Grid key={id} item xs={12} className={styles.gridItem}>
				<FormControl variant="filled" className={styles.formControl}>
					<Select
						id={`multipleChoiceField_${fieldName}`}
						className={styles.select}
						open={selectOpen}
						onClose={() => {
							setSelectOpen(false)
						}}
						onOpen={() => {
							setSelectOpen(true)
						}}
						multiple={
							fieldName === "total_work_experience" ||
							fieldName === "salary_expectation_month"
								? false
								: true
						}
						displayEmpty
						value={
							jobsReducer.filteringOptions[
								fieldNameMapper[fieldName]
							]
						}
						onChange={el => {
							if (!el.target.value.includes("btn")) {
								// Set value for this field in redux state
								const fieldValue = el.target.value

								dispatch(
									jobsActions.asyncSaveFilteringFieldAction(
										fieldValue,
										fieldNameMapper[fieldName]
									)
								)
							}
						}}
						input={
							<OutlinedInput
								classes={{ input: outlinedInputClasses.input }}
							/>
						}
						renderValue={selected => {
							if (selected.length === 0 || !selected) {
								return <em>zum Auswählen anklicken</em>
							}

							return (
								<div className={styles.chips}>
									{fieldName !== "salary_expectation_month" &&
										selected.map(value => (
											<Chip
												key={value}
												label={value}
												className={styles.chip}
												onMouseDown={event => {
													event.stopPropagation()
												}}
												onDelete={() => {
													// Remove value from redux
													dispatch(
														jobsActions.removeItemMultipleChoiceAction(
															value,
															fieldNameMapper[
																fieldName
															]
														)
													)
												}}
											/>
										))}

									{fieldName ===
										"salary_expectation_month" && (
										<Chip
											key={selected}
											label={selected}
											className={styles.chip}
											onMouseDown={event => {
												event.stopPropagation()
											}}
											onDelete={() => {
												// Remove value from redux
												dispatch(
													jobsActions.removeItemSingleChoiceAction(
														selected,
														fieldNameMapper[
															fieldName
														]
													)
												)
											}}
										/>
									)}
								</div>
							)
						}}
						MenuProps={
							mobileQuery
								? {
										classes: { list: styles.list },
										PaperProps: {
											style: {
												maxHeight: "100vh",
												minWidth: 200,
												width: "100%",
												bottom:
													Capacitor.getPlatform() ===
													"ios"
														? "calc(16px + env(safe-area-inset-bottom))"
														: 16,
												top:
													Capacitor.getPlatform() ===
													"ios"
														? "calc(16px + env(safe-area-inset-top))"
														: 16,
											},
										},
										marginThreshold: 0,
										variant: "menu",
										getContentAnchorEl: null,
										anchorReference: "anchorPosition",
										anchorPosition: {
											top:
												Capacitor.getPlatform() ===
												"ios"
													? "calc(16px + env(safe-area-inset-top))"
													: 16,
											left: 16,
											right: 16,
										},
								  }
								: {
										classes: { list: styles.list },
										PaperProps: {
											style: {
												maxHeight: "100vh",
												minWidth: 200,
												maxWidth: 552,
												width: "100%",
												bottom:
													Capacitor.getPlatform() ===
													"ios"
														? "calc(16px + env(safe-area-inset-bottom))"
														: 16,
												top:
													Capacitor.getPlatform() ===
													"ios"
														? "calc(16px + env(safe-area-inset-top))"
														: 16,
												marginTop: 16,
											},
										},
										marginThreshold: 0,
										variant: "menu",
										getContentAnchorEl: null,
										anchorEl: anchorRef.current,
										anchorOrigin: {
											horizontal: "center",
											vertical: "center",
										},
										transformOrigin: {
											horizontal: "center",
											vertical: "center",
										},
								  }
						}
					>
						{filterFieldOptions.map((option, id) => {
							if (option === "btn") {
								return (
									<ListItem
										key={option}
										value={option}
										className={styles.selectHeader}
										classes={{
											selected: styles.selectedOptionNone,
										}}
										divider
									>
										<Button
											key="btn"
											className={
												styles.closeMultipleChoiceListBtn
											}
											id="closeMultipleChoiceListBtn"
											color="primary"
											onClick={() => {
												setSelectOpen(false)
											}}
										>
											Fertig
										</Button>
									</ListItem>
								)
							} else {
								return (
									<ListItem
										key={option}
										value={option}
										className={styles.option}
										classes={{
											selected: styles.selectedOption,
										}}
										divider
									>
										<ListItemText
											primary={option}
											disableTypography
										/>
										<ListItemSecondaryAction>
											<Checkbox
												color="secondary"
												icon={
													<CheckOutlinedIcon
														className={
															styles.invisibleIcon
														}
													/>
												}
												checkedIcon={<CheckIcon />}
												checked={jobsReducer.filteringOptions[
													fieldNameMapper[fieldName]
												].includes(option)}
											/>
										</ListItemSecondaryAction>
									</ListItem>
								)
							}
						})}
					</Select>
				</FormControl>
			</Grid>
		</div>
	)
}

export default MultipleChoiceField
