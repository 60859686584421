import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"

// Components
import JobApplyBtn from "./JobApplyBtn"
import JobCardBanners from "../jobsListPage/JobCardBanners"
import JobShareBtn from "./JobShareBtn"
import SalaryRibbon from "../jobsListPage/SalaryRibbon"

// Redux
import { useDispatch, useSelector } from "react-redux"
import * as jobsActions from "../../state/jobsActions"
import * as tagManagerActions from "../../../tagManagerEvents/state/tagManagerActions"

// Material UI
import { Grid, useMediaQuery } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"

// Icons
import jobCardIconDepartment from "../../../images/jobCardIcons/jobCardIconDepartment.png"
import jobCardIconEmploymentForm from "../../../images/jobCardIcons/jobCardIconEmploymentForm.png"
import jobCardIconLocation from "../../../images/jobCardIcons/jobCardIconLocation.png"
import jobCardIconShifts from "../../../images/jobCardIcons/jobCardIconShifts.png"
import jobCardIconSalary from "../../../images/jobCardIcons/jobCardIconSalary.png"
import jobCardIconEmployer from "../../../images/jobCardIcons/jobCardIconEmployer.png"
import jobCardIconPalmTree from "../../../images/jobCardIcons/jobCardIconPalmTree.png"

// Styles
const useStyles = makeStyles({
	header: {
		marginTop: 80,
		marginBottom: 8,
		opacity: 0,
		borderBottom: "1px solid #e8e8e8",
	},
	staticHeader: {
		marginTop: 55,
		padding: 16,
		paddingBottom: 8,
		backgroundColor: "white",
		borderBottom: "1px solid #e8e8e8",
	},
	stickyHeader: {
		margin: "0 auto",
		width: "100%",
		marginTop: 55,
		padding: 16,
		zIndex: 2,
		paddingBottom: 0,
		backgroundColor: "white",
	},
	stickyHeaderLeft: {
		left: 100,
		margin: "0 auto",
		width: "100%",
		marginTop: 55,
		padding: 16,
		zIndex: 2,
		paddingBottom: 0,
		backgroundColor: "white",
	},
	contentLeft: {
		paddingLeft: 200,
	},
	justifyContent: {
		maxWidth: 756,
		margin: "0 auto",
		fontFamily: "'Nunito', sans-serif",
	},
	aspecificLink: {
		margin: "0 0 12px",
		fontSize: 12,
		fontWeight: "normal",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: 1.5,
		letterSpacing: "normal",
		color: "#3c424f",
		textDecoration: "underline",
		cursor: "pointer",
	},
	companyLogoContainer: {
		flexGrow: 1,
	},
	companyLogoWrapper: {
		position: "relative",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: 72,
		height: 72,
		borderRadius: 36,
		border: "solid 0.9px #e8e8e8",
	},
	companyLogo: {
		maxWidth: 50,
		maxHeight: 50,
	},
	companyLogoHidden: {
		display: "none",
	},
	headerIcon: {
		width: 16,
		height: 16,
		margin: "1px 0",
		marginRight: 2,
		objectFit: "contain",
	},
	jobTitle: {
		wordBreak: "break-word",
		margin: "2px 0",
		fontSize: 18,
		fontWeight: "bold",
	},
	companyName: {
		display: "flex",
		alignItems: "center",
		fontSize: 14,
		fontWeight: 600,
		margin: 0,
		marginBottom: 2,
	},
	location: {
		display: "flex",
		alignItems: "center",
		fontSize: 14,
		fontWeight: 600,
		margin: 0,
		marginBottom: 16,
	},
	locationIcon: {
		width: 18,
	},
	buttonNoStyling: {
		background: "none",
		color: "inherit",
		border: "none",
		padding: 0,
		font: "inherit",
		cursor: "pointer",
		outline: "inherit",
	},
	buttonNoStylingWithPadding: {
		background: "none",
		color: "inherit",
		border: "none",
		padding: 0,
		font: "inherit",
		cursor: "pointer",
		outline: "inherit",
		marginBottom: 10,
	},
	jobPillDataGridItem: {
		maxWidth: "90%",
		marginRight: 8,
		marginBottom: 4,
		padding: "4px 12px",
		borderRadius: 21.5,
		backgroundColor: "rgba(58, 93, 171, 0.1)",
	},
	jobPillSalaryDataGridItem: {
		maxWidth: "90%",
		marginRight: 8,
		marginBottom: 4,
		padding: "4px 12px",
		borderRadius: 21.5,
		backgroundColor: "rgba(58, 93, 171, 0.1)",
	},
	jobPillDataDepartmentGridItem: {
		maxWidth: "90%",
		marginRight: 8,
		marginBottom: 0,
		padding: "4px 12px",
		borderRadius: 21.5,
		backgroundColor: "rgba(58, 93, 171, 0.1)",
		"&:last-child": { marginBottom: 16 },
		cursor: "pointer",
	},
	jobPillDataGridContainer: {
		fontSize: 13,
		margin: 0,
	},
	jobPillDataParagraph: {
		fontSize: 10,
		fontWeight: "bold",
		margin: 0,
		lineHeight: 1.6,
		display: "flex",
		alignItems: "center",
	},
	jobPillDataIcon: {
		height: "15px !important",
		width: "15px !important",
		marginRight: 3,
	},
	stickyButtons: {
		width: "100%",
		position: "sticky",
		top: "calc(55px + env(safe-area-inset-top))",
		backgroundColor: "white",
		padding: 16,
		paddingBottom: 8,
		borderBottom: "1px solid #e8e8e8",
		zIndex: 3,
	},
	salaryRibbon: {
		left: 100,
	},
	goldContainer: {
		borderRadius: 21.5,
		marginRight: 6,
	},
	jobPillDataIconGold: {
		height: "12px !important",
		width: "12px !important",
	},
	jobPillDataGridItemGold: {
		marginRight: 0,
		marginBottom: 8,
		padding: "4px 12px",
		borderRadius: 21.5,
		background:
			"radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #bfa370 30%, #a8905f 40%, transparent 80%),radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 15%, #e6d7b0 25%, #7b6740 62.5%, #7b6740 100%);",
		backgroundSize: "125%",
	},
})

// Component
const JobHeader = props => {
	// Hooks
	const styles = useStyles()
	const dispatch = useDispatch()
	const history = useHistory()
	const theme = useTheme()

	// Destructuring props
	const { stickyHeader, companyLogo, job, jobId } = props

	// Local state
	const [showAllDepartments, setShowAllDepartments] = useState(false)
	const [showSalaryPill, setShowSalaryPill] = useState(false)

	// Determine if salary pill can be shown
	useEffect(() => {
		if (
			job.salary_from !== 0 &&
			job.salary_to !== 0 &&
			job.salary_from !== undefined &&
			job.salary_to !== undefined &&
			job.salary_from !== null &&
			job.salary_to !== null
		) {
			setShowSalaryPill(true)
		} else {
			setShowSalaryPill(false)
		}
	}, [job])

	// Media query
	const mediaQueryMdUp = useMediaQuery(theme.breakpoints.up("md"))

	// Redux selectors
	const jobApplied = useSelector(state => state.jobsReducer.jobApplied)
	const authReducer = useSelector(state => state.authReducer)

	// Component
	return (
		<>
			<Grid
				container
				className={
					mediaQueryMdUp
						? styles.stickyHeaderLeft
						: styles.stickyHeader
				}
			>
				<Grid item xs={12}>
					<Grid container className={styles.justifyContent}>
						<>
							{/* link to aspecific funnel (only unlogged) */}
							{authReducer && !authReducer.userLoggedIn && (
								<Grid item xs={12}>
									<button
										className={
											job.top_salary_seal
												? styles.buttonNoStylingWithPadding
												: styles.buttonNoStyling
										}
									>
										<p
											className={styles.aspecificLink}
											onClick={() => {
												dispatch(
													jobsActions.removeSpecificJobAction()
												)
												history.push("/form")
												dispatch(
													tagManagerActions.sendSimpleCustomEvent(
														{
															eventName:
																"clickRegisterAspecificUserJobPageLinkCustomEvent",
														}
													)
												)
											}}
										>
											Bei Workbee registrieren für weitere
											Jobs
										</p>
									</button>
								</Grid>
							)}

							{/* logo */}
							<>
								<Grid
									item
									xs={"auto"}
									className={styles.companyLogoContainer}
								>
									<div className={styles.companyLogoWrapper}>
										{companyLogo && (
											<img
												src={companyLogo}
												alt="company logo"
												className={styles.companyLogo}
											/>
										)}
										<SalaryRibbon
											showRibbon={job.top_salary_seal}
											isInJobOpening={true}
										/>
									</div>
								</Grid>

								<JobCardBanners
									jobOpening={job}
									location="jobOpening"
								/>
							</>

							{/* job title */}
							<Grid item xs={12}>
								<h1 id="JobTitle" className={styles.jobTitle}>
									{job.job_title_app
										? job.job_title_app
										: job.job_title}
								</h1>
							</Grid>

							{/* company name */}
							<Grid item xs={12}>
								<p className={styles.companyName}>
									{job.company_name_app}
								</p>
							</Grid>

							{/* address */}
							<Grid item xs={12}>
								<p className={styles.location}>
									<img
										alt="location"
										src={jobCardIconLocation}
										className={styles.locationIcon}
									/>{" "}
									{job.city}
								</p>
							</Grid>

							{/* employment form, department, shifts, type of employer pills */}
							<Grid
								container
								className={styles.jobPillDataGridContainer}
								direction="row"
								alignItems="flex-start"
							>
								{/* salary pill */}
								{showSalaryPill && (
									<>
										{job.top_salary_seal ? (
											<div
												className={styles.goldContainer}
											>
												<div
													className={
														styles.jobPillDataGridItemGold
													}
												>
													<p
														className={
															styles.jobPillDataParagraph
														}
													>
														{`${job.salary_from} - ${job.salary_to} €`}
													</p>
												</div>
											</div>
										) : (
											<Grid
												item
												xs="auto"
												className={
													styles.jobPillSalaryDataGridItem
												}
											>
												<p
													className={
														styles.jobPillDataParagraph
													}
												>
													<img
														src={jobCardIconSalary}
														alt="salary"
														className={
															styles.jobPillDataIcon
														}
													/>{" "}
													{`${job.salary_from} - ${job.salary_to} €`}
												</p>
											</Grid>
										)}
									</>
								)}

								{/* vacation days pill */}
								{job.vacation_days && job.vacation_days > 0 && (
									<>
										{job.vacation_days > 30 ? (
											<div
												className={styles.goldContainer}
											>
												<div
													className={
														styles.jobPillDataGridItemGold
													}
												>
													<p
														className={
															styles.jobPillDataParagraph
														}
													>
														<img
															alt="palmtree"
															src={
																jobCardIconPalmTree
															}
															className={
																styles.jobPillDataIcon
															}
														/>{" "}
														bis zu{" "}
														{job.vacation_days}{" "}
														Urlaubstage
													</p>
												</div>
											</div>
										) : (
											<Grid
												item
												xs="auto"
												className={
													styles.jobPillDataGridItem
												}
											>
												<p
													className={
														styles.jobPillDataParagraph
													}
												>
													<img
														alt="palmtree"
														src={
															jobCardIconPalmTree
														}
														className={
															styles.jobPillDataIcon
														}
													/>{" "}
													bis zu {job.vacation_days}{" "}
													Urlaubstage
												</p>
											</Grid>
										)}
									</>
								)}

								{/* employment form pill */}
								{job.employment_form &&
									job.employment_form.length > 0 && (
										<Grid
											item
											xs="auto"
											className={
												styles.jobPillDataGridItem
											}
										>
											<p
												className={
													styles.jobPillDataParagraph
												}
											>
												<img
													alt="employment form"
													src={
														jobCardIconEmploymentForm
													}
													className={
														styles.jobPillDataIcon
													}
												/>{" "}
												{job.employment_form
													.map(
														el =>
															el.employment_form_name
													)
													.join(", ")}
											</p>
										</Grid>
									)}

								{/* departments pill */}
								{job.departments &&
									job.departments.length > 0 && (
										<Grid
											item
											xs="auto"
											className={
												styles.jobPillDataGridItem
											}
											onClick={e => {
												e.preventDefault()

												if (showAllDepartments) {
													setShowAllDepartments(false)
												} else {
													setShowAllDepartments(true)
												}

												if (
													job.departments.length > 3
												) {
													// Record activity for Tracking
													dispatch(
														tagManagerActions.sendSimpleCustomEvent(
															{
																eventName:
																	"clickDepartmentPillCustomEvent",
															}
														)
													)
												}
											}}
										>
											<p
												className={
													styles.jobPillDataParagraph
												}
											>
												<img
													alt="departments"
													src={jobCardIconDepartment}
													className={
														styles.jobPillDataIcon
													}
												/>{" "}
												{/* showing only first 3 departments */}
												{!showAllDepartments &&
													job.departments &&
													job.departments.length &&
													job.departments.length >
														3 &&
													job.departments
														.slice(0, 3)
														.map(
															el =>
																el.department_name
														)
														.join(", ") + ", ..."}
												{/* showing all departments */}
												{(showAllDepartments ||
													(job.departments &&
														job.departments
															.length &&
														job.departments
															.length <= 3)) &&
													job.departments
														.map(
															el =>
																el.department_name
														)
														.join(", ")}
											</p>
										</Grid>
									)}

								{/* type of employer pill */}
								{job.type_of_employer && (
									<Grid
										item
										xs="auto"
										className={styles.jobPillDataGridItem}
									>
										<p
											className={
												styles.jobPillDataParagraph
											}
										>
											<img
												src={jobCardIconEmployer}
												alt="type_of_employer"
												className={
													styles.jobPillDataIcon
												}
											/>{" "}
											{job.type_of_employer}
										</p>
									</Grid>
								)}

								{/* shifts pill */}
								{job.shifts && job.shifts.length > 0 && (
									<Grid
										item
										xs="auto"
										className={styles.jobPillDataGridItem}
									>
										<p
											className={
												styles.jobPillDataParagraph
											}
										>
											<img
												alt="shifts"
												src={jobCardIconShifts}
												className={
													styles.jobPillDataIcon
												}
											/>{" "}
											{job.shifts
												.map(el => el.shift_name)
												.join(", ")}
										</p>
									</Grid>
								)}
							</Grid>
						</>
					</Grid>
				</Grid>
			</Grid>

			<Grid container className={styles.stickyButtons}>
				<Grid item xs={12}>
					{/* apply btn */}
					<JobApplyBtn
						jobApplied={jobApplied}
						jobId={jobId}
						stickyHeader={stickyHeader}
					/>

					{/* share btn */}
					<JobShareBtn
						job={job}
						url={window.location.href}
						location="jobPage"
						logoSrcs={companyLogo}
						jobTitle={
							job.job_title_app
								? job.job_title_app
								: job.job_title
						}
					/>
				</Grid>
			</Grid>
		</>
	)
}

export default JobHeader
