const url = window.location.href
export let env = ""
export let backendURL = ""
export let useSentry = true
export let isLocalEnv = false

if (url.includes("localhost")) {
	useSentry = false
	isLocalEnv = true
}

console.log(url)

if (
	url.includes("localhost:") ||
	url.includes("app-development") ||
	url.includes("localhost")
) {
	env = "development"
	backendURL = "https://api-development.workbee.de"
	// backendURL = "http://localhost:4560"
} else if (url.includes("app-staging")) {
	env = "staging"
	backendURL = "https://api-staging.workbee.de"
} else if (url.includes("app")) {
	env = "production"
	backendURL = "https://api.workbee.de"
}
