import React, { useState, useEffect } from "react"
import * as Sentry from "@sentry/browser"

// Components
import standardProfileFieldNames from "../../profile/dataStructures/standardProfileFieldNames"

// Material UI
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"

// Material UI icons
import HighlightOffIcon from "@material-ui/icons/HighlightOff"

// Redux
import { useDispatch, useSelector } from "react-redux"
import * as clickFunnelActions from "../state/clickFunnelActions"

// Utils
import parse from "autosuggest-highlight/parse"
import throttle from "lodash/throttle"

// Custom Hooks
import useManageQuestions from "../funnelLogicHooks/useManageQuestions"

const autocompleteService = { current: null }

// Styles
const useStyles = makeStyles(theme => ({
	icon: {
		color: theme.palette.text.secondary,
		marginRight: theme.spacing(2),
	},
	deleteLocationBtn: {
		cursor: "pointer",
		position: "relative",
		left: 25,
	},
	formField: {
		display: "flex",
		justifyContent: "center",
	},
}))

const useOutlinedInputStyles = makeStyles(theme => ({
	root: {
		backgroundColor: "white",
		borderRadius: 12,
	},
}))

// Component
export default function SFLocationGoogleMaps(props) {
	// Hooks
	const dispatch = useDispatch()
	const styles = useStyles()
	const outlinedInputClasses = useOutlinedInputStyles()
	const { manageQuestions } = useManageQuestions()

	// Destructuring props
	let { fieldName, id, citiesNumber, setCitiesNumber } = props

	// Local state
	const [value, setValue] = useState(null)
	const [inputValue, setInputValue] = useState("")
	const [options, setOptions] = useState([])
	const [userInputFilled, setUserInputFilled] = useState(false)

	// Redux selectors
	const clickFunnelReducer = useSelector(state => state.clickFunnelReducer)
	const profileReducer = useSelector(state => state.profileReducer)

	// Get coordinates using place id of a location
	const handlePlaceSelect = async newValue => {
		if (!newValue) return

		const placeId = newValue.place_id
		const geocoder = new window.google.maps.Geocoder()

		try {
			const results = await new Promise((resolve, reject) => {
				geocoder.geocode({ placeId: placeId }, (results, status) => {
					if (status === "OK" && results[0]) {
						resolve(results[0])
					} else {
						reject(new Error(`Geocoder failed due to: ${status}`))
					}
				})
			})

			const location = results.geometry.location
			const lat = location.lat()
			const lng = location.lng()

			return { lat, lng }
		} catch (error) {
			console.error(error.message)
			Sentry.captureException(error)
		}
	}

	// Memo
	const fetch = React.useMemo(
		() =>
			throttle((request, callback) => {
				autocompleteService.current.getPlacePredictions(
					{
						...request,
						fields: ["geometry", "name"],
						types: ["locality", "sublocality", "postal_code"],
						componentRestrictions: { country: "de" },
					},
					callback
				)
			}, 200),
		[]
	)

	// Fill value label effect
	useEffect(() => {
		if (
			profileReducer?.changes &&
			clickFunnelReducer?.formData?.locations?.length > 0 &&
			clickFunnelReducer?.formData?.locations[id].location_name !== null
		) {
			setInputValue(
				clickFunnelReducer.formData.locations[id].location_name
			)
			setUserInputFilled(true)
		}
	}, [setInputValue, clickFunnelReducer, profileReducer, id])

	useEffect(() => {
		if (userInputFilled && options.length > 0) {
			setUserInputFilled(false)
			setValue(options[0])
		}
	}, [options, userInputFilled])

	// Set results effects
	useEffect(() => {
		let active = true

		if (!autocompleteService.current && window.google) {
			autocompleteService.current =
				new window.google.maps.places.AutocompleteService()
		}
		if (!autocompleteService.current) {
			return undefined
		}

		if (inputValue === "") {
			setOptions(value ? [value] : [])
			return undefined
		}

		fetch({ input: inputValue }, results => {
			if (active) {
				let newOptions = []

				if (value) {
					newOptions = [value]
				}

				if (results) {
					newOptions = [...newOptions, ...results]
					newOptions = newOptions.filter(el => {
						return (
							el.types.includes("locality") ||
							el.types.includes("sublocality") ||
							el.types.includes("postal_code")
						)
					})
				}
				setOptions(newOptions)
			}
		})

		return () => {
			active = false
		}
	}, [value, inputValue, fetch])

	// Render
	return (
		<Autocomplete
			id="google-map-demo"
			classes={{ root: outlinedInputClasses.root }}
			getOptionLabel={option =>
				typeof option === "string" ? option : option.description
			}
			filterOptions={x => x}
			options={options}
			autoComplete
			disableClearable
			noOptionsText={"Gib deinen gewünschten Ort ein"}
			includeInputInList
			filterSelectedOptions
			value={value}
			onChange={(event, newValue) => {
				setOptions(newValue ? [newValue, ...options] : options)

				if (newValue) {
					handlePlaceSelect(newValue)
						.then(result => {
							if (result) {
								const { lat, lng } = result

								dispatch(
									clickFunnelActions.setDesiredLocationAction(
										newValue.description,
										id,
										lat,
										lng
									)
								)
							} else {
								console.error("Failed to get lat and lng")
							}
						})
						.catch(error => {
							console.error("Error in handlePlaceSelect:", error)
						})
				}

				// add/hide questions, move to next slide (if necessary)
				manageQuestions(props)
			}}
			onInputChange={(event, newInputValue) => {
				setInputValue(newInputValue)
			}}
			renderInput={params => (
				<TextField
					{...params}
					id={`userInputFieldFreeText${fieldName}`}
					color="secondary"
					label={standardProfileFieldNames[fieldName]}
					variant="outlined"
					fullWidth
					InputProps={{
						...params.InputProps,
						endAdornment: id === citiesNumber.length - 1 &&
							id > 0 && (
								<HighlightOffIcon
									className={styles.deleteLocationBtn}
									onClick={el => {
										el.stopPropagation()
										let filteredCities = [...citiesNumber]
										filteredCities.splice(id, 1)
										setCitiesNumber(filteredCities)
										dispatch(
											clickFunnelActions.removeDesiredLocationAction(
												id
											)
										)
									}}
								/>
							),
					}}
				/>
			)}
			renderOption={option => {
				const matches =
					option.structured_formatting.main_text_matched_substrings
				const parts = parse(
					option.structured_formatting.main_text,
					matches.map(match => [
						match.offset,
						match.offset + match.length,
					])
				)

				return (
					<Grid container alignItems="center">
						<Grid item>
							<LocationOnIcon className={styles.icon} />
						</Grid>
						<Grid item xs>
							{parts.map((part, index) => (
								<span
									key={index}
									style={{
										fontWeight: part.highlight ? 700 : 400,
									}}
								>
									{part.text}
								</span>
							))}

							<Typography variant="body2" color="textSecondary">
								{option.structured_formatting.secondary_text}
							</Typography>
						</Grid>
					</Grid>
				)
			}}
		/>
	)
}
