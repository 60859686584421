const questionsStructureSpecific = {
	benefits: {
		fieldName: "benefits",
		fieldText: "Welche 3 Jobvorteile sind dir besonders wichtig?",
		fieldType: "multipleChoice",
		slide: 0,
		logicJumps: {
			jumpType: "stateBasedBenefits",
			"Pflege & Medizin": {
				nextQuestion: "qualification",
				hideQuestions: false,
			},
			"Pädagogik & Soziale Arbeit": {
				nextQuestion: "qualification_educator",
				hideQuestions: false,
			},
			"Multiple Verticals or None": {
				nextQuestion: "work_field",
				hideQuestions: false,
			},
		},
	},
	work_field: {
		fieldName: "work_field",
		fieldText: "Zu welchem Berufsfeld gehörst du?",
		fieldType: "singleChoice",
		slide: 0,
		logicJumps: {
			jumpType: "optionsBased",
			"Pflege & Medizin": {
				nextQuestion: "qualification",
				hideQuestions: [
					"qualification_educator",
					"occupational_group_educator",
					"occupational_group_free_text",
					"ongoing_education_educator",
					"ongoing_education_freetext",
					"education_finish_date",
				],
			},
			"Pädagogik & Soziale Arbeit": {
				nextQuestion: "qualification_educator",
				hideQuestions: [
					"qualification",
					"occupational_group",
					"occupational_group_free_text",
					"qualification_hilfskraft",
					"ongoing_education",
					"ongoing_education_freetext",
					"education_finish_date",
				],
			},
		},
	},
	qualification_educator: {
		fieldName: "qualification_educator",
		fieldText: "Welche Qualifikation hast du?",
		fieldType: "singleChoiceSelect",
		slide: 0,
		logicJumps: {
			jumpType: "optionsBasedQualification",
			Weiteres: {
				nextQuestion: "occupational_group_educator",
				hideQuestions: [
					"ongoing_education_educator",
					"education_finish_date",
				],
			},
			"Noch in Ausbildung": {
				nextQuestion: "ongoing_education_educator",
				hideQuestions: [
					"occupational_group_educator",
					"occupational_group_free_text",
				],
			},
			other: {
				nextQuestion: "employment_form",
				hideQuestions: [
					"ongoing_education_educator",
					"ongoing_education_freetext",
					"education_finish_date",
					"occupational_group_educator",
					"occupational_group_free_text",
				],
			},
		},
	},
	qualification: {
		fieldName: "qualification",
		fieldText: "Welche Qualifikation hast du?",
		fieldType: "singleChoiceSelect",
		slide: 0,
		logicJumps: {
			jumpType: "optionsBasedQualification",
			Weiteres: {
				nextQuestion: "occupational_group",
				hideQuestions: [
					"qualification_hilfskraft",
					"ongoing_education",
					"education_finish_date",
				],
			},
			"Pflegehilfskraft/-assistenz": {
				nextQuestion: "qualification_hilfskraft",
				hideQuestions: [
					"occupational_group",
					"ongoing_education",
					"ongoing_education_freetext",
					"education_finish_date",
					"occupational_group_free_text",
				],
			},
			"Noch in Ausbildung": {
				nextQuestion: "ongoing_education",
				hideQuestions: [
					"qualification_hilfskraft",
					"occupational_group",
					"occupational_group_free_text",
				],
			},
			other: {
				nextQuestion: "employment_form",
				hideQuestions: [
					"qualification_hilfskraft",
					"ongoing_education",
					"ongoing_education_freetext",
					"education_finish_date",
					"occupational_group",
					"occupational_group_free_text",
				],
			},
		},
	},
	occupational_group_educator: {
		fieldName: "occupational_group_educator",
		fieldText: "Welcher Berufsgruppe bist du am besten zuzuordnen?",
		fieldType: "singleChoice",
		slide: 0,
		logicJumps: {
			jumpType: "optionsBasedOccupationalGroup",
			Weiteres: {
				nextQuestion: "occupational_group_free_text",
				hideQuestions: false,
			},
			other: {
				nextQuestion: "employment_form",
				hideQuestions: ["occupational_group_free_text"],
			},
		},
	},
	occupational_group: {
		fieldName: "occupational_group",
		fieldText: "Welcher Berufsgruppe bist du am besten zuzuordnen?",
		fieldType: "singleChoice",
		slide: 0,
		logicJumps: {
			jumpType: "optionsBasedOccupationalGroup",
			Weiteres: {
				nextQuestion: "occupational_group_free_text",
				hideQuestions: false,
			},
			other: {
				nextQuestion: "employment_form",
				hideQuestions: ["occupational_group_free_text"],
			},
		},
	},
	occupational_group_free_text: {
		fieldName: "occupational_group_free_text",
		fieldText: "Okay, welche genau?",
		fieldType: "freeText",
		slide: 0,
		logicJumps: {
			jumpType: "valueBased",
			nextQuestion: "employment_form",
			hideQuestions: false,
		},
	},
	qualification_hilfskraft: {
		fieldName: "qualification_hilfskraft",
		fieldText: "Welche Qualifizierung bringst du mit?",
		fieldType: "singleChoice",
		slide: 0,
		logicJumps: {
			jumpType: "optionsBasedHilfskraft",
			Weiteres: {
				nextQuestion: "qualification_hilfskraft_freetext",
				hideQuestions: false,
			},
			other: {
				nextQuestion: "employment_form",
				hideQuestions: ["qualification_hilfskraft_freetext"],
			},
		},
	},
	qualification_hilfskraft_freetext: {
		fieldName: "qualification_hilfskraft_freetext",
		fieldText: "Okay, welche genau?",
		fieldType: "freeText",
		slide: 0,
		logicJumps: {
			jumpType: "valueBased",
			nextQuestion: "employment_form",
			hideQuestions: false,
		},
	},
	ongoing_education: {
		fieldName: "ongoing_education",
		fieldText: "Welche?",
		fieldType: "singleChoice",
		slide: 0,
		logicJumps: {
			jumpType: "optionsBasedOngoingEducation",
			Weiteres: {
				nextQuestion: "ongoing_education_freetext",
				hideQuestions: false,
			},
			other: {
				nextQuestion: "education_finish_date",
				hideQuestions: ["ongoing_education_freetext"],
			},
		},
	},
	ongoing_education_educator: {
		fieldName: "ongoing_education_educator",
		fieldText: "Welche?",
		fieldType: "singleChoice",
		slide: 0,
		logicJumps: {
			jumpType: "optionsBasedOngoingEducation",
			Weiteres: {
				nextQuestion: "ongoing_education_freetext",
				hideQuestions: false,
			},
			other: {
				nextQuestion: "education_finish_date",
				hideQuestions: ["ongoing_education_freetext"],
			},
		},
	},
	ongoing_education_freetext: {
		fieldName: "ongoing_education_freetext",
		fieldText: "Okay, welche genau?",
		fieldType: "freeText",
		slide: 0,
		logicJumps: {
			jumpType: "valueBased",
			nextQuestion: "education_finish_date",
			hideQuestions: false,
		},
	},
	education_finish_date: {
		fieldName: "education_finish_date",
		fieldText: "Wann bist du (ungefähr) fertig?",
		fieldType: "datePicker",
		slide: 0,
		logicJumps: {
			jumpType: "valueBased",
			nextQuestion: "employment_form",
			hideQuestions: false,
		},
	},
	employment_form: {
		fieldName: "employment_form",
		fieldText: "Wie möchtest du arbeiten?",
		fieldType: "singleChoice",
		slide: 1,
		logicJumps: {
			jumpType: "optionsBased",
			Teilzeit: {
				nextQuestion: "hours_per_week",
				hideQuestions: false,
			},
			Vollzeit: {
				nextQuestion: "salary_expectation_month",
				hideQuestions: ["hours_per_week"],
			},
			Minijob: {
				nextQuestion: "german_level",
				hideQuestions: ["hours_per_week", "salary_expectation_month"],
			},
		},
	},
	hours_per_week: {
		fieldName: "hours_per_week",
		fieldText: "Wie viele Stunden pro Woche möchtest du arbeiten?",
		fieldType: "freeText",
		slide: 1,
		logicJumps: {
			jumpType: "valueBased",
			nextQuestion: "salary_expectation_month",
			hideQuestions: false,
		},
	},
	salary_expectation_month: {
		fieldName: "salary_expectation_month",
		fieldText:
			"Was möchtest du mindestens verdienen? (Bruttomonatsgehalt, Vollzeit, ohne Zuschläge) ",
		fieldType: "singleChoice",
		slide: 1,
		logicJumps: {
			jumpType: "valueBased",
			nextQuestion: "german_level",
			hideQuestions: false,
		},
	},
	german_level: {
		fieldName: "german_level",
		fieldText: "Wie schätzt du deine Deutschkenntnisse ein?",
		fieldType: "singleChoice",
		slide: 2,
		logicJumps: {
			jumpType: "optionsBasedGermanLevel",
			nativeAndDiplomaRequired: {
				nextQuestion: "total_work_experience",
				hideQuestions: ["education_recognized"],
			},
			nativeAndDiplomaNotRequired: {
				nextQuestion: "total_work_experience",
				hideQuestions: ["education_recognized", "attachment"],
			},
			notNativeAndNotPfegehilfskraft: {
				nextQuestion: "education_recognized",
				hideQuestions: ["attachment"],
			},
			notNativePflegehilfskraftAndDiplomaRequired: {
				nextQuestion: "total_work_experience",
				hideQuestions: ["education_recognized"],
			},
			notNativePflegehilfskraftAndDiplomaNotRequired: {
				nextQuestion: "total_work_experience",
				hideQuestions: ["education_recognized", "attachment"],
			},
		},
	},
	education_recognized: {
		fieldName: "education_recognized",
		fieldText: "Ist deine Ausbildung in Deutschland anerkannt?",
		fieldType: "singleChoice",
		slide: 2,
		logicJumps: {
			jumpType: "stateBasedEducationRecognized",
			diplomaRequired: {
				nextQuestion: "total_work_experience",
				hideQuestions: false,
			},
			diplomaNotRequired: {
				nextQuestion: "total_work_experience",
				hideQuestions: false,
			},
		},
	},
	attachment: {
		fieldName: "attachment",
		fieldText:
			"Bitte lade hier deine Examensurkunde oder einen anderen Qualifikationsnachweis hoch (z.B. Pflegebasiskurs).",
		fieldType: "uploadAttachment",
		slide: 2,
		logicJumps: {
			jumpType: "valueBased",
			nextQuestion: "total_work_experience",
			hideQuestions: false,
		},
	},
	total_work_experience: {
		fieldName: "total_work_experience",
		fieldText: {
			"Pflege & Medizin":
				"Wie viele Jahre Berufserfahrung hast du in dem Bereich Pflege & Medizin?",
			"Pädagogik & Soziale Arbeit":
				"Wie viele Jahre Berufserfahrung hast du in dem Bereich Pädagogik & Soziale Arbeit?",
		},
		fieldType: "singleChoiceSelect",
		slide: 3,
		logicJumps: {
			jumpType: "optionsBasedTotalWorkExperience",
			"Noch keine": {
				nextQuestion: "contact_data",
				hideQuestions: ["work_experience"],
			},
			other: {
				nextQuestion: "work_experience",
			},
		},
	},
	work_experience: {
		fieldName: "work_experience",
		fieldText: "Wer waren deine Arbeitgeber in den letzten 5 Jahren?",
		fieldType: "uploadWorkExperience",
		slide: 3,
		logicJumps: {
			jumpType: "valueBased",
			nextQuestion: "contact_data",
			hideQuestions: false,
		},
	},
	contact_data: {
		fieldName: "contact_data",
		fieldType: "contactData",
		slide: 4,
	},
}

// helpful variable to determine what is the highest
// slide int, used later in ProgressBar and NextPreviousBtns
// and click funnel tracking
export const slideHighestValueSpecific = Math.max(
	...[...Object.values(questionsStructureSpecific).map(el => el.slide)]
)

export default questionsStructureSpecific
