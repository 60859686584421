// names
export const OPEN_LOADING_SCREEN = "OPEN LOADING SCREEN"
export const CLOSE_LOADING_SCREEN = "CLOSE LOADING SCREEN"

// dispatchers
export const openLoadingScreenAction = loadingScreenData => ({
  type: OPEN_LOADING_SCREEN,
  payload: loadingScreenData,
})
export const closeLoadingScreenAction = () => ({
  type: CLOSE_LOADING_SCREEN,
})
