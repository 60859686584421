import React, { useState, useEffect, useRef, useMemo } from "react"
import { useHistory } from "react-router-dom"

// Data structures
import { profilePageFieldsCandidate } from "./dataStructures/profilePageFields"

// Components
import ProfilePageFieldsAffiliate from "./ProfilePageFieldsAffiliate"
import ProfilePageFieldsCandidate from "./ProfilePageFieldsCandidate"

// Redux
import { useSelector, useDispatch } from "react-redux"
import * as profileActions from "./state/profileActions"
import * as notificationsActions from "../notifications/state/notificationsActions"
import * as tagManagerActions from "../tagManagerEvents/state/tagManagerActions"

// Material UI
import { useTheme, makeStyles } from "@material-ui/core/styles"
import { useMediaQuery, Container, Grid, Button } from "@material-ui/core"

// Custom hooks
import useGoogleMaps from "../hooks/useGoogleMaps"

// Styles
const useStyles = makeStyles({
	topContainerPc: {
		marginRight: 5,
		marginLeft: 5,
		padding: 10,
		"&:nth-child(1)": {
			marginRight: 0,
		},
		"&:nth-child(2)": {
			marginLeft: 0,
		},
	},
	profilePageContainer: {
		display: "flex",
		flexDirection: "column",
		marginTop: 80,
	},
	profilePageContainerContentLeft: {
		display: "flex",
		flexDirection: "row",
		marginTop: 65,
		marginLeft: 200,
		justifyContent: "center",
	},
	basicDataGridContainer: {
		borderRadius: 12,
		paddingBottom: 12,
		marginBottom: 12,
		backgroundColor: "white",
		border: "1px solid rgba(0,0,0,0.1)",
		"& h2": {
			fontSize: 16,
			color: "#110c08",
			fontFamily: "'Nunito', sans-serif",
			fontWeight: 800,
			margin: 16,
		},
	},
	basicDataGridItem: {
		marginLeft: 16,
		marginRight: 16,
		marginBottom: 8,
	},
	contentDivider: {
		width: "100%",
		height: 100,
	},
	btnMobileContainer: {
		position: "fixed",
		bottom: 0,
		left: 0,
		backgroundColor: "white",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
	},
	saveBtnMobile: {
		width: 300,
		height: 42,
		display: "flex",
		borderRadius: 12,
		marginTop: 20,
		marginBottom: 20,
		backgroundColor: "#3a5dab",
		color: "white",
		"&:disabled": {
			backgroundColor: "grey",
		},
		"&:hover": {
			backgroundColor: "#3a5dab",
		},
	},
	saveBtn: {
		width: "100%",
		height: 42,
		display: "flex",
		borderRadius: 12,
		marginTop: 20,
		backgroundColor: "#3a5dab",
		color: "white",
		"&:disabled": {
			backgroundColor: "grey",
		},
		"&:hover": {
			backgroundColor: "#3a5dab",
		},
	},
	anchor: {
		width: "100%",
	},
})

// Component
const ProfilePage = () => {
	// Hooks
	const styles = useStyles()
	const theme = useTheme()
	const history = useHistory()
	const dispatch = useDispatch()
	useGoogleMaps()

	// Local state
	const [saveBtnDisabled, setSaveBtnDisabled] = useState(true)
	const [saveProfileBtnDisabled, setSaveProfileBtnDisabled] = useState(true)

	// Media queries
	const mediaQueryMdUp = useMediaQuery(theme.breakpoints.up("md"))

	// References
	const anchorRef = useRef(null)

	// Redux selectors
	const profileReducer = useSelector(state => state.profileReducer)

	// Type of employer values to check for conditional rendering of departments
	const typeOfEmployerValuesForDepartmentFieldRender = useMemo(() => {
		return ["Krankenhaus / Klinik", "Kinderkrankenhäuser"]
	}, [])

	// Clear departments when type of employer doesn't have any of the required values
	useEffect(() => {
		if (
			profileReducer?.profileData?.departments?.length > 0 &&
			profileReducer?.profileData?.type_of_employer &&
			!typeOfEmployerValuesForDepartmentFieldRender.some(val =>
				profileReducer.profileData.type_of_employer.includes(val)
			)
		) {
			dispatch(
				profileActions.changeProfileFieldAction(
					"departments",
					[],
					"profileData"
				)
			)
		}
	}, [
		dispatch,
		profileReducer.profileData.departments,
		profileReducer.profileData.type_of_employer,
		typeOfEmployerValuesForDepartmentFieldRender,
	])

	// Getting current profile data in case user makes changes, doesn't save and goes to another page
	useEffect(() => {
		function handleLocationChange(location) {
			if (location.pathname === "/profile") {
			} else {
				if (
					saveBtnDisabled &&
					profileReducer.changes.userType === "candidate"
				) {
					dispatch(profileActions.asyncGetProfileDataAction())
				} else if (
					saveBtnDisabled &&
					profileReducer.changes.userType === "affiliate"
				) {
					dispatch(profileActions.asyncGetPromoterProfileDataAction())
				}
			}
		}

		// Handle the current route on initial render
		handleLocationChange(history.location)

		// Listen for subsequent route changes
		const unlisten = history.listen(handleLocationChange)

		// Clean up the listener on component unmount
		return () => unlisten()

		// eslint-disable-next-line
	}, [history])

	// Set Btn as disabled when no value in field (affiliate)
	useEffect(() => {
		if (profileReducer?.changes?.userType === "affiliate") {
			const { first_name, last_name, phone, email } =
				profileReducer.profileData

			if (
				[first_name, last_name, phone, email].some(
					str => str.trim() === ""
				)
			) {
				setSaveBtnDisabled(true)
			} else {
				setSaveBtnDisabled(false)
			}
		}
	}, [profileReducer])

	// Set Btn as disabled when no value in field (candidate)
	useEffect(() => {
		if (profileReducer?.changes?.userType === "candidate") {
			const {
				first_name,
				last_name,
				phone,
				email,
				qualification,
				occupational_group,
				occupational_group_free_text,
				work_field,
				locations,
				benefits,
				type_of_employer,
				salary_expectation_month,
				employment_form,
				hours_per_week,
				departments,
				german_level,
				education_recognized,
				ongoing_education,
				education_finish_date,
				total_work_experience,
				qualification_hilfskraft,
				qualification_hilfskraft_freetext,
			} = profileReducer.profileData

			if (
				[
					first_name,
					last_name,
					phone,
					email,
					qualification,
					german_level,
					education_recognized,
					total_work_experience,
				].some(str => str.trim() === "") ||
				[locations, type_of_employer, benefits, employment_form].some(
					array => array?.length === 0
				)
			) {
				setSaveProfileBtnDisabled(true)
			} else if (
				qualification === "Noch in Ausbildung" &&
				(ongoing_education === "" || !education_finish_date)
			) {
				setSaveProfileBtnDisabled(true)
			} else if (
				(employment_form.includes("Teilzeit") ||
					employment_form.includes("Vollzeit")) &&
				salary_expectation_month === ""
			) {
				setSaveProfileBtnDisabled(true)
			} else if (
				employment_form.includes("Teilzeit") &&
				hours_per_week === ""
			) {
				setSaveProfileBtnDisabled(true)
			} else if (
				qualification === "Weiteres" &&
				occupational_group === ""
			) {
				setSaveProfileBtnDisabled(true)
			} else if (
				qualification === "Pflegehilfskraft/-assistenz" &&
				qualification_hilfskraft === ""
			) {
				setSaveProfileBtnDisabled(true)
			} else if (
				qualification === "Weiteres" &&
				occupational_group === "Weiteres" &&
				occupational_group_free_text === ""
			) {
				setSaveProfileBtnDisabled(true)
			} else if (
				qualification === "Pflegehilfskraft/-assistenz" &&
				qualification_hilfskraft === "Weiteres" &&
				qualification_hilfskraft_freetext === ""
			) {
				setSaveProfileBtnDisabled(true)
			} else if (
				work_field === "Pflege & Medizin" &&
				departments?.length === 0 &&
				typeOfEmployerValuesForDepartmentFieldRender.some(val =>
					type_of_employer.includes(val)
				)
			) {
				setSaveProfileBtnDisabled(true)
			} else {
				setSaveProfileBtnDisabled(false)
			}
		}
	}, [profileReducer, typeOfEmployerValuesForDepartmentFieldRender])

	// Render
	if (profileReducer?.changes?.userType === "candidate") {
		return (
			<>
				<div className={styles.anchor} ref={anchorRef}></div>
				<div
					className={
						mediaQueryMdUp
							? styles.profilePageContainerContentLeft
							: styles.profilePageContainer
					}
				>
					<Container
						maxWidth="xs"
						className={
							mediaQueryMdUp
								? styles.topContainerPc
								: styles.topContainer
						}
					>
						<Grid
							container
							className={styles.basicDataGridContainer}
						>
							<h2>Kontaktdaten</h2>

							<ProfilePageFieldsCandidate
								setSaveProfileBtnDisabled={
									setSaveProfileBtnDisabled
								}
								fieldsType={
									profilePageFieldsCandidate.profileData
								}
								anchorRef={anchorRef}
							/>
						</Grid>

						<Grid
							container
							className={styles.basicDataGridContainer}
						>
							<h2>Job-Vorteile</h2>

							<ProfilePageFieldsCandidate
								fieldsType={profilePageFieldsCandidate.benefits}
								anchorRef={anchorRef}
							/>
						</Grid>

						<Grid
							container
							className={styles.basicDataGridContainer}
						>
							<h2>Berufsfeld</h2>

							<ProfilePageFieldsCandidate
								fieldsType={
									profilePageFieldsCandidate.workField
								}
								anchorRef={anchorRef}
							/>
						</Grid>

						<Grid
							container
							className={styles.basicDataGridContainer}
						>
							<h2>Qualifikation</h2>

							<ProfilePageFieldsCandidate
								fieldsType={
									profilePageFieldsCandidate.qualification
								}
								anchorRef={anchorRef}
							/>

							{/* Conditional rendering of fields for people with ongoing education */}
							{profileReducer.profileData.qualification ===
								"Noch in Ausbildung" && (
									<>
										<h2>Welche?</h2>
										<ProfilePageFieldsCandidate
											fieldsType={
												profilePageFieldsCandidate.ongoingEducation
											}
											anchorRef={anchorRef}
										/>

										<h2>Wann bist du (ungefähr) fertig?</h2>
										<ProfilePageFieldsCandidate
											fieldsType={
												profilePageFieldsCandidate.educationFinishDate
											}
											anchorRef={anchorRef}
										/>
									</>
								)}

							{/* Conditional rendering of fields for people who are Hilfskraft */}
							{profileReducer.profileData.qualification ===
								"Pflegehilfskraft/-assistenz" && (
									<>
										<h2>
											Welche Qualifizierung bringst du mit?
										</h2>
										<ProfilePageFieldsCandidate
											fieldsType={
												profilePageFieldsCandidate.qualificationHilfskraft
											}
											anchorRef={anchorRef}
										/>

										{profileReducer.profileData
											.qualification_hilfskraft ===
											"Weiteres" && (
												<>
													<h2>Okay, welche genau?</h2>
													<ProfilePageFieldsCandidate
														fieldsType={
															profilePageFieldsCandidate.qualificationHilfskraftFreeText
														}
														anchorRef={anchorRef}
													/>
												</>
											)}
									</>
								)}

							{/* Conditional rendering of fields for people with other qualifications */}
							{profileReducer.profileData.qualification ===
								"Weiteres" && (
									<>
										<h2>Welche?</h2>
										<ProfilePageFieldsCandidate
											fieldsType={
												profilePageFieldsCandidate.occupationalGroup
											}
											anchorRef={anchorRef}
										/>

										{profileReducer.profileData
											.occupational_group === "Weiteres" && (
												<>
													<h2>Okay, welche genau?</h2>
													<ProfilePageFieldsCandidate
														fieldsType={
															profilePageFieldsCandidate.occupationalGroupFreeText
														}
														anchorRef={anchorRef}
													/>
												</>
											)}
									</>
								)}
						</Grid>

						{/* Showing departments when type_of_employer is one of the values stated in typeOfEmployerValuesForDepartmentFieldRender */}
						{profileReducer.profileData.work_field ===
							"Pflege & Medizin" &&
							profileReducer.profileData?.type_of_employer
								?.length > 0 &&
							typeOfEmployerValuesForDepartmentFieldRender.some(
								val =>
									profileReducer.profileData.type_of_employer.includes(
										val
									)
							) && (
								<Grid
									container
									className={styles.basicDataGridContainer}
								>
									<h2>Fachbereich</h2>

									<ProfilePageFieldsCandidate
										fieldsType={
											profilePageFieldsCandidate.departments
										}
										anchorRef={anchorRef}
									/>
								</Grid>
							)}

						<Grid
							container
							className={styles.basicDataGridContainer}
						>
							<h2>Bevorzugte Einrichtungen</h2>

							<ProfilePageFieldsCandidate
								fieldsType={
									profilePageFieldsCandidate.typeOfEmployer
								}
								anchorRef={anchorRef}
							/>
						</Grid>
					</Container>

					<Container
						maxWidth="xs"
						className={
							mediaQueryMdUp
								? styles.topContainerPc
								: styles.topContainer
						}
					>
						<Grid
							container
							className={styles.basicDataGridContainer}
						>
							<h2>Wunschorte</h2>

							<ProfilePageFieldsCandidate
								fieldsType={profilePageFieldsCandidate.location}
								anchorRef={anchorRef}
							/>
						</Grid>

						<Grid
							container
							className={styles.basicDataGridContainer}
						>
							<h2>Umfang</h2>

							<ProfilePageFieldsCandidate
								fieldsType={
									profilePageFieldsCandidate.employmentForm
								}
								anchorRef={anchorRef}
							/>

							{/* Conditional rendering of fields for people with various employment forms */}
							{(profileReducer.profileData.employment_form.includes(
								"Vollzeit"
							) ||
								profileReducer.profileData.employment_form.includes(
									"Teilzeit"
								)) && (
									<>
										<h2>
											Was möchtest du mindestens verdienen?
											(Bruttomonatsgehalt, ohne Zuschläge)
										</h2>
										<ProfilePageFieldsCandidate
											fieldsType={
												profilePageFieldsCandidate.salaryExpectationMonth
											}
											anchorRef={anchorRef}
										/>
										{profileReducer.profileData.employment_form.includes(
											"Teilzeit"
										) && (
												<>
													<h2>
														Wie viele Stunden pro Woche
														möchtest du arbeiten?
													</h2>
													<ProfilePageFieldsCandidate
														fieldsType={
															profilePageFieldsCandidate.hoursPerWeek
														}
														anchorRef={anchorRef}
													/>
												</>
											)}
									</>
								)}
						</Grid>

						<Grid
							container
							className={styles.basicDataGridContainer}
						>
							<h2>Wie schätzt du deine Deutschkenntnisse ein?</h2>

							<ProfilePageFieldsCandidate
								fieldsType={
									profilePageFieldsCandidate.germanLevel
								}
								anchorRef={anchorRef}
							/>

							{/* Conditional rendering of fields for people that do not have C2 level German proficiency */}
							{profileReducer.profileData.german_level !==
								"Muttersprache" && (
									<>
										<h2>
											Ist deine Ausbildung in Deutschland
											anerkannt?
										</h2>
										<ProfilePageFieldsCandidate
											fieldsType={
												profilePageFieldsCandidate.educationRecognized
											}
											anchorRef={anchorRef}
										/>
									</>
								)}
						</Grid>

						<Grid
							container
							className={styles.basicDataGridContainer}
						>
							<h2>{`Wie viele Jahre Berufserfahrung hast du in dem Bereich ${profileReducer.profileData.work_field}?`}</h2>

							<ProfilePageFieldsCandidate
								fieldsType={
									profilePageFieldsCandidate.totalWorkExperience
								}
								anchorRef={anchorRef}
							/>

							{/* Conditional rendering of fields for people with work experience */}
							{profileReducer.profileData
								.total_work_experience !== "Noch keine" && (
									<>
										<h2>
											Wer waren deine Arbeitgeber in den
											letzten 5 Jahren?
										</h2>
										<ProfilePageFieldsCandidate
											fieldsType={
												profilePageFieldsCandidate.workExperience
											}
											anchorRef={anchorRef}
										/>
									</>
								)}
						</Grid>

						{!mediaQueryMdUp && (
							<div className={styles.contentDivider}></div>
						)}

						<Grid
							container
							className={styles.basicDataGridContainer}
						>
							<Grid
								item
								xs={12}
								className={
									mediaQueryMdUp
										? styles.basicDataGridItem
										: styles.btnMobileContainer
								}
								id="profileDataSaveBtnGridItem"
								onClick={() => {
									if (saveProfileBtnDisabled) {
										dispatch(
											notificationsActions.sendNotificationAction(
												{
													open: true,
													message:
														"Bitte fülle die leeren Felder aus, die rot markiert sind.",
													type: "info",
													timeout: 5000,
												}
											)
										)
									}
								}}
							>
								<Button
									id="profileDataSaveBtn"
									className={
										mediaQueryMdUp
											? styles.saveBtn
											: styles.saveBtnMobile
									}
									disabled={saveProfileBtnDisabled}
									onClick={() => {
										setSaveProfileBtnDisabled(true)

										dispatch(
											profileActions.asyncPutProfileDataAction(
												{
													verifyEmail: false,
													refreshJobs: false,
													prepareCandidate: true,
												}
											)
										)

										// Record activity for Tracking
										dispatch(
											tagManagerActions.sendSimpleCustomEvent(
												{
													eventName:
														"saveProfilePageButtonCustomEvent",
												}
											)
										)
									}}
								>
									Daten speichern
								</Button>
							</Grid>
						</Grid>
					</Container>
				</div>
			</>
		)
	} else if (profileReducer?.changes?.userType === "affiliate") {
		return (
			<div
				className={
					mediaQueryMdUp
						? styles.profilePageContainerContentLeft
						: styles.profilePageContainer
				}
			>
				<Container maxWidth="sm" className={styles.topContainer}>
					<Grid container className={styles.basicDataGridContainer}>
						<h2>Kontaktdaten</h2>

						<ProfilePageFieldsAffiliate
							setSaveBtnDisabled={setSaveBtnDisabled}
						/>

						<Grid
							item
							xs={12}
							className={styles.basicDataGridItem}
							onClick={() => {
								if (saveBtnDisabled) {
									dispatch(
										notificationsActions.sendNotificationAction(
											{
												open: true,
												message:
													"Bitte fülle die leeren Felder aus, die rot markiert sind.",
												type: "info",
												timeout: 5000,
											}
										)
									)
								}
							}}
						>
							<Button
								className={styles.saveBtn}
								disabled={saveBtnDisabled}
								onClick={() => {
									setSaveBtnDisabled(true)

									dispatch(
										profileActions.asyncPutPromoterProfileDataAction(
											{
												verifyEmail: false,
												refreshJobs: false,
											}
										)
									)
								}}
							>
								Daten speichern
							</Button>
						</Grid>
					</Grid>
				</Container>
			</div>
		)
	} else {
		return null
	}
}
export default ProfilePage
