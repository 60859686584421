import React from "react"

// Material UI
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { Grid, useMediaQuery } from "@material-ui/core"

// Images
import quotationMarkSymbol from "./quotationMarkSymbol.svg"

// Redux
import { useSelector } from "react-redux"

// Styles
const useStyles = makeStyles({
	sectionHeader: {
		fontSize: 20,
		fontWeight: 600,
		lineHeight: 1.5,
		marginBottom: 16,
		textAlign: "center",
	},
	sectionGridContainer: {
		width: "100%",
		maxWidth: 1000,
		margin: "0 auto",
		marginBottom: 48,
	},
	employeeQuoteGridItem: {
		margin: "0 auto",
		maxWidth: 500,
		border: "1px solid #e8e8e8",
		backgroundColor: "#ffffff",
		borderRadius: 12,
		textAlign: "left",
	},
	employeeQuoteGridItemPC: {
		margin: "0 auto",
		maxWidth: 1000,
		border: "1px solid #e8e8e8",
		backgroundColor: "#ffffff",
		borderRadius: 12,
		textAlign: "left",
	},
	employeeQuoteSymbol: {
		marginTop: 50,
		marginBottom: 3,
		marginLeft: 32,
	},
	employeeQuoteText: {
		fontSize: 20,
		fontWeight: 600,
		margin: 0,
		marginLeft: 32,
		marginRight: 32,
	},
	employeeQuoteAuthor: {
		fontSize: 16,
		fontWeight: "normal",
		marginLeft: 32,
		marginRight: 32,
		marginTop: 8,
		marginBottom: 48,
	},
})

// Component
const ClientsEmployeeQuote = props => {
	// Hooks
	const styles = useStyles()
	const theme = useTheme()

	// Destructuring props
	const { employeeQuotesRef } = props

	// Media queries
	const mediaQueryMdUp = useMediaQuery(theme.breakpoints.up("md"))

	// Redux selectors
	const clientsReducer = useSelector(state => state.clientsReducer)

	// Render
	return (
		<>
			<h2 className={styles.sectionHeader} ref={employeeQuotesRef}>
				Über den Arbeitgeber
			</h2>
			{clientsReducer?.employee_quote &&
				clientsReducer?.employee_quote_name && (
					<Grid container className={styles.sectionGridContainer}>
						<Grid
							item
							xs={12}
							className={
								mediaQueryMdUp
									? styles.employeeQuoteGridItemPC
									: styles.employeeQuoteGridItem
							}
						>
							<img
								src={quotationMarkSymbol}
								alt="quotation mark"
								className={styles.employeeQuoteSymbol}
							/>
							<p className={styles.employeeQuoteText}>
								{clientsReducer.employee_quote}
							</p>
							<p className={styles.employeeQuoteAuthor}>
								{clientsReducer.employee_quote_name}
							</p>
						</Grid>
					</Grid>
				)}
		</>
	)
}

export default ClientsEmployeeQuote
