import React, { useState, useEffect, useRef } from "react"
import { useRouteMatch, Switch, Route, useHistory } from "react-router-dom"

// Data Structures
import questionsStructure, {
	slideHighestValue,
} from "./dataStructures/questionsStructure"
import questionsStructureSpecific, {
	slideHighestValueSpecific,
} from "./dataStructures/questionsStructureSpecific"
import funnelFieldOptions from "./dataStructures/funnelFieldOptions"
import slideMainText, {
	slideMainTextSpecific,
	slideMainTextAffiliate,
} from "./dataStructures/slideMainText"

// SEO
import generalSeoSchema from "../generalSeoSchema"
import { Helmet } from "react-helmet"

// Capacitor
import { Capacitor } from "@capacitor/core"

// Redux
import { useSelector, useDispatch } from "react-redux"
import * as clickFunnelActions from "./state/clickFunnelActions"
import * as jobsActions from "../jobs/state/jobsActions"
import * as tagManagerActions from "../tagManagerEvents/state/tagManagerActions"

// Firebase
import { ref, listAll, getDownloadURL } from "firebase/storage"
import { firebaseStorage } from "../firebase"

// Form Fields
import SingleChoiceField from "./fields/SingleChoiceField"
import MultipleChoiceField from "./fields/MultipleChoiceField"
import FreeTextField from "./fields/FreeTextField"
import LocationField from "./fields/LocationField"
import DatePickerField from "./fields/DatePickerField"
import UploadExperience from "./fields/UploadExperience"
import UploadAttachment from "./fields/UploadAttachment"
import ContactsDataField from "./fields/ContactsDataField"

// Components
import NextPreviousBtns from "./buttons/NextPreviousBtns"
import ProgressBar from "./ProgressBar"
import CompanyHeader from "./CompanyHeader"
import ProfileLayer from "./ProfileLayer"
import ClickFunnelFieldText from "./fields/ClickFunnelFieldText"

// Material UI
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { Grid, Container, useMediaQuery } from "@material-ui/core"

// CSS
import clickFunnelStyles from "./clickFunnelStyles"

const useStyles = makeStyles(theme => clickFunnelStyles(theme))

// Component
const ClickFunnel = props => {
	// Hooks
	const { path } = useRouteMatch()
	const styles = useStyles()
	const history = useHistory()
	const theme = useTheme()
	const dispatch = useDispatch()

	// Destructuring props
	const { fromProfile } = props

	// Media queries
	const mediaQueryMdUp = useMediaQuery(theme.breakpoints.up("md"))

	// References
	const anchorRef = useRef(null)

	// Local state
	const [funnelType, setFunnelType] = useState("aspecific")
	const [formSlide, setSlide] = useState(0)
	const [highestFormSlide, setHighestFormSlide] = useState(-1)
	const [questionTracker, modifyQuestionTracker] = useState([
		{
			questions: ["benefits"],
		},
	])
	const [clickedField, setClickedField] = useState([])
	const [citiesNumber, setCitiesNumber] = useState([1])
	const [companyLogo, setCompanyLogo] = useState(null)
	const [questionsStructureDynamic, setQuestionsStructureDynamic] =
		useState(questionsStructure)
	const [slideMainTextDynamic, setSlideMainTextDynamic] =
		useState(slideMainText)
	const [showFunnel, setShowFunnel] = useState(false)

	// Redux selectors
	const jobsReducer = useSelector(state => state.jobsReducer)
	const authReducer = useSelector(state => state.authReducer)
	const profileReducer = useSelector(state => state.profileReducer)

	// URL Parameters
	const queryString = window.location.search
	const urlParams = new URLSearchParams(queryString)
	const funnelTypeParam = urlParams.get("type")
	const jobIdParam = urlParams.get("jobId")

	// Tracking highest slide so far
	useEffect(() => {
		if (formSlide > highestFormSlide) {
			setHighestFormSlide(formSlide)
		}
	}, [formSlide, highestFormSlide])

	// Sending click funnel tracking event for non-affiliate funnels
	useEffect(() => {
		if (funnelType !== "beero" && showFunnel) {
			// adding +1 because most people start counting from 1
			dispatch(
				tagManagerActions.sendComplexCustomEvent({
					event: "clickFunnelGoForwardCustomEvent",
					clickFunnelCurrentSlideNumber: highestFormSlide + 1,
					clickFunnelType: funnelType,
					clickFunnelLength:
						funnelType === "specific"
							? slideHighestValueSpecific + 1
							: slideHighestValue + 1,
					clickFunnelPreviousQuestions: questionTracker
						.map(obj => obj.questions.join(", "))
						.join(", "),
				})
			)
		}
		// eslint-disable-next-line
	}, [highestFormSlide])

	// Redirecting to job/s when logged in
	useEffect(() => {
		if (profileReducer?.changes?.userType === "affiliate") {
		} else if (
			jobIdParam &&
			funnelTypeParam &&
			jobsReducer.job !== undefined &&
			jobsReducer.job !== "invalid id" &&
			authReducer.userLoggedIn
		) {
			history.push(`/jobs/${jobIdParam}`)
		} else if (
			jobIdParam &&
			funnelTypeParam &&
			jobsReducer.job === "invalid id" &&
			authReducer.userLoggedIn
		) {
			history.push("/jobs")
		} else if (authReducer.userLoggedIn && !fromProfile) {
			if (localStorage.getItem("shareJobId")) {
				const jobId = localStorage.getItem("shareJobId")
				history.push(
					profileReducer?.changes?.firstRegistration
						? `/jobs/${jobId}?firstRegistration=true`
						: `/jobs/${jobId}`
				)
				localStorage.removeItem("shareJobId")
			} else {
				history.push(
					profileReducer?.changes?.firstRegistration
						? `/jobs?firstRegistration=true`
						: "/jobs"
				)
			}
		}
	}, [
		profileReducer.changes,
		authReducer.userLoggedIn,
		jobsReducer.job,
		funnelTypeParam,
		jobIdParam,
		fromProfile,
		history,
	])

	// Showing form type
	useEffect(() => {
		if (
			funnelTypeParam === "specific" &&
			jobsReducer &&
			jobsReducer.job &&
			jobsReducer.job !== "invalid id"
		) {
			setFunnelType(funnelTypeParam)
			setQuestionsStructureDynamic(questionsStructureSpecific)
			setSlideMainTextDynamic(slideMainTextSpecific)
			setShowFunnel(true)
		} else if (funnelTypeParam === "beero") {
			setFunnelType(funnelTypeParam)
			modifyQuestionTracker([
				{
					questions: ["benefits", "work_field", "qualification"],
				},
				{
					questions: [
						"choice_type_of_employer",
						"choice_departments",
						"locations",
					],
				},
				{
					questions: ["employment_form"],
				},
				{
					questions: ["german_level"],
				},
				{
					questions: ["total_work_experience"],
				},
				{
					questions: ["contact_data"],
				},
			])
			setSlideMainTextDynamic(slideMainTextAffiliate)
			setSlide(5)
			setHighestFormSlide(5)
			setShowFunnel(true)
		} else if (
			funnelTypeParam === "specific" &&
			jobsReducer &&
			jobsReducer.job &&
			jobsReducer.job === "invalid id"
		) {
			setShowFunnel(true)
		} else if (
			funnelTypeParam !== "specific" ||
			(funnelTypeParam === "specific" && !jobIdParam)
		) {
			setShowFunnel(true)
		}
	}, [jobsReducer, funnelTypeParam, jobIdParam])

	// Call thunk to fetch job data on specific form
	useEffect(() => {
		jobIdParam &&
			dispatch(jobsActions.asyncGetSpecificJobAction(jobIdParam))
	}, [dispatch, jobIdParam])

	// Get images
	useEffect(() => {
		const getURL = (imageRef, imageType) => {
			if (Capacitor.isNativePlatform()) {
				imageRef._location.path_ = imageRef._location.path_.replaceAll(
					"/",
					"%2F"
				)
			}

			if (imageType === "companyLogo") {
				getDownloadURL(imageRef).then(url => {
					setCompanyLogo(url)
				})
			}
		}

		if (
			jobsReducer.job !== null &&
			jobsReducer.job !== undefined &&
			jobsReducer.job.zoho_client_id
		) {
			const storageRef = ref(
				firebaseStorage,
				`JobOpenings/${jobsReducer.job.zoho_job_openings_id}`
			)

			listAll(storageRef)
				.then(result => {
					if (result.items && result.items.length > 0) {
						const companyLogo = result.items.filter(el =>
							el.fullPath.includes("logo")
						)
						if (companyLogo.length > 0) {
							getURL(companyLogo[0], "companyLogo")
						}
					}
				})
				.catch(error => {
					console.log(error)
				})
		}
	}, [jobsReducer.job])

	// Scroll to top, when slide changes
	useEffect(() => {
		if (formSlide) {
			window.scrollTo(0, 0)
		}
	}, [formSlide])

	// Cleanup upon unmount
	useEffect(() => {
		return () => {
			modifyQuestionTracker([
				{
					questions: ["benefits"],
				},
			])
			setSlide(0)
			dispatch(clickFunnelActions.clearReducerAction())
		}
	}, [dispatch])

	// Render
	return (
		<Switch>
			<Route exact path={path}>
				{showFunnel && (
					<div
						className={
							mediaQueryMdUp
								? styles.clickFunnelContainerContentLeft
								: styles.clickFunnelContainer
						}
					>
						{/* SEO */}
						<Helmet>
							<title>Workbee - Registrierungsformular</title>
							<meta
								name="title"
								content="Workbee - Registrierungsformular"
							/>
							<meta
								name="description"
								content="Workbee - Registrierungsformular"
							/>
							<script type="application/ld+json">
								{JSON.stringify(generalSeoSchema)}
							</script>
						</Helmet>

						{/* Header */}
						<div ref={anchorRef}></div>

						<Container
							maxWidth="sm"
							className={styles.topContainer}
						>
							{/* Company Data */}
							{funnelType === "specific" && (
								<CompanyHeader companyLogo={companyLogo} />
							)}

							{/* Progress Bar */}
							<ProgressBar
								formSlide={formSlide}
								funnelType={funnelType}
							/>

							{/* Slide Main Text */}
							<h2>{slideMainTextDynamic[formSlide]}</h2>
						</Container>

						{fromProfile && <ProfileLayer />}

						{/* Funnel Slides */}
						{questionTracker.map((el, id) => {
							if (formSlide === id) {
								return el.questions.map(
									(question, questionId) => {
										const currentQuestionObj =
											questionsStructureDynamic[question]
										const { fieldName, fieldType } =
											currentQuestionObj

										// Fields Container
										return (
											<Container
												key={questionId}
												maxWidth="sm"
											>
												<Grid
													container
													spacing={1}
													justifyContent="center"
													key={questionId}
													className={
														styles.fieldsContainer
													}
												>
													{/* Field Text */}

													<ClickFunnelFieldText
														currentQuestionObj={
															currentQuestionObj
														}
														fieldName={fieldName}
														fieldType={fieldType}
													/>

													{/* Single Choice Fields */}

													{fieldType ===
														"singleChoice" &&
														funnelFieldOptions[
															fieldName
														].map(
															(
																fieldValue,
																id
															) => (
																<SingleChoiceField
																	id={id}
																	key={id}
																	fieldName={
																		fieldName
																	}
																	fieldValue={
																		fieldValue
																	}
																	formSlide={
																		formSlide
																	}
																	setSlide={
																		setSlide
																	}
																	clickedField={
																		clickedField
																	}
																	setClickedField={
																		setClickedField
																	}
																	question={
																		question
																	}
																	currentQuestionObj={
																		currentQuestionObj
																	}
																	questionTracker={
																		questionTracker
																	}
																	modifyQuestionTracker={
																		modifyQuestionTracker
																	}
																	funnelType={
																		funnelType
																	}
																/>
															)
														)}

													{/* Multiple Choice and Single Select Fields */}

													{(fieldType ===
														"multipleChoice" ||
														fieldType ===
															"singleChoiceSelect") && (
														<MultipleChoiceField
															id={id}
															key={id}
															fieldName={
																fieldName
															}
															formSlide={
																formSlide
															}
															setSlide={setSlide}
															clickedField={
																clickedField
															}
															setClickedField={
																setClickedField
															}
															question={question}
															currentQuestionObj={
																currentQuestionObj
															}
															questionTracker={
																questionTracker
															}
															modifyQuestionTracker={
																modifyQuestionTracker
															}
															funnelType={
																funnelType
															}
															anchorRef={
																anchorRef
															}
														/>
													)}

													{/* Free Text Fields */}

													{fieldType ===
														"freeText" && (
														<FreeTextField
															id={id}
															formSlide={
																formSlide
															}
															setSlide={setSlide}
															fieldName={
																fieldName
															}
															question={question}
															currentQuestionObj={
																currentQuestionObj
															}
															questionTracker={
																questionTracker
															}
															modifyQuestionTracker={
																modifyQuestionTracker
															}
															funnelType={
																funnelType
															}
														/>
													)}

													{/* Location Field */}

													{fieldType ===
														"locationField" && (
														<LocationField
															citiesNumber={
																citiesNumber
															}
															setCitiesNumber={
																setCitiesNumber
															}
															id={id}
															key={id}
															fieldName={
																fieldName
															}
															formSlide={
																formSlide
															}
															setSlide={setSlide}
															clickedField={
																clickedField
															}
															setClickedField={
																setClickedField
															}
															question={question}
															currentQuestionObj={
																currentQuestionObj
															}
															questionTracker={
																questionTracker
															}
															modifyQuestionTracker={
																modifyQuestionTracker
															}
															funnelType={
																funnelType
															}
														/>
													)}

													{/* Date Picker Field */}

													{fieldType ===
														"datePicker" && (
														<DatePickerField
															key={id}
															fieldName={
																fieldName
															}
															formSlide={
																formSlide
															}
															setSlide={setSlide}
															clickedField={
																clickedField
															}
															setClickedField={
																setClickedField
															}
															question={question}
															currentQuestionObj={
																currentQuestionObj
															}
															questionTracker={
																questionTracker
															}
															modifyQuestionTracker={
																modifyQuestionTracker
															}
															funnelType={
																funnelType
															}
														/>
													)}

													{/* Exam Scan */}

													{fieldType ===
														"uploadAttachment" && (
														<UploadAttachment
															key={id}
															fieldName={
																fieldName
															}
															formSlide={
																formSlide
															}
															setSlide={setSlide}
															clickedField={
																clickedField
															}
															setClickedField={
																setClickedField
															}
															question={question}
															currentQuestionObj={
																currentQuestionObj
															}
															questionTracker={
																questionTracker
															}
															modifyQuestionTracker={
																modifyQuestionTracker
															}
															funnelType={
																funnelType
															}
														/>
													)}

													{/* Work Experience */}

													{fieldType ===
														"uploadWorkExperience" && (
														<UploadExperience
															funnelFieldOptions={
																funnelFieldOptions[
																	"work_experience"
																]
															}
															key={id}
															fieldName={
																fieldName
															}
															formSlide={
																formSlide
															}
															setSlide={setSlide}
															clickedField={
																clickedField
															}
															setClickedField={
																setClickedField
															}
															question={question}
															currentQuestionObj={
																currentQuestionObj
															}
															questionTracker={
																questionTracker
															}
															modifyQuestionTracker={
																modifyQuestionTracker
															}
															funnelType={
																funnelType
															}
															anchorRef={
																anchorRef
															}
														/>
													)}

													{/* Contact Data */}

													{fieldType ===
														"contactData" && (
														<ContactsDataField
															clickedField={
																clickedField
															}
															setClickedField={
																setClickedField
															}
															fromProfile={
																fromProfile
															}
														/>
													)}
												</Grid>
											</Container>
										)
									}
								)
							} else {
								return null
							}
						})}

						{/* Next & Previous Buttons */}

						<NextPreviousBtns
							fromProfile={fromProfile}
							formSlide={formSlide}
							setSlide={setSlide}
							questionTracker={questionTracker}
							funnelType={funnelType}
						/>
					</div>
				)}
			</Route>
		</Switch>
	)
}

export default ClickFunnel
