import React, { useState, useEffect } from "react"

// Material UI
import { makeStyles } from "@material-ui/core/styles"
import { Grid } from "@material-ui/core"

// Icons
import jobCardIconDepartment from "../../../images/jobCardIcons/jobCardIconDepartment.png"
import jobCardIconEmploymentForm from "../../../images/jobCardIcons/jobCardIconEmploymentForm.png"
import jobCardIconSalary from "../../../images/jobCardIcons/jobCardIconSalary.png"
import jobCardIconEmployer from "../../../images/jobCardIcons/jobCardIconEmployer.png"
import jobCardIconPalmTree from "../../../images/jobCardIcons/jobCardIconPalmTree.png"

// Styles
const useStyles = makeStyles({
	jobPillDataGridItem: {
		marginRight: 8,
		marginBottom: 8,
		padding: "4px 12px",
		borderRadius: 21.5,
		backgroundColor: "rgba(58, 93, 171, 0.1)",
	},
	goldContainer: {
		borderRadius: 21.5,
		marginRight: 6,
		boxShadow: "0px 1px 10px 1px rgba(255,215,0,0.4)",
	},
	jobPillDataIconGold: {
		height: "12px !important",
		width: "12px !important",
	},
	jobPillDataGridItemGold: {
		marginRight: 0,
		marginBottom: 8,
		padding: "4px 12px",
		borderRadius: 21.5,
		background:
			"radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #bfa370 30%, #a8905f 40%, transparent 80%),radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 15%, #e6d7b0 25%, #7b6740 62.5%, #7b6740 100%);",
		backgroundSize: "125%",
	},
	jobPillDataGridContainer: {
		paddingLeft: 20,
		fontSize: 13,
		margin: 0,
	},
	jobPillDataParagraph: {
		fontSize: 10,
		fontWeight: "bold",
		margin: 0,
		lineHeight: 1.6,
		display: "flex",
		alignItems: "center",
	},
	jobPillDataIcon: {
		height: "15px !important",
		width: "15px !important",
		marginRight: 3,
	},
})

// Component
const JobCardPills = props => {
	// Hooks
	const styles = useStyles()

	// Destructuring props
	const { jobs, job } = props

	// Local state
	const [showAllDepartments, setShowAllDepartments] = useState(false)
	const [showSalaryPill, setShowSalaryPill] = useState(false)

	// Determine if salary pill can be shown
	useEffect(() => {
		if (
			jobs[job].salary_from !== 0 &&
			jobs[job].salary_to !== 0 &&
			jobs[job].salary_from !== undefined &&
			jobs[job].salary_to !== undefined &&
			jobs[job].salary_from !== null &&
			jobs[job].salary_to !== null
		) {
			setShowSalaryPill(true)
		} else {
			setShowSalaryPill(false)
		}
	}, [job, jobs])

	// Render
	return (
		<Grid
			container
			className={styles.jobPillDataGridContainer}
			direction="row"
			alignItems="flex-start"
		>
			{/* Salary */}
			{showSalaryPill && (
				<Grid item xs="auto" className={styles.lessSpacing}>
					{jobs[job].top_salary_seal ? (
						// Golden salary pill
						<div className={styles.goldContainer}>
							<div className={styles.jobPillDataGridItemGold}>
								<p className={styles.jobPillDataParagraph}>
									{`${jobs[job].salary_from} - ${jobs[job].salary_to} €`}
								</p>
							</div>
						</div>
					) : (
						// Standard salary pill
						<div className={styles.jobPillDataGridItem}>
							<p className={styles.jobPillDataParagraph}>
								<img
									src={jobCardIconSalary}
									alt="salary"
									className={styles.jobPillDataIcon}
								/>{" "}
								{`${jobs[job].salary_from} - ${jobs[job].salary_to} €`}
							</p>
						</div>
					)}
				</Grid>
			)}
			{/* Vacation days */}
			{jobs[job].vacation_days && jobs[job].vacation_days > 0 && (
				<Grid item xs="auto" className={styles.lessSpacing}>
					{jobs[job].vacation_days > 30 ? (
						<div className={styles.goldContainer}>
							<div className={styles.jobPillDataGridItemGold}>
								<p className={styles.jobPillDataParagraph}>
									<img
										alt="palmtree"
										src={jobCardIconPalmTree}
										className={styles.jobPillDataIcon}
									/>{" "}
									bis zu {jobs[job].vacation_days} Urlaubstage
								</p>
							</div>
						</div>
					) : (
						<div className={styles.jobPillDataGridItem}>
							<p className={styles.jobPillDataParagraph}>
								<img
									alt="palmtree"
									src={jobCardIconPalmTree}
									className={styles.jobPillDataIcon}
								/>{" "}
								bis zu {jobs[job].vacation_days} Urlaubstage
							</p>
						</div>
					)}
				</Grid>
			)}

			{/* Employment form */}
			{jobs[job].employment_form &&
				jobs[job].employment_form.length > 0 && (
					<Grid item xs="auto" className={styles.jobPillDataGridItem}>
						<p className={styles.jobPillDataParagraph}>
							<img
								alt="employment form"
								src={jobCardIconEmploymentForm}
								className={styles.jobPillDataIcon}
							/>{" "}
							{jobs[job].employment_form
								.map(el => el.employment_form_name)
								.join(", ")}
						</p>
					</Grid>
				)}

			{/* Departments */}
			{jobs[job].departments && jobs[job].departments.length > 0 && (
				<Grid
					item
					xs="auto"
					className={styles.jobPillDataGridItem}
					onClick={e => {
						e.preventDefault()

						if (showAllDepartments) {
							setShowAllDepartments(false)
						} else {
							setShowAllDepartments(true)
						}
					}}
				>
					<p className={styles.jobPillDataParagraph}>
						<img
							alt="departments"
							src={jobCardIconDepartment}
							className={styles.jobPillDataIcon}
						/>{" "}
						{/* showing only first 3 departments */}
						{!showAllDepartments &&
							jobs[job].departments &&
							jobs[job].departments.length &&
							jobs[job].departments.length > 3 &&
							jobs[job].departments
								.map(el => el.department_name)
								.slice(0, 3)
								.join(", ") + ", ..."}
						{/* showing all departments */}
						{(showAllDepartments ||
							(jobs[job].departments &&
								jobs[job].departments.length &&
								jobs[job].departments.length <= 3)) &&
							jobs[job].departments
								.map(el => el.department_name)
								.join(", ")}
					</p>
				</Grid>
			)}

			{/* Type of employer */}
			{jobs[job].type_of_employer && (
				<Grid item xs="auto" className={styles.jobPillDataGridItem}>
					<p className={styles.jobPillDataParagraph}>
						<img
							src={jobCardIconEmployer}
							alt="type_of_employer"
							className={styles.jobPillDataIcon}
						/>{" "}
						{jobs[job].type_of_employer}
					</p>
				</Grid>
			)}
		</Grid>
	)
}

export default JobCardPills
