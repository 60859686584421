export const profilePageFieldsAffiliate = [
	{
		fieldName: "salutation",
		fieldType: "salutation",
	},
	{
		fieldName: "first_name",
		fieldType: "freeText",
	},
	{
		fieldName: "last_name",
		fieldType: "freeText",
	},
	{
		fieldName: "phone",
		fieldType: "freeText",
	},
]

export const profilePageFieldsCandidate = {
	profileData: [
		{
			fieldName: "salutation",
			fieldType: "salutation",
		},
		{
			fieldName: "first_name",
			fieldType: "freeText",
		},
		{
			fieldName: "last_name",
			fieldType: "freeText",
		},
		{
			fieldName: "phone",
			fieldType: "freeText",
		},
	],
	benefits: [
		{
			fieldName: "benefits",
			fieldType: "multipleChoice",
		},
	],
	workField: [
		{
			fieldName: "workField",
			fieldType: "workField",
		},
	],
	qualification: [
		{
			fieldName: "qualification",
			fieldType: "singleChoice",
		},
	],
	occupationalGroup: [
		{
			fieldName: "occupational_group",
			fieldType: "singleChoice",
		},
	],
	occupationalGroupFreeText: [
		{
			fieldName: "occupational_group_free_text",
			fieldType: "freeText",
		},
	],
	ongoingEducation: [
		{
			fieldName: "ongoing_education",
			fieldType: "singleChoice",
		},
	],
	educationFinishDate: [
		{
			fieldName: "education_finish_date",
			fieldType: "dateField",
		},
	],
	qualificationHilfskraft: [
		{
			fieldName: "qualification_hilfskraft",
			fieldType: "singleChoice",
		},
	],
	qualificationHilfskraftFreeText: [
		{
			fieldName: "qualification_hilfskraft_freetext",
			fieldType: "freeText",
		},
	],
	typeOfEmployer: [
		{
			fieldName: "type_of_employer",
			fieldType: "multipleChoice",
		},
	],
	departments: [
		{
			fieldName: "departments",
			fieldType: "multipleChoice",
		},
	],
	location: [
		{
			fieldName: "locations",
			fieldType: "location",
		},
	],
	employmentForm: [
		{
			fieldName: "employment_form",
			fieldType: "pickMultiple",
		},
	],
	salaryExpectationMonth: [
		{
			fieldName: "salary_expectation_month",
			fieldType: "singleChoice",
		},
	],
	hoursPerWeek: [
		{
			fieldName: "hours_per_week",
			fieldType: "freeText",
		},
	],
	germanLevel: [
		{
			fieldName: "german_level",
			fieldType: "singleChoice",
		},
	],
	educationRecognized: [
		{
			fieldName: "education_recognized",
			fieldType: "singleChoice",
		},
	],
	totalWorkExperience: [
		{
			fieldName: "total_work_experience",
			fieldType: "singleChoice",
		},
	],
	workExperience: [
		{
			fieldName: "work_experience",
			fieldType: "workExperience",
		},
	],
}
