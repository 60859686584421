import * as actions from "./profileActions"

// Data Structures
import fieldOptions from "../../jobs/components/jobsListPage/jobFilterModal/fieldOptions"

// Initial state
export const initialState = {
	changes: {
		accordionIsOpen: false,
		accordionComponentType: null,
		setWantsTab: false,
		complete_profile: false,
		fromApp: true,
		profileDataLoaded: false,
		emailVerified: null,
		firstRegistration: false,
	},
}

// Reducer
export default function profileReducer(state = initialState, action) {
	switch (action.type) {
		case actions.GET_PROFILE_DATA_INIT:
			return state
		case actions.GET_PROFILE_DATA_ERROR:
			return state
		case actions.GET_PROFILE_DATA_SUCCESS:
			let {
				salutation,
				first_name,
				last_name,
				phone,
				qualification,
				commute_time,
				zip_code,
				contract_type,
				locations,
				earliest_start_date,
				employment_form,
				hours_per_week,
				occupational_group,
				occupational_group_free_text,
				salary_expectation_month,
				shifts,
				type_of_employer,
				email_opt_out,
				email_verified,
				drivers_license,
				education_recognized,
				extra_qualifications,
				german_level,
				work_experience,
				total_work_experience,
				complete_profile,
				job_opening_ids,
				diploma_present,
				qualification_of_interest,
				recruiter,
				departments,
				from_app,
				ab_test,
				benefits,
				zoho_candidate_id,
				affiliate_id,
				email,
				ongoing_education,
				education_finish_date,
				qualification_hilfskraft,
				qualification_hilfskraft_freetext,
			} = action.payload

			// Remapping backend response, so it can be used in the frontend
			contract_type = contract_type.map(el => el.contract_type_name)
			departments = departments.map(el => el.department_name)
			employment_form = employment_form.map(el => el.employment_form_name)
			extra_qualifications = extra_qualifications.map(
				el => el.extra_qualification_name
			)
			qualification = qualification[0]["qualification_name"]
			qualification_of_interest = qualification_of_interest.map(
				el => el.qualification_of_interest_name
			)
			shifts = shifts.map(el => el.shift_name)
			type_of_employer = type_of_employer.map(
				el => el.type_of_employer_name
			)

			let profileData = JSON.parse(
				JSON.stringify(
					{
						salutation: salutation,
						first_name: first_name,
						last_name: last_name,
						phone: phone,
						qualification: qualification,
						occupational_group: occupational_group,
						occupational_group_free_text:
							occupational_group_free_text,
						zip_code: zip_code,
						email: email,
						work_field: fieldOptions.qualification.includes(
							qualification
						)
							? "Pflege & Medizin"
							: "Pädagogik & Soziale Arbeit",
						contract_type: contract_type,
						locations: locations,
						earliest_start_date: earliest_start_date,
						employment_form: employment_form,
						hours_per_week: hours_per_week,
						salary_expectation_month: salary_expectation_month,
						shifts: shifts,
						commute_time: commute_time,
						type_of_employer: type_of_employer,
						email_opt_out: email_opt_out,
						qualification_of_interest,
						departments: departments,
						benefits: benefits.map(
							benefit => benefit.benefits_name
						),
						drivers_license: drivers_license,
						education_recognized: education_recognized,
						extra_qualifications: extra_qualifications,
						german_level: german_level,
						total_work_experience: total_work_experience,
						ongoing_education: ongoing_education,
						education_finish_date: education_finish_date,
						work_experience,
						diploma_present,
						qualification_hilfskraft: qualification_hilfskraft,
						qualification_hilfskraft_freetext:
							qualification_hilfskraft_freetext,
					},
					function (key, value) {
						if (key === "salutation") {
							return value === "keine Angabe" ||
								value === "None" ||
								value === ""
								? "keine Angabe"
								: value
						} else if (
							key === "contract_type" ||
							key === "employment_form" ||
							key === "shifts" ||
							key === "type_of_employer" ||
							key === "locations" ||
							key === "qualification_of_interest" ||
							key === "departments" ||
							key === "benefits" ||
							key === "extra_qualifications"
						) {
							return value === null ? [] : [value].flat()
						} else if (key === "work_experience") {
							const filteredWorkExperience = value.filter(
								item => {
									return !(
										item.company === "" &&
										item.period_length === ""
									)
								}
							)

							return value === null
								? [{ company: "", period_length: "" }]
								: filteredWorkExperience
						} else {
							return value == null ? "" : value
						}
					}
				)
			)

			let recruiterData = JSON.parse(
				JSON.stringify(
					{
						email: recruiter.email,
						first_name: recruiter.first_name,
						last_name: recruiter.last_name,
						phone: recruiter.phone,
						gender: recruiter.gender,
						zoho_recruiter_id: recruiter.zoho_recruiter_id,
					},
					function (key, value) {
						return value == null ? "" : value
					}
				)
			)

			return {
				...state,
				profileData: profileData,
				recruiterData: {
					...state.recruiterData,
					...recruiterData,
				},
				changes: {
					...state.changes,
					oldProfileData: JSON.stringify({
						profileData,
					}),
					newProfileData: JSON.stringify({
						profileData,
					}),
					canUpdate: false,
					isUpdating: false,
					complete_profile: complete_profile,
					job_opening_ids: job_opening_ids ? job_opening_ids : [],
					from_app: from_app,
					ab_test: ab_test,
					profileDataLoaded: true,
					zoho_candidate_id: zoho_candidate_id,
					emailVerified: email_verified,
					userType: "candidate",
					affiliateId: affiliate_id,
				},
			}
		case actions.GET_PROMOTER_PROFILE_DATA_SUCCESS:
			let basicDataPromoter = JSON.parse(
				JSON.stringify(
					{
						salutation: action.payload.salutation,
						first_name: action.payload.first_name,
						last_name: action.payload.last_name,
						phone: action.payload.phone,
						email: action.payload.email,
						locations: [],
					},
					function (key, value) {
						if (key === "salutation") {
							return value === "keine Angabe" ||
								value === "None" ||
								value === ""
								? "keine Angabe"
								: value
						} else {
							return value == null ? "" : value
						}
					}
				)
			)

			return {
				...state,
				profileData: basicDataPromoter,
				changes: {
					...state.changes,
					canUpdate: false,
					isUpdating: false,
					profileDataLoaded: true,
					emailVerified: action.payload.email_verified,
					userType: "affiliate",
					affiliateId: action.payload.affiliate_id,
				},
			}
		case actions.PUT_PROFILE_DATA_INIT:
			return {
				...state,
				changes: {
					...state.changes,
					isUpdating: true,
				},
			}
		case actions.PUT_PROFILE_DATA_SUCCESS:
			return {
				...state,
				changes: {
					...state.changes,
					isUpdating: false,
					isUpdated: true,
					canUpdate: false,
				},
			}
		case actions.PUT_PROFILE_DATA_ERROR:
			return state
		case actions.CHANGE_PROFILE_FIELD:
			let { fieldName, fieldNewValue, profileCategory } = action.payload

			// cleaning user choice
			if (
				fieldNewValue === "Altenpflege" ||
				fieldNewValue === "Krankenpflege"
			) {
				return {
					...state,
					[profileCategory]: {
						...state[profileCategory],
						[fieldName]: fieldNewValue,
						qualification: "",
					},
				}
			} else {
				return {
					...state,
					[profileCategory]: {
						...state[profileCategory],
						[fieldName]: fieldNewValue,
					},
				}
			}
		case actions.REMOVE_PROFILE_ITEM_MULTIPLE_CHOICE:
			let mcProfileFieldOld =
				state[action.payload.profileCategory][action.payload.fieldName]
			let filteredProfileData = mcProfileFieldOld.filter(
				el => el !== action.payload.fieldToRemove
			)
			let mcProfileFieldNew = [...filteredProfileData]

			return {
				...state,
				[action.payload.profileCategory]: {
					...state[action.payload.profileCategory],
					[action.payload.fieldName]: mcProfileFieldNew,
				},
			}
		case actions.SET_PROFILE_DESIRED_LOCATION:
			let currentLocations = [...state.profileData.locations]
			let newLocations = [...currentLocations]

			let fieldValue = action.fieldValue ? action.fieldValue : null
			let lat = action.lat ? action.lat : null
			let lng = action.lng ? action.lng : null

			// Create the new location object
			let newLocation = {
				location_name: fieldValue,
				coordinates: {
					latitude: lat,
					longitude: lng,
				},
			}

			if (newLocations[action.id] === null) {
				newLocations.splice(action.id, 1, newLocation)
			} else {
				newLocations.splice(action.id, 1, newLocation)
			}

			return {
				...state,
				profileData: {
					...state.profileData,
					locations: newLocations,
				},
			}
		case actions.REMOVE_PROFILE_DESIRED_LOCATION:
			let removeCurrentLocations = [...state.profileData.locations]
			let removeNewLocations = [...removeCurrentLocations]
			removeNewLocations.splice(action.id, 1)

			return {
				...state,
				profileData: {
					...state.profileData,
					locations: removeNewLocations,
				},
			}
		case actions.CHECK_PROFILE_CHANGES:
			let oldProfileData = state.changes.oldProfileData
			let newProfileData = JSON.stringify({
				...state.profileData,
			})

			if (oldProfileData !== newProfileData) {
				return {
					...state,
					changes: {
						...state.changes,
						newProfileData: newProfileData,
						canUpdate: true,
					},
				}
			} else {
				return {
					...state,
					changes: {
						...state.changes,
						canUpdate: false,
					},
				}
			}
		case actions.LOGOUT_CLEAN_DATA:
			return {
				changes: {
					accordionIsOpen: false,
					accordionComponentType: null,
					setWantsTab: false,
					complete_profile: false,
				},
			}
		case actions.PUT_ATTACHMENT_INIT:
			return state
		case actions.PUT_ATTACHMENT_SUCCESS:
			return state
		case actions.PUT_ATTACHMENT_ERROR:
			return state
		case actions.POST_ATTACHMENT_INIT:
			return state
		case actions.POST_ATTACHMENT_SUCCESS:
			return state
		case actions.POST_ATTACHMENT_ERROR:
			return state
		case actions.SET_RECRUITER_PHOTO_URL:
			return {
				...state,
				recruiterData: {
					...state.recruiterData,
					photoUrl: action.photoUrl,
				},
			}
		case actions.SET_RECRUITER_ACCORDION:
			return {
				...state,
				changes: {
					...state.changes,
					accordionIsOpen: action.isOpen,
					accordionComponentType: action.componentType,
				},
			}
		case actions.SET_WANTS_TAB:
			return {
				...state,
				changes: {
					...state.changes,
					setWantsTab: action.isSet,
				},
			}
		case actions.SET_PROFILE_DATA_FROM_COPY:
			return {
				...state,
				profileData: action.profileData.profileData,
			}
		case actions.ADD_EXPERIENCE_PROFILE_FIELD:
			return {
				...state,
				profileData: {
					...state.profileData,
					work_experience: [
						...state.profileData.work_experience,
						{ company: "", period_length: "" },
					],
				},
			}
		case actions.CHANGE_EXPERIENCE_PROFILE_FIELD:
			let oldProfileWorkExperience = [
				...state.profileData.work_experience,
			]
			oldProfileWorkExperience[action.payload.id][action.payload.type] =
				action.payload.experienceProfileNewValue
			let newProfileWorkExperience = [...oldProfileWorkExperience]

			return {
				...state,
				profileData: {
					...state.profileData,
					work_experience: newProfileWorkExperience,
				},
			}
		case actions.REMOVE_EXPERIENCE_PROFILE_FIELD:
			let removedProfileExperienceField = [
				...state.profileData.work_experience,
			]
			removedProfileExperienceField.splice(action.id, 1)

			return {
				...state,
				profileData: {
					...state.profileData,
					work_experience: removedProfileExperienceField,
				},
			}
		case actions.SET_FIRST_REGISTRATION:
			return {
				...state,
				changes: {
					...state.changes,
					firstRegistration: true,
				},
			}
		default:
			return state
	}
}
