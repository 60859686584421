const standardProfileFieldNames = {
	salutation: "Anrede",
	first_name: "Vorname",
	last_name: "Nachname",
	email: "E-Mail",
	email_confirmation: "E-Mail Bestätigung",
	phone: "Telefon",
	work_field: "Bereich",
	qualification: "Qualifikation",
	city: "Stadt",
	country: "Land",
	zip_code: "Postleitzahl",
	availability: "Erreichbarkeit",
	contract_type: "Vertragsart",
	locations: "Wunschort",
	earliest_start_date: "Frühestmöglicher Start",
	employment_form: "Beschäftigungsform",
	hours_per_week: "Stunden pro Woche",
	occupational_group: "Sonstige Position",
	occupational_group_free_text: "Deine Berufsgruppe",
	salary_expectation_month: "Wunschgehalt pro Monat",
	shifts: "Schichten",
	type_of_employer: "Wunscheinrichtung",
	email_opt_out: "Abmeldung E-Mail",
	departments: "Fachbereich",
	country_of_education: "Ausbildungsland",
	drivers_license: "Führerschein",
	education_germany: "Ausbildung in Deutschland absolviert",
	education_recognized: "Ausbildung in Deutschland anerkannt",
	extra_qualifications: "Zusatzqualifikationen",
	german_level: "Deutschkenntnisse",
	total_work_experience: "Berufserfahrung",
	work_experience: "Berufserfahrung in den letzten 5 Jahren",
	commute_time: "Pendelzeit",
	password: "Passwort",
	diploma_present: "Urkunde-Scan",
	qualification_of_interest: "Ich möchte arbeiten als:",
	education_finish_date: "",
	benefits: "Vorteile",
	gdprCheckboxChecked: "Zustimmung zu AGBs",
}

export default standardProfileFieldNames
