import React, { useEffect, useState, useRef } from "react"
import {
	useRouteMatch,
	Switch,
	Route,
	useLocation,
	useHistory,
} from "react-router-dom"

// Capacitor
import { Capacitor } from "@capacitor/core"

// Components
import Job from "../jobPage/Job"
import JobFilterModal from "./jobFilterModal/JobFilterModal"
import YourRecruiterField from "../../../profile/YourRecruiterField"
import JobCard from "./JobCard"
import FilterChips from "./FilterChips"

// SEO
import generalSeoSchema from "../../../generalSeoSchema"
import { Helmet } from "react-helmet"

// Redux
import { useDispatch, useSelector } from "react-redux"
import * as jobsActions from "../../state/jobsActions"
import * as profileActions from "../../../profile/state/profileActions"
import * as tutorialActions from "../../../tutorial/state/tutorialActions"
import * as tagManagerActions from "../../../tagManagerEvents/state/tagManagerActions"
import * as authActions from "../../../auth/state/authActions"

// Firebase
import { firebaseStorage } from "../../../firebase"
import { ref, listAll, getDownloadURL } from "firebase/storage"

// Material UI
import { useTheme, makeStyles } from "@material-ui/core/styles"
import {
	Container,
	Paper,
	Button,
	Grid,
	useMediaQuery,
} from "@material-ui/core"
import Skeleton from "@material-ui/lab/Skeleton"

// Material UI icons
import CloseIcon from "@material-ui/icons/Close"

// Images
import logoNoHat from "../../../images/logoNoHat.png"
import greenSeal from "../../../images/greenSeal.png"

// Custom Hooks
import { useScrollPosition } from "../../../hooks/useScrollPosition"

// Styles
const useStyles = makeStyles({
	containerBig: {
		maxWidth: 600,
		fontFamily: "'Nunito', sans-serif",
		color: "#110c08",
	},
	container: {
		paddingTop: 80,
		paddingBottom: 60,
	},
	containerWithLessPadding: {
		paddingTop: 30,
		paddingBottom: 60,
	},
	verificationGridItem: {
		fontFamily: "'Nunito', sans-serif",
		marginTop: 56,
		width: "100%",
		backgroundColor: "#fdf4cc",
	},
	verificationGridItemClose: {
		cursor: "pointer",
	},
	greenSeal: {
		width: 24,
		height: 24,
		marginRight: 6,
	},
	logoNoHat: {
		width: 26,
		marginBottom: 12,
		marginLeft: 6,
	},
	verificationHeader: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		padding: 16,
		fontSize: 14,
		fontWeight: "bold",
		margin: 0,
	},
	alternativeHeader: {
		display: "flex",
		alignItems: "center",
		fontSize: 14,
		padding: 0,
		margin: 0,
	},
	verificationParagraph: {
		padding: 16,
		paddingTop: 0,
		margin: 0,
		fontSize: 12,
	},
	verificationButton: {
		padding: 16,
		paddingTop: 0,
		margin: 0,
		fontSize: 12,
		fontWeight: 600,
		color: "#3a5dab",
		cursor: "pointer",
	},
	contentLeft: {
		marginLeft: 200,
	},
	skeletonJobCardGridItem: {
		paddingTop: "4px !important",
		paddingBottom: "4px !important",
	},
	noRelevantJobsPaper: {
		borderRadius: 16,
		padding: 16,
		color: "#3c424f",
		fontSize: 16,
		fontWeight: "normal",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: 1.38,
		letterSpacing: "normal",
		"& p": {
			margin: 0,
		},
	},
	noRelevantJobsHeader: {
		display: "flex",
		marginTop: 0,
		marginBottom: 16,
		fontFamily: "Nunito",
		fontSize: 18,
		fontWeight: 600,
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: 1.44,
		letterSpacing: "normal",
	},
	noRelevantJobsParagraph: {
		paddingBottom: 0,
	},
	applyBtnWorkbeeLoggedOut: {
		width: "100%",
		color: "#fafafa",
		marginTop: 16,
		backgroundColor: "#3a5dab",
		borderRadius: 16,
		"&:hover": {
			color: "#fafafa",
			backgroundColor: "#3a5dab",
		},
	},
})

// Component
const JobsPage = props => {
	// Hooks
	let { path, url } = useRouteMatch()
	const dispatch = useDispatch()
	const styles = useStyles()
	const theme = useTheme()
	const location = useLocation()
	const history = useHistory()

	// Refs
	const anchorRef = useRef(null)

	// Destructuring props
	const { forWordpress } = props

	// Media Queries
	const mediaQueryMdUp = useMediaQuery(theme.breakpoints.up("md"))

	// Redux selectors
	const authReducer = useSelector(state => state.authReducer)
	const jobsReducer = useSelector(state => state.jobsReducer)
	const profileReducer = useSelector(state => state.profileReducer)
	const tutorialReducer = useSelector(state => state.tutorialReducer)

	// Local state
	const [getJobs, setGetJobs] = useState(true)
	const [jobsApplied, setJobsApplied] = useState([])
	const [logoSrcs, setLogoSrcs] = useState([])
	const [documentHeight, setDocumentHeight] = useState(null)
	const [detectScroll, setDetectScroll] = useState(true)
	const [matchingJobsPage, setMatchingJobsPage] = useState(1)
	const [canGetNextBatch, setCanGetNextBatch] = useState(false)
	const [showSkeletonJobs, setShowSkeletonJobs] = useState(false)
	const [emailVerifyPromptOpen, setEmailVerifyPromptOpen] = useState(true)
	const [emailVerifyPromptEmailSent, setEmailVerifyPromptEmailSent] =
		useState(false)

	// Query params
	const queryString = window.location.search
	const params = new URLSearchParams(queryString)
	const qualificationsParameterValue = params.get("qualifications")
	const locationsParameterValue = params.get("locations")
	const verticalParameterValue = params.get("vertical")

	// Change the background for the iframe
	useEffect(() => {
		if (forWordpress) {
			document.getElementById("root").style.backgroundColor = "white"
			document.querySelector("html").style.backgroundColor = "white"
		}
	}, [forWordpress])

	// Get user data if necessary
	useEffect(() => {
		if (
			authReducer?.userLoggedIn &&
			authReducer?.email &&
			!profileReducer?.changes?.profileDataLoaded &&
			!forWordpress
		) {
			dispatch(profileActions.asyncDetermineUserType(authReducer.email))
		}
	}, [
		authReducer.userLoggedIn,
		authReducer.email,
		profileReducer.changes.profileDataLoaded,
		dispatch,
		forWordpress,
	])

	// Send event to GTM everytime a user views the job list
	useEffect(() => {
		if (location.pathname === "/jobs") {
			dispatch(
				tagManagerActions.sendComplexCustomEvent({
					event: "userViewedJobList",
					isUserLoggedIn: authReducer?.userLoggedIn
						? "userLoggedIn"
						: "userLoggedOut",
				})
			)
		}
	}, [location, authReducer.userLoggedIn, dispatch])

	// Open job tutorial
	useEffect(() => {
		if (
			tutorialReducer?.canDisplay &&
			(tutorialReducer?.type === "specificCandidateRegistered" ||
				tutorialReducer?.type === "aspecificCandidateRegistered")
		) {
			dispatch(
				tutorialActions.setCanDisplayAction({
					canDisplay: false,
				})
			)
			dispatch(tutorialActions.setOpenAction({ open: true }))
		}
	}, [tutorialReducer, dispatch])

	// Grab job matches
	useEffect(() => {
		if (
			getJobs &&
			authReducer?.userLoggedIn &&
			jobsReducer.jobs === undefined &&
			profileReducer?.changes?.profileDataLoaded
		) {
			setGetJobs(false)
			if (
				qualificationsParameterValue ||
				locationsParameterValue ||
				verticalParameterValue
			) {
				dispatch(
					jobsActions.asyncGetJobsAction({
						page: matchingJobsPage,
						filteringOptions: jobsReducer.filteringOptions,
					})
				)
			} else {
				dispatch(
					jobsActions.asyncGetJobsAction({ page: matchingJobsPage })
				)
			}
		} else if (
			(getJobs &&
				authReducer?.userLoggedIn === false &&
				jobsReducer.jobs === undefined &&
				profileReducer?.changes?.profileDataLoaded === false) ||
			(getJobs &&
				forWordpress &&
				authReducer?.userLoggedIn === true &&
				jobsReducer.jobs === undefined &&
				profileReducer?.changes?.profileDataLoaded === false)
		) {
			setGetJobs(false)
			if (
				qualificationsParameterValue ||
				locationsParameterValue ||
				verticalParameterValue
			) {
				dispatch(
					jobsActions.asyncGetJobsAction({
						page: matchingJobsPage,
						filteringOptions: jobsReducer.filteringOptions,
					})
				)
			} else {
				dispatch(
					jobsActions.asyncGetJobsAction({ page: matchingJobsPage })
				)
			}
		}
	}, [
		dispatch,
		authReducer,
		jobsReducer,
		profileReducer,
		matchingJobsPage,
		getJobs,
		locationsParameterValue,
		qualificationsParameterValue,
		verticalParameterValue,
		forWordpress,
	])

	// Show which jobs where applied to
	useEffect(() => {
		if (profileReducer?.changes !== undefined) {
			setJobsApplied(profileReducer.changes.job_opening_ids)
		}
	}, [profileReducer, jobsApplied])

	// Grab logos
	useEffect(() => {
		if (
			jobsReducer.jobs !== "no jobs" &&
			jobsReducer.jobs !== null &&
			jobsReducer.jobs !== undefined &&
			jobsReducer.jobs.length > 0
		) {
			let allLogosSrc = []

			jobsReducer.jobs.forEach(job => {
				const storageRef = ref(
					firebaseStorage,
					`JobOpenings/${job.zoho_job_openings_id}`
				)

				listAll(storageRef)
					.then(result => {
						if (result.items.length > 0) {
							const companyLogo = result.items.filter(el =>
								el.fullPath.includes("logo")
							)

							if (Capacitor.isNativePlatform()) {
								companyLogo[0]._location.path_ =
									companyLogo[0]._location.path_.replaceAll(
										"/",
										"%2F"
									)
							}

							if (companyLogo.length > 0) {
								getDownloadURL(companyLogo[0])
									.then(url => {
										allLogosSrc = [
											...allLogosSrc,
											{
												url: url,
												zoho_client_id:
													job.zoho_client_id,
											},
										]
										setLogoSrcs(allLogosSrc)
									})
									.catch(error => {
										// Handle any errors
									})
							}
						}
					})
					.catch(error => {
						console.log(error)
					})
			})
		}
	}, [jobsReducer.jobs])

	// Set matching page to 1 when no jobs are shown
	useEffect(() => {
		if (!jobsReducer?.jobs) {
			setMatchingJobsPage(1)
		}
	}, [jobsReducer.jobs])

	// Display msg that no jobs were found
	useEffect(() => {
		if (jobsReducer.jobs === "no jobs" && authReducer?.userLoggedIn) {
			dispatch(profileActions.setRecruiterAccordion(true, "noJobsInfo"))
		} else if (jobsReducer?.jobs?.length > 0 && authReducer?.userLoggedIn) {
			dispatch(profileActions.setRecruiterAccordion(false))
		}
	}, [authReducer, jobsReducer.jobs, dispatch])

	// Set new jobs page
	useScrollPosition(({ prevPos, currPos }) => {
		if (
			documentHeight &&
			detectScroll &&
			(location.pathname === "/jobs" ||
				location.pathname === "/jobsWordpress") &&
			Math.abs(currPos.y) > 0.8 * documentHeight - 350
		) {
			setDetectScroll(false)

			if (
				jobsReducer.jobs &&
				jobsReducer.jobs.length &&
				jobsReducer.jobs.length < jobsReducer.jobCount
			) {
				setMatchingJobsPage(matchingJobsPage + 1)
				setCanGetNextBatch(true)
				setShowSkeletonJobs(true)
			}
		}
	})

	// Get next job batch
	useEffect(() => {
		if (canGetNextBatch) {
			setCanGetNextBatch(false)

			if (
				qualificationsParameterValue ||
				locationsParameterValue ||
				verticalParameterValue
			) {
				dispatch(
					jobsActions.asyncGetJobsAction({
						page: matchingJobsPage,
						filteringOptions: jobsReducer.filteringOptions,
					})
				)
			} else {
				dispatch(
					jobsActions.asyncGetJobsAction({
						page: matchingJobsPage,
						filteringOptions: jobsReducer.filteringOptions,
					})
				)
			}
		}
	}, [
		canGetNextBatch,
		dispatch,
		matchingJobsPage,
		jobsReducer.filteringOptions,
		qualificationsParameterValue,
		locationsParameterValue,
		verticalParameterValue,
	])

	// Set new document height
	useEffect(() => {
		if (
			jobsReducer.jobs &&
			jobsReducer.jobs.length &&
			jobsReducer.jobs.length > 0
		) {
			setShowSkeletonJobs(false)
			setDocumentHeight(document.body.offsetHeight - 700)
			setDetectScroll(true)
		}
	}, [jobsReducer.jobs])

	// Render
	if (authReducer?.userLoggedIn !== null) {
		return (
			<>
				<div
					className={
						mediaQueryMdUp && !forWordpress
							? styles.contentLeft
							: ""
					}
					ref={anchorRef}
				/>
				<div
					className={
						mediaQueryMdUp && !forWordpress
							? styles.contentLeft
							: ""
					}
				>
					<Switch>
						<Route exact path={path}>
							{/* Email verification banner */}
							{!forWordpress &&
								emailVerifyPromptOpen &&
								authReducer?.userLoggedIn &&
								(profileReducer?.changes?.emailVerified ===
									false ||
									profileReducer?.changes?.emailVerified ===
										"False") && (
									<div
										xs={12}
										className={styles.verificationGridItem}
										onClick={e => {
											e.stopPropagation()

											if (!emailVerifyPromptEmailSent) {
												// sending verification email
												dispatch(
													authActions.asyncSendEmailLoginAction(
														{
															email: authReducer.email,
															emailType:
																"verification",
															userType:
																profileReducer
																	.changes
																	.userType ===
																"candidate"
																	? "candidate"
																	: "promoter",
														}
													)
												)
											}

											// switching the prompt's content
											setEmailVerifyPromptEmailSent(true)
										}}
									>
										{!emailVerifyPromptEmailSent && (
											<h3
												className={
													styles.verificationHeader
												}
											>
												Du musst dich noch verifizieren.
												<CloseIcon
													className={
														styles.verificationGridItemClose
													}
													color="primary"
													onClick={() => {
														setEmailVerifyPromptOpen(
															false
														)
													}}
												/>
											</h3>
										)}
										{emailVerifyPromptEmailSent && (
											<div
												className={
													styles.verificationHeader
												}
											>
												<h3
													className={
														styles.alternativeHeader
													}
												>
													<img
														src={greenSeal}
														alt="logoNoHat"
														className={
															styles.greenSeal
														}
													/>
													E-Mail versandt!{" "}
													<img
														src={logoNoHat}
														alt="logoNoHat"
														className={
															styles.logoNoHat
														}
													/>
												</h3>
												<CloseIcon
													className={
														styles.verificationGridItemClose
													}
													color="primary"
													onClick={() => {
														setEmailVerifyPromptOpen(
															false
														)
													}}
												/>
											</div>
										)}
										{!emailVerifyPromptEmailSent && (
											<p
												className={
													styles.verificationParagraph
												}
											>
												Wir haben dir eine E-Mail
												geschickt, um sicher zu sein,
												dass du es bist. Klicke den Link
												in der E-Mail, um dich zu
												verifizieren.
											</p>
										)}
										{emailVerifyPromptEmailSent && (
											<p
												className={
													styles.verificationParagraph
												}
											>
												Gehe in dein E-Mail-Postfach und
												klicke den Link, den wir dir
												zugeschickt haben. Schau im
												Zweifel in deinen Spam-Ordner.
											</p>
										)}
										{!emailVerifyPromptEmailSent && (
											<p
												className={
													styles.verificationButton
												}
												onClick={e => {
													e.stopPropagation()

													// sending verification email
													dispatch(
														authActions.asyncSendEmailLoginAction(
															{
																email: authReducer.email,
																emailType:
																	"verification",
																userType:
																	profileReducer
																		.changes
																		.userType ===
																	"candidate"
																		? "candidate"
																		: "promoter",
															}
														)
													)

													// switching the prompt's content
													setEmailVerifyPromptEmailSent(
														true
													)
												}}
											>
												BITTE DEN LINK ERNEUT SENDEN
											</p>
										)}
									</div>
								)}

							<Container className={styles.containerBig}>
								<Grid
									container
									spacing={3}
									className={
										emailVerifyPromptOpen &&
										authReducer?.userLoggedIn &&
										profileReducer?.changes
											?.emailVerified === false
											? styles.containerWithLessPadding
											: styles.container
									}
								>
									{/* SEO */}
									<Helmet>
										<title>Workbee - Jobs</title>
										<meta
											name="title"
											content="Workbee - Jobs"
										/>
										<meta
											name="description"
											content="Spannende Jobs warten auf Dich!"
										/>
										<script type="application/ld+json">
											{JSON.stringify(generalSeoSchema)}
										</script>
									</Helmet>

									{/* Filter jobs modal */}
									{jobsReducer.filterJobsModalOpen && (
										<JobFilterModal />
									)}

									{/* Filter Chips */}
									{jobsReducer.filteringOptions &&
										!forWordpress && (
											<Grid item xs={12}>
												<FilterChips
													setMatchingJobsPage={
														setMatchingJobsPage
													}
													anchorRef={anchorRef}
												/>
											</Grid>
										)}

									{/* Your recruiter field */}
									{profileReducer.recruiterData && (
										<Grid item xs={12}>
											<YourRecruiterField />
										</Grid>
									)}

									{/* General jobs list */}
									<JobCard
										jobs={jobsReducer.jobs}
										url={url}
										logoSrcs={logoSrcs}
										jobsApplied={jobsApplied}
										forWordpress={forWordpress}
										openInNewTab={
											forWordpress ? true : false
										}
									/>

									{/* Initial skeleton jobs */}
									{(jobsReducer.jobs === undefined ||
										showSkeletonJobs) && (
										<Grid item xs={12}>
											<Grid container spacing={3}>
												{Array(3)
													.fill(1)
													.map((el, id) => (
														<Grid
															item
															key={id}
															xs={12}
															className={
																styles.skeletonJobCardGridItem
															}
														>
															<Skeleton
																height={158}
																style={{
																	borderRadius: 12,
																}}
																variant="rect"
															/>
														</Grid>
													))}
											</Grid>
										</Grid>
									)}

									{/* CTA for logged out users */}
									{((!authReducer?.userLoggedIn &&
										jobsReducer.jobs === "no jobs") ||
										(!authReducer?.userLoggedIn &&
											jobsReducer?.jobs?.length ===
												jobsReducer?.jobCount)) && (
										<Grid item xs={12}>
											<Paper
												elevation={2}
												className={
													styles.noRelevantJobsPaper
												}
											>
												<Grid container>
													<Grid item xs={12}>
														<h4
															className={
																styles.noRelevantJobsHeader
															}
														>
															Nicht das Passende
															gefunden?
														</h4>
														<p
															className={
																styles.noRelevantJobsParagraph
															}
														>
															Kein Problem! Bei
															Workbee endet die
															Suche nicht hier.
															Melde dich an und
															erhalte individuell
															abgestimmte
															Job-Empfehlungen,
															die zu deinen
															Interessen und
															Fähigkeiten passen.
														</p>
														<Button
															variant="contained"
															color="primary"
															size="large"
															className={
																styles.applyBtnWorkbeeLoggedOut
															}
															onClick={() => {
																dispatch(
																	jobsActions.removeSpecificJobAction()
																)
																history.push(
																	"/form"
																)
																window.scrollTo(
																	0,
																	0
																)
															}}
														>
															Kostenlos anmelden
														</Button>
													</Grid>
												</Grid>
											</Paper>
										</Grid>
									)}
								</Grid>
							</Container>
						</Route>

						{/* Route to specific job */}
						<Route path={`${path}/:jobId`}>
							<Job />
						</Route>
					</Switch>
				</div>
			</>
		)
	} else {
		return null
	}
}

export default JobsPage
