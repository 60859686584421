// Data Structures
import questionsStructure from "../dataStructures/questionsStructure"
import questionsStructureSpecific from "../dataStructures/questionsStructureSpecific"

// Redux
import { useSelector, useDispatch } from "react-redux"
import * as clickFunnelActions from "../state/clickFunnelActions"

// Hooks
import useCanGoForward from "./useCanGoForward"

const useManageQuestions = () => {
	// Hooks
	const { canGoForward } = useCanGoForward()
	const dispatch = useDispatch()

	// Redux selectors
	const clickFunnelReducer = useSelector(state => state.clickFunnelReducer)
	const jobsReducer = useSelector(state => state.jobsReducer)

	const manageQuestions = props => {
		// Destructuring props
		const {
			question,
			setSlide,
			fieldValue,
			formSlide,
			currentQuestionObj,
			questionTracker,
			modifyQuestionTracker,
			funnelType,
		} = props

		// Variables
		let questionsStructureDynamic = questionsStructure
		if (funnelType === "specific") {
			questionsStructureDynamic = questionsStructureSpecific
		}

		// Setting the variables
		let nextQuestion = null
		let hideQuestions = null

		if (currentQuestionObj.logicJumps.jumpType === "optionsBased") {
			nextQuestion =
				currentQuestionObj.logicJumps[fieldValue].nextQuestion
			hideQuestions =
				currentQuestionObj.logicJumps[fieldValue].hideQuestions
		} else if (currentQuestionObj.logicJumps.jumpType === "valueBased") {
			nextQuestion = currentQuestionObj.logicJumps.nextQuestion
			hideQuestions = currentQuestionObj.logicJumps.hideQuestions
		} else if (
			currentQuestionObj.logicJumps.jumpType === "workFieldBased"
		) {
			let workField = clickFunnelReducer.formData.work_field

			if (currentQuestionObj.fieldName === "choice_type_of_employer") {
				nextQuestion =
					currentQuestionObj.logicJumps[workField][fieldValue]
						.nextQuestion
				hideQuestions =
					currentQuestionObj.logicJumps[workField][fieldValue]
						.hideQuestions
			} else if (currentQuestionObj.fieldName === "type_of_employer") {
				nextQuestion =
					currentQuestionObj.logicJumps[workField].nextQuestion
				hideQuestions =
					currentQuestionObj.logicJumps[workField].hideQuestions
			}
		} else if (
			currentQuestionObj.logicJumps.jumpType ===
			"optionsBasedQualification"
		) {
			if (
				fieldValue === "Weiteres" ||
				fieldValue === "Pflegehilfskraft/-assistenz" ||
				fieldValue === "Noch in Ausbildung"
			) {
				nextQuestion =
					currentQuestionObj.logicJumps[fieldValue].nextQuestion
				hideQuestions =
					currentQuestionObj.logicJumps[fieldValue].hideQuestions
			} else {
				nextQuestion = currentQuestionObj.logicJumps.other.nextQuestion
				hideQuestions =
					currentQuestionObj.logicJumps.other.hideQuestions
			}
		} else if (
			currentQuestionObj.logicJumps.jumpType ===
			"optionsBasedTypeOfEmployer"
		) {
			const reduxFieldValue = clickFunnelReducer.formData.type_of_employer

			if (
				reduxFieldValue.includes("Kinderkrankenhäuser") ||
				reduxFieldValue.includes("Krankenhaus / Klinik")
			) {
				nextQuestion =
					currentQuestionObj.logicJumps.showDepartments.nextQuestion
				hideQuestions =
					currentQuestionObj.logicJumps.showDepartments.hideQuestions
			} else {
				nextQuestion = currentQuestionObj.logicJumps.other.nextQuestion
				hideQuestions =
					currentQuestionObj.logicJumps.other.hideQuestions
			}
		} else if (
			currentQuestionObj.logicJumps.jumpType ===
			"optionsBasedOccupationalGroup"
		) {
			if (fieldValue === "Weiteres") {
				nextQuestion =
					currentQuestionObj.logicJumps[fieldValue].nextQuestion
				hideQuestions =
					currentQuestionObj.logicJumps[fieldValue].hideQuestions
			} else {
				nextQuestion = currentQuestionObj.logicJumps.other.nextQuestion
				hideQuestions =
					currentQuestionObj.logicJumps.other.hideQuestions
			}
		} else if (
			currentQuestionObj.logicJumps.jumpType ===
			"optionsBasedOngoingEducation"
		) {
			if (fieldValue === "Weiteres") {
				nextQuestion =
					currentQuestionObj.logicJumps[fieldValue].nextQuestion
				hideQuestions =
					currentQuestionObj.logicJumps[fieldValue].hideQuestions
			} else {
				nextQuestion = currentQuestionObj.logicJumps.other.nextQuestion
				hideQuestions =
					currentQuestionObj.logicJumps.other.hideQuestions
			}
		} else if (
			currentQuestionObj.logicJumps.jumpType === "optionsBasedHilfskraft"
		) {
			if (fieldValue === "Weiteres") {
				nextQuestion =
					currentQuestionObj.logicJumps[fieldValue].nextQuestion
				hideQuestions =
					currentQuestionObj.logicJumps[fieldValue].hideQuestions
			} else {
				nextQuestion = currentQuestionObj.logicJumps.other.nextQuestion
				hideQuestions =
					currentQuestionObj.logicJumps.other.hideQuestions
			}
		} else if (
			currentQuestionObj.logicJumps.jumpType === "optionsBasedGermanLevel"
		) {
			let jumpCase = null
			let isNative = fieldValue === "Muttersprache"
			let isDiplomaRequired =
				jobsReducer &&
				jobsReducer.job &&
				jobsReducer.job.diploma_required
					? jobsReducer.job.diploma_required
					: false
			let isPflegehilfskraft =
				clickFunnelReducer.formData.qualification ===
				"Pflegehilfskraft/-assistenz"

			if (isNative && isDiplomaRequired) {
				jumpCase = "nativeAndDiplomaRequired"
			} else if (isNative && !isDiplomaRequired) {
				jumpCase = "nativeAndDiplomaNotRequired"
			} else if (!isNative && !isPflegehilfskraft) {
				jumpCase = "notNativeAndNotPfegehilfskraft"
			} else if (!isNative && isPflegehilfskraft && isDiplomaRequired) {
				jumpCase = "notNativePflegehilfskraftAndDiplomaRequired"
			} else if (!isNative && isPflegehilfskraft && !isDiplomaRequired) {
				jumpCase = "notNativePflegehilfskraftAndDiplomaNotRequired"
			}

			nextQuestion = currentQuestionObj.logicJumps[jumpCase].nextQuestion
			hideQuestions =
				currentQuestionObj.logicJumps[jumpCase].hideQuestions
		} else if (
			currentQuestionObj.logicJumps.jumpType ===
			"optionsBasedTotalWorkExperience"
		) {
			if (fieldValue === "Noch keine") {
				nextQuestion =
					currentQuestionObj.logicJumps[fieldValue].nextQuestion
				hideQuestions =
					currentQuestionObj.logicJumps[fieldValue].hideQuestions
			} else {
				nextQuestion = currentQuestionObj.logicJumps.other.nextQuestion
				hideQuestions =
					currentQuestionObj.logicJumps.other.hideQuestions
			}
		} else if (
			currentQuestionObj.logicJumps.jumpType ===
			"stateBasedEducationRecognized"
		) {
			let isDiplomaRequired =
				jobsReducer &&
				jobsReducer.job &&
				jobsReducer.job.diploma_required
					? jobsReducer.job.diploma_required
					: false
			let jumpCase = null

			if (isDiplomaRequired) {
				jumpCase = "diplomaRequired"
			} else {
				jumpCase = "diplomaNotRequired"
			}

			nextQuestion = currentQuestionObj.logicJumps[jumpCase].nextQuestion
			hideQuestions =
				currentQuestionObj.logicJumps[jumpCase].hideQuestions
		} else if (
			currentQuestionObj.logicJumps.jumpType === "stateBasedBenefits"
		) {
			const vertical =
				jobsReducer?.job?.verticals?.length === 1
					? jobsReducer.job.verticals[0]
					: "Multiple Verticals or None"
			const jumpCase = vertical

			nextQuestion = currentQuestionObj.logicJumps[jumpCase].nextQuestion
			hideQuestions =
				currentQuestionObj.logicJumps[jumpCase].hideQuestions
		}

		// Adding questions logic
		if (
			nextQuestion &&
			!questionTracker[formSlide].questions.includes(nextQuestion) &&
			questionsStructureDynamic[nextQuestion].slide === formSlide
		) {
			let allQuestionsInSlide = [...questionTracker[formSlide].questions]
			let indexOfCurrentQuestion = allQuestionsInSlide.indexOf(question)

			allQuestionsInSlide.splice(
				indexOfCurrentQuestion + 1,
				0,
				nextQuestion
			)

			let questionTrackerCopy = [...questionTracker]
			questionTrackerCopy[formSlide].questions = allQuestionsInSlide

			modifyQuestionTracker(questionTrackerCopy)
		}

		// Hiding questions logic
		if (hideQuestions) {
			let questionTrackerCopy = [...questionTracker]
			let filteredQuestions = questionTrackerCopy[
				formSlide
			].questions.filter(el => !hideQuestions.includes(el))

			// special case for work_field to clean up data on the next page
			if (
				funnelType === "aspecific" &&
				question === "work_field" &&
				questionTrackerCopy[formSlide + 1]
			) {
				if (clickFunnelReducer.formData.work_field) {
					questionTrackerCopy[1] = {
						questions: ["choice_type_of_employer"],
					}

					dispatch(
						clickFunnelActions.clearMultipleChoiceAction(
							"type_of_employer"
						)
					)
					dispatch(
						clickFunnelActions.clearMultipleChoiceAction(
							"departments"
						)
					)
					dispatch(
						clickFunnelActions.setTextFieldValueAction(
							"",
							"choice_type_of_employer"
						)
					)
					dispatch(
						clickFunnelActions.setTextFieldValueAction(
							"",
							"choice_departments"
						)
					)
				}
			}

			questionTrackerCopy[formSlide].questions = filteredQuestions
			modifyQuestionTracker(questionTrackerCopy)

			// Clearing values in Redux
			hideQuestions.forEach(question => {
				let fieldType = questionsStructureDynamic[question].fieldType
				if (fieldType === "multipleChoice") {
					dispatch(
						clickFunnelActions.clearMultipleChoiceAction(question)
					)
				} else if (fieldType === "freeText") {
					dispatch(
						clickFunnelActions.setTextFieldValueAction("", question)
					)
				} else if (
					fieldType === "singleChoice" ||
					fieldType === "singleChoiceSelect"
				) {
					dispatch(
						clickFunnelActions.asyncSaveFieldAction("", question)
					)
				}
			})
		}

		// Moving to next slide logic
		if (questionsStructureDynamic[nextQuestion].slide !== formSlide) {
			// Add new slide and question only if it doesn't exist yet
			if (!questionTracker[formSlide + 1]) {
				let questionTrackerCopy = [...questionTracker]
				questionTrackerCopy = [
					...questionTrackerCopy,
					{
						questions: [
							questionsStructureDynamic[nextQuestion].fieldName,
						],
					},
				]

				modifyQuestionTracker(questionTrackerCopy)
			}
			// Moving to the next slide automatically only for single choice and
			// attachment upload fields and when all questions are answered
			if (
				(currentQuestionObj.fieldType === "singleChoice" ||
					currentQuestionObj.fieldType === "singleChoiceSelect") &&
				canGoForward(props)
			) {
				setSlide(questionsStructureDynamic[nextQuestion].slide)
			}
		}
	}

	return { manageQuestions }
}

export default useManageQuestions
