import React, { useCallback, useEffect, useState } from "react"

// Components
import { useDropzone } from "react-dropzone"

// Redux
import { useDispatch } from "react-redux"
import * as notificationsActions from "../../notifications/state/notificationsActions"
import * as clickFunnelActions from "../state/clickFunnelActions"

// Material UI
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"

// Custom Hooks
import useManageQuestions from "../funnelLogicHooks/useManageQuestions"

// Styles
const useStyles = makeStyles({
	dropZone: {
		backgroundColor: "rgba(0,0,0,0.1)",
		minHeight: 50,
		display: "flex",
		flexDirection: "column",
		borderRadius: 5,
		border: "1px dashed black",
		justifyContent: "center",
		cursor: "pointer",
		marginBottom: 49,
	},
	dropZoneInfo: {
		margin: 10,
	},
	yourScanLabel: {
		wordBreak: "break-all",
	},
	dropZoneContainer: {
		paddingBottom: 15,
	},
})

// Component
const UploadAttachment = props => {
	// Hooks
	let styles = useStyles()
	let dispatch = useDispatch()
	const { manageQuestions } = useManageQuestions()

	// Local state
	const [newFiles, setNewFiles] = useState([])

	// Handlers
	const onDrop = useCallback(
		acceptedFiles => {
			setNewFiles(acceptedFiles)

			if (acceptedFiles.length === 1) {
				dispatch(
					clickFunnelActions.setAttachmentAction(
						acceptedFiles[0],
						acceptedFiles[0].name
					)
				)

				// add/hide questions, move to next slide (if necessary)
				manageQuestions(props)
			}
		},
		[dispatch, props, manageQuestions]
	)

	// File upload config
	const { fileRejections, getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: "image/jpeg, image/png, application/pdf, application/msword",
		maxFiles: 1,
		maxSize: 10485760,
	})

	// Add/hide questions, move to next slide (if necessary)
	useEffect(() => {
		manageQuestions(props)
	})

	// File validation
	useEffect(() => {
		if (
			fileRejections.length === 1 &&
			fileRejections[0].errors &&
			fileRejections[0].errors[0].code === "file-invalid-type"
		) {
			dispatch(
				notificationsActions.sendNotificationAction({
					open: true,
					message:
						"Unterstützte Dateitypen sind: .pdf .doc .jpg und .png",
					type: "info",
				})
			)
		} else if (
			fileRejections.length === 1 &&
			fileRejections[0].errors &&
			fileRejections[0].errors[0].code === "file-too-large"
		) {
			dispatch(
				notificationsActions.sendNotificationAction({
					open: true,
					message: "Die Datei darf nicht größer als 10 MB sein.",
					type: "info",
				})
			)
		} else if (fileRejections.length > 1) {
			dispatch(
				notificationsActions.sendNotificationAction({
					open: true,
					message: "Du kannst nur 1 Datei hochladen.",
					type: "info",
				})
			)
		}
	}, [fileRejections, dispatch])

	// Render
	return (
		<Grid item xs={12} className={styles.dropZoneContainer}>
			<div {...getRootProps({ className: styles.dropZone })}>
				<input id="uploadDocumentInput" {...getInputProps()} />
				<p className={styles.dropZoneInfo}>
					Ziehe und lege die Dateien hierher oder klicke, um den Scan
					hochzuladen.
				</p>
				<p className={styles.dropZoneInfo}>
					Smartphone-Nutzer: Wenn du auf diesen Bereich klickst,
					kannst du ein Foto von deinem Scan machen und es in einem
					Rutsch hochladen.
				</p>
			</div>

			{/* New Files */}

			{newFiles.length === 1 && (
				<p className={styles.yourScanLabel}>
					dein Scan: {newFiles[0].path}
				</p>
			)}
		</Grid>
	)
}

export default UploadAttachment
