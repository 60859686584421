import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"

// Images
import logoOutlined from "../images/logoOutlined.png"

// Redux
import { useDispatch, useSelector } from "react-redux"
import * as profileActions from "../profile/state/profileActions"

// Firebase
import { onAuthStateChanged } from "firebase/auth"
import { firebaseAuth } from "../firebase"

// Material UI
import { makeStyles } from "@material-ui/core/styles"
import CircularProgress from "@material-ui/core/CircularProgress"

// Styles
const useStyles = makeStyles(theme => ({
	loadingScreen: {
		display: "flex",
		flexDirection: "column",
		width: "100vw",
		height: "100vh",
		position: "absolute",
		top: 0,
		zIndex: 3000,
		backgroundColor: "#e8b300",
		justifyContent: "center",
		alignItems: "center",
		opacity: 1,
	},
	loadingScreenExit: {
		animation: `$animationHide 3000ms ${theme.transitions.easing.easeInOut}`,
	},
	"@keyframes animationHide": {
		"0%": {
			opacity: 1,
		},
		"100%": {
			opacity: 0,
		},
	},
	paper: {
		padding: "2px 10px",
	},
	logo: {
		display: "flex",
		marginBottom: "30px",
	},
	spinner: {
		color: "black",
	},
}))

// Component
const LoadingScreen = () => {
	// Hooks
	const history = useHistory()
	const styles = useStyles()
	const dispatch = useDispatch()

	// Redux selectors
	const authReducer = useSelector(state => state.authReducer)

	// Local state
	const [getProfileDataRequestSent, setGetProfileRequestSent] =
		useState(false)

	// Getting profile data
	useEffect(() => {
		if (getProfileDataRequestSent) {
			dispatch(profileActions.asyncDetermineUserType(authReducer.email))
		}
	}, [authReducer.email, getProfileDataRequestSent, dispatch])

	// Logic for showing the loading screen
	useEffect(() => {
		let mounted = true
		onAuthStateChanged(firebaseAuth, user => {
			if (user && mounted) {
				if (!getProfileDataRequestSent) {
					setGetProfileRequestSent(true)
				}
			} else if (mounted) {
				history.push("/jobs")
			}
		})

		return () => {
			mounted = false
		}
	})

	// Render
	return (
		<div className={styles.loadingScreen}>
			<img src={logoOutlined} className={styles.logo} alt="app logo" />
			<CircularProgress className={styles.spinner} />
		</div>
	)
}

export default LoadingScreen
