import React, { useEffect, useState } from "react"

// Components
import Carousel from "react-material-ui-carousel"

// Material UI Icons
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"

// Material UI
import { makeStyles } from "@material-ui/core/styles"
import { Grid } from "@material-ui/core"

// Styles
const useStyles = makeStyles({
	sectionGridContainerGallery: {
		maxWidth: 1000,
		margin: "0 auto",
		paddingTop: 40,
	},
	imageCarousel: {
		marginTop: 40,
		overflow: "visible",
		marginBottom: 48,
	},
	youtubeWrapper: {
		borderRadius: 12,
		overflow: "hidden",
		paddingBottom: "56.25%",
		position: "relative",
		height: 0,
		display: "flex",
		justifyContent: "center",
		"& iframe": {
			left: 0,
			top: 0,
			height: "100%",
			width: "100%",
			position: "absolute",
		},
		"& img": {
			borderRadius: 12,
			top: 0,
			height: "100%",
			width: "auto",
			position: "absolute",
		},
	},
})

// Component
const ClientsGallery = props => {
	// Hooks
	const styles = useStyles()

	// Destructuring props
	const {
		companyPhotosAvailable,
		imgsLoaded,
		downloadedImages,
		youtubeLink,
	} = props

	// Local state
	const [galleryMedia, setGalleryMedia] = useState(
		imgsLoaded && companyPhotosAvailable ? downloadedImages : []
	)

	// Adding youtube link before photos
	useEffect(() => {
		const validateYoutubeRegex =
			/^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/

		if (youtubeLink?.match(validateYoutubeRegex)) {
			setGalleryMedia([
				{
					nodeName: "Video",
					src: youtubeLink?.includes("watch")
						? youtubeLink.replace("watch?v=", "embed/")
						: youtubeLink,
				},
				...downloadedImages,
			])
		} else {
			setGalleryMedia(downloadedImages)
		}
	}, [youtubeLink, downloadedImages])

	// Render
	return (
		<Grid container className={styles.sectionGridContainerGallery}>
			<Grid item xs={12}>
				{((companyPhotosAvailable && imgsLoaded) || youtubeLink) && (
					<Carousel
						className={styles.imageCarousel}
						IndicatorIcon={null}
						NextIcon={<ArrowForwardIcon />}
						PrevIcon={<ArrowBackIcon />}
						navButtonsProps={{
							style: {
								backgroundColor: "#3a5dab",
								pointerEvents: "auto",
							},
						}}
						navButtonsWrapperProps={{
							style: {
								height: "100%",
								pointerEvents: "none",
							},
						}}
						indicatorContainerProps={{
							style: {
								zIndex: 1,
								top: "-40px",
								position: "absolute",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								width: "100%",
							},
						}}
						indicatorIconButtonProps={{
							style: {
								maxWidth: 34,
								height: 8,
								borderRadius: 3,
								backgroundColor: "#acbce0",
								margin: "0 2px",
								flex: "1 1 auto",
							},
						}}
						activeIndicatorIconButtonProps={{
							style: {
								backgroundColor: "#3a5dab",
							},
						}}
						navButtonsAlwaysVisible
						autoPlay={false}
						timeout={{
							appear: 300,
							enter: 300,
							exit: 300,
						}}
					>
						{galleryMedia.map(media => (
							<Grid container key={media}>
								<Grid item xs={12}>
									{/* Videos */}
									{media.nodeName === "Video" && (
										<div className={styles.youtubeWrapper}>
											<iframe
												width="853"
												height="480"
												src={`${media.src}`}
												frameBorder="0"
												allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
												allowFullScreen
												title="Embedded youtube"
											/>
										</div>
									)}
									{/* Images */}
									{media.nodeName === "IMG" && (
										<div className={styles.youtubeWrapper}>
											<img
												style={{
													height: "100%",
													borderRadius: 6,
												}}
												src={media.src}
												alt="company"
											/>
										</div>
									)}
								</Grid>
							</Grid>
						))}
					</Carousel>
				)}
			</Grid>
		</Grid>
	)
}

export default ClientsGallery
