import React from "react"

// Material UI
import Grid from "@material-ui/core/Grid"
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from "@material-ui/pickers"
import { makeStyles } from "@material-ui/core/styles"

// Date Components
import "date-fns"
import { de } from "date-fns/locale"
import DateFnsUtils from "@date-io/date-fns"

// Form Components
import standardProfileFieldNames from "../../profile/dataStructures/standardProfileFieldNames"

// Redux
import { useDispatch, useSelector } from "react-redux"
import * as clickFunnelActions from "../state/clickFunnelActions"

// Custom Hooks
import useManageQuestions from "../funnelLogicHooks/useManageQuestions"

// Styles
const useStyles = makeStyles({
	gridItem: {
		"& fieldset": {
			borderRadius: 12,
		},
		marginBottom: 49,
	},
	input: {
		width: "100%",
	},
})

// Component
const DatePickerField = props => {
	// Hooks
	const dispatch = useDispatch()
	const styles = useStyles()
	const { manageQuestions } = useManageQuestions()

	// Redux selectors
	const clickFunnelReducer = useSelector(state => state.clickFunnelReducer)

	// Destructuring props
	const { fieldName } = props

	// Render
	return (
		<Grid item xs={12} className={styles.gridItem}>
			<MuiPickersUtilsProvider utils={DateFnsUtils} locale={de}>
				<KeyboardDatePicker
					inputProps={{
						style: {
							WebkitBoxShadow: "0 0 0 1000px white inset",
							borderRadius: 12,
						},
					}}
					className={styles.input}
					inputVariant="outlined"
					okLabel="Ok"
					clearLabel="Clear"
					cancelLabel="Cancel"
					invalidDateMessage="Ungültiges Datumsformat"
					id={`userInputField${fieldName}`}
					label={standardProfileFieldNames[fieldName]}
					format="dd/MM/yyyy"
					value={clickFunnelReducer.formData[fieldName]}
					onChange={date => {
						// Sending value to reducer
						dispatch(
							clickFunnelActions.setTextFieldValueAction(
								date,
								fieldName
							)
						)

						// add/hide questions, move to next slide (if necessary)
						manageQuestions(props)
					}}
					KeyboardButtonProps={{
						"aria-label": "change date",
					}}
				/>
			</MuiPickersUtilsProvider>
		</Grid>
	)
}

export default DatePickerField
