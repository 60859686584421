import { useSelector } from "react-redux"

// Data structures
import questionsStructure from "../dataStructures/questionsStructure"
import questionsStructureSpecific from "../dataStructures/questionsStructureSpecific"

// Helper functions
function isValidDate(d) {
	return d instanceof Date && !isNaN(d)
}

function isValidEmail(email) {
	return email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/g)
}

const useCanGoForward = () => {
	// Redux selectors
	const clickFunnelReducer = useSelector(state => state.clickFunnelReducer)
	const profileReducer = useSelector(state => state.profileReducer)

	// Logic to enable or disable funnel buttons
	const canGoForward = props => {
		// Destructuring props
		const { formSlide, questionTracker, fromProfile, funnelType } = props

		// Variables
		let questionsStructureDynamic = questionsStructure
		if (funnelType === "specific") {
			questionsStructureDynamic = questionsStructureSpecific
		}

		if (questionTracker[formSlide]) {
			const everyQuestionFilled = [...questionTracker][
				formSlide
			].questions
				.map(question => {
					const fieldType =
						questionsStructureDynamic[question].fieldType
					const fieldName =
						questionsStructureDynamic[question].fieldName
					const reducerFieldValue =
						clickFunnelReducer.formData[
							questionsStructureDynamic[question].fieldName
						]

					if (
						fieldName !== "choice_extra_qualifications" &&
						fieldName !== "want_more_jobs" &&
						fieldName !== "drivers_license" &&
						fieldName !== "email" &&
						(fieldType === "singleChoice" ||
							fieldType === "singleChoiceSelect" ||
							fieldType === "freeText") &&
						reducerFieldValue
					) {
						return true
					} else if (
						fieldType === "datePicker" &&
						reducerFieldValue &&
						isValidDate(reducerFieldValue)
					) {
						return true
					} else if (
						fieldType === "multipleChoice" &&
						reducerFieldValue &&
						reducerFieldValue.length > 0
					) {
						return true
					} else if (
						fieldType === "locationField" &&
						reducerFieldValue &&
						reducerFieldValue.length > 0 &&
						reducerFieldValue.some(el => el !== null)
					) {
						return true
					} else if (fieldType === "uploadAttachment") {
						return true
					} else if (fieldType === "uploadWorkExperience") {
						return true
					} else if (
						(fieldName === "choice_extra_qualifications" ||
							fieldName === "drivers_license" ||
							fieldName === "want_more_jobs") &&
						reducerFieldValue !== ""
					) {
						return true
					} else if (fieldType === "contactData") {
						const contactDataFieldValue =
							clickFunnelReducer.formData

						if (
							!fromProfile &&
							contactDataFieldValue["gdprCheckboxChecked"] &&
							contactDataFieldValue["salutation"] &&
							contactDataFieldValue["first_name"] &&
							contactDataFieldValue["last_name"] &&
							contactDataFieldValue["phone"] &&
							contactDataFieldValue["email"] &&
							isValidEmail(contactDataFieldValue["email"]) &&
							contactDataFieldValue["email"] ===
								contactDataFieldValue["email_confirmation"]
						) {
							return true
						} else if (
							(fromProfile ||
								profileReducer.changes.userType ===
									"affiliate") &&
							contactDataFieldValue["salutation"] &&
							contactDataFieldValue["first_name"] &&
							contactDataFieldValue["last_name"] &&
							contactDataFieldValue["phone"]
						) {
							return true
						} else {
							return false
						}
					} else {
						return false
					}
				})
				.every(el => el)

			if (everyQuestionFilled) {
				return true
			} else {
				return false
			}
		}
	}

	return { canGoForward }
}

export default useCanGoForward
