import React, { useEffect, useState, useRef } from "react"
import { useRouteMatch, Switch, Route } from "react-router-dom"

// Redux
import { useSelector, useDispatch } from "react-redux"
import * as jobsActions from "../../state/jobsActions"

// Material UI
import { useTheme, makeStyles } from "@material-ui/core/styles"
import {
	Container,
	Divider,
	Grid,
	useMediaQuery,
	Paper,
} from "@material-ui/core"

// Firebase
import { firebaseStorage } from "../../../firebase"
import { ref, listAll, getDownloadURL } from "firebase/storage"

// Capacitor
import { Capacitor } from "@capacitor/core"

// Components
import YourRecruiterField from "../../../profile/YourRecruiterField"
import JobCard from "../jobsListPage/JobCard"
import Job from "../jobPage/Job"
import FilterChips from "./FilterChips"

// Styles
const useStyles = makeStyles({
	containerBig: {
		maxWidth: 600,
		fontFamily: "'Nunito', sans-serif",
		color: "#110c08",
	},
	container: {
		paddingTop: 80,
		paddingBottom: 60,
	},
	containerWithLessPadding: {
		paddingTop: 30,
		paddingBottom: 60,
	},
	verificationGridItem: {
		fontFamily: "'Nunito', sans-serif",
		marginTop: 56,
		width: "100%",
		backgroundColor: "#fdf4cc",
	},
	verificationGridItemClose: {
		cursor: "pointer",
	},
	divider: {
		width: "100%",
		marginBottom: 8,
	},
	greenSeal: {
		width: 24,
		height: 24,
		marginRight: 6,
	},
	logoNoHat: {
		width: 26,
		marginBottom: 12,
		marginLeft: 6,
	},
	verificationHeader: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		padding: 16,
		fontSize: 14,
		fontWeight: "bold",
		margin: 0,
	},
	alternativeHeader: {
		display: "flex",
		alignItems: "center",
		fontSize: 14,
		padding: 0,
		margin: 0,
	},
	verificationParagraph: {
		padding: 16,
		paddingTop: 0,
		margin: 0,
		fontSize: 12,
	},
	verificationButton: {
		padding: 16,
		paddingTop: 0,
		margin: 0,
		fontSize: 12,
		fontWeight: 600,
		color: "#3a5dab",
		cursor: "pointer",
	},
	contentLeft: {
		marginLeft: 200,
	},
	skeletonJobCardGridItem: {
		paddingTop: "4px !important",
		paddingBottom: "4px !important",
	},
	noRelevantJobsPaper: {
		borderRadius: 16,
		padding: 16,
		color: "#3c424f",
		fontSize: 16,
		fontWeight: "normal",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: 1.38,
		letterSpacing: "normal",
		"& p": {
			margin: 0,
		},
	},
	noRelevantJobsHeader: {
		display: "flex",
		marginTop: 0,
		marginBottom: 16,
		fontFamily: "Nunito",
		fontSize: 18,
		fontWeight: 600,
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: 1.44,
		letterSpacing: "normal",
	},
	noRelevantJobsParagraph: {
		paddingBottom: 0,
	},
	applyBtnWorkbeeLoggedOut: {
		width: "100%",
		color: "#fafafa",
		marginTop: 16,
		backgroundColor: "#3a5dab",
		borderRadius: 16,
		"&:hover": {
			color: "#fafafa",
			backgroundColor: "#3a5dab",
		},
	},
	noApplicationsPaper: {
		padding: 24,
	},
})

// Component
const ApplicationPage = () => {
	// Hooks
	let { path, url } = useRouteMatch()
	const styles = useStyles()
	const theme = useTheme()
	const dispatch = useDispatch()

	// Refs
	const anchorRef = useRef(null)

	// Redux selectors
	const authReducer = useSelector(state => state.authReducer)
	const profileReducer = useSelector(state => state.profileReducer)
	const jobsReducer = useSelector(state => state.jobsReducer)

	// Media Queries
	const mediaQueryMdUp = useMediaQuery(theme.breakpoints.up("md"))

	// Local state
	const [logoSrcs, setLogoSrcs] = useState([])
	const [applicationStatusFilter, setApplicationStatusFilter] =
		useState("all")
	const [filteredStatusApplications, setFilteredStatusApplications] =
		useState([])

	// Filtering job applications by status
	useEffect(() => {
		if (jobsReducer.applications) {
			if (applicationStatusFilter === "all") {
				setFilteredStatusApplications([...jobsReducer.applications])
			} else if (applicationStatusFilter === "active") {
				setFilteredStatusApplications([
					...jobsReducer.applications.filter(
						el => el.status_color !== "red"
					),
				])
			} else if (applicationStatusFilter === "inactive") {
				setFilteredStatusApplications([
					...jobsReducer.applications.filter(
						el => el.status_color === "red"
					),
				])
			}
		}
	}, [applicationStatusFilter, jobsReducer.applications])

	// Getting job data
	useEffect(() => {
		if (
			(jobsReducer.applications === undefined ||
				jobsReducer.applications === null) &&
			authReducer.userLoggedIn !== null
		) {
			dispatch(jobsActions.asyncGetAppliedJobsAction())
		}
	}, [dispatch, authReducer.userLoggedIn, jobsReducer.applications])

	// Grab logos
	useEffect(() => {
		if (
			jobsReducer.applications !== null &&
			jobsReducer.applications !== undefined &&
			jobsReducer.applications.length > 0
		) {
			let allLogosSrc = []

			jobsReducer.applications.forEach(application => {
				const storageRef = ref(
					firebaseStorage,
					`JobOpenings/${application.zoho_job_openings_id}`
				)

				listAll(storageRef)
					.then(result => {
						if (result.items.length > 0) {
							const companyLogo = result.items.filter(el =>
								el.fullPath.includes("logo")
							)
							if (companyLogo.length > 0) {
								if (Capacitor.isNativePlatform()) {
									companyLogo[0]._location.path_ =
										companyLogo[0]._location.path_.replaceAll(
											"/",
											"%2F"
										)
								}

								getDownloadURL(companyLogo[0])
									.then(url => {
										allLogosSrc = [
											...allLogosSrc,
											{
												url: url,
												zoho_client_id:
													application.zoho_client_id,
											},
										]
										setLogoSrcs(allLogosSrc)
									})
									.catch(error => {
										// Handle any errors
									})
							}
						}
					})
					.catch(error => {
						console.log(error)
					})
			})
		}
	}, [jobsReducer.applications])

	// Render
	if (authReducer?.userLoggedIn !== null) {
		return (
			<>
				<div
					className={mediaQueryMdUp ? styles.contentLeft : ""}
					ref={anchorRef}
				/>
				<div className={mediaQueryMdUp ? styles.contentLeft : ""}>
					<Switch>
						<Route exact path={path}>
							<Container className={styles.containerBig}>
								<Grid
									container
									spacing={3}
									className={styles.container}
								>
									{/* Your recruiter field */}
									{profileReducer.recruiterData && (
										<Grid item xs={12}>
											<YourRecruiterField />
										</Grid>
									)}

									{/* Filter Chips */}
									{jobsReducer.filteringOptions && (
										<Grid item xs={12}>
											<FilterChips
												anchorRef={anchorRef}
												applicationStatusFilter={
													applicationStatusFilter
												}
												setApplicationStatusFilter={
													setApplicationStatusFilter
												}
											/>
										</Grid>
									)}

									{/* Divider */}
									<Grid item xs={12}>
										<Divider className={styles.divider} />
									</Grid>

									{/* General jobs list */}
									<JobCard
										jobs={filteredStatusApplications}
										url={url}
										logoSrcs={logoSrcs}
										forWordpress={false}
										openInNewTab={false}
										forApplicationList={true}
									/>

									{/* Message when no applications in a given category */}
									{filteredStatusApplications?.length ===
										0 && (
										<Grid item xs={12}>
											<Paper
												elevation={2}
												className={
													styles.noApplicationsPaper
												}
											>
												{applicationStatusFilter ===
												"all"
													? "Du hast keine Bewerbungen vorliegen."
													: applicationStatusFilter ===
													  "active"
													? "Du hast keine aktive Bewerbungen."
													: "Du hast keine inaktive Bewerbungen."}
											</Paper>
										</Grid>
									)}
								</Grid>
							</Container>
						</Route>

						{/* Route to specific job */}
						<Route path={`${path}/:jobId`}>
							<Job />
						</Route>
					</Switch>
				</div>
			</>
		)
	} else {
		return null
	}
}

export default ApplicationPage
