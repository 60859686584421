import React, { useCallback, useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"

// Redux
import { useDispatch, useSelector } from "react-redux"
import * as notificationsActions from "../../../notifications/state/notificationsActions"
import * as profileActions from "../../../profile/state/profileActions"
import * as jobsActions from "../../state/jobsActions"

// MaterialUI
import { makeStyles } from "@material-ui/core/styles"
import { Chip, Avatar, Button, Grid } from "@material-ui/core"

// Material UI icons
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd"

// Styles
const useStyles = makeStyles({
	recruiterGridItem: {
		display: "flex",
		alignItems: "center",
	},
	recruiterMsgGridItem: {
		width: "100%",
	},
	recruiterChip: {
		height: "42px !important",
		borderBottomLeftRadius: "0px !important",
		position: "absolute",
		top: 23,
		backgroundColor: "#fcfcfc",
	},
	recruiterAvatar: {
		height: "35px !important",
		width: "35px !important",
		marginLeft: "3px !important",
	},
	recruiterImg: {
		height: 50,
		width: 50,
		boxShadow: "3px 3px 10px rgba(0,0,0,0.4)",
	},
	recruiterMsg: {
		backgroundColor: "#e8b300",
		padding: 10,
		paddingTop: 20,
		borderRadius: 5,
		border: "1px solid rgba(0,0,0,0)",
		borderTopLeftRadius: 0,
		marginBottom: 10,
	},
	dropZone: {
		backgroundColor: "rgba(0,0,0,0.1)",
		minHeight: 50,
		display: "flex",
		flexDirection: "column",
		borderRadius: 5,
		border: "1px dashed black",
		justifyContent: "center",
		cursor: "pointer",
		marginBottom: 10,
	},
	dropZoneInfo: {
		margin: 10,
	},
	dropZoneLabel: {
		margin: "10px 0px",
		color: "rgba(0, 0, 0, 0.54)",
		fontSize: 12,
	},
	yourScanLabel: {
		wordBreak: "break-all",
	},
	applyBtnContainer: {
		display: "flex",
		justifyContent: "center",
	},
	applyBtn: {
		color: "white",
		width: 200,
	},
})

// Component
const FilePicker = props => {
	// Hooks
	const styles = useStyles()
	const dispatch = useDispatch()

	// Destructuring props
	const { setDialogOpen, disableApplyBtn } = props

	// Redux selectors
	const jobsReducer = useSelector(state => state.jobsReducer)
	const recruiterData = useSelector(
		state => state.profileReducer.recruiterData
	)

	// Local state
	const [newFiles, setNewFiles] = useState([])
	const [recruiterPhotoAvailable, setRecruiterPhotoAvailable] =
		useState(false)

	// Dropzone settings
	const onDrop = useCallback(acceptedFiles => {
		setNewFiles(acceptedFiles)
	}, [])

	const { fileRejections, getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: "image/jpeg, image/png, application/pdf, application/msword",
		maxFiles: 1,
		maxSize: 10485760,
	})

	// File Rejections Feedback
	useEffect(() => {
		if (
			fileRejections.length === 1 &&
			fileRejections[0].errors &&
			fileRejections[0].errors[0].code === "file-invalid-type"
		) {
			dispatch(
				notificationsActions.sendNotificationAction({
					open: true,
					message:
						"Unterstützte Dateitypen sind: .pdf .doc .jpg und .png",
					type: "info",
				})
			)
		} else if (
			fileRejections.length === 1 &&
			fileRejections[0].errors &&
			fileRejections[0].errors[0].code === "file-too-large"
		) {
			dispatch(
				notificationsActions.sendNotificationAction({
					open: true,
					message: "Die Datei darf nicht größer als 10 MB sein.",
					type: "info",
				})
			)
		} else if (fileRejections.length > 1) {
			dispatch(
				notificationsActions.sendNotificationAction({
					open: true,
					message: "Du kannst nur 1 Datei hochladen.",
					type: "info",
				})
			)
		}
	}, [fileRejections, dispatch])

	// Recruiter photo available
	useEffect(() => {
		if (recruiterData?.photoUrl) {
			setRecruiterPhotoAvailable(true)
		}
	}, [recruiterData])

	// Render
	return (
		<section className="container">
			<Grid container>
				{recruiterPhotoAvailable && (
					<Grid item className={styles.recruiterGridItem}>
						<Chip
							size="medium"
							label={recruiterData.first_name}
							className={styles.recruiterChip}
							variant="outlined"
							avatar={
								<Avatar className={styles.recruiterAvatar}>
									<img
										src={recruiterData.photoUrl}
										alt="recruiter"
										className={styles.recruiterImg}
									/>
								</Avatar>
							}
						/>
					</Grid>
				)}
				<Grid item className={styles.recruiterMsgGridItem}>
					<p className={styles.recruiterMsg}>
						Für diese Stelle benötigen wir deine Examensurkunde. Du
						kannst uns diese aber auch nach der Bewerbung
						unkompliziert zukommen lassen.
					</p>
				</Grid>
				<Grid item xs={12} className={styles.fileUploadGridItem}>
					<p className={styles.dropZoneLabel}>Urkunde-Scan</p>
					<div {...getRootProps({ className: styles.dropZone })}>
						<input
							id="certificationDropZone"
							{...getInputProps()}
						/>
						<p className={styles.dropZoneInfo}>
							Ziehe und lege die Dateien hierher oder klicke, um
							den Scan hochzuladen.
						</p>
						<p className={styles.dropZoneInfo}>
							Smartphone-Nutzer: Wenn Du auf diesen Bereich
							klickst, kannst Du ein Foto von Deinem Scan machen
							und es in einem Rutsch hochladen.
						</p>
					</div>
				</Grid>
			</Grid>

			{/* Apply Btn */}
			<Grid container>
				<Grid item xs={12}>
					{newFiles.length === 1 && (
						<p className={styles.yourScanLabel}>
							Dein Scan: {newFiles[0].path}
						</p>
					)}
				</Grid>
				<Grid item xs={12} className={styles.applyBtnContainer}>
					<Button
						className={styles.applyBtn}
						id="examApplyBtn"
						variant="contained"
						color="secondary"
						size="large"
						startIcon={<AssignmentIndIcon />}
						onClick={() => {
							if (newFiles.length === 1) {
								const fileToUpload = newFiles[0]
								const fileName = newFiles[0].name
								setNewFiles([])

								dispatch(
									profileActions.asyncPostAttachmentAction(
										fileToUpload,
										fileName,
										false,
										true,
										jobsReducer.job.zoho_job_openings_id
									)
								)
							} else {
								dispatch(
									jobsActions.asyncAssociateCandidateAction(
										jobsReducer.job.zoho_job_openings_id
									)
								)
							}

							setDialogOpen(false)
							disableApplyBtn(true)
						}}
					>
						Bewerben
					</Button>
				</Grid>
			</Grid>
		</section>
	)
}

export default FilePicker
